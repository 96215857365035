/**
 * @file inspired by https://github.com/marmelab/ra-datagrid/blob/main/packages/ra-datagrid/src/Datagrid.tsx
 */

import {
  DatagridClasses,
  DatagridConfigurableProps,
  DatagridProps,
  DatagridRoot,
  PreferenceKeyContextProvider,
  useListContext,
  useResourceContext,
} from 'react-admin'

import { Skeleton } from '@mui/material'
import {
  DataGridPro,
  DataGridProProps,
  GridApiContext,
  GridColumnsInitialState,
  GridInitialState,
  useGridApiRef,
} from '@mui/x-data-grid-pro'

import { clsx } from 'clsx'
import { times } from 'lodash-es'

import {
  ColumnsHookOptions,
  PreferredColumnVisiblityHookOptions,
  useColumns,
  useEditHandlers,
  usePaginationModel,
  usePreferredColumnOrder,
  usePreferredColumnVisibility,
  usePreferredColumnWidths,
  usePreferredDensity,
  useSortModel,
} from './hooks'
import { RABulkActionsToolbar } from './RABulkActionsToolbar'

import styles from './MuiDatagrid.module.scss'

const DEFAULT_SKELETONS_COUNT = 10

export interface MuiDatagridGridColumnsInitialState extends Partial<GridColumnsInitialState> {
  /**
   * @deprecated Not supported by MuiDatagrid — use the `omit` prop instead
   *
   * @see {@link MuiDatagridProps.omit}
   */
  columnVisibilityModel?: GridColumnsInitialState['columnVisibilityModel']
}

export interface MuiDatagridGridInitialState extends GridInitialState {
  columns?: MuiDatagridGridColumnsInitialState
}

export interface MuiDatagridProps
  extends Omit<DataGridProProps, 'columns' | 'rows'>,
    Pick<DatagridProps, 'bulkActionButtons' | 'sx' | 'className'>,
    Pick<DatagridConfigurableProps, 'preferenceKey'>,
    Pick<ColumnsHookOptions, 'children' | 'columnDefaults'>,
    Pick<PreferredColumnVisiblityHookOptions, 'omit'> {
  initialState?: MuiDatagridGridInitialState
}

/**
 * Use a Material UI `<DatagridPro>` as a React Admin `<Datagrid>`
 *
 * Built to be used with `<Column>` components as children
 *
 * @example
 *
 * ```tsx
 * import { List, TextField } from 'react-admin'
 * import { MuiDatagrid, Column } from 'components/MuiDatagrid'
 *
 * <List>
 *   <MuiDatagrid>
 *     <Column width={200}><TextField source="foo" /></Column>
 *     <Column width={300}><TextField source="bar" /></Column>
 *   </MuiDatagrid>
 * </List>
 * ```
 *
 * @see [MUI `<DataGridPro>` documentation](https://mui.com/x/react-data-grid/#commercial-version)
 * @see [React Admin `<Datagrid>` documentation](https://marmelab.com/react-admin/Datagrid.html)
 */
export const MuiDatagrid = ({
  apiRef: customApiRef,
  preferenceKey: customPreferenceKey,
  ...props
}: MuiDatagridProps) => {
  const defaultApiRef = useGridApiRef()
  const apiRef = customApiRef ?? defaultApiRef

  const resource = useResourceContext()
  const preferenceKey = `preferences.${customPreferenceKey ?? `${resource}.datagrid`}`

  return (
    <PreferenceKeyContextProvider value={preferenceKey}>
      <GridApiContext.Provider value={apiRef}>
        <MuiDatagridWithContext apiRef={apiRef} {...props} />
      </GridApiContext.Provider>
    </PreferenceKeyContextProvider>
  )
}

const MuiDatagridWithContext = ({
  children,
  bulkActionButtons,
  columnDefaults,
  omit,
  sx,
  className,
  ...muiProps
}: Omit<MuiDatagridProps, 'preferenceKey'>) => {
  const {
    // data
    data,
    total,
    error,
    isLoading,

    // selection
    selectedIds,
    onSelect,
  } = useListContext()

  const { columns } = useColumns({ children, columnDefaults })
  // const { filterModel, onFilterModelChange } = useFilterModel() // TODO: Implement filtering
  const { page, pageSize, onPageChange, onPageSizeChange } = usePaginationModel()
  const { onCellEditCommit } = useEditHandlers()
  usePreferredDensity()
  usePreferredColumnOrder()
  usePreferredColumnVisibility({ omit })
  usePreferredColumnWidths()
  useSortModel()

  // Bulk actions are only disabled if explicitly set to false
  const hasBulkActions = bulkActionButtons !== false

  const skeletonColumns = columns.map((column) => ({
    ...column,
    renderCell: () => <Skeleton variant="text" width="100%" height="60%" />,
  }))

  const skeletonRows = times(pageSize ?? DEFAULT_SKELETONS_COUNT, (index) => ({
    id: index,
    ...columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.field]: index,
      }),
      {},
    ),
  }))

  return (
    <DatagridRoot sx={sx} className={clsx(DatagridClasses.root, className)}>
      <RABulkActionsToolbar bulkActionButtons={bulkActionButtons} />
      <DataGridPro
        /**
         * Columns */
        columns={isLoading ? skeletonColumns : columns}
        /**
         * Data */
        rows={isLoading ? skeletonRows : data ?? []}
        rowCount={total ?? 0}
        error={error}
        loading={false}
        /**
         * Sorting */
        sortingMode="server"
        disableMultipleColumnsSorting
        /**
         * Pagination */
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        /**
         * TODO: Implement Filtering */
        // filterMode="server"
        // filterModel={filterModel}
        // onFilterModelChange={onFilterModelChange}
        /**
         * Selection */
        disableSelectionOnClick
        checkboxSelection={hasBulkActions}
        hideFooterSelectedRowCount
        selectionModel={selectedIds}
        onSelectionModelChange={onSelect}
        /**
         * Editing */
        onCellEditCommit={onCellEditCommit}
        /**
         * UI */
        autoHeight
        hideFooter
        {...muiProps}
        className={styles.datagrid}
      />
    </DatagridRoot>
  )
}
