import { ThemeOptions } from '@mui/material'

import Typography from 'styles/modules/_Typography.scss'

export const tooltipsConfig: ThemeOptions = {
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },

      styleOverrides: {
        tooltip: {
          fontSize: Typography.smallFontSize,
        },
      },
    },
  },
}
