import { UpdateParams } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { FollowUpType } from 'types/records.types'

import { FollowUpFormValues } from './FollowUpForm'

// SEE: https://docs.people.assurance.com/#mutation-update_follow_up_appointments
export const UPDATE_FOLLOW_UP_APPOINTMENT = gql`
  mutation Update_follow_up_appointments($follow_up: UpdateFollowUpAppointmentInput!, $id: uuid!) {
    data: update_follow_up_appointments(follow_up: $follow_up, id: $id) {
      returning {
        appointment_type
        created_at
        duration_minutes
        id
        opportunity_id
        scheduled_time
        send_sms_confirmation
        updated_at
      }
    }
  }
`

// SEE: https://docs.people.assurance.com/#mutation-update_opportunities
export const UPDATE_FOLLOW_UP_REMINDER = gql`
  mutation Update_follow_up_reminders($follow_up: UpdateFollowUpReminderInput!, $id: uuid!) {
    data: update_follow_up_reminders(follow_up: $follow_up, id: $id) {
      returning {
        created_at
        duration_minutes
        id
        opportunity_id
        reminder_type
        scheduled_time
        updated_at
      }
    }
  }
`

export const updateFollowUpQuery: CustomQuery<FetchType.UPDATE> = async ({
  client,
  clientOptions,
  parseResponse,
  params,
}) => {
  const {
    data: { id, sub_type, scheduled_time, duration_minutes, note, send_sms_confirmation, type },
  } = params as UpdateParams<FollowUpFormValues>

  const variables = {
    id,
    follow_up: {
      scheduled_time,
      duration_minutes,
      note: note ?? '',
      ...(type === FollowUpType.APPOINTMENT && {
        send_sms_confirmation,
        appointment_type: sub_type,
      }),
      ...(type === FollowUpType.REMINDER && { reminder_type: sub_type }),
    },
  }

  const response = await client.mutate({
    ...clientOptions,

    mutation:
      type === FollowUpType.APPOINTMENT ? UPDATE_FOLLOW_UP_APPOINTMENT : UPDATE_FOLLOW_UP_REMINDER,
    variables,
  })

  return parseResponse(response)
}
