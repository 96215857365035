/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Inspired by [`ra-data-hasura` makeNestedTarget](https://github.com/hasura/ra-data-hasura/blob/064a5ad20524b511b82d760e800f267915adf1e6/src/buildVariables/makeNestedTarget.ts)
 */

import { set } from 'lodash-es'

type TargetEquals = {
  _eq: any
}

type NestedTarget<K> =
  | K
  | {
      [key: string]: K | NestedTarget<K>
    }

/**
 * @example
 * // This simple example should make clear what this function does
 *
 * makeNestedTarget("a.b", 42)
 * makeNestedTarget("a#b", 42)
 * // => { a: { b: { _eq: 42 } } }
 *
 * makeNestedTarget("a#b@_contains@c#d", id)
 * // => { a: { b: { _contains: { c: { d: 42 } } } } }
 *
 * // . -> # to make nested filtering support the same separator/standard
 */
export const makeNestedTarget = (
  target: string,
  id: string | number,
): NestedTarget<TargetEquals> => {
  const [path, operation = '_eq', oppath] = target.split('@')
  const value = oppath
    ? set(
        {},
        oppath
          .split('.')
          .join('#') // nested filtering support the same standard
          .split('#'),
        id,
      )
    : id

  return set({}, path.split('.').join('#').split('#'), {
    [operation]: value,
  }) as NestedTarget<TargetEquals>
}
