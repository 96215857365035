import { useCallback, useEffect, useState } from 'react'

import { ConfigurableDatagridColumn, usePreference } from 'react-admin'

import { GridColDef, useGridApiContext } from '@mui/x-data-grid-pro'

import { isEqual } from 'lodash-es'

import { isEqualIgnoringSort } from 'lib/helpers/comparison-helpers'

/**
 * Synchronize/store column order with React Admin preferences
 */
export const usePreferredColumnOrder = () => {
  const [initialized, setInitialized] = useState(false)
  const apiRef = useGridApiContext()

  const [preferredOrder, _setPreferredOrder] = usePreference<ConfigurableDatagridColumn[]>(
    // Must match the key used in React Admin <SelectColumnsButton>:
    // https://github.com/marmelab/react-admin/blob/160841a7/packages/ra-ui-materialui/src/list/datagrid/DatagridConfigurable.tsx#L136
    'availableColumns',
    [],
  )
  const [, setPreferredVisible] = usePreference('columns')

  const setPreferredOrder = useCallback(
    (columnDefinitions: GridColDef[]) => {
      _setPreferredOrder(
        columnDefinitions.map(({ field, headerName }) => ({
          index: field,
          source: field,
          label: headerName,
        })),
      )
    },
    [_setPreferredOrder],
  )

  useEffect(
    function initializePreference() {
      if (initialized) return

      const allColumns = apiRef.current.getAllColumns()
      const cachedPreferencesAreValid = isEqualIgnoringSort(
        preferredOrder.map((c) => c.source),
        allColumns.map((c) => c.field),
      )

      if (cachedPreferencesAreValid) return

      // Cached preferences are invalid, so reset them
      setPreferredOrder(allColumns)
      setPreferredVisible(undefined)
      setInitialized(true)
    },
    [apiRef, initialized, preferredOrder, setPreferredOrder, setPreferredVisible],
  )

  useEffect(
    function onMUIColumnOrderChange() {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
        const currentColumns = apiRef.current.getAllColumns()

        const matchesPreferredOrder = isEqual(
          currentColumns.map((c) => c.field),
          preferredOrder.map((c) => c.source),
        )

        if (matchesPreferredOrder) return

        setPreferredOrder(currentColumns)
      })
    },
    [apiRef, preferredOrder, setPreferredOrder],
  )

  useEffect(
    function onRAColumnOrderChange() {
      if (!preferredOrder.length) return

      const currentColumns = apiRef.current.getAllColumns()
      const matchesCurrentOrder = isEqual(
        preferredOrder.map((c) => c.source),
        currentColumns.map((c) => c.field),
      )

      if (matchesCurrentOrder) return

      const hasCheckboxSelectColumn = currentColumns.some((c) => c.type === 'checkboxSelection')
      const offset = hasCheckboxSelectColumn ? 1 : 0

      preferredOrder.forEach(({ index: field }, index) => {
        apiRef.current.setColumnIndex(field, index + offset)
      })
    },
    [apiRef, preferredOrder],
  )

  return [preferredOrder, setPreferredOrder] as const
}
