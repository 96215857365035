import { isCurrentPageTwoIframesDeep } from 'lib/helpers/iframe-helpers'
import { PersonRecord } from 'types/records.types'

import { DEPLOY_ENV, DeployEnv, DIALER_URL } from './environment-variables'

/**
 * Is the dialer available/are we loaded within the dialer?
 *
 * HACK: Due to browser security restrictions, we can’t check if we’re loaded on
 * `DIALER_URL` directly. We also can’t access any dialer APIs directly from a
 * nested iFrame. So our best guess is if we’re loaded two iFrames deep. ¯\_(ツ)_/¯
 */
export const isDialerAvailable = isCurrentPageTwoIframesDeep

// Test phone number for use in development, recommended by Dialer team
export const TEST_PHONE_NUMBER = `+1${815_475_5345}`

export const ErrorMessage = {
  WINDOW_TOP_UNDEFINED: 'Unable to send call event; `window.top` is not defined',
  NOT_IN_DIALER_IFRAME: `Unable to send call event; this page must be loaded within ${DIALER_URL} to place calls.`,
}

/**
 * Event names for the agent_dialer project. Intended to be used with the
 * `window.postMessage` API.
 *
 * IMPORTANT: Must match the events defined in the agent_dialer project
 *
 * SEE: https://github.com/assuranceiq/agent_dialer
 */
export enum DialerEvents {
  OPPORTUNITY_CALLBACK = 'opportunity_callback',
}

// Extrapolated from insurance repo dialer JS:
// See https://github.com/assuranceiq/insurance/blob/5df09403/app/assets/javascripts/agent/dialer.coffee.erb#L231-L251
export const startCall = (
  {
    lead_id,
    lead_type,
    fname,
    lname,
    birth_date,
    gender,
    contact_method: { email, phone: realPhone, city, state, zip },
  }: PersonRecord,
  leadMetaData: Record<string, unknown> = {},
) => {
  if (!window.top) throw new Error(ErrorMessage.WINDOW_TOP_UNDEFINED)
  if (!isCurrentPageTwoIframesDeep) throw new Error(ErrorMessage.NOT_IN_DIALER_IFRAME)

  const fullName = fname && lname ? `${fname} ${lname}` : undefined
  const phone = DEPLOY_ENV === DeployEnv.PRODUCTION ? realPhone : TEST_PHONE_NUMBER

  if (DEPLOY_ENV !== DeployEnv.PRODUCTION)
    console.warn(`Calling test number (${phone}) instead of actual phone: ${realPhone}`)

  /**
   * Payload extrapolated from insurance repo dialer JS default payload and `lead_meta_data`
   * SEE: https://github.com/assuranceiq/insurance/blob/5df09403/app/assets/javascripts/agent/dialer.coffee.erb#L32-L43
   */
  const payload = {
    // NOTE: Delta might require these to be strings? Part of VEGA-327; be
    // careful before reverting
    id: lead_id.toString(),
    lead_id: lead_id.toString(),

    name: fullName,
    shopper_name: fullName,

    email,
    phone,
    phone_number: phone,

    insuranceLine: lead_type,
    insurance_line: lead_type, // Duplicated in attempt to fix VEGA-327
    isMarketingCall: true,
    utm_source: 'project-vega',

    birth_date,
    gender,

    city,
    state,
    zip,

    ...leadMetaData,
  }

  window.top.postMessage(
    JSON.stringify({
      type: DialerEvents.OPPORTUNITY_CALLBACK,
      payload,
    }),
    DIALER_URL,
  )
}
