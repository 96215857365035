import { useMemo, useState } from 'react'

import { FunctionField, RaRecord, TextFieldProps, useRecordContext } from 'react-admin'

import { ConditionalTooltip, ConditionalTooltipProps } from 'components/ConditionalTooltip'
import { NullValue } from 'components/values'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface TextEllipsisFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends TextFieldProps<RecordType> {
  /** @default '100%' */
  width?: string

  /** @default 150 */
  maxTextLength?: number

  /** Set to `false` to disable the full text tooltip on hover */
  tooltip?: boolean

  ConditionalTooltipProps?: Omit<ConditionalTooltipProps, 'children'>
}

/**
 * Truncates long text with an ellipsis, and shows the full text in a tooltip on
 * hover.
 *
 * **NOTE:** This component is memoized—props will not be updated after the
 * first render. If you need to update these props, you will need to unmount and
 * remount the component.
 */
export function TextEllipsisField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  width = '100%',
  maxTextLength = 150,
  tooltip = true,
  ConditionalTooltipProps: mutableConditionalTooltipProps,
  record: customRecord,
  ...mutableProps
}: TextEllipsisFieldProps<RecordType>) {
  const record = useRecordContext<RecordType>({ record: customRecord })
  const textValue = getData<string>(record, source, '').trim()
  const [immutableProps] = useState(mutableProps)
  const [immutableConditionalTooltipProps] = useState(mutableConditionalTooltipProps)

  const Memoized = useMemo(() => {
    if (!textValue) return <NullValue />
    const truncatedTextValue =
      textValue.length > maxTextLength
        ? `${textValue.slice(0, maxTextLength - 1).trim()}…`
        : textValue

    return (
      <ConditionalTooltip
        enabled={tooltip}
        title={truncatedTextValue}
        enterDelay={500}
        {...immutableConditionalTooltipProps}
        wrapperProps={{
          ...immutableConditionalTooltipProps?.wrapperProps,
          sx: { width, ...immutableConditionalTooltipProps?.wrapperProps?.sx },
        }}
      >
        <FunctionField
          source={source}
          noWrap={true}
          {...immutableProps}
          sx={{
            display: 'inline-block',
            width,
            ...immutableProps?.sx,
          }}
          render={() => truncatedTextValue}
        />
      </ConditionalTooltip>
    )
  }, [
    immutableConditionalTooltipProps,
    immutableProps,
    maxTextLength,
    source,
    textValue,
    tooltip,
    width,
  ])

  return Memoized
}
