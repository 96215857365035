import { useListContext, useNotify, useResourceContext, useUpdate } from 'react-admin'

import { DataGridProProps } from '@mui/x-data-grid-pro'

/**
 * NOTE: WIP — This functionality has not been tested yet.
 * Copied from https://github.com/marmelab/ra-datagrid/blob/main/packages/ra-datagrid/src/Datagrid.tsx
 * TODO: Test this hook and update this comment block.
 */
export const useEditHandlers = (): Pick<DataGridProProps, 'onCellEditCommit'> => {
  const { data } = useListContext()
  const resource = useResourceContext()
  const notify = useNotify()
  const [update] = useUpdate()

  const onCellEditCommit: DataGridProProps['onCellEditCommit'] = (params) => {
    update(
      resource,
      {
        id: params.id,
        data: {
          [params.field]: params.value,
        },
        previousData: data[Number(params.id)],
      },
      {
        onError: (error) => {
          notify(
            typeof error === 'string'
              ? error
              : (error instanceof Error && error.message) || 'ra.notification.http_error',
            {
              type: 'warning',
              messageArgs: {
                _:
                  typeof error === 'string'
                    ? error
                    : error instanceof Error && error.message
                    ? error.message
                    : undefined,
              },
            },
          )
        },
      },
    )
  }

  return {
    onCellEditCommit,
  }
}
