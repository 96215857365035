import { FocusEventHandler, useState } from 'react'

import {
  CommonInputProps,
  FieldProps,
  FieldTitle,
  InputHelperText,
  InputProps,
  RaRecord,
  useInput,
} from 'react-admin'

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Rating,
  RatingProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { IconRatingStar } from '@assuranceiq/react-icons/bold/RatingStar'
import { IconRatingStarAlternate } from '@assuranceiq/react-icons/bold/RatingStarAlternate'

import { clsx } from 'clsx'

import { TypedRaRecord } from 'types/react-admin.types'

export interface RatingInputProps<RecordType extends TypedRaRecord = RaRecord>
  extends CommonInputProps {
  className?: string
  source: NonNullable<FieldProps<RecordType>['source']>
  FormControlProps?: FormControlProps
  RatingProps?: RatingProps
}

/**
 * Input a rating from 0 to 5
 */
export const RatingInput = <RecordType extends TypedRaRecord = RaRecord>({
  className,
  defaultValue,
  format = formatRating,
  FormControlProps: { margin = 'dense', ...FormControlProps } = {},
  helperText,
  label,
  onBlur,
  onChange,
  parse,
  RatingProps: { size = 'large', ...RatingProps } = {},
  resource,
  source,
  validate,
}: RatingInputProps<RecordType>) => {
  const {
    id,
    isRequired,
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
  })

  const [focused, setFocused] = useState(false)
  const labelId = `${id}-label`

  const onRatingFocus: FocusEventHandler<HTMLSpanElement> = (event) => {
    setFocused(true)
    RatingProps?.onFocus?.(event)
  }

  const onRatingBlur: FocusEventHandler<HTMLSpanElement> = (event) => {
    setFocused(false)
    field.onBlur()
    RatingProps?.onBlur?.(event)
  }

  return (
    <StyledFormControl
      // @ts-ignore StyledFormControl definitely has a `component` prop
      component="fieldset"
      className={clsx('ra-input', `ra-input-${source}`, className)}
      margin={margin}
      error={(isTouched || isSubmitted) && invalid}
      focused={focused}
      {...FormControlProps}
    >
      <FormLabel id={labelId}>
        <FieldTitle {...{ label, source, resource, isRequired }} />
      </FormLabel>

      <RadioGroup aria-labelledby={labelId}>
        <Rating
          {...{ id, size, ...RatingProps, ...field }}
          value={Number(field.value)}
          icon={<IconRatingStar />}
          emptyIcon={<IconRatingStarAlternate />}
          onFocus={onRatingFocus}
          onBlur={onRatingBlur}
        />
      </RadioGroup>

      <FormHelperText>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </StyledFormControl>
  )
}

const CLASS_PREFIX = 'RaRatingInput'
export const RatingInputClasses = {
  label: `${CLASS_PREFIX}-label`,
}

// Copied from ra-ui-materialui/src/input/RadioButtonGroupInput.tsx:
// See https://github.com/marmelab/react-admin/blob/c238fd5a/packages/ra-ui-materialui/src/input/RadioButtonGroupInput.tsx#L285-L293
const StyledFormControl = styled(FormControl, {
  name: CLASS_PREFIX,
  overridesResolver: (_props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${RatingInputClasses.label}`]: {
    transform: 'translate(0, 5px) scale(0.75)',
    transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
  },
}))

/**
 * Converts a rating from the dataProvider to a number, which is required by the
 * Rating component
 */
const formatRating: InputProps<number>['format'] = (value: number | string) =>
  value ? Number(value) : 0
