/* eslint-disable no-restricted-imports */

import { ErrorInfo } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from 'react-error-boundary'

import {
  ErrorFallback,
  ErrorFallbackComponent,
  ErrorFallbackProps,
  FullscreenErrorFallback,
  ModalErrorFallback,
} from './ErrorFallback'

export enum ErrorBoundaryVariant {
  DEFAULT = 'default',
  FULLSCREEN = 'fullscreen',
  MODAL = 'modal',
}

export type ErrorBoundaryProps = Partial<ReactErrorBoundaryProps> & {
  variant?: `${ErrorBoundaryVariant}`

  /**
   * Set fallback props (messaging, buttons, etc) based off the error received.
   *
   * NOTE: This is only used if you are using the default fallback UI.
   */
  fallbackProps?: ErrorFallbackProps['dynamicProps']

  /**
   * If true, do not report the error to Datadog. Otherwise reports all errors.
   */
  omitFromDatadog?: (error: Error, info: ErrorInfo) => boolean | void
}

const VARIANTS: Record<ErrorBoundaryVariant, ErrorFallbackComponent> = {
  [ErrorBoundaryVariant.DEFAULT]: ErrorFallback,
  [ErrorBoundaryVariant.FULLSCREEN]: FullscreenErrorFallback,
  [ErrorBoundaryVariant.MODAL]: ModalErrorFallback,
}

/**
 * Wraps an `ErrorBoundary` around other React components to “catch” errors and
 * render a fallback UI. The component includes default fallback UIs for
 * different use cases (through the `variant` prop), but you can also provide
 * your own.
 *
 * Reports all errors to Datadog, unless `omitFromDatadog` is provided and
 * returns `true`.
 *
 * Based off the [`react-error-boundary` package](https://github.com/bvaughn/react-error-boundary)
 * and shares the same API.
 *
 * - See [`react-error-boundary` API](https://github.com/bvaughn/react-error-boundary#readme)
 */
export const ErrorBoundary = ({
  children,
  variant = ErrorBoundaryVariant.DEFAULT,
  fallbackProps,
  onError: onErrorCallback,
  omitFromDatadog,
  ...props
}: ErrorBoundaryProps) => {
  const datadog = useDatadogRUM()
  const hasCustomFallback = props.fallback || props.fallbackRender || props.FallbackComponent

  if (!hasCustomFallback) {
    const Fallback = VARIANTS[variant]

    props.fallbackRender = (props) => {
      return <Fallback dynamicProps={fallbackProps} {...props} />
    }
  }

  const sendToDatadog: ErrorBoundaryProps['onError'] = (error, info) => {
    if (omitFromDatadog?.(error, info)) return

    // SEE: https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError = new Error(error.message, { cause: error })
    renderingError.name = 'ReactRenderingError'
    renderingError.stack = info.componentStack ?? error.stack

    datadog.addError(renderingError)
  }

  const onError: ErrorBoundaryProps['onError'] = (error, info) => {
    onErrorCallback?.(error, info)
    sendToDatadog(error, info)
  }

  return (
    <ReactErrorBoundary {...(props as ReactErrorBoundaryProps)} onError={onError}>
      {children}
    </ReactErrorBoundary>
  )
}
