import { useCallback, useEffect, useMemo, useState } from 'react'

import { Validator } from 'react-admin'

import { addMinutes, roundToNearestMinutes } from 'date-fns'

import {
  displayTimeAndTimezone,
  formatLocaleDateTime,
  LOCAL_TIMEZONE,
  safeParseDate,
} from 'lib/helpers/datetime-helpers'
import { FollowUpFormValues } from 'resources/all_follow_ups/components/FollowUpForm'
import { ClientTimezone } from 'types/records.types'

export interface MinAppointmentTimeHookOptions {
  minuteInterval: number
  /** The interval to round up to */
  clientTimezone?: ClientTimezone
}

export const useMinAppointmentTime = ({
  minuteInterval,
  clientTimezone,
}: MinAppointmentTimeHookOptions) => {
  const [now, setNow] = useState(new Date())

  const minAppointmentTime = useMemo(
    () =>
      roundToNearestMinutes(addMinutes(now, minuteInterval), {
        nearestTo: minuteInterval,
      }),
    [minuteInterval, now],
  )

  /** @example "10/1/21 8:00 AM MST (7:00 AM PST)" */
  const formattedMinAppointmentTime = useMemo(() => {
    const minClientDate = formatLocaleDateTime(minAppointmentTime, {
      dateStyle: 'short',
      timeZone: clientTimezone,
    })
    const minLocalDate = formatLocaleDateTime(minAppointmentTime, {
      dateStyle: 'short',
      timeZone: LOCAL_TIMEZONE,
    })
    const minClientTime = displayTimeAndTimezone(minAppointmentTime, clientTimezone)
    const minLocalTime = displayTimeAndTimezone(minAppointmentTime, LOCAL_TIMEZONE)
    const minLocalDateTime = [
      minLocalDate !== minClientDate ? minLocalDate : '',
      minLocalTime !== minClientTime ? minLocalTime : '',
    ]
      .filter(Boolean)
      .join(' ')

    return `${minClientDate} ${minClientTime}${minLocalDateTime ? ` (${minLocalDateTime})` : ''}`
  }, [minAppointmentTime, clientTimezone])

  // Update the current time every minute
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  const validateMinAppointmentTime = useCallback<Validator>(
    (rawDateString: FollowUpFormValues['scheduled_time']) => {
      const scheduledTime = safeParseDate(rawDateString)

      if (scheduledTime < minAppointmentTime)
        return `Scheduled time cannot be in the past; must be after ${formattedMinAppointmentTime}`
    },
    [formattedMinAppointmentTime, minAppointmentTime],
  )

  return {
    /** Current time, rounded up to the nearest valid {@link minuteInterval} */
    minAppointmentTime,
    validateMinAppointmentTime,
  }
}
