import { FunctionField, List, ListProps } from 'react-admin'

import { Stack } from '@mui/material'

import { CallButton } from 'components/CallButton'
import { LastContactField, NullableTextField, PhoneField, UsStateField } from 'components/fields'
import { LinkedPersonField } from 'components/fields/LinkedPersonField'
import { FilterByColumn } from 'components/FilterByColumn'
import { Column, MuiDatagrid, MuiDatagridListActions } from 'components/MuiDatagrid'
import { OpenPlateButton } from 'components/OpenPlateButton'
import { useCurrentUser } from 'hooks'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag'
import { LABEL } from 'i18n/resource-translations'
import { DEFAULT_COLUMN_WIDTH } from 'lib/helpers/column-width-values'
import { listDefaults } from 'lib/helpers/list-helpers'
import { VIRTUAL_SOURCE } from 'lib/helpers/virtual-sources'
import { PersonsShowDrawer } from 'resources/persons/components/PersonsShowDrawer'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { SortOrder } from 'types/react-admin.types'
import { CallStatRecord } from 'types/records.types'

import { getMissedCallsList, MISSED_CALLS_SOURCE } from './MissedCallsList.queries'

export const MissedCallsList = (props: ListProps) => {
  const enableSearch = useFeatureFlag(FeatureFlag.ENABLE_CLIENT_LIST_SEARCH)
  const { currentUser } = useCurrentUser()
  const user_id = currentUser.id

  return (
    <>
      <List
        {...listDefaults({ primaryList: true })}
        {...props}
        actions={<MuiDatagridListActions />}
        sort={{ field: MISSED_CALLS_SOURCE.LAST_CONTACT, order: SortOrder.DESC }}
        queryOptions={{
          meta: {
            user_id, // Force re-fetch when ID changes
            query: getMissedCallsList({ user_id }),
          } satisfies DataProviderMeta<FetchType.GET_LIST>,
        }}
        filters={
          enableSearch
            ? [<FilterByColumn key="filter-by-column" source="prevent-key-error" alwaysOn />]
            : undefined
        }
      >
        <MuiDatagrid
          bulkActionButtons={false}
          initialState={{
            pinnedColumns: {
              left: [MISSED_CALLS_SOURCE.PERSON_FIRST_NAME],
              right: [VIRTUAL_SOURCE.ACTIONS],
            },
          }}
        >
          <Column width={DEFAULT_COLUMN_WIDTH.FULL_NAME}>
            <LinkedPersonField<CallStatRecord>
              source={MISSED_CALLS_SOURCE.PERSON}
              sortBy={MISSED_CALLS_SOURCE.PERSON_FIRST_NAME}
            />
          </Column>

          <Column width={DEFAULT_COLUMN_WIDTH.LAST_CONTACT} type="dateTime">
            <LastContactField<CallStatRecord>
              source={MISSED_CALLS_SOURCE.LAST_CONTACT}
              dispositionNameSource={MISSED_CALLS_SOURCE.DISPOSITION}
              sortByOrder={SortOrder.DESC}
            />
          </Column>

          <Column width={200}>
            <NullableTextField<CallStatRecord> source={MISSED_CALLS_SOURCE.DISPOSITION} />
          </Column>

          <Column width={DEFAULT_COLUMN_WIDTH.STATE}>
            <UsStateField<CallStatRecord> source={MISSED_CALLS_SOURCE.PERSON_STATE} />
          </Column>

          <Column width={DEFAULT_COLUMN_WIDTH.PHONE}>
            <PhoneField<CallStatRecord>
              data-dd-privacy={SessionReplayPrivacy.MASK}
              source={MISSED_CALLS_SOURCE.PERSON_PHONE}
            />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.EMAIL}>
            <NullableTextField<CallStatRecord>
              data-dd-privacy={SessionReplayPrivacy.MASK}
              source={MISSED_CALLS_SOURCE.PERSON_EMAIL}
            />
          </Column>

          <Column width={DEFAULT_COLUMN_WIDTH.LEAD_ID}>
            <NullableTextField<CallStatRecord> source={MISSED_CALLS_SOURCE.PERSON_LEAD_ID} />
          </Column>

          <Column
            field={VIRTUAL_SOURCE.ACTIONS}
            width={220}
            sortable={false}
            resizable={false}
            disableColumnMenu
          >
            <FunctionField<CallStatRecord>
              label={LABEL.ACTIONS}
              render={({ person }) => (
                <Stack direction="row" spacing={0}>
                  <OpenPlateButton person={person} />
                  <CallButton person={person} />
                </Stack>
              )}
            />
          </Column>
        </MuiDatagrid>
        <PersonsShowDrawer {...props} />
      </List>
    </>
  )
}
