import { useMemo } from 'react'

import { RadioButtonGroupInput, RadioButtonGroupInputProps } from 'react-admin'

import { useFormContext } from 'react-hook-form'

import { convertEnumToChoices } from 'lib/helpers/convert-ra-field-data'

type RequiredOption<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

export type SafeRadioButtonGroupInputProps = RequiredOption<
  RadioButtonGroupInputProps,
  'choices' | 'source'
>

/**
 * A wrapper around `<RadioButtonGroupInput>` that handles the case where the current value is not
 * in the list of choices (shows the current value as a disabled choice).
 */
export const SafeRadioButtonGroupInput = ({
  choices,
  disabled,
  format,
  source,
  ...props
}: SafeRadioButtonGroupInputProps) => {
  const { watch } = useFormContext()
  const currentValue = watch(source)
  const formattedValue = format ? format(currentValue) : currentValue

  const isInvalid = useMemo(
    () => formattedValue && !choices.find(({ id }) => id === formattedValue),
    [choices, formattedValue],
  )

  return (
    <RadioButtonGroupInput
      {...props}
      source={source}
      choices={
        isInvalid && formattedValue
          ? convertEnumToChoices({ [formattedValue]: formattedValue }) // only display invalid choice
          : choices
      }
      disabled={disabled || isInvalid}
    />
  )
}
