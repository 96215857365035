import { ListControllerProps, ListProps } from 'react-admin'

import { NoResults, NoResultsIconType } from 'components/NoResults'

export interface ListDefaultsOptions {
  /** Is this the primary list for the current route? i.e. a `<Resource list>`. */
  primaryList: boolean
}

/**
 * Default props for react-admin ListBase components
 * @see https://marmelab.com/react-admin/ListBase.html#props
 */
export const listBaseDefaults = ({
  primaryList,
}: ListDefaultsOptions): Partial<ListControllerProps> => ({
  disableSyncWithLocation: !primaryList, // Disable URL sync
  exporter: false, // Disable export button
  perPage: primaryList ? 25 : 10,

  // Disable react-admin’s sort/pagination/filter caching
  // SEE: https://marmelab.com/react-admin/List.html#storekey
  storeKey: false,
})

/**
 * Default props for react-admin List components
 * @see https://marmelab.com/react-admin/List.html#props
 */
export const listDefaults = ({ primaryList }: ListDefaultsOptions): Partial<ListProps> => ({
  ...listBaseDefaults({ primaryList }),

  empty: (
    <NoResults
      message="Please refine your filters and try again"
      icon={NoResultsIconType.EmptyBox}
    />
  ),
})
