import { createStore, createTypedHooks, EasyPeasyConfig, State } from 'easy-peasy'

import { debugModel, DebugModel } from './models/debug'
import { featureFlagsModel, FeatureFlagsModel } from './models/featureFlags'

export interface StoreModel {
  debug: DebugModel
  featureFlags: FeatureFlagsModel
}

export type StoreState = State<StoreModel>
export type StoreConfig = EasyPeasyConfig<StoreState>

export const model: StoreModel = {
  debug: debugModel,
  featureFlags: featureFlagsModel,
}

/**
 * Useful for creating a mocked store with custom injections or mockActions enabled
 *
 * @see https://easy-peasy.dev/docs/api/store-config.html#standard-configuration
 * @see https://easy-peasy.dev/docs/tutorials/testing.html#strategy-1-mocking-actions
 * @see https://easy-peasy.dev/docs/tutorials/testing.html#mocking-calls-to-services
 */
export const createVegaStore = (config: StoreConfig = {}) =>
  createStore<StoreModel, StoreState>(model, {
    ...config,

    // Increment this after major refactors to clear persisted state
    // SEE: https://easy-peasy.dev/docs/api/persist.html#managing-model-updates
    version: 1,
  })

export const store = createVegaStore()

export const { useStore, useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>()
