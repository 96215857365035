import { Resources } from 'lib/api/api.types'
import { ALL_FOLLOW_UPS_SOURCE } from 'resources/all_follow_ups/AllFollowUpsList/AllFollowUpsList.queries'
import { CLIENTS_SOURCE } from 'resources/clients/ClientsList/ClientsList.queries'
import { OPPORTUNITIES_SOURCE } from 'resources/opportunities/OpportunitiesList/OpportunitiesList.queries'
import { FollowUpType } from 'types/records.types'

export const followUpResourceByType: Record<FollowUpType, Resources> = {
  [FollowUpType.APPOINTMENT]: Resources.FOLLOW_UP_APPOINTMENTS,
  [FollowUpType.REMINDER]: Resources.FOLLOW_UP_REMINDERS,
}

/** Common descriptions across the project */
export const DESCRIPTION = {
  SALES_STAGE:
    'Representing the sales journey, Opportunity Stages help to track progress and make informed decisions',
  ENROLLMENT_PERIOD: 'This is the enrollment period the customers can be enrolling for',
} as const

/** Get the description by source */
// prettier-ignore
export const RESOURCE_DESCRIPTION = {
  [ALL_FOLLOW_UPS_SOURCE.SALES_STAGE]: DESCRIPTION.SALES_STAGE,
  [OPPORTUNITIES_SOURCE.SALES_STAGE]:  DESCRIPTION.SALES_STAGE,

  [ALL_FOLLOW_UPS_SOURCE.ENROLLMENT_PERIOD]: DESCRIPTION.ENROLLMENT_PERIOD,
  [CLIENTS_SOURCE.ENROLLMENT_PERIOD]:        DESCRIPTION.ENROLLMENT_PERIOD,
  [OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD]:  DESCRIPTION.ENROLLMENT_PERIOD,
} as const
