import { Labeled } from 'react-admin'

import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'

import { CopyToClipboard } from 'components/CopyToClipboard'
import { EnumField, NullableDateField, NullableTextField } from 'components/fields'
import { CurrencyField } from 'components/fields/CurrencyField'
import { PolicyPlanIDField } from 'components/fields/PolicyPlanIDField'
import { NoResults } from 'components/NoResults'
import { useRankedPolicySource } from 'hooks'
import { LABEL } from 'i18n/resource-translations'
import { POLICY_TYPE_DISPLAY_VALUES } from 'resources/opportunities/opportunity-constants'
import { PersonRecord } from 'types/records.types'

import { PERSON_SOURCE } from '../../PersonsShow.queries'

const TITLE = 'Policy Details'

export const PoliciesTab = () => {
  const { rankedPolicySource, hasNoPolicy } = useRankedPolicySource<PersonRecord>(
    PERSON_SOURCE.POLICY_RELATED_INFOS,
  )

  if (hasNoPolicy) return <NoResults title="No policy found" sx={{ height: '30vh' }} />

  return (
    <Card>
      <CardContent sx={{ py: 1 }}>
        <Typography variant="subtitle1">{TITLE}</Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_STATUS}>
              <NullableTextField source={`${rankedPolicySource}.normalized_data.policy_status`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_ENROLLMENT_ID}>
              <CopyToClipboard>
                <NullableTextField
                  source={`${rankedPolicySource}.normalized_data.enrollment_code`}
                />
              </CopyToClipboard>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_START_DATE}>
              <NullableDateField
                source={`${rankedPolicySource}.normalized_data.policy_start_date`}
                variant="body1"
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_TERMINATION_DATE}>
              <NullableDateField
                source={`${rankedPolicySource}.normalized_data.policy_termination_date`}
                variant="body1"
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_CARRIER}>
              <NullableTextField source={`${rankedPolicySource}.normalized_data.policy_carrier`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_NAME}>
              <NullableTextField source={`${rankedPolicySource}.normalized_data.policy_name`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_PLAN_ID}>
              <PolicyPlanIDField source={rankedPolicySource} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_TYPE}>
              <EnumField
                source={`${rankedPolicySource}.normalized_data.policy_type`}
                displayValues={POLICY_TYPE_DISPLAY_VALUES}
                variant="body2"
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_NUMBER}>
              <NullableTextField source={`${rankedPolicySource}.normalized_data.policy_number`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.ASSURANCE_POLICY_NUMBER}>
              <CopyToClipboard>
                <NullableTextField source={`${rankedPolicySource}.de_policy_id`} />
              </CopyToClipboard>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.POLICY_PREMIUM}>
              <CurrencyField source={`${rankedPolicySource}.normalized_data.policy_premium`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label={LABEL.SELLING_AGENT}>
              <NullableTextField source={`${rankedPolicySource}.normalized_data.plan_agent`} />
            </Labeled>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
