import { useMemo } from 'react'

import { AuthProvider, HttpError, UserIdentity } from 'react-admin'

import { ApolloError } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { useCurrentUser } from 'hooks/useCurrentUser'

/**
 * Returns a React Admin Auth Provider, configured for Auth0
 *
 * - See [Auth Providers documentation](https://marmelab.com/react-admin/Authentication.html)
 *
 * NOTE: This hook can only be used within an `<Auth0Provider>`
 *
 * ```tsx
 * const authProvider = useAuth0AuthProvider()
 *
 * return (
 *   <Admin authProvider={authProvider}>
 *     …
 *   </Admin>
 * )
 * ```
 */
export const useAuth0AuthProvider = () => {
  const { isAuthenticated, logout: auth0Logout } = useAuth0()
  const { currentUser } = useCurrentUser()

  const authProvider = useMemo<AuthProvider>(
    () => ({
      // This method never gets called directly—login is handled by Auth0Provider
      async login() {
        return
      },

      /**
       * @see https://marmelab.com/react-admin/AuthProviderWriting.html#logout
       */
      async logout() {
        await auth0Logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      },

      /**
       * When the user navigates, make sure that their credentials are still valid
       *
       * @see https://marmelab.com/react-admin/AuthProviderWriting.html#checkauth
       */
      async checkAuth() {
        if (!isAuthenticated) throw new Error('Not authenticated')
      },

      /**
       * When the dataProvider returns an error, check if this is an authentication
       * error (meaning we need to login).
       *
       * If this method returns a rejected promise, the user will be redirected to the login page.
       *
       * @see https://marmelab.com/react-admin/AuthProviderWriting.html#checkerror
       */
      async checkError(error) {
        if (error instanceof HttpError) {
          if (error.message.match(/Login Required/i) || [401, 403].includes(error.status)) {
            throw error
          }
        } else if (error instanceof ApolloError) {
          for (const graphQLError of error.graphQLErrors) {
            const errorCode = String(graphQLError.extensions?.code).trim()
            switch (errorCode) {
              case 'UNAUTHENTICATED':
              case 'FORBIDDEN':
                throw error
            }
          }
        }
      },

      /**
       * Get the user’s profile data
       *
       * @see https://marmelab.com/react-admin/AuthProviderWriting.html#getidentity
       */
      async getIdentity() {
        return {
          id: currentUser.id,
          fullName: currentUser.fullName,
          avatar: currentUser.picture,
        } satisfies UserIdentity
      },

      /**
       * Get the user’s permissions
       *
       * @see https://marmelab.com/react-admin/AuthProviderWriting.html#getpermissions
       */
      async getPermissions() {
        return
      },
    }),
    [auth0Logout, isAuthenticated, currentUser],
  )

  return authProvider
}
