import { Show, ShowProps, TabbedShowLayout, useShowContext, WrapperField } from 'react-admin'

import { Box, CardContent, Skeleton, Typography } from '@mui/material'

import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

import { clsx } from 'clsx'

import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag'
import { Resources } from 'lib/api/api.types'
import { PersonsToolbar } from 'resources/persons/components/PersonsToolbar'

import { getPerson } from './PersonsShow.queries'
import { OpportunitiesTab } from './tabs/OpportunitiesTab'
import { OverviewTab } from './tabs/OverviewTab'
import { PoliciesTab } from './tabs/PoliciesTab'

import styles from './PersonsShow.module.scss'

const SkeletonLoader = () => (
  <>
    <CardContent>
      <Typography variant="h5" sx={{ mb: 0.5 }}>
        <Skeleton variant="text" width="20%" />
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ display: 'flex' }}>
        Lead ID:
        <Skeleton variant="text" width="10%" sx={{ ml: 1 }} />
      </Typography>
    </CardContent>
    <Box sx={{ display: 'flex', px: 2, py: 1.5 }}>
      <Skeleton variant="text" width="10%" sx={{ mr: 2 }} />
      <Skeleton variant="text" width="10%" />
    </Box>

    <Box padding={2} sx={{ backgroundColor: Colors['bg-light'], height: '100%' }}>
      <OverviewTab isLoading={true} />
    </Box>
  </>
)

const ShowContent = () => {
  const { isLoading } = useShowContext()
  const enablePolicyStatus = useFeatureFlag(FeatureFlag.ENABLE_POLICY_STATUS, {
    previewValue: true,
  })

  if (isLoading) {
    return <SkeletonLoader />
  }

  return (
    <>
      <CardContent>
        <PersonsToolbar />
      </CardContent>
      <TabbedShowLayout
        className={styles.tabs}
        syncWithLocation={false} // TODO: We should store opened tab in URL, but syncWithLocation rewrites full path
      >
        <TabbedShowLayout.Tab label="Overview">
          <WrapperField label={false}>
            <Box padding={2}>
              <OverviewTab />
            </Box>
          </WrapperField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Pipeline">
          <WrapperField label={false}>
            <OpportunitiesTab />
          </WrapperField>
        </TabbedShowLayout.Tab>

        {enablePolicyStatus && (
          <TabbedShowLayout.Tab label="Policies">
            <WrapperField label={false}>
              <Box padding={2}>
                <PoliciesTab />
              </Box>
            </WrapperField>
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
    </>
  )
}

/** @see https://marmelab.com/react-admin/ShowTutorial.html */
export const PersonsShow = (props: ShowProps) => {
  return (
    <Show
      {...props}
      className={clsx(styles.show, props.className)}
      queryOptions={{
        meta: {
          query: getPerson,
        } as DataProviderMeta<FetchType.GET_ONE>,
      }}
      resource={Resources.PERSONS} // Hardcode to enable use in other resource contexts
    >
      <ShowContent />
    </Show>
  )
}

export default PersonsShow
