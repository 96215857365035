import {
  Show,
  ShowProps,
  SimpleShowLayout,
  SimpleShowLayoutProps,
  useRecordContext,
  useShowContext,
  WrapperField,
} from 'react-admin'

import { Box } from '@mui/material'

import { Loading } from '@assuranceiq/react-components'

import { EnumField, NullableDateField, NullableTextField } from 'components/fields'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { LABEL } from 'i18n/resource-translations'
import { Resources } from 'lib/api/api.types'
import { ALL_FOLLOW_UPS_SOURCE } from 'resources/all_follow_ups/AllFollowUpsList/AllFollowUpsList.queries'
import { EditFollowUpButton } from 'resources/all_follow_ups/components'
import { TextPreview } from 'resources/all_follow_ups/components/FollowUpForm/components/TextConfirmation'
import { FOLLOW_UP_PHASE_DISPLAY_VALUES } from 'resources/all_follow_ups/follow-up-constants'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { SortOrder } from 'types/react-admin.types'
import { AllFollowUpsRecord, FollowUpType, OpportunitySource } from 'types/records.types'

import sharedStyles from 'styles/Shared.module.scss'

import { getFollowUp } from './AllFollowUpsShow.queries'

const LOADER_HEIGHT = 424 // minimum height of content

export const AllFollowUpsShowLayout = ({
  sx,
  ...props
}: Omit<SimpleShowLayoutProps, 'children'>) => {
  const followUpRecord = useRecordContext<AllFollowUpsRecord>()
  const { isLoading } = useShowContext()

  if (isLoading) return <Loading height={LOADER_HEIGHT} justifyContent="center" width="100%" />

  return (
    <>
      <SimpleShowLayout sx={{ ...sx, pb: 0 }} {...props}>
        <NullableTextField source={ALL_FOLLOW_UPS_SOURCE.TYPE} label="Follow-up type" />

        <EnumField<AllFollowUpsRecord>
          source={ALL_FOLLOW_UPS_SOURCE.SUB_TYPE}
          displayValues={FOLLOW_UP_PHASE_DISPLAY_VALUES}
        />

        <NullableDateField<AllFollowUpsRecord>
          source={ALL_FOLLOW_UPS_SOURCE.SCHEDULED_TIME}
          label="Date & Time"
          showTooltip
          options={{
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            year: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
          }}
          variant="body1"
          sortByOrder={SortOrder.DESC}
        />

        {followUpRecord?.send_sms_confirmation && (
          <WrapperField label="Text Reminder Sent to Client">
            <TextPreview followUpRecord={followUpRecord} />
          </WrapperField>
        )}

        <WrapperField label={LABEL.NOTES}>
          <EnumField
            className={sharedStyles.disabledInputImitation}
            sx={{ width: '100%' }}
            data-dd-privacy={SessionReplayPrivacy.MASK}
            source={
              followUpRecord?.type === FollowUpType.APPOINTMENT
                ? ALL_FOLLOW_UPS_SOURCE.APPOINTMENT_NOTES
                : ALL_FOLLOW_UPS_SOURCE.REMINDER_NOTES
            }
          />
        </WrapperField>
      </SimpleShowLayout>
      <Box sx={{ p: 3, pt: 0, display: 'flex', justifyContent: 'end' }}>
        <EditFollowUpButton
          record={{
            person: followUpRecord.opportunity.person[0],
            scheduled_time: followUpRecord.scheduled_time,
            source: OpportunitySource.CLIENT_LIST,
          }}
          followUpType={followUpRecord.type}
          icon={<></>}
          label="Edit"
          ButtonProps={{
            variant: 'contained',
            color: 'primary',
          }}
        />
      </Box>
    </>
  )
}

/** @see https://marmelab.com/react-admin/ShowTutorial.html */
export const AllFollowUpsShow = (props: ShowProps) => {
  return (
    <Show
      {...props}
      queryOptions={{
        meta: {
          query: getFollowUp,
        } as DataProviderMeta<FetchType.GET_ONE>,
      }}
      resource={Resources.ALL_FOLLOW_UPS} // Hardcode to enable use in other resource contexts
    >
      <AllFollowUpsShowLayout />
    </Show>
  )
}
