import { LeadType, LOI } from 'types/records.types'

export interface ConvertEnumToChoicesOptions {
  displayNames?: Record<string, string>
}

/**
 * Convert enum to field choices (for RadioGroup, CheckboxGroup, SelectInput)
 */
export const convertEnumToChoices = (
  enumObj = {} as object,
  options?: ConvertEnumToChoicesOptions,
) => {
  const values = Object.values(enumObj)
  return values.map((item) => ({
    id: item,
    name: options?.displayNames?.[item] ?? item,
  }))
}

/**
 * Map array to field choices (for RadioGroup, CheckboxGroup, SelectInput)
 */
export const mapArrayToChoices = (array?: string[]) => {
  if (!array) return []

  return array.map((item) => ({
    id: item,
    name: item,
  }))
}

/** Converts a person/lead `lead_type` to LOI (if possible) */
export const convertLeadTypeToLOI = (leadType: LeadType) => {
  switch (leadType) {
    case LeadType.MEDICARE:
      return LOI.MEDICARE
    case LeadType.HEALTH:
      return LOI.HEALTH
    case LeadType.LIFE:
      return LOI.LIFE
    case LeadType.AUTO:
    case LeadType.HOME:
    case LeadType.MOTORCYCLE:
    case LeadType.RENT:
      return LOI.P_AND_C
    // Deprecated/invalid lead types
    case LeadType.FLEX:
    case LeadType.MORTGAGE:
    case LeadType.PET:
    default:
      console.error(`Unexpected lead type: ${leadType}; not sure how to map this to an LOI`)
      return undefined
  }
}
