import { forwardRef, useEffect, useMemo, useState } from 'react'

import { ConfigurableDatagridColumn, TextInput, TextInputProps, useListContext } from 'react-admin'

import { CircularProgress, MenuItem, Select, SelectProps, Stack } from '@mui/material'

import { IconSearch1 } from '@assuranceiq/react-icons/bold/Search1'

import { useFormContext } from 'react-hook-form'

import { PhoneInput } from 'components/inputs'
import { useColumnPreference } from 'hooks/useColumnPreference'
import { FULL_NAME_FILTER } from 'lib/helpers/virtual-sources'
import { CLIENTS_SOURCE } from 'resources/clients/ClientsList/ClientsList.queries'

import styles from './FilterByColumn.module.scss'

export interface FilterByColumnProps extends Partial<TextInputProps> {}

enum Comparator {
  Eq = '_eq',
  ILike = '_ilike',
}

const FILTERS: Record<string, Comparator> = {
  [CLIENTS_SOURCE.PERSON_FIRST_NAME]: Comparator.ILike,
  [CLIENTS_SOURCE.LEAD_ID]: Comparator.Eq,
  [CLIENTS_SOURCE.ENROLLMENT_CODE]: Comparator.Eq,
  [CLIENTS_SOURCE.PERSON_PHONE]: Comparator.ILike,
  [CLIENTS_SOURCE.PERSON_EMAIL]: Comparator.ILike,
}

const SOURCE_INPUT: Record<string, typeof PhoneInput> = {
  [CLIENTS_SOURCE.PERSON_PHONE]: PhoneInput,
}

const convertToFilterSource = (source: string | undefined) => {
  if (!source) return
  if (source === CLIENTS_SOURCE.PERSON_FIRST_NAME) return FULL_NAME_FILTER

  const comparator = FILTERS[source]
  if (!comparator) return

  return `${source.replaceAll('.', '#').replaceAll(/\[\d+\]/g, '')}@${comparator}`
}

const defaultSelectedColumn = (
  filterValues: object,
  filterColumns: ConfigurableDatagridColumn[],
) => {
  if (!filterValues || Object.keys(filterValues).length <= 0) return filterColumns?.[0]

  const filterSource = Object.keys(filterValues)[0]
  return filterColumns.find(
    ({ source }) => convertToFilterSource(source) === filterSource.replaceAll(/\[\d+\]/g, '[0]'),
  )
}

export const FilterByColumn = forwardRef<HTMLInputElement, FilterByColumnProps>(
  function FilterByColumn(props, ref) {
    const { filterValues, isFetching, isLoading, setFilters } = useListContext()
    const { getValues } = useFormContext()
    const { availableColumns } = useColumnPreference()

    const filterColumns = useMemo(
      function excludeColumnsFromFilter() {
        return availableColumns.filter(({ source: columnSource = '' }) => FILTERS[columnSource])
      },
      [availableColumns],
    )

    const [selectedColumn, setSelectedColumn] = useState<ConfigurableDatagridColumn | undefined>(
      () => defaultSelectedColumn(filterValues, filterColumns),
    )

    useEffect(
      function resetSelectedColumn() {
        if (filterColumns.find((column) => column.source === selectedColumn?.source)) return

        setFilters({}, [])
        setSelectedColumn(defaultSelectedColumn({}, filterColumns))
      },
      [filterColumns, selectedColumn?.source, setFilters],
    )

    const onFilterChange: SelectProps<string>['onChange'] = (event) => {
      const currentColumn = filterColumns.find((column) => column.source === event.target.value)

      setSelectedColumn(currentColumn)
      setFilters({}, [])
    }

    if (!selectedColumn?.source) return null

    const filterSource = selectedColumn && convertToFilterSource(selectedColumn.source)
    if (!filterSource) return null

    const InputComponent = SOURCE_INPUT[selectedColumn.source] || TextInput

    // TODO: search for combining inputs
    return (
      <Stack className={styles.root} direction="row">
        <Select
          className={styles.columns}
          value={selectedColumn?.source || filterColumns?.[0].source}
          onChange={onFilterChange}
        >
          {filterColumns.map((column) => (
            <MenuItem key={column.source} className={styles.columnOption} value={column.source}>
              {column.label}
            </MenuItem>
          ))}
        </Select>
        <InputComponent
          ref={ref}
          className={styles.input}
          label=""
          resettable
          InputProps={{
            placeholder: `Search ${selectedColumn?.label}`,
            startAdornment:
              !isLoading && isFetching && !!getValues(filterSource) ? (
                <CircularProgress className={styles.progress} size="1em" />
              ) : (
                <IconSearch1 className={styles.searchIcon} />
              ),
          }}
          {...props}
          source={filterSource || ''}
          validate={[]} // reset default values
        />
      </Stack>
    )
  },
)
