import { FC } from 'react'

import { LicenseInfo } from '@mui/x-data-grid-pro'

import { AssuranceThemeProvider } from '@assuranceiq/react-components'

import { StoreProvider } from 'easy-peasy'
import { BrowserRouter } from 'react-router-dom'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { ReactAdmin } from 'components/ReactAdmin'
import { MUI_PRO_LICENSE_KEY } from 'lib/environment-variables'
import { store } from 'lib/store'
import { theme } from 'styles/theme'

import { Auth0WithReactRouterIntegrationProvider } from './Auth0WithReactRouterIntegrationProvider'
import { DatadogProvider } from './DatadogProvider'
import { ErrorBoundaryWithAppContext } from './ErrorBoundaryWithAppContext'
import { IframeURLMessenger } from './IframeURLMessenger'
import { IntercomLauncher } from './IntercomLauncher'
import { RequireAuthentication } from './RequireAuthentication'
import { SessionRecorder } from './SessionRecorder'
import { UserDataRecorder } from './UserDataRecorder'

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY)

export const App = () => {
  return (
    <AllProviders>
      <ReactAdmin />
    </AllProviders>
  )
}

export const AllProviders: FC = ({ children }) => {
  return (
    <DatadogProvider>
      <AssuranceThemeProvider theme={theme}>
        <ErrorBoundary variant="fullscreen">
          <BrowserRouter>
            <StoreProvider store={store}>
              <Auth0WithReactRouterIntegrationProvider>
                <ErrorBoundaryWithAppContext>
                  <RequireAuthentication>
                    <UserDataRecorder />
                    <SessionRecorder />
                    {children}
                    <IntercomLauncher />
                    <IframeURLMessenger />
                  </RequireAuthentication>
                </ErrorBoundaryWithAppContext>
              </Auth0WithReactRouterIntegrationProvider>
            </StoreProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </AssuranceThemeProvider>
    </DatadogProvider>
  )
}
