import { ComponentProps, useEffect, useMemo } from 'react'

import {
  CheckboxGroupInput,
  required,
  SaveButton,
  SimpleForm,
  useGetManyReference,
  useRecordContext,
  Validator,
} from 'react-admin'

import { Stack } from '@mui/material'

import { useFormContext } from 'react-hook-form'

import { RatingInput } from 'components/inputs'
import { SafeRadioButtonGroupInput } from 'components/inputs/SafeRadioButtonGroupInput'
import { useStableDataDogAction } from 'hooks/useStableDataDogAction'
import { Resources } from 'lib/api/api.types'
import { convertEnumToChoices, mapArrayToChoices } from 'lib/helpers/convert-ra-field-data'
import { TODAY_ISO_DATE } from 'lib/helpers/datetime-helpers'
import { currentEnrollmentPeriods } from 'lib/helpers/enrollment-period-helpers'
import { LOIS, PRODUCTS, SALES_STAGES } from 'lib/record-values'
import {
  LOI_DISPLAY_VALUES,
  PRODUCT_DISPLAY_VALUES,
  SALES_STAGE_DISPLAY_VALUES,
} from 'resources/opportunities/opportunity-constants'
import { FormRecord } from 'types/forms.types'
import {
  EnrollmentPeriod,
  HealthEnrollmentType,
  HealthProduct,
  LOI,
  MedicareEnrollmentType,
  MedicareProduct,
  OpportunityRecord,
} from 'types/records.types'

export type OpportunityFormValues = FormRecord &
  Pick<
    OpportunityRecord,
    | 'id'
    | 'loi'
    | 'products'
    | 'enrollment_period'
    | 'agent_assigned_opportunity_quality'
    | 'agent_assigned_sales_stage'
  >

export interface OpportunityFormProps extends Partial<ComponentProps<typeof SimpleForm>> {
  defaultValues?: Partial<OpportunityFormValues>
}

export const OPPORTUNITY_DEFAULT_VALUES: Partial<OpportunityFormValues> = {
  products: [],
}

export const OPPORTUNITY_EXISTING_ERROR_MESSAGE =
  'Opportunity LOI/Enrollment Period error: opportunity already exists for this enrollment period'

const LOI_CHOICES = convertEnumToChoices(LOIS, { displayNames: LOI_DISPLAY_VALUES })
const SALES_STAGE_CHOICES = convertEnumToChoices(SALES_STAGES, {
  displayNames: SALES_STAGE_DISPLAY_VALUES,
})
const PRODUCT_CHOICES = LOIS.reduce((choices, loi) => {
  return {
    ...choices,
    [loi]: convertEnumToChoices(PRODUCTS[loi], { displayNames: PRODUCT_DISPLAY_VALUES }),
  }
}, {} as Record<LOI, unknown[]>)

export const OpportunityForm = ({ defaultValues, ...props }: OpportunityFormProps) => {
  return (
    <SimpleForm
      toolbar={
        <Stack direction="row" justifyContent="flex-end" padding={3}>
          <SaveButton icon={<></>} label="Save" alwaysEnable />
        </Stack>
      }
      minWidth={850}
      padding={1}
      defaultValues={{ ...OPPORTUNITY_DEFAULT_VALUES, ...defaultValues }}
      {...props}
    >
      <OpportunityFormControls />
    </SimpleForm>
  )
}

export interface OpportunityFormControlsProps {
  disabled?: boolean
}

export const OpportunityFormControls = ({ disabled }: OpportunityFormControlsProps) => {
  const { stableAddAction, resetStableActionContext } = useStableDataDogAction()
  const {
    formState: { defaultValues, dirtyFields, touchedFields },
    resetField,
    setValue,
    trigger,
    watch,
  } = useFormContext<Partial<OpportunityFormValues>>()

  const assignedAgentID = watch('agent_id')
  const products = watch('products')
  const leadId = watch('person.lead_id')
  const loi = watch('loi')
  const enrollmentPeriod = watch('enrollment_period')
  const editMode = !!watch('id')

  const record = useRecordContext()

  /**
   * NOTE: THIS SHOULD MATCH FILTER/QUERY LOGIC IN `FollowUpOpportunityWizard`
   *
   * TODO: DRY up opportunity fetching logic across `CreateOrLinkOpportunity`,
   * `LinkToExistingOpportunity`, and `OpportunityFormControls`—they should all
   * use the same opportunities data
   *
   * SEE: https://assuranceiq.atlassian.net/browse/VEGA-471
   */
  const { data: opportunities } = useGetManyReference<OpportunityRecord>(Resources.OPPORTUNITIES, {
    target: 'lead_id',
    id: record.lead_id || leadId,
    filter: {
      assigned_agent_id: assignedAgentID,
    },
  })

  // Enrollment Periods depend on LOI and Product
  // SEE: https://assuranceiq.atlassian.net/wiki/spaces/PRODUCT/pages/3199241606/Functional+Follow-up+and+Opportunity+Details#Time-Period-Details
  const enrollmentPeriodChoices = useMemo(() => {
    const availableEnrollmentPeriods = () => {
      if (!loi) return []

      const enrollmentPeriodsForLOI = currentEnrollmentPeriods()[loi]
      const selectedProduct = products?.[0]

      if (!selectedProduct) return enrollmentPeriodsForLOI

      switch (loi) {
        case LOI.HEALTH: {
          switch (selectedProduct) {
            case HealthProduct.ACA:
              return enrollmentPeriodsForLOI.filter(
                (period) => period !== HealthEnrollmentType.THIRTY_DAY,
              )
            case HealthProduct.ANCILLARY:
            case HealthProduct.STM:
              return enrollmentPeriodsForLOI.filter(
                (period) => period === HealthEnrollmentType.THIRTY_DAY,
              )
          }
          break
        }
        case LOI.MEDICARE: {
          switch (selectedProduct) {
            case MedicareProduct.MED_SUPP:
              return [MedicareEnrollmentType.IEP]
          }
          break
        }
      }

      return enrollmentPeriodsForLOI
    }

    return mapArrayToChoices(availableEnrollmentPeriods())
  }, [loi, products])

  const productChoices = useMemo(() => {
    if (!loi) return []

    return PRODUCT_CHOICES[loi] ?? []
  }, [loi])

  useEffect(
    function resetProduct() {
      if (loi === defaultValues?.loi) {
        resetField('products')
      } else {
        // unblock products for other LOIs
        setValue('products', [])
      }
    },
    [defaultValues?.loi, loi, resetField, setValue],
  )

  useEffect(
    function resetEnrollmentPeriod() {
      if (loi === defaultValues?.loi) {
        resetField('enrollment_period')
      } else {
        // unblock enrollment period for other LOIs
        setValue('enrollment_period', undefined)
      }
    },
    [defaultValues?.loi, loi, products, resetField, setValue],
  )

  useEffect(
    // otherwise the validation will be triggered on the next button click
    function triggerEnrollmentPeriodValidation() {
      if (!touchedFields.enrollment_period && !dirtyFields.enrollment_period) return

      trigger('enrollment_period').then((isValid) => {
        if (isValid) return

        setValue('enrollment_period', enrollmentPeriod, { shouldTouch: true })
      })
    },
    [
      dirtyFields.enrollment_period,
      enrollmentPeriod,
      loi,
      setValue,
      touchedFields.enrollment_period,
      trigger,
    ],
  )

  const validateEnrollmentPeriod: Validator = (value?: EnrollmentPeriod) => {
    if (!value || editMode) return

    const isNotExpired = (opportunity: OpportunityRecord) =>
      opportunity.expiration_date && opportunity.expiration_date > TODAY_ISO_DATE

    const isInvalid = !!opportunities?.find(
      (opportunity) =>
        // Exclude expired opportunities from validation
        isNotExpired(opportunity) &&
        opportunity.enrollment_period === value &&
        opportunity.loi === loi &&
        opportunity.id !== record.id,
    )

    if (isInvalid) {
      stableAddAction(OPPORTUNITY_EXISTING_ERROR_MESSAGE, { loi, enrollment_period: value })
      return `An Opportunity already exists for ${enrollmentPeriod}. Please link to the existing opportunity or select a different Enrollment Period.`
    }

    resetStableActionContext()
  }

  return (
    <>
      <SafeRadioButtonGroupInput
        source="loi"
        label="What’s the associated Line of Insurance?"
        choices={LOI_CHOICES}
        validate={required()}
        disabled={disabled || editMode}
      />
      {!!loi && loi === LOI.P_AND_C ? (
        <CheckboxGroupInput
          source="products"
          label="What’s the product type?"
          choices={productChoices}
          validate={required()}
          disabled={disabled || editMode}
        />
      ) : (
        <SafeRadioButtonGroupInput
          source="products"
          label="What’s the product type?"
          choices={productChoices}
          format={(value: Array<string>) => value[0]}
          parse={(value) => [value]}
          validate={required()}
          disabled={disabled || editMode}
        />
      )}
      <SafeRadioButtonGroupInput
        source="enrollment_period"
        label="What Enrollment Period will be used?"
        choices={enrollmentPeriodChoices}
        validate={[required(), validateEnrollmentPeriod]}
        disabled={disabled || editMode}
      />
      <SafeRadioButtonGroupInput
        source="agent_assigned_sales_stage"
        label="What’s the stage of this Opportunity?"
        choices={SALES_STAGE_CHOICES}
        validate={required()}
        row={false}
        disabled={disabled}
      />
      <RatingInput<OpportunityFormValues>
        source="agent_assigned_opportunity_quality"
        label="Chance to Close"
        format={(value) => String(value)}
        parse={(value) => Number(value)}
        validate={required()}
        FormControlProps={{
          disabled,
        }}
      />
    </>
  )
}
