import { addMinutes, startOfMinute, subMinutes } from 'date-fns'

import { safeParseDate } from './datetime-helpers'

/**
 * When messaging customers, we add some time before/after the actual appointment
 * to expect their call.
 *
 * IMPORTANT: Any changes must be synced with the person service, which generates
 * the actual text message.
 * SEE: https://github.com/assuranceiq/person-service/blob/b75552b0ae7fa9bce5b5f0b56ac645bea2e44049/vega_api/app/services/journey/appointment_sms_confirmation.rb#L5-L8
 */
export const ESTIMATED_CALL_BACK_WINDOW = {
  beforeMinutes: 30,
  afterMinutes: 60,
}

export interface AppointmentTimeRangeOptions {
  /** ISO format date string */
  date: string
  durationMinutes: number
  timeZone?: string

  /**
   * Is this being shown to a customer? If so, we pad the time range by
   * {@link ESTIMATED_CALL_BACK_WINDOW} minutes.
   */
  isCustomerEstimate?: boolean
}

/**
 * Returns a formatted date and time range for a given appointment date.
 *
 * Set {@link AppointmentTimeRangeOptions['isCustomerEstimate']} to `true` if
 * giving customers an appointment time range.
 */
export const appointmentTimeRange = ({
  date: dateArg,
  durationMinutes,
  timeZone,
  isCustomerEstimate = false,
}: AppointmentTimeRangeOptions) => {
  const dateTime = safeParseDate(dateArg)

  const date = dateTime.toLocaleString(navigator.language, {
    dateStyle: 'full',
    timeZone,
  })

  const start = subMinutes(
    startOfMinute(dateTime),
    isCustomerEstimate ? ESTIMATED_CALL_BACK_WINDOW.beforeMinutes : 0,
  )
  const end = addMinutes(
    startOfMinute(dateTime),
    isCustomerEstimate ? ESTIMATED_CALL_BACK_WINDOW.afterMinutes : durationMinutes,
  )

  const startTime = start.toLocaleTimeString(navigator.language, {
    timeStyle: 'short',
    timeZone,
  })
  const endTime = end.toLocaleTimeString(navigator.language, {
    timeStyle: 'short',
    timeZone,
  })

  return `${date} between ${startTime}-${endTime}`
}
