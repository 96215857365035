import { forwardRef } from 'react'

import { Button, ButtonProps, Tooltip, TooltipProps } from '@mui/material'
import { unstable_useId as useId } from '@mui/material/utils'

import { clsx } from 'clsx'
import { Link, To } from 'react-router-dom'

import styles from './TooltippedIconButton.module.scss'

export interface OutlinedIconButtonProps
  extends Omit<ButtonProps, 'startIcon' | 'endIcon' | 'title'> {
  /** @see https://mui.com/material-ui/guides/routing/#button */
  component?: typeof Link | string

  /** @see https://mui.com/material-ui/guides/routing/#button */
  to?: To

  title: TooltipProps['title']
  tooltipProps?: Omit<TooltipProps, 'title'>
}

/**
 * Renders an icon in a standard button, with a required tooltip. Supports all button variants.
 *
 * - [Button API](https://mui.com/material-ui/api/button/)
 * - [Tooltip API](https://mui.com/material-ui/api/tooltip/)
 */
export const TooltippedIconButton = forwardRef<HTMLButtonElement, OutlinedIconButtonProps>(
  function ForwardedTooltippedIconButton({ children, title, tooltipProps, ...buttonProps }, ref) {
    const tooltipID = useId()

    return (
      <Tooltip placement="top" enterDelay={0} {...tooltipProps} id={tooltipID} title={title}>
        <Button
          {...buttonProps}
          ref={ref}
          aria-labelledby={tooltipID}
          className={clsx(styles.button, buttonProps.className)}
          startIcon={children}
        />
      </Tooltip>
    )
  },
)
