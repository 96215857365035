import { action, Action, persist } from 'easy-peasy'

import { DEPLOY_ENV, DeployEnv } from 'lib/environment-variables'

export interface DebugModel {
  agentID: number

  setAgentID: Action<DebugModel, number>
}

// Find more agent IDs: https://assuranceiq.slack.com/archives/C04FD4JH4HF/p1684513838720609
const STAGING_DEMO_AGENT_ID = 23742 // Alex Aponte - https://staging.assurance.com/admin/users/23742/user_profile
const PROD_DEMO_AGENT_ID = 1093287 // Jordan - https://assurance.com/admin/users/1093287/user_profile

const model: DebugModel = {
  agentID: DEPLOY_ENV === DeployEnv.PRODUCTION ? PROD_DEMO_AGENT_ID : STAGING_DEMO_AGENT_ID,

  setAgentID: action((state, id) => {
    state.agentID = id
  }),
}

export const debugModel = persist(model, {
  storage: 'localStorage',
  migrations: {
    migrationVersion: 2,
    2: (state) => {
      delete state.showMockData
    },
    1: (state) => {
      state.showMockData = false
    },
  },
})
