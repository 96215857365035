import { ReactNode } from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import { Box, Tooltip } from '@mui/material'

import { useDatadogRUM } from '@assuranceiq/react-components'

export interface ErrorValueProps {
  /** The error message */
  error: string

  /**
   * Logs the error message to Datadog
   * @default true
   */
  logError?: boolean
  children?: ReactNode
}

export const ErrorValue = ({ error, children, logError = true }: ErrorValueProps) => {
  const datadog = useDatadogRUM()

  if (logError) datadog.addError(error)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={error} arrow>
        <ErrorIcon color="error" fontSize="small" />
      </Tooltip>
      {children && (
        <>
          &thinsp;
          {children}
        </>
      )}
    </Box>
  )
}
