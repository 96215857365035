import { ElementType } from 'react'

import { FunctionField, FunctionFieldProps, RaRecord } from 'react-admin'

import { NullValue } from 'components/values'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface EnumFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Partial<FunctionFieldProps<RecordType>> {
  source: Exclude<FunctionFieldProps<RecordType>['source'], undefined>

  // TypographyProps does not expose the `component` prop, see https://github.com/mui/material-ui/issues/19512
  component?: ElementType

  displayValues?: Record<string, string>
}

/**
 * Display the text field with display values. It supports arrays of strings.
 * If the value is not found in the displayValues, it will display the value itself.
 */
export function EnumField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  displayValues = {},
  ...props
}: EnumFieldProps<RecordType>) {
  return (
    <FunctionField
      render={(record: RecordType) => {
        const data = getData<string | Array<string>>(record, source)
        const arrayValue = typeof data === 'string' ? [data] : data

        if (arrayValue && arrayValue.length > 0) {
          return arrayValue.map((value) => displayValues[value] || value).join(', ')
        }

        return <NullValue />
      }}
      {...props}
    />
  )
}
