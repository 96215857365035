import { ShowInDialogButton, ShowInDialogButtonProps } from '@react-admin/ra-form-layout'

import { Box } from '@mui/material'

// TODO: It should be "react-icons/regular/View1" icon, but it's includes default fill value which can't be rewritten
import { IconView } from '@assuranceiq/react-icons/bold/View'

import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { Resources } from 'lib/api/api.types'
import { AllFollowUpsShowLayout } from 'resources/all_follow_ups/AllFollowUpsShow'
import { getFollowUp } from 'resources/all_follow_ups/AllFollowUpsShow/AllFollowUpsShow.queries'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { AllFollowUpsRecord } from 'types/records.types'

interface ShowFollowUpButtonProps extends Partial<ShowInDialogButtonProps> {
  record: AllFollowUpsRecord
}

export const ShowFollowUpButton = ({ record, ...rest }: ShowFollowUpButtonProps) => {
  return (
    <ShowInDialogButton
      icon={<IconView />}
      queryOptions={{
        meta: {
          query: getFollowUp,
        } as DataProviderMeta<FetchType.GET_ONE>,
      }}
      resource={Resources.ALL_FOLLOW_UPS} // Hardcode to enable use in other resource contexts
      title={
        <Box sx={{ mb: -3 }}>
          <span>Follow-up with </span>
          <span data-dd-privacy={SessionReplayPrivacy.MASK}>
            {record.opportunity.person[0]?.fname} {record.opportunity.person[0]?.lname}
          </span>
        </Box>
      }
      label=""
      ButtonProps={{ 'aria-label': 'View', ...rest.ButtonProps }}
      {...rest}
    >
      <AllFollowUpsShowLayout sx={{ p: 3 }} />
    </ShowInDialogButton>
  )
}
