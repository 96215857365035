import { RadioCardGroupChoice } from 'components/inputs'
import { FollowUpSubType, FollowUpType } from 'types/records.types'

export const TYPE_CHOICES: Array<RadioCardGroupChoice> = [
  {
    id: FollowUpType.APPOINTMENT,
    name: FollowUpType.APPOINTMENT,
    subtext: 'Agree on a date and time to call your Client',
  },
  {
    id: FollowUpType.REMINDER,
    name: FollowUpType.REMINDER,
    subtext: 'Create a Reminder that is only for me',
  },
]

export const FOLLOW_UP_PHASE_DISPLAY_VALUES: Record<FollowUpSubType, string> = {
  [FollowUpSubType.PRE_ENROLLMENT]: 'Pre-Enrollment',
  [FollowUpSubType.ENROLLMENT]: 'Enrollment',
  [FollowUpSubType.POST_SALE]: 'Post sale',
}
