import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { isCurrentPageInIframe } from 'lib/helpers/iframe-helpers'
import { IframeURLMessage, MessageType, sendCrmMessage } from 'lib/helpers/iframe-messaging'

export const IframeURLMessenger = () => {
  const location = useLocation()

  useEffect(() => {
    if (isCurrentPageInIframe) return

    console.warn('Current page does not appear to be in an iframe; URL messaging will be disabled')
  }, [])

  useEffect(() => {
    if (!isCurrentPageInIframe) return

    sendCrmMessage<IframeURLMessage>(MessageType.GET_IFRAME_URL, {
      url: window.location.href,
    })
  }, [location])

  return null
}
