import { ChangeEventHandler, useState } from 'react'

import { ConfigurableDatagridColumn, FieldToggle } from 'react-admin'

import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import { Box, ButtonProps, Popover, PopoverProps } from '@mui/material'

import { TooltippedIconButton } from 'components/TooltippedIconButton'
import { useColumnPreference } from 'hooks/useColumnPreference'

export interface MuiDatagridColumnsButtonProps extends ButtonProps {
  resource?: string
  preferenceKey?: string
}

/**
 * Renders a button that lets users show/hide and reorder columns in a MuiDatagrid
 */
export const MuiDatagridColumnsButton = ({
  resource: customResource,
  preferenceKey: customPreferenceKey,
  ...buttonProps
}: MuiDatagridColumnsButtonProps) => {
  const { availableColumns, setAvailableColumns, columns, setColumns } = useColumnPreference(
    customResource,
    customPreferenceKey,
  )
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const onClick: ButtonProps['onClick'] = (event): void => {
    setAnchorEl(event.currentTarget)
  }

  const onClose: PopoverProps['onClose'] = (): void => {
    setAnchorEl(undefined)
  }

  const onToggle: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.checked) {
      // add the column at the right position
      setColumns(
        availableColumns
          .filter((column) => column.index === event.target.name || columns.includes(column.index))
          .map((column) => column.index),
      )
    } else {
      setColumns(columns.filter((index) => index !== event.target.name))
    }
  }

  const onMove = (
    index1: ConfigurableDatagridColumn['index'],
    index2: ConfigurableDatagridColumn['index'],
  ) => {
    const index1Pos = availableColumns.findIndex((field) => field.index === index1)
    const index2Pos = availableColumns.findIndex((field) => field.index === index2)

    if (index1Pos === -1 || index2Pos === -1) return

    const newAvailableColumns =
      index1Pos > index2Pos
        ? [
            ...availableColumns.slice(0, index2Pos),
            availableColumns[index1Pos],
            ...availableColumns.slice(index2Pos, index1Pos),
            ...availableColumns.slice(index1Pos + 1),
          ]
        : [
            ...availableColumns.slice(0, index1Pos),
            ...availableColumns.slice(index1Pos + 1, index2Pos + 1),
            availableColumns[index1Pos],
            ...availableColumns.slice(index2Pos + 1),
          ]

    setAvailableColumns(newAvailableColumns)
    setColumns((columns) =>
      newAvailableColumns
        .filter((column) => columns.includes(column.index))
        .map((column) => column.index),
    )
  }

  return (
    <>
      <TooltippedIconButton title="Table Columns" {...buttonProps} onClick={onClick}>
        <ViewWeekIcon />
      </TooltippedIconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={1}>
          {availableColumns.map((column) => (
            <FieldToggle
              key={column.index}
              source={column.source}
              label={column.label}
              index={column.index}
              selected={columns.includes(column.index)}
              onToggle={onToggle}
              onMove={onMove}
            />
          ))}
        </Box>
      </Popover>
    </>
  )
}
