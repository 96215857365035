import { CheckboxGroupInputClasses, RadioButtonGroupInputClasses } from 'react-admin'

import { outlinedInputClasses, ThemeOptions } from '@mui/material'

import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

import { RatingInputClasses } from 'components/inputs'

import Input from 'styles/modules/_Input.scss'
import Spacing from 'styles/modules/_Spacing.scss'
import Typography from 'styles/modules/_Typography.scss'

// TODO: Move to ARC
export const inputsConfig = {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: Colors['gray-100'],
          fontWeight: Typography.fontWeightSemiBold,
          marginBottom: '.5em',

          [`&.MuiInputLabel-root, &.${RadioButtonGroupInputClasses.label}, &.${RatingInputClasses.label}`]:
            {
              transform: 'unset',
              position: 'unset',
            },

          '&.Mui-focused': {
            color: Colors['gray-100'],
          },
          // Selector for reqiured asterisk
          '> span > span[aria-hidden="true"]': {
            color: Colors['error'],
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: -parseInt(Spacing.unit) / 2,
        },
      },
    },

    // NOTE: padding ovverides not wotking with MuiInputBase
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0',
        },

        input: {
          padding: `${parseInt(Spacing.unit) * 1.5}px ${parseInt(Spacing.unit) * 2}px`,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: Colors['common-white'],
          [`fieldset.${outlinedInputClasses.notchedOutline}`]: {
            legend: {
              width: 0, // Hide legend cutout/notch
            },
          },

          '&.Mui-error': {
            backgroundColor: Colors['error-light-accent'],
            color: Colors['error'],
          },

          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            boxShadow: Input['focused-input-box-shadow'],
            borderWidth: '1px',
          },

          '&.Mui-disabled': {
            backgroundColor: Colors['bg-light'],
          },
        },

        input: {
          '&.Mui-disabled': {
            webkitTextFillColor: Colors['text-muted'],
          },
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {
        shrink: true, // Always display label above input
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    // @ts-ignore React-admin components are not typed

    RaRadioButtonGroupInput: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          [`& .${RadioButtonGroupInputClasses.label}`]: {
            transform: 'unset',
          },
        },
      },
    },

    RaCheckboxGroupInput: {
      styleOverrides: {
        root: {
          [`& .${CheckboxGroupInputClasses.label}`]: {
            transform: 'unset',
          },
        },
      },
    },

    RaWizardFormStep: {
      styleOverrides: {
        root: {
          '& legend': {
            display: 'initial',
          },
          '& > legend': {
            display: 'none',
          },
        },
      },
    },

    RaSimpleForm: {
      styleOverrides: {
        root: {
          // Reduce spacing between save button
          paddingBottom: 0,
        },
      },
    },
  },
} as const satisfies ThemeOptions
