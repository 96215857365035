import { DataProvider } from 'react-admin'

import { ApolloClient, MutationOptions, NormalizedCacheObject, QueryOptions } from '@apollo/client'

import { FetchType } from 'hooks/useDataProvider'
import { Resources } from 'lib/api/api.types'

import { buildQuery } from './buildQuery'
import { buildVariables } from './buildVariables'
import { getResponseParser } from './getResponseParser'
import {
  GetFetchParams,
  GetFetchType,
  MutationFetchParams,
  MutationFetchType,
  MutationOptionsParams,
  QueryOptionsParams,
  UseDataProviderParams,
} from './useDataProvider.types'

const queryOptions = (params: QueryOptionsParams): QueryOptions => {
  return {
    query: buildQuery(params),
    variables: buildVariables(params.resource, params.fetchType, params.params),

    // SEE: https://github.com/marmelab/react-admin/blob/master/packages/ra-data-graphql/README.md#when-i-create-or-edit-a-resource-the-list-or-edit-page-does-not-refresh-its-data
    fetchPolicy: 'network-only',
  }
}

const mutationOptions = (params: MutationOptionsParams): MutationOptions => {
  return {
    mutation: buildQuery(params),
    variables: buildVariables(params.resource, params.fetchType, params.params),
  }
}

const buildQueryMethod =
  (client: ApolloClient<NormalizedCacheObject>, fetchType: GetFetchType) =>
  async (resource: Resources, params: GetFetchParams) => {
    const clientOptions = queryOptions({ resource, params, fetchType })
    const parseResponse = getResponseParser(fetchType)

    if (params.meta?.query) {
      return params.meta.query({ resource, params, client, clientOptions, parseResponse })
    }

    const response = await client.query(clientOptions)

    return parseResponse(response)
  }

const buildMutationMethod =
  (client: ApolloClient<NormalizedCacheObject>, fetchType: MutationFetchType) =>
  async (resource: Resources, params: MutationFetchParams) => {
    const clientOptions = mutationOptions({ resource, params, fetchType })
    const parseResponse = getResponseParser(fetchType)

    if (params.meta?.query) {
      return params.meta.query({ resource, params, client, clientOptions, parseResponse })
    }

    const response = await client.mutate(clientOptions)

    return parseResponse(response.data)
  }

export const useDataProvider = ({ client }: UseDataProviderParams) => {
  /**
   * Cast to `DataProvider<string>` to satisfy
   * {@link https://github.com/marmelab/react-admin/blob/a944fbd4a62f4f24ca9a277b9ab249751f01700d/packages/ra-core/src/types.ts#L87 | dataProvider}
   * prop
   */
  const provider = {
    getList: buildQueryMethod(client, FetchType.GET_LIST),
    getOne: buildQueryMethod(client, FetchType.GET_ONE),
    getMany: buildQueryMethod(client, FetchType.GET_MANY),
    getManyReference: buildQueryMethod(client, FetchType.GET_MANY_REFERENCE),

    create: buildMutationMethod(client, FetchType.CREATE),
    update: buildMutationMethod(client, FetchType.UPDATE),
    updateMany: buildMutationMethod(client, FetchType.UPDATE_MANY),
    delete: buildMutationMethod(client, FetchType.DELETE),
    deleteMany: buildMutationMethod(client, FetchType.DELETE_MANY),
  } satisfies DataProvider<Resources> as DataProvider<string> // See comment above

  return provider
}
