export const requireEnvVar = (value?: string) => {
  if (typeof value === 'undefined')
    throw new Error('Environment variable is undefined—make sure to add it to your .env file.')

  return value
}

/**
 * Possible values for the `DEPLOY_ENV` environment variable.
 *
 * @example
 *   // Check if on a preview build
 *   DEPLOY_ENV === DeployEnv.PREVIEW
 */
export enum DeployEnv {
  DEVELOPMENT = 'development',
  PREVIEW = 'preview',
  INTEGRATION = 'integration',
  PRODUCTION = 'production',
}

/**
 * The current deploy environment (e.g. `"integration"`)
 *
 * Note that this is different than `NODE_ENV`, which defines the current
 * build/compile environment (e.g. `"development"`/`"production"`)
 *
 * See [Webpack docs on `NODE_ENV`](https://webpack.js.org/guides/production/#specify-the-mode)
 */
export const DEPLOY_ENV = requireEnvVar(process.env.REACT_APP_DEPLOY_ENV).toLowerCase() as DeployEnv

export const AUTH0_DOMAIN = requireEnvVar(process.env.REACT_APP_AUTH0_DOMAIN)
export const AUTH0_CLIENT_ID = requireEnvVar(process.env.REACT_APP_AUTH0_CLIENT_ID)
export const AUTH0_RESPONSE_TYPE = requireEnvVar(process.env.REACT_APP_AUTH0_RESPONSE_TYPE)

// Datadog
export const DD_APPLICATION_ID = requireEnvVar(process.env.REACT_APP_DD_APPLICATION_ID)
export const DD_CLIENT_TOKEN = requireEnvVar(process.env.REACT_APP_DD_CLIENT_TOKEN)
export const DD_SAMPLE_RATE = parseInt(requireEnvVar(process.env.REACT_APP_DD_SAMPLE_RATE), 10)
export const DD_SERVICE = requireEnvVar(process.env.REACT_APP_DD_SERVICE)
export const DD_VERSION = requireEnvVar(process.env.REACT_APP_DD_VERSION)
export const DD_SESSION_REPLAY_SAMPLE_RATE = parseInt(
  requireEnvVar(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE),
  10,
)

export const INTERCOM_APPLICATION_ID = requireEnvVar(process.env.REACT_APP_INTERCOM_APPLICATION_ID)

export const MUI_PRO_LICENSE_KEY = requireEnvVar(process.env.REACT_APP_MUI_PRO_LICENSE_KEY)

export const PERSON_SERVICE_API_ENDPOINT = requireEnvVar(
  process.env.REACT_APP_PERSON_SERVICE_API_ENDPOINT,
)
export const PERSON_SERVICE_AUTH0_AUDIENCE = requireEnvVar(
  process.env.REACT_APP_PERSON_SERVICE_AUTH0_AUDIENCE,
)

export const CEO_SERVICE_API_ENDPOINT = requireEnvVar(
  process.env.REACT_APP_CEO_SERVICE_API_ENDPOINT,
)
export const CEO_SERVICE_AUTH0_AUDIENCE = requireEnvVar(
  process.env.REACT_APP_CEO_SERVICE_AUTH0_AUDIENCE,
)

export const CRM_URL = requireEnvVar(process.env.REACT_APP_CRM_URL)
export const DIALER_URL = requireEnvVar(process.env.REACT_APP_DIALER_URL)
