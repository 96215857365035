import { useEffect } from 'react'

import { DEPLOY_ENV, DeployEnv } from 'lib/environment-variables'
import { useStoreActions, useStoreState } from 'lib/store'

export enum FeatureFlag {
  ENABLE_CLIENT_LIST_SEARCH = 'vega_enable_client_list_search',
  ENABLE_POLICY_STATUS = 'vega_enable_policy_status',
}

export interface FeatureFlagsOptions {
  /** Set value for development and preview environments */
  previewValue?: boolean
}

const FEATURE_FLAGS_QUERY_PARAM = 'feature_flags'

/**
 * Get the feature flag value
 */
export const useFeatureFlag = (name: FeatureFlag, options?: FeatureFlagsOptions) => {
  const items = useStoreState((state) => state.featureFlags.items)

  if (options?.previewValue && [DeployEnv.DEVELOPMENT, DeployEnv.PREVIEW].includes(DEPLOY_ENV))
    return true

  return !!items[name]
}

/**
 * Extract feature flags from the URL query param and save them to local storage
 */
export const useFeatureFlagStore = () => {
  const setItems = useStoreActions((state) => state.featureFlags.setItems)

  useEffect(() => {
    const featureFlags = new URLSearchParams(window.location.search).get(FEATURE_FLAGS_QUERY_PARAM)
    if (!featureFlags) return // persist feature flags on route change

    setItems(JSON.parse(featureFlags))
  }, [setItems])
}
