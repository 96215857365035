import { useMemo } from 'react'

import { List, ListProps, WithRecord, WrapperField } from 'react-admin'

import { Stack } from '@mui/material'

import { CallButton } from 'components/CallButton'
import {
  EnumField,
  ExpiredOpportunityField,
  LastContactField,
  LinkedPersonField,
  NullableDateField,
  NullableTextField,
  RatingField,
  TextEllipsisField,
  UsStateField,
} from 'components/fields'
import { Column, MuiDatagrid, MuiDatagridListActions } from 'components/MuiDatagrid'
import { useCurrentUser, useRankedPolicySource } from 'hooks'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag'
import { LABEL } from 'i18n/resource-translations'
import { DEFAULT_COLUMN_WIDTH } from 'lib/helpers/column-width-values'
import { listDefaults } from 'lib/helpers/list-helpers'
import { RESOURCE_DESCRIPTION } from 'lib/helpers/resource-mapping'
import { VIRTUAL_SOURCE } from 'lib/helpers/virtual-sources'
import {
  DeleteFollowUpButton,
  EditFollowUpButton,
  FollowUpsQuickFilters,
  QUICK_FILTER_SORTS,
  QuickFilter,
  quickFilters,
  ShowFollowUpButton,
} from 'resources/all_follow_ups/components'
import { FOLLOW_UP_PHASE_DISPLAY_VALUES } from 'resources/all_follow_ups/follow-up-constants'
import {
  LOI_DISPLAY_VALUES,
  PRODUCT_DISPLAY_VALUES,
  SALES_STAGE_DISPLAY_VALUES,
} from 'resources/opportunities/opportunity-constants'
import { PersonsShowDrawer } from 'resources/persons/components/PersonsShowDrawer'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { SortOrder } from 'types/react-admin.types'
import { AllFollowUpsRecord, FollowUpType, OpportunitySource } from 'types/records.types'

import { ALL_FOLLOW_UPS_SOURCE, getAllFollowUpsList } from './AllFollowUpsList.queries'

import styles from './AllFollowUpsList.module.scss'

/** @see https://marmelab.com/react-admin/ListTutorial.html */
export const AllFollowUpsList = (props: ListProps) => {
  const enablePolicyStatus = useFeatureFlag(FeatureFlag.ENABLE_POLICY_STATUS, {
    previewValue: true,
  })
  const { currentUser } = useCurrentUser()
  const assigned_agent_id = currentUser.id

  const { rankedPolicySource } = useRankedPolicySource<AllFollowUpsRecord>(
    ALL_FOLLOW_UPS_SOURCE.POLICY_RELATED_INFOS,
  )

  const filterDefaultValues = useMemo(() => {
    const filters = quickFilters()
    return filters[QuickFilter.CURRENT]
  }, [])

  return (
    <>
      <List
        {...listDefaults({ primaryList: true })}
        {...props}
        actions={
          <MuiDatagridListActions>
            <FollowUpsQuickFilters />
          </MuiDatagridListActions>
        }
        queryOptions={{
          meta: {
            assigned_agent_id, // Force re-fetch when ID changes
            query: getAllFollowUpsList({ assigned_agent_id }),
          } as DataProviderMeta<FetchType.GET_LIST>,
        }}
        sort={QUICK_FILTER_SORTS[QuickFilter.CURRENT]}
        filterDefaultValues={filterDefaultValues}
      >
        <MuiDatagrid
          bulkActionButtons={false}
          initialState={{
            pinnedColumns: {
              left: [ALL_FOLLOW_UPS_SOURCE.PERSON_FIRST_NAME],
              right: [VIRTUAL_SOURCE.ACTIONS],
            },
          }}
          omit={undefined} // TODO: Fix `omit` support; see VEGA-299
        >
          <Column width={DEFAULT_COLUMN_WIDTH.FULL_NAME} sortable={false}>
            <LinkedPersonField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.PERSON}
              sortBy={ALL_FOLLOW_UPS_SOURCE.PERSON_FIRST_NAME}
            />
          </Column>

          <Column width={180} type="dateTime">
            <NullableDateField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.SCHEDULED_TIME}
              showTooltip
              options={{
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
                year: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
              }}
              sortByOrder={SortOrder.DESC}
            />
          </Column>

          <Column width={DEFAULT_COLUMN_WIDTH.CHANCE_TO_CLOSE}>
            <RatingField<AllFollowUpsRecord> source={ALL_FOLLOW_UPS_SOURCE.CHANCE_TO_CLOSE} />
          </Column>

          {enablePolicyStatus && (
            <Column width={DEFAULT_COLUMN_WIDTH.POLICY_STATUS} sortable={false}>
              <NullableTextField
                label={LABEL.POLICY_STATUS}
                source={`${rankedPolicySource}.normalized_data.policy_status`}
              />
            </Column>
          )}

          <Column width={DEFAULT_COLUMN_WIDTH.STATE} sortable={false}>
            <UsStateField<AllFollowUpsRecord> source={ALL_FOLLOW_UPS_SOURCE.PERSON_STATE} />
          </Column>

          <Column
            width={DEFAULT_COLUMN_WIDTH.LAST_CONTACT}
            type="dateTime"
            sortable={false} // TODO: Enable [Follow-ups > Last Contact] sorting
          >
            <LastContactField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.LAST_CONTACT}
              dispositionNameSource={ALL_FOLLOW_UPS_SOURCE.LAST_CONTACT_DISPOSITION_NAME}
              sortByOrder={SortOrder.DESC}
            />
          </Column>

          <Column width={150}>
            <EnumField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.PRODUCTS}
              displayValues={PRODUCT_DISPLAY_VALUES}
            />
          </Column>

          <Column field={VIRTUAL_SOURCE.NOTES} filterable={false} sortable={false} width={220}>
            <WithRecord<AllFollowUpsRecord>
              label={LABEL.NOTES}
              render={(followUp) => (
                <TextEllipsisField
                  data-dd-privacy={SessionReplayPrivacy.MASK}
                  source={
                    followUp.type === FollowUpType.APPOINTMENT
                      ? ALL_FOLLOW_UPS_SOURCE.APPOINTMENT_NOTES
                      : ALL_FOLLOW_UPS_SOURCE.REMINDER_NOTES
                  }
                />
              )}
            />
          </Column>

          <Column width={120}>
            <NullableTextField<AllFollowUpsRecord> source={ALL_FOLLOW_UPS_SOURCE.TYPE} />
          </Column>

          <Column width={120}>
            <EnumField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.SUB_TYPE}
              displayValues={FOLLOW_UP_PHASE_DISPLAY_VALUES}
            />
          </Column>

          <Column
            width={DEFAULT_COLUMN_WIDTH.SALES_STAGE}
            description={RESOURCE_DESCRIPTION[ALL_FOLLOW_UPS_SOURCE.SALES_STAGE]}
          >
            <EnumField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.SALES_STAGE}
              displayValues={SALES_STAGE_DISPLAY_VALUES}
            />
          </Column>

          {/* TODO: Should be hidden by default with `omit`, waiting on VEGA-299 */}
          <Column width={DEFAULT_COLUMN_WIDTH.LOI}>
            <EnumField<AllFollowUpsRecord>
              source={ALL_FOLLOW_UPS_SOURCE.LOI}
              label="LOI"
              displayValues={LOI_DISPLAY_VALUES}
            />
          </Column>

          <Column
            width={DEFAULT_COLUMN_WIDTH.ENROLLMENT_PERIOD}
            description={RESOURCE_DESCRIPTION[ALL_FOLLOW_UPS_SOURCE.ENROLLMENT_PERIOD]}
            field={ALL_FOLLOW_UPS_SOURCE.ENROLLMENT_PERIOD}
          >
            <WrapperField label={LABEL.ENROLLMENT_PERIOD}>
              <Stack direction="row" spacing={1}>
                <NullableTextField<AllFollowUpsRecord>
                  source={ALL_FOLLOW_UPS_SOURCE.ENROLLMENT_PERIOD}
                />
                <ExpiredOpportunityField<AllFollowUpsRecord>
                  source={{
                    expiredDate: ALL_FOLLOW_UPS_SOURCE.EXPIRATION_DATE,
                    enrollmentPeriod: ALL_FOLLOW_UPS_SOURCE.ENROLLMENT_PERIOD,
                  }}
                  chipProps={{ size: 'small' }}
                />
              </Stack>
            </WrapperField>
          </Column>

          <Column width={DEFAULT_COLUMN_WIDTH.LEAD_ID} sortable={false}>
            <NullableTextField<AllFollowUpsRecord> source={ALL_FOLLOW_UPS_SOURCE.LEAD_ID} />
          </Column>

          <Column
            width={220}
            field={VIRTUAL_SOURCE.ACTIONS}
            filterable={false}
            sortable={false}
            resizable={false}
          >
            <WithRecord<AllFollowUpsRecord>
              label={LABEL.ACTIONS}
              render={(record) => {
                const person = record.opportunity.person[0]

                return (
                  <Stack className={styles.actions} direction="row">
                    <ShowFollowUpButton record={record} />
                    <CallButton person={person} />
                    <EditFollowUpButton
                      record={{
                        person,
                        scheduled_time: record.scheduled_time,
                        source: OpportunitySource.CLIENT_LIST,
                      }}
                      followUpType={record.type}
                    />
                    <DeleteFollowUpButton />
                  </Stack>
                )
              }}
            />
          </Column>
        </MuiDatagrid>
      </List>
      <PersonsShowDrawer {...props} />
    </>
  )
}
