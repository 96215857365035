import { useEffect } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useStoreState } from 'lib/store'

export const UserDataRecorder = () => {
  const datadog = useDatadogRUM()
  const { currentUser } = useCurrentUser()
  const featureFlags = useStoreState((state) => state.featureFlags.items)

  useEffect(() => {
    // Only record specific user data
    const { id, unmaskedID, fullName, email, roles, canMasquerade, isMasquerading } = currentUser

    // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration
    datadog.setUser({
      id: unmaskedID.toString(),
      name: fullName,
      email,
      roles,
      canMasquerade,
      isMasquerading,
      masqueradeID: isMasquerading ? id : null,
    })
  }, [currentUser, datadog])

  useEffect(() => {
    Object.entries(featureFlags).forEach(([flag, isEnabled]) => {
      // https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection
      datadog.addFeatureFlagEvaluation(flag, isEnabled)
    })
  }, [datadog, featureFlags])

  return null
}
