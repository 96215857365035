import { useCallback, useEffect, useState } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { useMatch } from 'react-router-dom'
import { IntercomProps, IntercomProvider, useIntercom } from 'react-use-intercom'

import { CRM_URL, INTERCOM_APPLICATION_ID } from 'lib/environment-variables'
import { isCurrentPageInIframe } from 'lib/helpers/iframe-helpers'
import { IntercomSettingsMessage, MessageType, sendCrmMessage } from 'lib/helpers/iframe-messaging'
import { CREATE_FROM_PLATES_PATH } from 'lib/helpers/path-helpers'

export const IntercomBoot = () => {
  const { boot } = useIntercom()
  const datadog = useDatadogRUM()

  /**
   * Respond to message from the CRM
   * https://github.com/assuranceiq/insurance/blob/master/app/views/agent/clients.html.slim#L26
   */
  const onCrmMessage = useCallback(
    (event: MessageEvent<IntercomSettingsMessage>) => {
      const { type: messageType, ...intercomSettings } = event.data
      if (event.origin !== CRM_URL) return
      if (messageType !== MessageType.INTERCOM_POST_SETTINGS) return
      if (!intercomSettings?.user_hash)
        return datadog.addError('Missing user_hash in Intercom settings')

      const { created_at, name, email, user_hash, ...customAttributes } = intercomSettings

      // Boot function expects settings as camelCase
      const formattedIntercomSettings: IntercomProps = {
        createdAt: created_at,
        name,
        email,
        userHash: user_hash,
        customAttributes,
      }

      boot(formattedIntercomSettings)
    },
    [boot, datadog],
  )

  useEffect(() => {
    window.addEventListener('message', onCrmMessage)
    sendCrmMessage(MessageType.INTERCOM_GET_SETTINGS)

    return () => {
      window.removeEventListener('message', onCrmMessage)
    }
  }, [onCrmMessage])

  return null
}

export const IntercomLauncher = () => {
  const isCreateFromPlates = useMatch(CREATE_FROM_PLATES_PATH)
  const [readyToLoad, setReadyToLoad] = useState(false)

  // Load Intercom after the page has loaded and the browser is idle
  useEffect(() => {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        setReadyToLoad(true)
      })
    } else {
      // Fallback for browsers that do not support requestIdleCallback
      setTimeout(() => {
        setReadyToLoad(true)
      }, 0)
    }
  }, [])

  if (isCreateFromPlates) return null
  if (!readyToLoad) return null
  if (!isCurrentPageInIframe) return null

  return (
    // Include the provider here to conditionally render the Intercom, avoiding unnecessary scripts in the App when not needed.
    <IntercomProvider appId={INTERCOM_APPLICATION_ID}>
      <IntercomBoot />
    </IntercomProvider>
  )
}
