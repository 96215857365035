import { useState } from 'react'

import {
  CreateInDialogButton as RaCreateInDialogButton,
  CreateInDialogButtonProps as RaCreateInDialogButtonProps,
} from '@react-admin/ra-form-layout'

import { useQueryClient } from 'react-query'

import { HandleCloseFormDialog } from 'components/HandleCloseFormDialog'

export interface CreateInDialogButtonProps extends RaCreateInDialogButtonProps {
  invalidateResourcesOnSuccess?: string[]
}

/**
 * This component is a wrapper around `CreateInDialogButton` from `@react-admin/ra-form-layout`.
 * It adds the following features:
 * - Close the dialog when the mutation is successful
 * - Invalidate resources on success
 */
export const CreateInDialogButton = ({
  children,
  invalidateResourcesOnSuccess = [],
  mutationOptions,
  ...props
}: CreateInDialogButtonProps) => {
  const [closeDialog, setCloseDialog] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const onSuccess = () => {
    invalidateResourcesOnSuccess.forEach((resource) => {
      // copied from @react-admin/ra-form-layout/src/forms/dialog-form/CreateDialog.tsx
      // Because redirecting to the list doesn't remount the List component, we need
      // to explicitly ask react-query to refresh the list queries
      queryClient.invalidateQueries([resource, 'getList'])
      queryClient.invalidateQueries([resource, 'getManyReference'])
    })

    setCloseDialog(true)
  }

  return (
    <RaCreateInDialogButton
      {...props}
      mutationOptions={{
        ...mutationOptions,
        onSuccess: (...args) => {
          onSuccess()
          mutationOptions?.onSuccess?.(...args)
        },
      }}
    >
      <HandleCloseFormDialog close={closeDialog} setClose={setCloseDialog} />
      {children}
    </RaCreateInDialogButton>
  )
}
