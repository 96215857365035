import { useEffect } from 'react'

import { usePreference } from 'react-admin'

import { GridDensity, gridDensityValueSelector, useGridApiContext } from '@mui/x-data-grid-pro'

/**
 * Synchronize/store table density with React Admin preferences
 */
export const usePreferredDensity = () => {
  const apiRef = useGridApiContext()

  const [density, setDensity] = usePreference<GridDensity>('density')

  useEffect(
    function initializePreference() {
      if (density) return

      setDensity(gridDensityValueSelector(apiRef))
    },
    [apiRef, density, setDensity],
  )

  useEffect(() => {
    return apiRef.current.subscribeEvent('stateChange', () => {
      const newDensity = gridDensityValueSelector(apiRef)
      if (newDensity === density) return

      setDensity(newDensity)
    })
  }, [apiRef, density, setDensity])

  useEffect(() => {
    if (!density) return
    if (density === gridDensityValueSelector(apiRef)) return

    apiRef.current.setDensity(density)
  }, [apiRef, density])

  return [density, setDensity] as const
}
