export enum Resources {
  ALL_FOLLOW_UPS = 'all_follow_ups',
  FOLLOW_UP_APPOINTMENTS = 'follow_up_appointments',
  FOLLOW_UP_REMINDERS = 'follow_up_reminders',
  CLIENTS = 'clients',
  OPPORTUNITIES = 'opportunities',
  PERSONS = 'persons',
  MISSED_CALLS = 'missed_calls',
  NOTES = 'notes',
  LEAD_TZ = 'lead_tz',
  DERIVED_LEAD_FIELDS = 'derived_lead_fields',
}
