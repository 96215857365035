import { FC } from 'react'

import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

import { api } from 'lib/api'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'lib/environment-variables'

export const Auth0WithReactRouterIntegrationProvider: FC = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
    const path = (appState?.returnTo || window.location.href).replace(window.location.origin, '')
    navigate(path)
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        ...api.authorizationParams,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}
