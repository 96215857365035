import { FC, useEffect } from 'react'

import { Loading, useDatadogRUM } from '@assuranceiq/react-components'

import { OAuthError, useAuth0 } from '@auth0/auth0-react'

import { AppLayout } from 'components/App/AppLayout'

const AUTO_RECOVERABLE_ERRORS = [
  'mfa_required', // we sometimes get this, not sure why it wouldn’t auto redirect
  'Invalid state',
]

export const RequireAuthentication: FC = ({ children }) => {
  const { error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const datadog = useDatadogRUM()

  useEffect(() => {
    if (isLoading || isAuthenticated) return

    datadog.addAction('Redirecting to Auth0 (User Not Authenticated)')
    loginWithRedirect()
  }, [datadog, isLoading, isAuthenticated, loginWithRedirect])

  if (error) {
    const errorName = error instanceof OAuthError ? error.error : error.message

    if (AUTO_RECOVERABLE_ERRORS.includes(errorName)) {
      loginWithRedirect()
    } else {
      throw error
    }
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <AppLayout>
      <Loading fullscreen fullscreenTitle="Logging in…" />
    </AppLayout>
  )
}
