import { DatagridClasses } from 'react-admin'

import { ThemeOptions } from '@mui/material'

import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

import Typography from 'styles/modules/_Typography.scss'

export const tablesConfig: ThemeOptions = {
  components: {
    MuiTablePagination: {
      defaultProps: {
        rowsPerPageOptions: [10, 25, 50, 100],
      },

      styleOverrides: {
        root: {
          color: Colors['gray-100'],
        },
      },
    },

    // @ts-ignore React-admin components are not typed

    RaDatagrid: {
      styleOverrides: {
        // TODO: Update default table styles in ARC
        root: {
          [`.${DatagridClasses.table}`]: {
            borderCollapse: 'inherit', // Fixes styling of sticky header border
          },

          [`.${DatagridClasses.row}`]: {
            '.MuiTableCell-root': {
              borderBottom: `1px solid ${Colors['gray-25']}`,
            },
            [`&:not(.${DatagridClasses.headerRow}):last-child .MuiTableCell-root`]: {
              borderBottom: 'none',
            },
            [`.${DatagridClasses.headerCell}`]: {
              backgroundColor: Colors['bg-light'],
              fontWeight: Typography.fontWeightBold,
              color: Colors['gray-100'],
            },
          },
        },
      },
    },
  },
}
