import { action, Action, persist } from 'easy-peasy'

export interface FeatureFlagsModel {
  items: Record<string, boolean>

  setItems: Action<FeatureFlagsModel, FeatureFlagsModel['items']>
}

const model: FeatureFlagsModel = {
  items: {},

  setItems: action((state, items) => {
    state.items = items
  }),
}

export const featureFlagsModel = persist<FeatureFlagsModel>(model, {
  storage: 'localStorage',
  migrations: {
    migrationVersion: 1,
    1: (state) => {
      state.items = {}
    },
  },
})
