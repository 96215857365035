import { ReactNode, useState } from 'react'

import { FunctionField, useNotify, useRecordContext, useTranslate, WithRecord } from 'react-admin'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { IconArrowDown1 } from '@assuranceiq/react-icons/bold/ArrowDown1'
import { IconArrowUp1 } from '@assuranceiq/react-icons/bold/ArrowUp1'
import { IconCalendar } from '@assuranceiq/react-icons/bold/Calendar'
import { IconPencil } from '@assuranceiq/react-icons/bold/Pencil'
import { IconVipCrownQueen2 } from '@assuranceiq/react-icons/bold/VipCrownQueen2'

import { CreateInDialogButton } from 'components/CreateInDialogButton'
import { EditInDialogButton } from 'components/EditInDialogButton'
import { ExpiredOpportunityField, NullableTextField, RatingField } from 'components/fields'
import { EnumField } from 'components/fields/EnumField'
import { OpportunityWizardFormValues } from 'components/FollowUpOpportunityWizard'
import { createFollowUpQuery } from 'components/FollowUpOpportunityWizard/FollowUpOpportunityWizard.queries'
import { TimeAgo } from 'components/TimeAgo'
import { NullValue } from 'components/values'
import { DataProviderMeta, useCurrentUser, useNotifyFollowUpCreated } from 'hooks'
import { FetchType } from 'hooks/useDataProvider'
import { Resources } from 'lib/api/api.types'
import { displayFullDateTime } from 'lib/helpers/datetime-helpers'
import { getData } from 'lib/helpers/get-data'
import { FollowUpForm } from 'resources/all_follow_ups/components'
import { OpportunityForm } from 'resources/opportunities'
import { DeleteOpportunityButton } from 'resources/opportunities/components/DeleteOpportunityButton'
import { updateOpportunityQuery } from 'resources/opportunities/components/OpportunityForm/OpportunityForm.queries'
import { OPPORTUNITIES_SOURCE } from 'resources/opportunities/OpportunitiesList'
import {
  LOI_DISPLAY_VALUES,
  PRODUCT_DISPLAY_VALUES,
  SALES_STAGE_DISPLAY_VALUES,
} from 'resources/opportunities/opportunity-constants'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { FormRecord } from 'types/forms.types'
import { OpportunityRecord, OpportunitySource } from 'types/records.types'

import styles from './OpportunityCard.module.scss'

export interface OpportunityCardProps {
  /**
   * Add CRUD actions to the card and follow-up list
   *
   * @default false
   */
  addActions?: boolean

  /**
   * @default false
   */
  collapsed?: boolean

  /**
   * Opportunity Follow-ups list
   */
  followUpsList: ReactNode

  /**
   * List item marker for the card
   *
   * @default <IconVipCrownQueen2 />
   */
  listItemMarker?: JSX.Element
}

const CreateFollowUpButton = () => {
  const { currentUser } = useCurrentUser()
  const notifyFollowUpCreated = useNotifyFollowUpCreated()
  const opportunity = useRecordContext<OpportunityRecord>()

  const formRecord: FormRecord & Pick<OpportunityWizardFormValues, 'opportunity_id'> = {
    agent_id: currentUser.id,
    person: opportunity.person[0],
    source: OpportunitySource.CLIENT_LIST,

    // Link follow-up to opportunity
    opportunity_id: opportunity.id,
  }

  return (
    <CreateInDialogButton
      fullWidth
      ButtonProps={{
        variant: 'outlined',
        color: 'primary',
        className: styles.createFollowUpButton,
        title: 'Add New Follow-Up',
      }}
      label="Add New"
      resource={Resources.OPPORTUNITIES}
      record={formRecord}
      title={
        <>
          Create Follow-up for{' '}
          <span data-dd-privacy={SessionReplayPrivacy.MASK}>
            {opportunity?.person[0].fname} {opportunity?.person[0].lname}
          </span>
        </>
      }
      mutationOptions={{
        meta: {
          query: createFollowUpQuery,
        } as DataProviderMeta<FetchType.CREATE>,
        onSuccess: notifyFollowUpCreated,
      }}
      invalidateResourcesOnSuccess={[Resources.OPPORTUNITIES, Resources.ALL_FOLLOW_UPS]}
    >
      <FollowUpForm />
    </CreateInDialogButton>
  )
}

const defaultCardMarker = (
  <Box className={styles.cardMarker}>
    <IconVipCrownQueen2 aria-label="Vip crown queen icon" role="img" />
  </Box>
)

export const OpportunityCard = ({
  addActions = false,
  collapsed: defaultCollapsed = false,
  followUpsList,
  listItemMarker = defaultCardMarker,
}: OpportunityCardProps) => {
  const translate = useTranslate()
  const opportunity = useRecordContext<OpportunityRecord>()
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed)
  const notify = useNotify()

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Card className={styles.root}>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <CardContent className={styles.content}>
          <Stack alignItems="start" direction="row" spacing={1}>
            {listItemMarker}

            <Stack alignItems="start">
              <Stack alignItems="center" direction="row" spacing={2}>
                <Typography>
                  <EnumField<OpportunityRecord>
                    source={OPPORTUNITIES_SOURCE.LOI}
                    displayValues={LOI_DISPLAY_VALUES}
                    variant="body1"
                    sx={{ fontWeight: 'bold' }}
                  />{' '}
                  <EnumField<OpportunityRecord>
                    source={OPPORTUNITIES_SOURCE.PRODUCTS}
                    displayValues={PRODUCT_DISPLAY_VALUES}
                    variant="body1"
                    sx={{ fontWeight: 'bold' }}
                  />{' '}
                  for{' '}
                  <NullableTextField<OpportunityRecord>
                    source={OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD}
                    variant="body1"
                    sx={{ fontWeight: 'bold' }}
                  />
                </Typography>
                <ExpiredOpportunityField<OpportunityRecord>
                  source={{
                    expiredDate: OPPORTUNITIES_SOURCE.EXPIRATION_DATE,
                    enrollmentPeriod: OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD,
                  }}
                />
                <Chip
                  label={
                    <EnumField<OpportunityRecord>
                      source={OPPORTUNITIES_SOURCE.SALES_STAGE}
                      displayValues={SALES_STAGE_DISPLAY_VALUES}
                      variant="inherit" // Use Chip label styles
                    />
                  }
                />
                <RatingField<OpportunityRecord>
                  source={OPPORTUNITIES_SOURCE.CHANCE_TO_CLOSE}
                  className={styles.chanceToClose}
                />
              </Stack>
              <FunctionField<OpportunityRecord>
                source={OPPORTUNITIES_SOURCE.UPDATED_AT}
                render={(record, source) => {
                  const lastUpdated = getData<string>(record, source)

                  return (
                    <Typography component="span" variant="small" color="text.secondary">
                      Last updated{' '}
                      {lastUpdated ? (
                        <Tooltip title={displayFullDateTime(lastUpdated)}>
                          <TimeAgo date={lastUpdated} />
                        </Tooltip>
                      ) : (
                        <NullValue />
                      )}
                    </Typography>
                  )
                }}
              />

              <Stack direction="row" marginTop={1}>
                <WithRecord<OpportunityRecord>
                  render={(record) => {
                    const followUpsCount = getData<number>(
                      record,
                      OPPORTUNITIES_SOURCE.FOLLOW_UPS_COUNT,
                    )

                    return (
                      <Button
                        startIcon={<IconCalendar />}
                        endIcon={
                          collapsed ? (
                            <IconArrowDown1 aria-label="Arrow down icon" role="img" />
                          ) : (
                            <IconArrowUp1 aria-label="Arrow up icon" role="img" />
                          )
                        }
                        className={styles.collapseButton}
                        onClick={handleCollapse}
                        disabled={followUpsCount === 0}
                      >
                        {translate(`resources.${Resources.ALL_FOLLOW_UPS}.count`, {
                          smart_count: followUpsCount,
                        })}
                      </Button>
                    )
                  }}
                />
                {addActions && <CreateFollowUpButton />}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        {addActions && (
          <CardActions className={styles.actions}>
            <Stack direction="row">
              <EditInDialogButton
                icon={<IconPencil />}
                title={
                  <>
                    <span>Update an Opportunity for </span>
                    <span data-dd-privacy={SessionReplayPrivacy.MASK}>
                      {opportunity?.person[0].fname} {opportunity?.person[0].lname}
                    </span>
                  </>
                }
                ButtonProps={{
                  title: 'Edit Opportunity',
                  variant: 'text',
                  color: 'secondary',
                }}
                label=""
                mutationMode="pessimistic"
                mutationOptions={{
                  meta: {
                    query: updateOpportunityQuery,
                  } as DataProviderMeta<FetchType.UPDATE>,
                  onSuccess: () => notify('Opportunity successfully updated', { type: 'success' }),
                }}
              >
                <OpportunityForm />
              </EditInDialogButton>

              <DeleteOpportunityButton
                label=""
                title="Delete Opportunity"
                variant="text"
                color="secondary"
              />
            </Stack>
          </CardActions>
        )}
      </Stack>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <CardContent className={styles.details}>{followUpsList}</CardContent>
      </Collapse>
    </Card>
  )
}
