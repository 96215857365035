import { useNotify } from 'react-admin'
import { EditInDialogButtonProps } from '@react-admin/ra-form-layout'

import { Box } from '@mui/material'

import { IconPencil } from '@assuranceiq/react-icons/bold/Pencil'

import { EditInDialogButton } from 'components/EditInDialogButton'
import { useCurrentUser } from 'hooks'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { displayFullDateTimeAndTimezone } from 'lib/helpers/datetime-helpers'
import { followUpResourceByType } from 'lib/helpers/resource-mapping'
import { EditFollowUpForm, updateFollowUpQuery } from 'resources/all_follow_ups/components'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { FormRecord } from 'types/forms.types'
import { FollowUpType } from 'types/records.types'

import styles from './EditFollowUpButton.module.scss'

interface EditFollowUpButtonProps extends Partial<EditInDialogButtonProps> {
  record: Pick<FormRecord, 'person' | 'scheduled_time' | 'source'>
  followUpType: FollowUpType
}

export const EditFollowUpButton = ({ followUpType, record, ...rest }: EditFollowUpButtonProps) => {
  const notify = useNotify()
  const { currentUser } = useCurrentUser()

  const formRecord: FormRecord = {
    ...record,
    agent_id: currentUser.id,
  }

  return (
    <EditInDialogButton
      icon={<IconPencil />}
      resource={followUpResourceByType[followUpType]}
      title={
        <Box sx={{ mb: -3 }}>
          <span>Edit a Follow-up for </span>
          <span data-dd-privacy={SessionReplayPrivacy.MASK}>
            {formRecord.person?.fname} {formRecord.person?.lname}
          </span>
        </Box>
      }
      label=""
      classes={{ paper: styles.dialogPaper }}
      mutationMode="pessimistic" // HACK: Treat auto-refresh as it has different resource
      mutationOptions={{
        meta: {
          query: updateFollowUpQuery,
        } as DataProviderMeta<FetchType.UPDATE>,
        onSuccess: (response) => {
          const hasScheduledTimeChanged = response.scheduled_time !== `${record.scheduled_time}Z`
          const message = hasScheduledTimeChanged
            ? `You have re-scheduled a Follow-up ${followUpType} to ${displayFullDateTimeAndTimezone(
                response.scheduled_time,
                response.scheduled_timezone,
              )}`
            : 'You have updated the Follow-up'

          notify(message, {
            type: 'success',
          })
        },
      }}
      ButtonProps={{ 'aria-label': 'Edit', ...rest.ButtonProps }}
      {...rest}
    >
      <EditFollowUpForm formRecord={formRecord} />
    </EditInDialogButton>
  )
}
