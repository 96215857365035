import { useRef } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { isMatch } from 'lodash-es'

/**
 * This hook is used to prevent the DataDog RUM action from being triggered on every render.
 * It will only trigger when the action context changes.
 */
export const useStableDataDogAction = () => {
  const { addAction } = useDatadogRUM()
  const actionContextRef = useRef<Parameters<typeof addAction>[1]>()

  /**
   * This function will only trigger the DataDog RUM action if the action context has changed.
   * @see [addAction method docs](https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm)
   */
  const stableAddAction = (
    name: Parameters<typeof addAction>[0],
    context: Parameters<typeof addAction>[1],
  ) => {
    const lastActionContext = actionContextRef.current
    if (lastActionContext && isMatch(lastActionContext, context || {})) return

    actionContextRef.current = context
    addAction(name, context)
  }

  /** Reset the action context. */
  const resetStableActionContext = () => {
    actionContextRef.current = []
  }

  return { stableAddAction, resetStableActionContext }
}
