export const PHONE_REGEX =
  /^\+(?<countryCode>\d+)(?<areaCode>\d{3})(?<telephonePrefix>\d{3})(?<lineNumber>\d{4})$/

/**
 * Formats a phone number into a human-readable format
 *
 * @example
 *   formatPhoneNumber('+12061234567') // => "(206) 123-4567"
 */
export const formatPhoneNumber = (phone: string, { displayUSCountryCode = false } = {}) => {
  const match = phone.match(PHONE_REGEX)

  if (!match || !match.groups) {
    console.error(`${JSON.stringify(phone)} does not match expected format: ${PHONE_REGEX}`)
    return phone
  }

  const { countryCode, areaCode, telephonePrefix, lineNumber } = match.groups

  const internationalCountryCode =
    !displayUSCountryCode && countryCode === '1' ? '' : `+${countryCode} `

  return `${internationalCountryCode}(${areaCode}) ${telephonePrefix}-${lineNumber}`
}
