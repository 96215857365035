import { useMemo, useState } from 'react'

import { FunctionField, Link, RaRecord } from 'react-admin'

import { FullNameField, FullNameFieldProps } from 'components/fields/FullNameField'
import { getData } from 'lib/helpers/get-data'
import { usePersonsShowDrawerPath } from 'resources/persons/components/PersonsShowDrawer'
import { TypedRaRecord } from 'types/react-admin.types'

export interface LinkedPersonFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends FullNameFieldProps<RecordType> {
  sourceLeadID?: FullNameFieldProps<RecordType>['source']
}

/**
 * Displays a person’s full name and links to their drawer page.
 *
 * **NOTE:** This component is memoized—props will not be updated after the
 * first render. If you need to update these props, you will need to unmount and
 * remount the component.
 */
export function LinkedPersonField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  sourceFirstName,
  sourceLastName,
  sourceLeadID,
  ...mutableProps
}: LinkedPersonFieldProps<RecordType>) {
  const [immutableProps] = useState(mutableProps)
  const personsShowDrawerPath = usePersonsShowDrawerPath()

  const Memoized = useMemo(() => {
    return (
      <FunctionField
        source={source}
        color="primary" // HACK: Ensure text-overflow ellipsis matches link color
        {...immutableProps}
        render={(record: RaRecord) => {
          const leadIDSource = sourceLeadID || [source, 'lead_id'].filter(Boolean).join('.')
          const lead_id = getData<string>(record, leadIDSource, '')

          return (
            <Link to={personsShowDrawerPath(lead_id)}>
              <FullNameField
                source={source}
                sourceFirstName={sourceFirstName}
                sourceLastName={sourceLastName}
              />
            </Link>
          )
        }}
      />
    )
  }, [personsShowDrawerPath, immutableProps, source, sourceFirstName, sourceLastName, sourceLeadID])

  return Memoized
}
