import { ComponentProps, FC, useEffect, useMemo } from 'react'

import {
  ListBase,
  RecordContextProvider,
  required,
  SaveButton,
  useGetManyReference,
  useInput,
  useRecordContext,
  WithListContext,
} from 'react-admin'
import {
  useWizardFormContext,
  useWizardFormStepContext,
  WizardForm,
} from '@react-admin/ra-form-layout'

import CheckIcon from '@mui/icons-material/Check'
import { Box, Button, Container, FormLabel, Radio, Stack, useTheme } from '@mui/material'

import { isEmpty } from 'lodash-es'
import { useFormContext } from 'react-hook-form'

import { RadioCardGroupChoice, RadioCardGroupInput } from 'components/inputs'
import { PaginationIfNeeded } from 'components/PaginationIfNeeded'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { Resources } from 'lib/api/api.types'
import { listBaseDefaults } from 'lib/helpers/list-helpers'
import {
  FollowUpFormControls,
  FollowUpFormValues,
  getFollowUpDefaultValues,
} from 'resources/all_follow_ups/components'
import {
  OPPORTUNITY_DEFAULT_VALUES,
  OpportunityFormControls,
  OpportunityFormValues,
} from 'resources/opportunities/components/OpportunityForm'
import {
  getOpportunitiesList,
  OPPORTUNITIES_SOURCE,
} from 'resources/opportunities/OpportunitiesList'
import {
  ExtendedFollowUpList,
  OpportunityCard,
} from 'resources/persons/PersonsShow/tabs/OpportunitiesTab/components/OpportunityCard'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { FormRecord } from 'types/forms.types'
import { SortOrder } from 'types/react-admin.types'
import { OpportunityRecord } from 'types/records.types'

import { PatchedWizardProgress } from './PatchedWizardProgress'

import styles from './FollowUpOpportunityWizard.module.scss'

export type OpportunityWizardFormValues = FollowUpFormValues &
  OpportunityFormValues &
  Pick<OpportunityRecord, 'id'> & {
    isCreatingOpportunity: boolean
    opportunity_id?: string
  }

export interface FollowUpOpportunityWizardProps extends Partial<ComponentProps<typeof WizardForm>> {
  defaultValues?: Partial<OpportunityWizardFormValues>
}

const CREATE_OPPORTUNITY_CHOICES: Array<RadioCardGroupChoice> = [
  {
    id: 'false',
    name: 'Link to existing opportunity',
    subtext: 'Continue the Client relationship',
  },
  {
    id: 'true',
    name: 'Create a New Opportunity',
    subtext: 'Track a new Client relationship or continue it with a new product',
  },
]

const WIZARD_DEFAULT_VALUES: Partial<OpportunityWizardFormValues> = {
  isCreatingOpportunity: true,
}

const Toolbar = () => {
  const { hasNextStep, hasPreviousStep, goToNextStep, goToPreviousStep } = useWizardFormContext()
  const {
    formState: { errors, touchedFields },
    getValues,
    setFocus,
    setValue,
    trigger,
  } = useFormContext()

  const onNextClick = async () => {
    const isValid = await trigger()

    if (isValid) {
      goToNextStep()
      return
    }
    const errorFields = Object.keys(errors)

    errorFields.forEach((field) => {
      if (touchedFields[field]) return

      // Trigger validation for untouched fields
      setValue(field, getValues(field), { shouldTouch: true })
    })
    setFocus(errorFields[0])
  }

  return (
    <Stack direction="row-reverse" className={styles.toolbar}>
      {hasNextStep ? (
        <Button variant="contained" disabled={!isEmpty(errors)} onClick={onNextClick}>
          Next
        </Button>
      ) : (
        <SaveButton icon={<></>} label="Create Follow-up & Opportunity" alwaysEnable />
      )}
      {hasPreviousStep && (
        <Button variant="outlined" color="secondary" onClick={() => goToPreviousStep()}>
          Previous
        </Button>
      )}
    </Stack>
  )
}

/**
 * Renders children only if a step is active or passed due to isolating react-hook-form‘s logic
 * across steps (e.g. validation, errors, etc.)
 */
const RenderActiveOrPassedStep: FC = ({ children }) => {
  const { currentStep, step } = useWizardFormStepContext()

  return currentStep >= step ? <>{children}</> : null
}

export const FollowUpOpportunityWizard = ({
  defaultValues,
  ...props
}: FollowUpOpportunityWizardProps) => {
  const theme = useTheme()

  return (
    <WizardForm
      defaultValues={{
        ...OPPORTUNITY_DEFAULT_VALUES,
        ...getFollowUpDefaultValues(defaultValues),
        ...WIZARD_DEFAULT_VALUES,
      }}
      className={styles.wizardForm}
      progress={
        <Container maxWidth="sm">
          <PatchedWizardProgress
            className={styles.progressBar}
            completedStepComponent={
              <Box className={styles.completedIcon}>
                <CheckIcon sx={{ fontSize: theme.typography.medium.fontSize }} />
              </Box>
            }
          />
        </Container>
      }
      toolbar={<Toolbar />}
      {...props}
    >
      <WizardForm.Step label="Link Opportunity">
        <RenderActiveOrPassedStep>
          <Stack padding={1}>
            <CreateOrLinkOpportunity />
          </Stack>
        </RenderActiveOrPassedStep>
      </WizardForm.Step>
      <WizardForm.Step label="Schedule Follow-up">
        <RenderActiveOrPassedStep>
          <Stack padding={1}>
            <FollowUpFormControls />
          </Stack>
        </RenderActiveOrPassedStep>
      </WizardForm.Step>
    </WizardForm>
  )
}

const CreateOrLinkOpportunity = () => {
  const {
    formState: { touchedFields },
    setValue,
    clearErrors,
    watch,
  } = useFormContext<OpportunityWizardFormValues>()
  const assignedAgentID = watch('agent_id')
  const leadId = watch('person.lead_id')

  const record = useRecordContext<FormRecord>()

  /**
   * NOTE: THIS MUST MATCH FILTER/QUERY LOGIC IN `LinkToExistingOpportunity`
   *
   * TODO: DRY up opportunity fetching logic across `CreateOrLinkOpportunity`,
   * `LinkToExistingOpportunity`, and `OpportunityFormControls`—they should all
   * use the same opportunities data
   *
   * SEE: https://assuranceiq.atlassian.net/browse/VEGA-471
   */
  const {
    data: opportunities,
    isLoading,
    isSuccess,
  } = useGetManyReference<OpportunityRecord>(Resources.OPPORTUNITIES, {
    target: 'lead_id',
    id: record.person.lead_id || leadId,
    filter: {
      assigned_agent_id: assignedAgentID,
    },
  })
  const noOpportunities = !isSuccess || !opportunities || opportunities.length === 0
  const isCreatingOpportunity = watch('isCreatingOpportunity')

  useEffect(
    function resetLinkedOpportunity() {
      const defaultOpportunityID = opportunities?.length === 1 ? opportunities[0].id : ''

      setValue('opportunity_id', defaultOpportunityID)
      clearErrors()
    },
    [isCreatingOpportunity, setValue, clearErrors, opportunities],
  )

  useEffect(
    function switchToExistingOpportunity() {
      if (!isCreatingOpportunity || noOpportunities || touchedFields['isCreatingOpportunity'])
        return

      setValue('isCreatingOpportunity', false, { shouldTouch: true })
    },
    [isCreatingOpportunity, noOpportunities, setValue, touchedFields],
  )

  const createOpportunityChoices = useMemo(() => {
    return CREATE_OPPORTUNITY_CHOICES.map((choice) => {
      if (choice.id === 'false' && noOpportunities) {
        return {
          ...choice,
          tooltip: 'You do not have existing Opportunities for this Client.',
          disabled: true,
        }
      }

      return choice
    })
  }, [noOpportunities])

  return (
    <>
      <RadioCardGroupInput
        source="isCreatingOpportunity"
        label="How would you like to link this Follow-up? An Opportunity can have multiple Follow-ups."
        format={(value) => (value === true ? 'true' : 'false')}
        parse={(value) => (value === 'true' ? true : false)}
        choices={createOpportunityChoices}
      />
      {isCreatingOpportunity ? (
        <OpportunityFormControls disabled={isLoading} />
      ) : (
        <LinkToExistingOpportunity />
      )}
    </>
  )
}

const LinkToExistingOpportunity = () => {
  const record = useRecordContext<FormRecord>()
  const {
    field,
    fieldState: { invalid },
  } = useInput({
    source: 'opportunity_id',
    resource: Resources.OPPORTUNITIES,
    validate: required(),
  })

  const assigned_agent_id = record.agent_id
  const { lead_id } = record.person

  return (
    <ListBase
      {...listBaseDefaults({ primaryList: false })}
      resource={Resources.OPPORTUNITIES}
      sort={{ field: OPPORTUNITIES_SOURCE.UPDATED_AT, order: SortOrder.DESC }}
      queryOptions={{
        meta: {
          lead_id, // Force refresh on change
          assigned_agent_id, // Force refresh on change

          query: getOpportunitiesList({
            lead_id,
            assigned_agent_id,
          }),
        } as DataProviderMeta<FetchType.GET_LIST>,
      }}
    >
      <WithListContext<OpportunityRecord>
        render={({ data: opportunities }) => {
          return (
            <Stack direction="column" spacing={2}>
              <FormLabel error={invalid} role={invalid ? 'alert' : 'none'}>
                Select one of the Opportunities created for{' '}
                <strong data-dd-privacy={SessionReplayPrivacy.MASK}>
                  {record.person.fname} {record.person.lname}
                </strong>{' '}
                below:
              </FormLabel>
              {opportunities?.map((opportunity, index) => (
                <RecordContextProvider key={opportunity.id} value={opportunity}>
                  <OpportunityCard
                    collapsed
                    followUpsList={<ExtendedFollowUpList />}
                    listItemMarker={
                      <Radio
                        inputProps={{ 'aria-label': `Opportunity ${index + 1}` }}
                        {...field}
                        value={opportunity.id}
                        checked={field.value === opportunity.id}
                      />
                    }
                  />
                </RecordContextProvider>
              ))}
            </Stack>
          )
        }}
      />
      <PaginationIfNeeded />
    </ListBase>
  )
}
