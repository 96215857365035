import { WithRecord } from 'react-admin'

import {
  EnumField,
  NullableDateField,
  NullableTextField,
  TextEllipsisField,
} from 'components/fields'
import { LABEL } from 'i18n/resource-translations'
import { DEFAULT_COLUMN_WIDTH } from 'lib/helpers/column-width-values'
import { ALL_FOLLOW_UPS_SOURCE } from 'resources/all_follow_ups/AllFollowUpsList'
import { FOLLOW_UP_PHASE_DISPLAY_VALUES } from 'resources/all_follow_ups/follow-up-constants'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { SortOrder } from 'types/react-admin.types'
import { AllFollowUpsRecord, FollowUpType } from 'types/records.types'

import {
  OpportunityFollowUpsList,
  OpportunityFollowUpsListProps,
} from '../OpportunityFollowUpsList'

export const ExtendedFollowUpList = (props: Omit<OpportunityFollowUpsListProps, 'children'>) => (
  <OpportunityFollowUpsList {...props}>
    <NullableDateField<AllFollowUpsRecord>
      source={ALL_FOLLOW_UPS_SOURCE.SCHEDULED_TIME}
      showTooltip
      options={{
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      }}
      sortByOrder={SortOrder.DESC}
    />
    <NullableTextField<AllFollowUpsRecord> source={ALL_FOLLOW_UPS_SOURCE.TYPE} />
    <EnumField<AllFollowUpsRecord>
      source={ALL_FOLLOW_UPS_SOURCE.SUB_TYPE}
      displayValues={FOLLOW_UP_PHASE_DISPLAY_VALUES}
    />

    <NullableDateField<AllFollowUpsRecord>
      label={LABEL.CREATED_AT}
      source={ALL_FOLLOW_UPS_SOURCE.CREATED_AT}
      showTooltip
      options={{
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      }}
    />

    <WithRecord<AllFollowUpsRecord>
      label={LABEL.NOTES}
      render={(followUp) => (
        <TextEllipsisField
          data-dd-privacy={SessionReplayPrivacy.MASK}
          width={`${DEFAULT_COLUMN_WIDTH.NOTES}px`}
          source={
            followUp.type === FollowUpType.APPOINTMENT
              ? ALL_FOLLOW_UPS_SOURCE.APPOINTMENT_NOTES
              : ALL_FOLLOW_UPS_SOURCE.REMINDER_NOTES
          }
        />
      )}
    />
  </OpportunityFollowUpsList>
)
