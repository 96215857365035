import { ComponentProps, useMemo } from 'react'

import {
  FunctionField,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TextInput,
  useEditContext,
  useGetManyReference,
  useGetOne,
} from 'react-admin'

import { Box, Grid, Tooltip, Typography } from '@mui/material'

import { Loading } from '@assuranceiq/react-components'

import { addDays, startOfHour } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { FormLabelWithTooltip } from 'components/FormLabelWithTooltip'
import { RadioCardGroupInput } from 'components/inputs'
import { TimeAgo } from 'components/TimeAgo'
import { NullValue } from 'components/values'
import { convertEnumToChoices } from 'lib/helpers/convert-ra-field-data'
import { displayFullDateTime } from 'lib/helpers/datetime-helpers'
import { getData } from 'lib/helpers/get-data'
import { followUpResourceByType } from 'lib/helpers/resource-mapping'
import { ALL_FOLLOW_UPS_SOURCE } from 'resources/all_follow_ups/AllFollowUpsList'
import {
  FOLLOW_UP_PHASE_DISPLAY_VALUES,
  TYPE_CHOICES,
} from 'resources/all_follow_ups/follow-up-constants'
import { FormRecord } from 'types/forms.types'
import {
  AllFollowUpsRecord,
  FollowUpAppointmentRecord,
  FollowUpReminderRecord,
  FollowUpSubType,
  FollowUpType,
} from 'types/records.types'

import { AppointmentDateTime } from './components/AppointmentDateTime'
import { TextConfirmation } from './components/TextConfirmation'

const LOADER_HEIGHT = 480 // 500 (min dialog height set in 'EditFollowUpButton.module.scss') - 20 (title height)

export type FollowUpFormValues = FormRecord &
  Pick<AllFollowUpsRecord, 'id' | 'type' | 'sub_type' | 'duration_minutes' | 'scheduled_time'> &
  Partial<Pick<AllFollowUpsRecord, 'scheduled_timezone'>> &
  Pick<FollowUpAppointmentRecord, 'send_sms_confirmation'> & {
    note: string
  }

export interface FollowUpFormProps extends Partial<ComponentProps<typeof SimpleForm>> {
  defaultValues?: Partial<FollowUpFormValues>
}

export const getFollowUpDefaultValues = (defaultValues?: FollowUpFormProps['defaultValues']) => {
  const startOfCurrentHour = startOfHour(new Date())
  const tomorrowSameTime = addDays(startOfCurrentHour, 1)

  return {
    type: FollowUpType.APPOINTMENT,
    sub_type: FollowUpSubType.PRE_ENROLLMENT,
    duration_minutes: 15,
    scheduled_time: tomorrowSameTime.toISOString(),
    send_sms_confirmation: true,
    ...defaultValues,
  } satisfies Partial<FollowUpFormValues>
}

interface EditFollowUpFormProps extends FollowUpFormProps {
  formRecord: FormRecord
}

export const EditFollowUpForm = ({
  defaultValues,
  formRecord,
  ...props
}: EditFollowUpFormProps) => {
  const { record: { id: followUpId, ...followUpRecord } = {} } = useEditContext<
    FollowUpAppointmentRecord | FollowUpReminderRecord
  >()

  const followUpType =
    'appointment_type' in followUpRecord ? FollowUpType.APPOINTMENT : FollowUpType.REMINDER

  const { data: reminderOrAppointment, isLoading: isReminderOrAppointmentLoading } = useGetOne<
    FollowUpAppointmentRecord | FollowUpReminderRecord
  >(followUpResourceByType[followUpType], {
    id: followUpId || '',
  })

  const { data: notes, isLoading: isNotesLoading } = useGetManyReference('notes', {
    target:
      followUpType === FollowUpType.APPOINTMENT
        ? 'follow_up_appointment_id'
        : 'follow_up_reminder_id',
    id: followUpId,
  })

  const mergedDataValues =
    reminderOrAppointment && 'appointment_type' in reminderOrAppointment
      ? {
          type: FollowUpType.APPOINTMENT,
          sub_type: reminderOrAppointment.appointment_type,
        }
      : {
          type: FollowUpType.REMINDER,
          sub_type: reminderOrAppointment?.reminder_type,
        }

  if (isReminderOrAppointmentLoading || isNotesLoading)
    return <Loading height={LOADER_HEIGHT} justifyContent="center" width="100%" />

  return (
    <FollowUpForm
      defaultValues={{
        ...defaultValues,
        ...formRecord,
        ...reminderOrAppointment,
        ...mergedDataValues,
        note: notes?.[0]?.body,
      }}
      {...props}
    />
  )
}

export const FollowUpForm = ({ defaultValues, ...props }: FollowUpFormProps) => {
  return (
    <SimpleForm
      toolbar={
        <Box
          sx={{
            p: 3,
            pt: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FunctionField<AllFollowUpsRecord>
            source={ALL_FOLLOW_UPS_SOURCE.UPDATED_AT}
            render={(record, source) => {
              const isEditForm = !!record.id
              if (!isEditForm) return null

              const lastUpdated = getData<string>(record, source)

              return (
                <Typography component="span" variant="small" color="text.secondary">
                  Last updated{' '}
                  {lastUpdated ? (
                    <Tooltip title={displayFullDateTime(lastUpdated)}>
                      <TimeAgo date={lastUpdated} />
                    </Tooltip>
                  ) : (
                    <NullValue />
                  )}
                </Typography>
              )
            }}
          />
          <SaveButton alwaysEnable />
        </Box>
      }
      padding={1}
      defaultValues={{ ...getFollowUpDefaultValues(defaultValues) }}
      {...props}
    >
      <FollowUpFormControls />
    </SimpleForm>
  )
}

export const FollowUpFormControls = () => {
  const { watch } = useFormContext<FollowUpFormValues>()
  const type = watch('type')
  const followUpId = watch('id')
  const isEditForm = !!followUpId

  return (
    <Grid container>
      <Grid item xs={12}>
        {!isEditForm && (
          <RadioCardGroupInput
            source="type"
            label="How do you want to Follow-up with this client?"
            disabled={isEditForm}
            choices={TYPE_CHOICES}
          />
        )}

        <FollowUpTypeInput />

        <AppointmentDateTime />

        {type === FollowUpType.APPOINTMENT && <TextConfirmation />}

        <TextInput
          source="note"
          sx={{ mt: 2 }}
          label={
            <FormLabelWithTooltip
              title={
                !isEditForm &&
                'Warning: Do not include PII such as SSN, Credit Card, or Bank information'
              }
              conditionalTooltipProps={{ placement: 'right' }}
            >
              Notes (optional)
            </FormLabelWithTooltip>
          }
          placeholder="Do not include PII such as SSN, Credit Card, or Bank information"
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  )
}

const FollowUpTypeInput = () => {
  const { watch } = useFormContext<FollowUpFormValues>()
  const type = watch('type')

  const choices = useMemo(
    () => convertEnumToChoices(FollowUpSubType, { displayNames: FOLLOW_UP_PHASE_DISPLAY_VALUES }),
    [],
  )

  return (
    <RadioButtonGroupInput
      source="sub_type"
      label={`What type of ${type} is this?`}
      choices={choices}
    />
  )
}
