import { useCallback } from 'react'

import { Validator } from 'react-admin'

import { addDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useFormContext } from 'react-hook-form'

import { safeParseDate } from 'lib/helpers/datetime-helpers'
import { FollowUpFormValues } from 'resources/all_follow_ups/components'
import { FollowUpType } from 'types/records.types'

export interface FindCallingTimeHookOptions {
  clientTimezone: string
}

const CALLING_HOURS_START = 8
const CALLING_HOURS_END = 20

/**
 * Returns the available to call appointment time range in the client’s timezone
 *
 * @param date - The date in client's timezone
 * @returns An array of two dates: [start, end]
 */
const callingTimeRange = (date: Date) => {
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), CALLING_HOURS_START)
  const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), CALLING_HOURS_END)

  return [start, end]
}

/**
 * Returns the next available to call time in the client’s timezone
 *
 * @param date - The date in client's timezone
 */
const nearestCallingTime = (date: Date) => {
  const [start, end] = callingTimeRange(date)

  if (date < start) return start
  if (date > end) return addDays(start, 1)

  return date
}

export const useFindAppointmentCallingTime = ({ clientTimezone }: FindCallingTimeHookOptions) => {
  const { watch } = useFormContext<FollowUpFormValues>()
  const type = watch('type')

  const isCallingTime = useCallback(
    (date: Date) => {
      if (type !== FollowUpType.APPOINTMENT) return true

      const [start, end] = callingTimeRange(date)

      return date >= start && date <= end
    },
    [type],
  )

  const validateAppointmentCallingTime = useCallback<Validator>(
    (rawDateString: FollowUpFormValues['scheduled_time']) => {
      if (type !== FollowUpType.APPOINTMENT) return

      const zonedScheduleTime = utcToZonedTime(safeParseDate(rawDateString), clientTimezone)

      if (isCallingTime(zonedScheduleTime)) return

      const [start, end] = callingTimeRange(zonedScheduleTime)
      const startTime = start.toLocaleTimeString(navigator.language, { timeStyle: 'short' })
      const endTime = end.toLocaleTimeString(navigator.language, { timeStyle: 'short' })

      return `Scheduled time must be in calling hours between ${startTime}-${endTime}`
    },
    [clientTimezone, isCallingTime, type],
  )

  return {
    isCallingTime,
    nearestCallingTime,
    validateAppointmentCallingTime,
  }
}
