import OpenInNew from '@mui/icons-material/OpenInNew'
import { Button, ButtonProps } from '@mui/material'

import { ConditionalTooltip } from 'components/ConditionalTooltip'
import { isCurrentPageInIframe } from 'lib/helpers/iframe-helpers'
import { MessageType, RedirectCRMMessage, sendCrmMessage } from 'lib/helpers/iframe-messaging'
import { plateURL } from 'lib/helpers/path-helpers'
import { PersonRecord } from 'types/records.types'

export interface OpenPlateButtonProps extends Omit<ButtonProps, 'onClick'> {
  person?: PersonRecord | null
  children?: ButtonProps['children']
}

export const OpenPlateButton = ({
  person,
  children = 'Open Plate',
  ...props
}: OpenPlateButtonProps) => {
  const disableButton = !person?.lead_id

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!person) return
    if (!isCurrentPageInIframe) return
    if (event.metaKey || event.ctrlKey) return // Allow opening in new tab

    event.preventDefault()
    sendCrmMessage<RedirectCRMMessage>(MessageType.REDIRECT_CRM, {
      url: plateURL(person),
    })
  }

  return (
    <ConditionalTooltip enabled={disableButton} title="Unable to determine Lead ID">
      {/* @ts-ignore errors about target attribute—it’s supported */}
      <Button
        color="primary"
        size="small"
        startIcon={<OpenInNew />}
        {...props}
        disabled={disableButton}
        href={disableButton ? undefined : plateURL(person)}
        onClick={onClick}
      >
        {children}
      </Button>
    </ConditionalTooltip>
  )
}
