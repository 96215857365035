import { ElementType, forwardRef } from 'react'

import { TextInput, TextInputProps } from 'react-admin'

import { InputBaseComponentProps } from '@mui/material'

import { noop } from 'lodash-es'
import { useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'

import { PHONE_REGEX } from 'lib/helpers/phone-helpers'

export interface PhoneInputProps extends TextInputProps {}

// SEE: https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
interface MaskedInputProps extends Omit<InputBaseComponentProps, 'onChange'> {
  onChange: (event: { target: { name: string; value: string } }) => void
}

const MaskedInput: ElementType<InputBaseComponentProps> = forwardRef<
  HTMLInputElement,
  MaskedInputProps
>(function MaskedInput({ onChange: _ignoredOnChange, ...props }, ref) {
  const { setValue } = useFormContext()

  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      mask="(000) 000-0000"
      unmask={true}
      overwrite="shift"
      onAccept={(value: string) => setValue(props.name, value)}
      onChange={
        // suppress warnings about controlled input, we use `onAccept` instead
        noop
      }
    />
  )
})

export function PhoneInput({
  format = formatPhone,
  parse = parsePhone,
  validate = validatePhone,
  ...props
}: PhoneInputProps) {
  return (
    <TextInput
      {...{ format, parse, validate, ...props }}
      InputProps={{
        inputComponent: MaskedInput,
        ...props.InputProps,
      }}
    />
  )
}

const formatPhone: TextInputProps['format'] = (phone?: string | null) =>
  phone?.replace(/^\+1/, '') ?? '' // remove "+1" prefix
const parsePhone: TextInputProps['parse'] = (phone?: string | null) =>
  phone?.replace(/^(?=[^+])/, '+1') ?? '' // add "+1" prefix
const validatePhone: TextInputProps['validate'] = (phone?: string | null) =>
  phone && !phone.match(PHONE_REGEX) ? 'Must be a full 10 digit phone number' : undefined
