import { ReactNode } from 'react'

import { GridColDef } from '@mui/x-data-grid-pro'

export interface ColumnProps extends Partial<GridColDef> {
  children: ReactNode

  /** @deprecated TODO: add support to MuiDatagrid */
  disableReorder?: boolean

  /** @deprecated TODO: add support to MuiDatagrid */
  hideable?: boolean
}

/**
 * Define columns in the MuiDatagrid, with `GridColDef` props.
 *
 * Use as a direct child of `<MuiDatagrid>`, and then add React Admin `<Field>`
 * components as children.
 *
 * @see [`GridColDef` documentation](https://mui.com/x/react-data-grid/column-definition/#api)
 * @see [React Admin `Field`s documentation](https://marmelab.com/react-admin/Fields.html)
 */
export const Column = ({ children }: ColumnProps) => <>{children}</>
