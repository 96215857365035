/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Inspired by [`ra-data-hasura` buildGetListVariables](https://github.com/hasura/ra-data-hasura/blob/6af27545608d3adc1f3b5be17f1c0bfb6a5965a3/src/buildVariables/buildGetListVariables.ts)
 * without introspection
 */

import { omit, set } from 'lodash-es'

import { FetchType } from 'hooks/useDataProvider'

type BuildGetListVariables = (resource: string, fetchType: FetchType, params: any) => any

const SPLIT_TOKEN = '#'
const MULTI_SORT_TOKEN = ','
const SPLIT_OPERATION = '@'

export const buildGetListVariables: BuildGetListVariables = (resource, _, params) => {
  const result: any = {}
  const {
    customFilters = [],
    filter: { distinct_on = '', ...restFilter },
  } = params
  let filterObj = { ...restFilter }

  /**
   * Keys with comma separated values
   * @example
   * {
   *   'title@ilike,body@like,authors@similar': string,
   *   'col1@like,col2@like': string
   * }
   */
  const orFilterKeys = Object.keys(filterObj).filter((e) => e.includes(','))

  /**
   * Format filters
   * @example
   * {
   *   'title@ilike': 'test',
   *   'body@like': 'test',
   *   'authors@similar': 'test',
   *   'col1@like': 'val',
   *   'col2@like': 'val'
   * }
   */
  const orFilterObj = orFilterKeys.reduce((acc, commaSeparatedKey) => {
    const keys = commaSeparatedKey.split(',')
    return {
      ...acc,
      ...keys.reduce((acc2, key) => {
        return {
          ...acc2,
          [key]: filterObj[commaSeparatedKey],
        }
      }, {}),
    }
  }, {})
  filterObj = omit(filterObj, orFilterKeys)

  // eslint doesn't like the recursive function
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const makeNestedFilter = (obj: any, operation: string): any => {
    if (Object.keys(obj).length === 1) {
      const [key] = Object.keys(obj)
      return { [key]: makeNestedFilter(obj[key], operation) }
    } else {
      return { [operation]: obj }
    }
  }

  const filterReducer = (obj: any) => (acc: any, key: any) => {
    let filter
    if (key === 'ids') {
      filter = { id: { _in: obj['ids'] } }
    } else if (Array.isArray(obj[key])) {
      const [keyName, operation = '_in', opPath] = key.split(SPLIT_OPERATION)
      const value = opPath ? set({}, opPath.split(SPLIT_TOKEN), obj[key]) : obj[key]
      filter = set({}, keyName.split(SPLIT_TOKEN), { [operation]: value })
    } else if (obj[key] && obj[key].format === 'hasura-raw-query') {
      filter = set({}, key.split(SPLIT_TOKEN), obj[key].value || {})
    } else {
      const [keyName, operation = ''] = key.split(SPLIT_OPERATION)
      let operator
      if (operation === '{}') operator = {}

      // PATCH: There are no field types without introspection, so filter operators are hardcoded
      // SEE: https://github.com/hasura/ra-data-hasura/blob/6af27545608d3adc1f3b5be17f1c0bfb6a5965a3/src/buildVariables/buildGetListVariables.ts#L102-L132

      // Else block runs when the field is not found in Graphql schema.
      // Most likely it's nested. If it's not, it's better to let
      // Hasura fail with a message than silently fail/ignore it
      if (!operator)
        operator = {
          [operation || '_eq']: operation.includes('like') ? `%${obj[key]}%` : obj[key],
        }

      filter = set({}, keyName.split(SPLIT_TOKEN), operator)
    }
    return [...acc, filter]
  }
  const andFilters = Object.keys(filterObj)
    .reduce(filterReducer(filterObj), customFilters)
    .filter(Boolean)
  const orFilters = Object.keys(orFilterObj).reduce(filterReducer(orFilterObj), []).filter(Boolean)

  result['where'] = {
    _and: andFilters,
    ...(orFilters.length && { _or: orFilters }),
  }

  if (params.pagination && params.pagination.perPage > -1) {
    result['limit'] = parseInt(params.pagination.perPage, 10)
    result['offset'] = (params.pagination.page - 1) * params.pagination.perPage
  }

  if (params.sort) {
    const { field, order } = params.sort
    const hasMultiSort = field.includes(MULTI_SORT_TOKEN) || order.includes(MULTI_SORT_TOKEN)
    if (hasMultiSort) {
      const fields = field.split(MULTI_SORT_TOKEN)
      const orders = order.split(MULTI_SORT_TOKEN).map((order: string) => order.toLowerCase())

      if (fields.length !== orders.length) {
        throw new Error(
          `The ${resource} list must have an order value for each sort field. Sort fields are "${fields.join(
            ',',
          )}" but sort orders are "${orders.join(',')}"`,
        )
      }

      const multiSort = fields.map((field: any, index: number) => makeSort(field, orders[index]))
      result['order_by'] = multiSort
    } else {
      result['order_by'] = makeSort(field, order)
    }
  }

  if (distinct_on) {
    result['distinct_on'] = distinct_on
  }

  return result
}

/**
 * if the field contains a SPLIT_OPERATION, it means it's column ordering option.
 *
 * @example
 * ```
 * makeSort('title', 'ASC') => { title: 'asc' }
 * ```
 * @example
 * ```
 * makeSort('title@nulls_last', 'ASC') => { title: 'asc_nulls_last' }
 * ```
 * @example
 * ```
 * makeSort('title@nulls_first', 'ASC') => { title: 'asc_nulls_first' }
 * ```
 */
const makeSort = (field: string, sort: 'ASC' | 'DESC') => {
  const [fieldName, operation] = field.split(SPLIT_OPERATION)
  const fieldSort = operation ? `${sort}_${operation}` : sort
  return set({}, fieldName, fieldSort.toLowerCase())
}
