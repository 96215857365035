import { CreateParams } from 'react-admin'

import { gql } from 'graphql-tag'

import { OpportunityWizardFormValues } from 'components/FollowUpOpportunityWizard'
import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { FollowUpFormValues } from 'resources/all_follow_ups/components'
import { OpportunityFormValues } from 'resources/opportunities'
import { CreateFollowUpInput, CreateOpportunityInput, FollowUpType } from 'types/records.types'

// SEE: https://docs.people.assurance.com/#mutation-insert_opportunities
export const CREATE_OPPORTUNITY_AND_FOLLOW_UP = gql`
  mutation Insert_opportunities($opportunity: CreateOpportunityInput!) {
    data: insert_opportunities(opportunity: $opportunity) {
      returning {
        id # ID required for react-admin/react-query caching
        agent_assigned_opportunity_quality
        agent_assigned_sales_stage
        assigned_agent_id
        created_at
        created_by_id
        enrollment_period
        lead_id
        loi
        products
        source
        updated_at

        follow_up_appointments(limit: 1, order_by: { created_at: desc }) {
          id # ID required for react-admin/react-query caching
          appointment_type
          scheduled_time
        }

        follow_up_reminders(limit: 1, order_by: { created_at: desc }) {
          id # ID required for react-admin/react-query caching
          reminder_type
          scheduled_time
        }
      }
    }
  }
`

// SEE: https://docs.people.assurance.com/#mutation-insert_follow_up_appointments
export const CREATE_FOLLOW_UP_APPOINTMENT = gql`
  mutation Insert_follow_up_appointments($follow_up: CreateFollowUpAppointmentInput!) {
    data: insert_follow_up_appointments(follow_up: $follow_up) {
      returning {
        id # ID required for react-admin/react-query caching
        appointment_type
        duration_minutes
        opportunity_id
        scheduled_time
        created_at
        updated_at
      }
    }
  }
`

// SEE: https://docs.people.assurance.com/#mutation-insert_follow_up_reminders
export const CREATE_FOLLOW_UP_REMINDER = gql`
  mutation Insert_follow_up_reminders($follow_up: CreateFollowUpReminderInput!) {
    data: insert_follow_up_reminders(follow_up: $follow_up) {
      returning {
        id # ID required for react-admin/react-query caching
        reminder_type
        duration_minutes
        opportunity_id
        scheduled_time
        created_at
        updated_at
      }
    }
  }
`

export const createFollowUpQuery: CustomQuery<FetchType.CREATE> = async ({
  client,
  clientOptions,
  parseResponse,
  params,
}) => {
  const {
    data: { isCreatingOpportunity, type: followUpType },
  } = params as CreateParams<
    FollowUpFormValues & OpportunityFormValues & OpportunityWizardFormValues
  >

  const variables = isCreatingOpportunity
    ? variablesForCreateOpportunityAndFollowUp(params)
    : variablesForCreateFollowUpForExistingOpportunity(params)

  const mutation = isCreatingOpportunity
    ? CREATE_OPPORTUNITY_AND_FOLLOW_UP
    : followUpType === FollowUpType.APPOINTMENT
    ? CREATE_FOLLOW_UP_APPOINTMENT
    : CREATE_FOLLOW_UP_REMINDER

  const response = await client.mutate({
    ...clientOptions,

    mutation,
    variables,
  })

  return parseResponse(response)
}

type GetVariables = (
  data: CreateParams<FollowUpFormValues & OpportunityFormValues & OpportunityWizardFormValues>,
) => object

const variablesForCreateOpportunityAndFollowUp: GetVariables = (params) => {
  const {
    data: {
      person: { lead_id },
      agent_id,
      agent_assigned_opportunity_quality,
      agent_assigned_sales_stage,
      delta_contact_id,
      enrollment_period,
      loi,
      products,
      sub_type,
      scheduled_time,
      duration_minutes,
      note,
      send_sms_confirmation,
      source,
      type,
    },
  } = params

  const followUp: Omit<CreateFollowUpInput, 'opportunity_id'> = {
    scheduled_time,
    duration_minutes,
    note,
    ...(type === FollowUpType.APPOINTMENT && {
      send_sms_confirmation,
      appointment_type: sub_type,
    }),
    ...(type === FollowUpType.REMINDER && { reminder_type: sub_type }),
  }

  return {
    opportunity: {
      lead_id,

      assigned_agent_id: agent_id,
      created_by_id: agent_id,

      agent_assigned_opportunity_quality,
      agent_assigned_sales_stage,
      enrollment_period,
      loi,
      products,

      delta_contact_id,
      source,

      ...(type === FollowUpType.APPOINTMENT && { follow_up_appointments: followUp }),
      ...(type === FollowUpType.REMINDER && { follow_up_reminders: followUp }),
    } satisfies CreateOpportunityInput,
  }
}

const variablesForCreateFollowUpForExistingOpportunity: GetVariables = (params) => {
  const {
    data: {
      duration_minutes,
      note,
      opportunity_id,
      sub_type,
      scheduled_time,
      send_sms_confirmation,
      type,
    },
  } = params

  if (!opportunity_id)
    throw new Error('Unable to create linked follow-up: Missing required `opportunity_id`')

  return {
    follow_up: {
      opportunity_id,
      scheduled_time,
      duration_minutes,
      note,
      ...(type === FollowUpType.APPOINTMENT && {
        send_sms_confirmation,
        appointment_type: sub_type,
      }),
      ...(type === FollowUpType.REMINDER && { reminder_type: sub_type }),
    } satisfies CreateFollowUpInput,
  }
}
