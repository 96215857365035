/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Inspired by [`ra-data-hasura` buildVariables](https://github.com/hasura/ra-data-hasura/blob/8503ba74adf33632b455e874356d0cc6b3f053ca/src/buildVariables/index.ts)
 * without introspection
 */

import { FetchType } from 'hooks/useDataProvider'

import { buildGetListVariables } from './buildGetListVariables'
import { makeNestedTarget } from './makeNestedTarget'

export type BuildVariables = (resource: string, fetchType: FetchType, params: any) => any

export const buildVariables: BuildVariables = (resource, fetchType, params) => {
  switch (fetchType) {
    case FetchType.GET_LIST:
      return buildGetListVariables(resource, fetchType, params)
    case FetchType.GET_MANY_REFERENCE: {
      const built = buildGetListVariables(resource, fetchType, params)
      if (params.filter) {
        return {
          ...built,
          where: {
            _and: [...built['where']['_and'], makeNestedTarget(params.target, params.id)],
          },
        }
      }
      return {
        ...built,
        where: makeNestedTarget(params.target, params.id),
      }
    }
    case FetchType.GET_MANY:
    case FetchType.DELETE_MANY:
      return {
        where: { id: { _in: params.ids } },
      }

    case FetchType.GET_ONE:
      return {
        where: { id: { _eq: params.id } },
        limit: 1,
      }

    case FetchType.DELETE:
      return {
        where: { id: { _eq: params.id } },
      }
    case FetchType.CREATE:
      return {
        objects: params.data,
      }

    case FetchType.UPDATE:
      return {
        _set: params.data,
        where: { id: { _eq: params.id } },
      }

    case FetchType.UPDATE_MANY:
      return {
        _set: params.data,
        where: { id: { _in: params.ids } },
      }
  }
}
