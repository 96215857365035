import { CRM_URL } from 'lib/environment-variables'
import { LeadType } from 'types/records.types'

export type IframeMessage = {
  type: MessageType
}

export type IframeURLMessage = IframeMessage & {
  type: MessageType.GET_IFRAME_URL
  url: string
}

export type RedirectCRMMessage = IframeMessage & {
  type: MessageType.REDIRECT_CRM
  url: string
}

/**
 * Message sent from the CRM to the Vega iFrame when the user opens the CreateFromPlates modal
 * (i.e. the "Pipeline" button in the CRM plates view)
 *
 * @see https://github.com/assuranceiq/insurance/blob/03d9785d21654e842ecf2c125b17931488170aa3/app/views/agent/common/_vega_pipeline_button.html.slim#L35-L63
 */
export type ModalOpenMessage = IframeMessage & {
  type: MessageType.MODAL_OPEN

  agent_fname?: string
  delta_contact_id?: string

  // NOTE: Any changes below require a corresponding change in the CRM
  // SEE: https://github.com/assuranceiq/insurance/blob/03d9785d21654e842ecf2c125b17931488170aa3/app/views/agent/common/_vega_pipeline_button.html.slim#L45-L62
  person: {
    lead_id: number
    lead_type: LeadType
    fname?: string
    lname?: string
    gender?: string
    birth_date?: string

    contact_method: {
      email?: string
      phone?: string
      city?: string
      state?: string
      zip?: string
    }
  }
}

/**
 * Message sent from the CRM to the Vega iFrame when Client page is loaded
 * @see https://github.com/assuranceiq/insurance/pull/41413/commits/0afcea63efebd2ea7265b7af2fe6c46c0fae8ce0#diff-f57d95ca31b5e8a639eba8b12746d235fbfb791b0c7bcd948175c519bcd95b10R26
 *
 * Original Intercom settings structure can be found here:
 * @see https://github.com/assuranceiq/insurance/blob/master/app/views/layouts/agent.html.slim#L105
 */
export type IntercomSettingsMessage = IframeMessage & {
  app_id?: string
  api_base?: string
  name?: string
  email?: string
  created_at?: string
  assurance_selling_line_of_insurance?: string
  assurance_team_name?: string
  assurance_start_date?: string
  assurance_user_role?: string
  payment_failure_commission?: string
  user_hash: string
}

/**
 * Message types sent to/from the CRM via `window.postMessage()`. Search the CRM codebase for these
 * names to find the corresponding code.
 */
export enum MessageType {
  GET_IFRAME_URL = 'GET_IFRAME_URL',

  LOGGED_IN = 'VEGA_MODAL_LOGGED_IN',
  MODAL_OPEN = 'VEGA_MODAL_OPEN',
  MODAL_CLOSED = 'VEGA_MODAL_CLOSED',

  REDIRECT_CRM = 'VEGA_REDIRECT_CRM',

  INTERCOM_GET_SETTINGS = 'VEGA_INTERCOM_GET_SETTINGS',
  INTERCOM_POST_SETTINGS = 'VEGA_INTERCOM_POST_SETTINGS',
}

export const sendCrmMessage = <Message extends IframeMessage = IframeMessage>(
  type: Message['type'],
  payload?: Omit<Message, 'type'>,
) => {
  window.parent.postMessage(
    {
      type,
      ...payload,
    },
    CRM_URL,
  )
}
