import { QueryOptions } from '@apollo/client'
import { gql } from 'graphql-tag'

import { FetchType } from 'hooks/useDataProvider'
import { api } from 'lib/api'
import { Resources } from 'lib/api/api.types'

export interface BuildQueryParams {
  resource: Resources
  fetchType: FetchType
}

function resourceFields(resource: Resources) {
  return api.resourceFields[resource] || 'id'
}

export function buildQuery({ resource, fetchType }: BuildQueryParams): QueryOptions['query'] {
  switch (fetchType) {
    case FetchType.GET_LIST:
    case FetchType.GET_MANY_REFERENCE:
      return gql`
        query ($limit: Int, $offset: Int, $order_by: [${resource}_order_by!], $where: ${resource}_bool_exp) {
          items: ${resource}(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
            ${resourceFields(resource)}
          }
          total: ${resource}_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }`

    case FetchType.GET_ONE:
      return gql`
        query ($limit: Int, $where: ${resource}_bool_exp) {
          returning: ${resource}(limit: $limit, where: $where) {
            ${resourceFields(resource)}
          }
        }`

    case FetchType.GET_MANY:
      return gql`
        query ($where: ${resource}_bool_exp) {
          items: ${resource}(where: $where) {
            ${resourceFields(resource)}
          }
        }`

    case FetchType.CREATE:
      return gql`
        mutation ($data: ${resource}_insert_input!) {
          data: insert_${resource}(object: $data) {
            returning {
              ${resourceFields(resource)}
            }
          }
        }`

    case FetchType.UPDATE:
      return gql`
        mutation ($id: Int!, $data: ${resource}_set_input!) {
          data: update_${resource}_by_pk(pk_columns: { id: $id }, _set: $data) {
            returning {
              ${resourceFields(resource)}
            }
          }
        }`

    case FetchType.UPDATE_MANY:
      return gql`
        mutation ($where: ${resource}_bool_exp!, $data: ${resource}_set_input!) {
          data: update_${resource}(where: $where, _set: $data) {
            affected_rows
          }
        }`

    case FetchType.DELETE:
      return gql`
        mutation ($id: Int!) {
          returning: delete_${resource}_by_pk(id: $id) {
            ${resourceFields(resource)}
          }
        }`

    case FetchType.DELETE_MANY:
      return gql`
        mutation ($where: ${resource}_bool_exp!) {
          data: delete_${resource}(where: $where) {
            affected_rows
          }
        }`
  }
}
