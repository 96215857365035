import { FC } from 'react'

import { TopToolbar } from 'react-admin'

import { Stack } from '@mui/material'

import { MuiDatagridColumnsButton } from './MuiDatagridColumnsButton'
import { MuiDatagridDensityButton } from './MuiDatagridDensityButton'

export const MuiDatagridListActions: FC = ({ children }) => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    {children}
    <Stack direction="row" spacing={1}>
      <MuiDatagridColumnsButton variant="outlined" color="secondary" size="small" />
      <MuiDatagridDensityButton variant="outlined" color="secondary" size="small" />
    </Stack>
  </TopToolbar>
)
