import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'
import { GridColumnHeaderTitle, GridColumnHeaderTitleProps } from '@mui/x-data-grid-pro'

import { ConditionalTooltip } from 'components/ConditionalTooltip'

import styles from './HeaderCellWithTooltip.module.scss'

export interface HeaderCellWithTooltipProps extends Partial<GridColumnHeaderTitleProps> {}

/**
 * Define MuiDatagrid's header with tooltip icon.
 */
export const HeaderCellWithTooltip = ({
  description,
  label,
  columnWidth,
}: HeaderCellWithTooltipProps) => {
  if (!label || !columnWidth) return null

  return (
    <Box className={styles.root}>
      <ConditionalTooltip title={description}>
        <span className={styles.tooltip}>
          <GridColumnHeaderTitle columnWidth={columnWidth} label={label} description={<></>} />
          {description && <InfoOutlinedIcon fontSize="inherit" />}
        </span>
      </ConditionalTooltip>
    </Box>
  )
}
