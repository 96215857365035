import { CreateParams, UpdateParams } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { OpportunityFormValues } from 'resources/opportunities'

// SEE: https://docs.people.assurance.com/#mutation-insert_opportunities
export const CREATE_OPPORTUNITY = gql`
  mutation Insert_opportunities($opportunity: CreateOpportunityInput!) {
    data: insert_opportunities(opportunity: $opportunity) {
      returning {
        agent_assigned_opportunity_quality
        agent_assigned_sales_stage
        assigned_agent_id
        created_at
        created_by_id
        enrollment_period
        id
        lead_id
        loi
        products
        source
        updated_at
      }
    }
  }
`

// SEE: https://docs.people.assurance.com/#mutation-update_opportunities
export const UPDATE_OPPORTUNITY = gql`
  mutation Update_opportunities($id: uuid!, $opportunity: UpdateOpportunityInput!) {
    data: update_opportunities(id: $id, opportunity: $opportunity) {
      returning {
        agent_assigned_opportunity_quality
        agent_assigned_sales_stage
        assigned_agent_id
        created_at
        created_by_id
        enrollment_period
        id
        lead_id
        loi
        products
        source
        updated_at
      }
    }
  }
`

export const createOpportunityQuery: CustomQuery<FetchType.CREATE> = async ({
  client,
  clientOptions,
  parseResponse,
  params,
}) => {
  const {
    data: {
      person: { lead_id },
      agent_id,
      agent_assigned_opportunity_quality,
      agent_assigned_sales_stage,
      enrollment_period,
      loi,
      products,
      source,
    },
  } = params as CreateParams<OpportunityFormValues>

  const variables = {
    opportunity: {
      source,
      assigned_agent_id: agent_id,
      created_by_id: agent_id,

      lead_id,

      agent_assigned_opportunity_quality,
      agent_assigned_sales_stage,
      enrollment_period,
      loi,
      products,
    },
  }

  const response = await client.mutate({
    ...clientOptions,

    mutation: CREATE_OPPORTUNITY,
    variables,
  })

  return parseResponse(response)
}

export const updateOpportunityQuery: CustomQuery<FetchType.UPDATE> = async ({
  client,
  clientOptions,
  parseResponse,
  params,
}) => {
  const {
    data: {
      id,
      agent_id,
      agent_assigned_opportunity_quality,
      agent_assigned_sales_stage,
      enrollment_period,
      loi,
      products,
      source,
    },
  } = params as UpdateParams<OpportunityFormValues>

  const variables = {
    id,
    opportunity: {
      source,
      assigned_agent_id: agent_id,
      created_by_id: agent_id,

      agent_assigned_opportunity_quality,
      agent_assigned_sales_stage,
      enrollment_period,
      loi,
      products,
    },
  }

  const response = await client.mutate({
    ...clientOptions,

    mutation: UPDATE_OPPORTUNITY,
    variables,
  })

  return parseResponse(response)
}
