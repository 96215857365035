import { DeleteParams } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'

// SEE: https://docs.people.assurance.com/#mutation-delete_follow_up_appointments
export const DELETE_FOLLOW_UP_APPOINTMENTS = gql`
  mutation Delete_follow_up_appointments($id: uuid!) {
    delete_follow_up_appointments(id: $id)
  }
`

// SEE: https://docs.people.assurance.com/#mutation-delete_follow_up_reminders
export const DELETE_FOLLOW_UP_REMINDERS = gql`
  mutation Delete_follow_up_reminders($id: uuid!) {
    delete_follow_up_reminders(id: $id)
  }
`

export const deleteFollowUpAppointments: CustomQuery<FetchType.DELETE> = async ({
  client,
  parseResponse,
  clientOptions,
  params,
}) => {
  const { id } = params as DeleteParams

  const variables = {
    id,
  }

  const response = await client.mutate({
    ...clientOptions,

    mutation: DELETE_FOLLOW_UP_APPOINTMENTS,
    variables,
  })

  // TODO: Ask API team to change all delete endpoints to hasura standard
  return parseResponse({ data: { data: { returning: [response.data] } } })
}

export const deleteFollowUpReminders: CustomQuery<FetchType.DELETE> = async ({
  client,
  parseResponse,
  clientOptions,
  params,
}) => {
  const { id } = params as DeleteParams

  const variables = {
    id,
  }

  const response = await client.mutate({
    ...clientOptions,

    mutation: DELETE_FOLLOW_UP_REMINDERS,
    variables,
  })

  // TODO: Ask API team to change all delete endpoints to hasura standard
  return parseResponse({ data: { data: { returning: [response.data] } } })
}
