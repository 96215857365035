import { useCallback } from 'react'

import { FunctionField, FunctionFieldProps, RaRecord } from 'react-admin'

import { UsState, UsStates } from '@assuranceiq/react-components'

import { NullValue } from 'components/values'
import { getData } from 'lib/helpers/get-data'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { TypedRaRecord } from 'types/react-admin.types'

export interface UsStateFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Omit<FunctionFieldProps<RecordType>, 'render' | 'children'> {}

/**
 * Renders state abbreviations as full names: "CA" -> "California"
 */
export function UsStateField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  ...props
}: UsStateFieldProps<RecordType>) {
  const renderState = useCallback(
    (record: RecordType) => {
      const state = getData<UsState | null>(record, source)

      if (!state) return <NullValue />
      if (!UsStates[state]) return state
      return UsStates[state]
    },
    [source],
  )

  return (
    <FunctionField
      {...props}
      data-dd-privacy={SessionReplayPrivacy.MASK}
      source={source}
      render={renderState}
    />
  )
}
