import { useRecordContext, WrapperField } from 'react-admin'

import { Chip, Grid, Stack, Typography } from '@mui/material'

import { CallButton } from 'components/CallButton'
import { EnumField, FullNameField, NullableTextField } from 'components/fields'
import { OpenPlateButton } from 'components/OpenPlateButton'
import { getData } from 'lib/helpers/get-data'
import { SALES_STAGE_DISPLAY_VALUES } from 'resources/opportunities/opportunity-constants'
import { PERSON_SOURCE } from 'resources/persons/PersonsShow/PersonsShow.queries'
import { PersonRecord } from 'types/records.types'

export const PersonsToolbar = () => {
  const person = useRecordContext<PersonRecord>()
  const salesStage = getData<string>(person, PERSON_SOURCE.SALES_STAGE)

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item>
        <Stack direction="row" spacing={2} alignItems="center">
          <FullNameField<PersonRecord> variant="h5" component="h5" />
          {salesStage && (
            <Chip
              label={
                <EnumField<PersonRecord>
                  source={PERSON_SOURCE.SALES_STAGE}
                  displayValues={SALES_STAGE_DISPLAY_VALUES}
                  variant="inherit" // Use Chip label styles
                />
              }
              variant="outlined"
              color="warning"
            />
          )}
        </Stack>

        <WrapperField label={false}>
          <Typography variant="body2" component="span" color="text.secondary">
            Lead ID:
          </Typography>
          &nbsp;
          <NullableTextField<PersonRecord>
            source={PERSON_SOURCE.LEAD_ID}
            component="span"
            color="text.secondary"
          />
        </WrapperField>
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1}>
          <CallButton person={person} variant="outlined" color="secondary" />
          <OpenPlateButton person={person} variant="contained" color="primary" />
        </Stack>
      </Grid>
    </Grid>
  )
}
