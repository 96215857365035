import { defaultTheme as reactAdminTheme } from 'react-admin'

// https://mui.com/x/react-data-grid/getting-started/#typescript
import type {} from '@mui/x-data-grid-pro/themeAugmentation'

import { createAssuranceTheme } from '@assuranceiq/react-components'
import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

import Spacing from 'styles/modules/_Spacing.scss'
import Typography from 'styles/modules/_Typography.scss'

import {
  buttonsConfig,
  chipConfig,
  dialogConfig,
  inputsConfig,
  tablesConfig,
  tabsConfig,
  tooltipsConfig,
  typographyConfig,
} from './config'

const defaultAssuranceTheme = createAssuranceTheme()

// Ensure element spans the full-width of the viewport, regardless of parent containers
// SEE: https://css-tricks.com/full-width-containers-limited-width-parents/
const guaranteeFullWidth = {
  marginInline: 'calc(50% - 50vw)',
}

export const theme = createAssuranceTheme({
  ...reactAdminTheme,
  ...defaultAssuranceTheme,

  typography: typographyConfig.typography,

  components: {
    ...reactAdminTheme.components,
    ...defaultAssuranceTheme.components,

    ...buttonsConfig.components,
    ...inputsConfig.components,
    ...tablesConfig.components,
    ...tabsConfig.components,
    ...tooltipsConfig.components,
    ...typographyConfig.components,
    ...dialogConfig.components,
    ...chipConfig.components,

    MuiAlert: {
      variants: [
        {
          props: { variant: 'filled' },
          style: { border: 'none' }, // Removes border caused by defaulting Paper to "outlined" variant
        },
        {
          props: { variant: 'standard' },
          style: { border: 'none' }, // Removes border caused by defaulting Paper to "outlined" variant
        },
      ],
    },

    MuiPaper: {
      defaultProps: {
        // NOTE: This has the side effect of restyling default MUI components that use Paper, like Alert
        variant: 'outlined',
      },
    },

    MuiRating: {
      styleOverrides: {
        root: {
          '& .MuiRating-icon': {
            padding: '0.125em',
          },

          '& .MuiSvgIcon-root': {
            fontSize: '1em',
          },

          '& .MuiRating-iconFilled': {
            color: Colors['warning-light'],
          },

          '& .MuiRating-iconEmpty': {
            color: Colors['gray-60'],
          },

          '&.MuiRating-readOnly .MuiRating-iconEmpty': {
            color: Colors['gray-50'],
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          '.MuiToolbar-root': {
            paddingInline: parseInt(Spacing.unit) * 2,
            justifyContent: 'flex-start',
          },

          '.MuiTablePagination-spacer': {
            display: 'none', // move pagination to left
          },
        },
      },
    },

    // @ts-ignore React-admin components are not typed

    RaList: {
      styleOverrides: {
        root: {
          '.RaList-content': {
            border: 'none',
            background: 'none',
            ...guaranteeFullWidth,
          },
          '.MuiTablePagination-root': {
            ...guaranteeFullWidth,

            '.MuiToolbar-root': {
              paddingInline: parseInt(Spacing.unit) * 3,
            },
          },
          '.RaList-actions > .MuiToolbar-root': {
            flexBasis: 'auto', // redefine the default 100% for screen width < 900px
            paddingBottom: parseInt(Spacing.unit) * 0.5,
          },
          '.MuiToolbar-root': {
            justifyContent: 'flex-end',

            form: {
              flexBasis: 'auto',
            },
          },
        },
      },
    },

    RaLabeled: {
      styleOverrides: {
        root: {
          '.RaLabeled-label': {
            fontSize: Typography.smallFontSize,
          },
        },
      },
    },

    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          '.RaSimpleShowLayout-row': {
            marginBottom: parseInt(Spacing.unit) * 3,
            marginTop: 0,

            '.RaLabeled-label': {
              fontSize: Typography.bodyFontSize,
              fontWeight: '600',
              color: Colors['gray-100'],
              marginBottom: parseInt(Spacing.unit) * 2,

              '& + *': {
                fontSize: Typography.bodyFontSize,
              },
            },
          },
        },
      },
    },
  },
})
