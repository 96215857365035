import { CreateInDialogButton, CreateInDialogButtonProps } from 'components/CreateInDialogButton'
import { FollowUpOpportunityWizard } from 'components/FollowUpOpportunityWizard'
import { createFollowUpQuery } from 'components/FollowUpOpportunityWizard/FollowUpOpportunityWizard.queries'
import { useCurrentUser } from 'hooks'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { useNotifyFollowUpCreated } from 'hooks/useNotifyFollowUpCreated'
import { Resources } from 'lib/api/api.types'
import { convertLeadTypeToLOI } from 'lib/helpers/convert-ra-field-data'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { FormRecord } from 'types/forms.types'

export interface CreateOpportunityButtonProps extends CreateInDialogButtonProps {
  record: Omit<FormRecord, 'agent_id'>
}

/**
 * Opens the Opportunity/Follow-up wizard
 */
export const CreateOpportunityButton = ({
  record,
  ButtonProps,
  ...props
}: CreateOpportunityButtonProps) => {
  const notifyFollowUpCreated = useNotifyFollowUpCreated()
  const { currentUser } = useCurrentUser()

  const formRecord: FormRecord = {
    ...record,
    agent_id: currentUser.id,
  }

  return (
    <CreateInDialogButton
      label="Create Opportunity"
      resource={Resources.OPPORTUNITIES}
      record={formRecord}
      title={
        <>
          Create Opportunity
          {(record.person.fname || record.person.lname) && (
            <>
              {' '}
              for{' '}
              <span data-dd-privacy={SessionReplayPrivacy.MASK}>
                {record.person.fname} {record.person.lname}
              </span>
            </>
          )}
        </>
      }
      fullWidth
      ButtonProps={{
        startIcon: null,
        variant: 'outlined',
        color: 'secondary',
        ...ButtonProps,
      }}
      mutationOptions={{
        meta: {
          query: createFollowUpQuery,
        } as DataProviderMeta<FetchType.CREATE>,
        onSuccess: notifyFollowUpCreated,
      }}
      invalidateResourcesOnSuccess={[Resources.OPPORTUNITIES, Resources.ALL_FOLLOW_UPS]}
      {...props}
    >
      <FollowUpOpportunityWizard
        defaultValues={{
          loi: convertLeadTypeToLOI(record.person.lead_type),
        }}
      />
    </CreateInDialogButton>
  )
}
