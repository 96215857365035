import { FC } from 'react'

import { DatadogRUMProvider, DatadogRUMProviderProps } from '@assuranceiq/react-components'

import {
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_SESSION_REPLAY_SAMPLE_RATE,
  DD_VERSION,
  DEPLOY_ENV,
} from 'lib/environment-variables'
import { isCurrentPageInIframe } from 'lib/helpers/iframe-helpers'
import { SessionReplayPrivacy } from 'types/datadog.types'

/**
 * @see https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event
 */
const beforeDatadogSendEvent: NonNullable<DatadogRUMProviderProps['beforeSend']> = (event) => {
  if (event.type === 'error') {
    // Ignore Auth0 silent auth expired token errors—Auth0 redirects to login automatically
    // SEE: https://auth0.com/docs/authenticate/login/configure-silent-authentication#error-responses
    if (event.error.message.match(/^Login required$/i)) return false
  }

  return true
}

export const DatadogProvider: FC = ({ children }) => {
  return (
    <DatadogRUMProvider
      applicationId={DD_APPLICATION_ID}
      clientToken={DD_CLIENT_TOKEN}
      service={DD_SERVICE}
      env={DEPLOY_ENV}
      version={DD_VERSION}
      sessionReplaySampleRate={DD_SESSION_REPLAY_SAMPLE_RATE}
      sessionSampleRate={DD_SAMPLE_RATE}
      startSessionReplayRecordingManually
      trackLongTasks
      trackResources // NOTE: Significantly increases amount of data sent
      trackSessionAcrossSubdomains
      trackUserInteractions
      defaultPrivacyLevel={SessionReplayPrivacy.MASK_USER_INPUT}
      enableExperimentalFeatures={['feature_flags']}
      useCrossSiteSessionCookie={isCurrentPageInIframe}
      beforeSend={beforeDatadogSendEvent}
    >
      {children}
    </DatadogRUMProvider>
  )
}
