import { ElementType } from 'react'

import { FunctionField, FunctionFieldProps, RaRecord } from 'react-admin'

import { getData } from 'lib/helpers/get-data'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { TypedRaRecord } from 'types/react-admin.types'

export interface FullNameFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Partial<FunctionFieldProps<RecordType>> {
  /**
   * The record object containing `fname` and `lname` fields
   *
   * @example
   *   <FullNameField
   *     record={{ related_person: { fname: 'John', lname: 'Doe' } }}
   *     source='related_person
   *   />
   *
   * @default record
   */
  source?: FunctionFieldProps<RecordType>['source']

  sourceFirstName?: FunctionFieldProps<RecordType>['source']
  sourceLastName?: FunctionFieldProps<RecordType>['source']

  // TypographyProps does not expose the `component` prop, see https://github.com/mui/material-ui/issues/19512
  component?: ElementType
}

/**
 * Renders the first and last name
 */
export function FullNameField<RecordType extends TypedRaRecord = RaRecord>({
  label = 'Name',
  sortBy = 'fname',
  sourceFirstName,
  sourceLastName,
  ...props
}: FullNameFieldProps<RecordType>) {
  return (
    <FunctionField
      label={label}
      sortBy={sortBy}
      data-dd-privacy={SessionReplayPrivacy.MASK}
      // prettier-ignore
      render={(record: RecordType, source?: string) => {
        const firstNameSource = sourceFirstName || [source, 'fname'].filter(Boolean).join('.')
        const lastNameSource  = sourceLastName  || [source,  'lname'].filter(Boolean).join('.')
        const firstName = getData<string>(record, firstNameSource)
        const lastName  = getData<string>(record, lastNameSource)

        return `${firstName} ${lastName}`
      }}
      {...props}
    />
  )
}
