import { ComponentProps } from 'react'

import HelpIcon from '@mui/icons-material/Help'
import { FormLabel as MuiFormLabel } from '@mui/material'

import { clsx } from 'clsx'

import { ConditionalTooltip, ConditionalTooltipProps } from 'components/ConditionalTooltip'

import styles from './FormLabelWithTooltip.module.scss'

export interface FormLabelWithTooltipProps
  extends Omit<ComponentProps<typeof MuiFormLabel>, 'title'> {
  title?: ConditionalTooltipProps['title']
  conditionalTooltipProps?: Omit<ConditionalTooltipProps, 'title' | 'children'>
}

export const FormLabelWithTooltip = ({
  children,
  className,
  title,
  conditionalTooltipProps,
  ...props
}: FormLabelWithTooltipProps) => {
  return (
    <MuiFormLabel className={clsx(styles.label, className)} {...props}>
      <ConditionalTooltip
        title={title}
        placement="top-start"
        {...conditionalTooltipProps}
        className={clsx(styles.tooltip, conditionalTooltipProps?.className)}
      >
        <>
          <span>{children}</span>
          {title && <HelpIcon className={styles.tooltipIcon} />}
        </>
      </ConditionalTooltip>
    </MuiFormLabel>
  )
}
