import { useMemo, useState } from 'react'

import {
  FieldProps,
  RaRecord,
  sanitizeFieldRestProps,
  useListContext,
  useRecordContext,
} from 'react-admin'

import PhoneMissed from '@mui/icons-material/PhoneMissed'
import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material'

import { TimeAgo } from 'components/TimeAgo'
import { NullValue } from 'components/values'
import { displayFullDateTime, safeParseDate } from 'lib/helpers/datetime-helpers'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface LastContactFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends FieldProps<RecordType>,
    Omit<TypographyProps, 'textAlign'> {
  dispositionNameSource?: FieldProps<RecordType>['source']
  typographyProps?: TypographyProps
}

/**
 * Matches the following examples:
 * - `"Missed Inbound - Timeout"`
 * - `"Missed Direct Inbound - Timeout"`
 * - `"Missed Inbound - Lead Hung up"`
 * - `"Missed Inbound - Rejected'"`
 */
const MISSED_INBOUND = /missed\b.*\binbound/i

/**
 * Displays a live-updating relative time for the given date (e.g. "2 minutes
 * ago"), and a missed call icon if the client has a missed call.
 *
 * **NOTE:** This component is memoized—any `mutableProps`/`typographyProps`
 * will not be updated after the first render. If you need to update these
 * props, you will need to unmount and remount the component.
 *
 * @see {@link LastContactFieldProps}
 */
export const LastContactField = <RecordType extends TypedRaRecord = RaRecord>({
  source,
  dispositionNameSource,
  typographyProps: mutableTypographyProps,
  ...mutableProps
}: LastContactFieldProps<RecordType>) => {
  // Prevent re-renders on mutable props
  const [typographyProps] = useState(mutableTypographyProps)
  const [immutableProps] = useState(mutableProps)

  const record = useRecordContext<RecordType>()
  const isRenderedInList = !!useListContext().data

  const lastContacted = getData<string>(record, source)
  const dispositionName = dispositionNameSource
    ? getData<string>(record, dispositionNameSource)
    : null

  const Memoized = useMemo(() => {
    if (!lastContacted) return <NullValue />

    const lastContactDate = safeParseDate(lastContacted)
    const sanitizedProps = sanitizeFieldRestProps(immutableProps)

    return (
      <Stack
        direction="row"
        gap={1}
        {...sanitizedProps}
        sx={{
          justifyContent: isRenderedInList ? 'space-between' : 'flex-start',
          alignItems: 'center',
          width: '100%',
          maxWidth: '9em',
          ...sanitizedProps.sx,
        }}
      >
        <Typography component="span" variant="body2" {...typographyProps}>
          <Tooltip title={displayFullDateTime(lastContactDate)}>
            <TimeAgo date={lastContactDate} />
          </Tooltip>
        </Typography>
        {dispositionName?.match?.(MISSED_INBOUND) && (
          <Tooltip
            title={`You have a missed call from this client on ${displayFullDateTime(
              lastContactDate,
            )}`}
          >
            <PhoneMissed
              color="error"
              sx={(theme) => ({ fontSize: theme.typography.body.fontSize })}
            />
          </Tooltip>
        )}
      </Stack>
    )
  }, [immutableProps, isRenderedInList, lastContacted, dispositionName, typographyProps])

  return Memoized
}
