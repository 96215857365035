import { useMemo, useRef, useState } from 'react'

import {
  PreferenceKeyContextProvider,
  SelectColumnsButtonProps,
  usePreference,
  useResourceContext,
} from 'react-admin'

import { ButtonProps, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { unstable_useId as useId } from '@mui/material/utils'
import {
  DATA_GRID_DEFAULT_SLOTS_COMPONENTS,
  GridDensity,
  GridDensityTypes,
} from '@mui/x-data-grid-pro'

import { TooltippedIconButton } from 'components/TooltippedIconButton'

const { DensityCompactIcon, DensityStandardIcon, DensityComfortableIcon } =
  DATA_GRID_DEFAULT_SLOTS_COMPONENTS

interface MenuOption {
  icon: React.ElementType
  label: string
  value: GridDensityTypes
}

const MENU_OPTIONS: Record<GridDensityTypes, MenuOption> = {
  [GridDensityTypes.Compact]: {
    icon: DensityCompactIcon,
    label: 'Compact',
    value: GridDensityTypes.Compact,
  },
  [GridDensityTypes.Standard]: {
    icon: DensityStandardIcon,
    label: 'Standard',
    value: GridDensityTypes.Standard,
  },
  [GridDensityTypes.Comfortable]: {
    icon: DensityComfortableIcon,
    label: 'Comfortable',
    value: GridDensityTypes.Comfortable,
  },
}

export interface SelectDensityButtonProps
  extends Pick<SelectColumnsButtonProps, 'preferenceKey' | 'resource'>,
    ButtonProps {}

export const MuiDatagridDensityButton = ({
  preferenceKey: customPreferenceKey,
  resource: customResource,
  ...buttonProps
}: SelectDensityButtonProps) => {
  const resource = useResourceContext({ resource: customResource })
  const preferenceKey = `preferences.${customPreferenceKey ?? `${resource}.datagrid`}`

  return (
    <PreferenceKeyContextProvider value={preferenceKey}>
      <MuiDatagridDensityButtonWithPreferences {...buttonProps} />
    </PreferenceKeyContextProvider>
  )
}

const MuiDatagridDensityButtonWithPreferences = (buttonProps: ButtonProps) => {
  const buttonId = useId()
  const menuId = useId()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [density, setDensity] = usePreference<GridDensity>('density', GridDensityTypes.Standard)

  const ButtonIcon = useMemo(() => MENU_OPTIONS[density].icon, [density])

  return (
    <>
      <TooltippedIconButton
        title="Table Density"
        {...buttonProps}
        id={buttonId}
        ref={buttonRef}
        aria-haspopup="menu"
        aria-controls={menuId}
        onClick={(event) => {
          setOpen((prevOpen) => !prevOpen)
          buttonProps.onClick?.(event)
        }}
      >
        <ButtonIcon />
      </TooltippedIconButton>
      <Menu
        id={menuId}
        anchorEl={open ? buttonRef.current : null}
        open={open}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        onClose={() => setOpen(false)}
      >
        {Object.values(MENU_OPTIONS).map(({ icon: Icon, label, value }) => (
          <MenuItem
            key={value}
            selected={value === density}
            onClick={() => {
              setDensity(value)
              setOpen(false)
            }}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
