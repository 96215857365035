import { useMemo } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'

import { useCurrentUser } from 'hooks'
import { api, HASURA_DEFAULT_ROLE, HASURA_MANAGEMENT_ROLE } from 'lib/api'

const { uri, authorizationParams } = api

export const useApolloClient = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { currentUser } = useCurrentUser()
  const datadog = useDatadogRUM()

  const client = useMemo(() => {
    /**
     * Authentication (through Context Link)
     * @see https://www.apollographql.com/docs/react/api/link/apollo-link-context
     */
    const authMiddleware = setContext(async (_request, { headers: previousHeaders = {} }) => {
      const token = await getAccessTokenSilently({ authorizationParams })
      const hasuraRole = currentUser.canMasquerade ? HASURA_MANAGEMENT_ROLE : HASURA_DEFAULT_ROLE

      return {
        headers: {
          ...previousHeaders,
          Authorization: `Bearer ${token}`,
          'X-Hasura-Role': hasuraRole,
        },
      }
    })

    /**
     * Error Link
     *
     * Handle and inspect errors in your GraphQL network stack.
     * @see https://www.apollographql.com/docs/react/api/link/apollo-link-error
     */
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          datadog.addError(
            `[Vega Apollo Client - GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        )

      if (networkError) datadog.addError(`[Vega Apollo Client - Network error]: ${networkError}`)
    })

    const terminatingLink = new HttpLink({ uri })

    return new ApolloClient({
      cache: new InMemoryCache(),

      link: ApolloLink.from([authMiddleware, errorLink, terminatingLink]),
    })
  }, [datadog, getAccessTokenSilently, currentUser.canMasquerade])

  return client
}
