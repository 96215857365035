import { HTMLAttributes, useEffect, useRef, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Fade, IconButton, Stack } from '@mui/material'

import { IconCheckCircle1 } from '@assuranceiq/react-icons/bold/CheckCircle1'

import { ConditionalTooltip } from 'components/ConditionalTooltip'

export interface CopyToClipboardProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The content to copy to the clipboard
   *
   * @default children innerText
   */
  content?: string
}

const RESET_COPIED_TIMEOUT = 3000

/**
 * Copy the content to the clipboard when the user clicks the copy icon
 */
export const CopyToClipboard = ({
  children,
  content: contentInProp,
  ...props
}: CopyToClipboardProps) => {
  const childrenRef = useRef<HTMLDivElement>(null)
  const [hovered, setHovered] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)

  const label = copied ? 'Copied!' : 'Copy to clipboard'
  const IconComponent = copied ? IconCheckCircle1 : ContentCopyIcon

  useEffect(
    function resetCopied() {
      if (!copied) return

      const timeout = setTimeout(() => setCopied(false), RESET_COPIED_TIMEOUT)
      return () => clearTimeout(timeout)
    },
    [copied],
  )

  const onClick = () => {
    const content = contentInProp ?? childrenRef.current?.innerText
    if (!content) return

    navigator.clipboard.writeText(content)
    setCopied(true)
  }

  return (
    <Stack
      direction="row"
      alignContent="center"
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div ref={childrenRef}>{children}</div>
      <Fade in={hovered}>
        <div>
          <ConditionalTooltip title={label}>
            <IconButton
              aria-label={label}
              onClick={onClick}
              sx={{
                fontSize: '0.75em',
                paddingY: 0.5,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <IconComponent style={{ fontSize: '1em' }} />
            </IconButton>
          </ConditionalTooltip>
        </div>
      </Fade>
    </Stack>
  )
}
