/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'

import { ApolloClient, MutationOptions, NormalizedCacheObject, QueryOptions } from '@apollo/client'

import { Resources } from 'lib/api/api.types'
import { ResourceName } from 'types/react-admin.types'

import { buildQuery } from './buildQuery'
import { GetResponseParser } from './getResponseParser'

export type ApolloQueryOrMutationOptions<F extends FetchType> = F extends GetFetchType
  ? QueryOptions
  : MutationOptions

export type DataProviderParams = GetFetchParams | MutationFetchParams

export type GetFetchParams = GetListParams | GetOneParams | GetManyParams | GetManyReferenceParams

export type GetFetchType =
  | FetchType.GET_LIST
  | FetchType.GET_ONE
  | FetchType.GET_MANY
  | FetchType.GET_MANY_REFERENCE

export enum FetchType {
  GET_LIST = 'GET_LIST',
  GET_ONE = 'GET_ONE',
  GET_MANY = 'GET_MANY',
  GET_MANY_REFERENCE = 'GET_MANY_REFERENCE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_MANY = 'UPDATE_MANY',
  DELETE = 'DELETE',
  DELETE_MANY = 'DELETE_MANY',
}

export type MutationFetchParams =
  | CreateParams
  | UpdateParams
  | DeleteParams
  | UpdateManyParams
  | DeleteManyParams

export type MutationFetchType =
  | FetchType.CREATE
  | FetchType.UPDATE
  | FetchType.DELETE
  | FetchType.UPDATE_MANY
  | FetchType.DELETE_MANY

export interface MutationOptionsParams extends QueryOptionsParams {}

export interface QueryOptionsParams {
  resource: Resources
  fetchType: FetchType
  params: DataProviderParams
}

export interface UseDataProviderParams {
  client: ApolloClient<NormalizedCacheObject>
}

export type BuildQueryResult = ReturnType<typeof buildQuery>

/** @see {@link CustomQueryConfig.query} for documentation */
export type CustomQuery<
  F extends FetchType,
  R extends RaRecord = any,
  TCacheShape = any,
> = (options: {
  client: ApolloClient<TCacheShape>
  resource: ResourceName
  params: QueryParams[F]

  /**
   * An options object intended to be used in an Apollo Client’s `query` or
   * `mutate` methods.
   *
   * Object contains the default `query`/`mutate` and `variables` for the given
   * query
   *
   * ```ts    (Can’t use '@example' for properties)
   * // Queries like `GET_LIST` would return query options
   * client.query(clientOptions)
   * client.query({
   *   ...clientOptions,
   *   query: clientOptions.query,
   *   variables: clientOptions.variables,
   * })
   *
   * // Mutations like `UPDATE` would return mutate options
   * client.mutate(clientOptions)
   * client.mutate({
   *   ...clientOptions,
   *   mutation: clientOptions.mutation,
   *   variables: clientOptions.variables,
   * })
   * ```
   */
  clientOptions: ApolloQueryOrMutationOptions<F>

  /**
   * Parses the response from `client.query()`/`client.mutate()` and returns
   * expected data format for React Admin
   *
   * ```ts    (Can’t use '@example' for properties)
   * const response = await client.query(clientOptions)
   * return parseResponse(response)
   * ```
   */
  parseResponse: ReturnType<GetResponseParser<F>>
}) => Promise<QueryResults<R>[F]>

/**
 * Customize the query for a particular resource request
 *
 * See {@link CustomQueryConfig} for query options
 *
 * ```ts
 * const dataProvider = useDataProvider({
 *   api,
 *   client,
 *   customQueries: {
 *     todos: {        // <-- resource name
 *       GET_ONE: {    // <-- FetchType
 *         // Select just the fields you need, instead of defaulting to all for a table
 *         fields: gql`{ id, title, is_completed }`,
 *       },
 *     },
 *
 *     products: {
 *       GET_LIST: {
 *         // Write a completely custom query, while leveraging the defaults
 *         query: async ({ client, clientOptions, params, parseResponse }) => {
 *           const response = await client.query({
 *             ...clientOptions,
 *
 *             query: GET_PRODUCTS_LIST,
 *
 *             variables: {
 *               ...clientOptions.variables,
 *
 *               foo: params.meta.foo,
 *             },
 *           })
 *
 *           return parseResponse(response)
 *         },
 *       },
 *     },
 *   },
 * })
 * ```
 */
export interface CustomQueryConfig<F extends FetchType> {
  /**
   * Overrides the dataProvider with a custom query. The custom query must be
   * responsible for handling standard params (sorting, pagination, filtering,
   * etc) and returning data in a way that React Admin can parse.
   *
   * **NOTE:** If you’re using this to customize filtering, be sure to check out
   * [ra-data-hasura’s built-in special filters support](https://github.com/hasura/ra-data-hasura#special-filter-feature)
   * first.
   *
   * **NOTE:** You can also provide custom queries for individual requests by
   * setting `query` in the [`queryOptions` `meta` parameter](https://marmelab.com/react-admin/List.html#queryoptions)
   *
   * ```tsx
   * query: async ({ client, clientOptions, params, parseResponse }) => {
   *   const response = await client.query({
   *     ...clientOptions
   *     variables: {
   *       ...clientOptions.variables,
   *       foo: params.foo
   *     },
   *   })
   *
   *   return parseResponse(response)
   * }
   * ```
   *
   * ```tsx
   * import { List } from 'react-admin'
   * import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
   *
   * const myQuery: CustomQuery<FetchType.GET_LIST> = async (…) => {
   *   …
   * }
   *
   * const UserList = () => (
   *   <List
   *     queryOptions={{
   *       meta: {
   *         query: myQuery,
   *       } as DataProviderMeta<FetchType.GET_LIST>,
   *     }}
   *   >
   *     …
   *   </List>
   * )
   * ```
   */
  query?: CustomQuery<F>
}

/**
 * Adds types for the `meta` parameter
 *
 * - See https://marmelab.com/react-admin/List.html#adding-meta-to-the-dataprovider-call
 * - See https://marmelab.com/react-admin/Actions.html#meta-parameter
 *
 * ```tsx
 * import { List } from 'react-admin'
 * import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
 *
 * return (
 *   <List
 *     queryOptions={{
 *       meta: { … } as DataProviderMeta<FetchType.GET_LIST>,
 *     }}
 *   >
 *     …
 *   </List>
 * )
 * ```
 */
// NOTE: <Pick>s from CustomQueryConfig to preserve type documentation/intellisense
export interface DataProviderMeta<F extends FetchType>
  extends Partial<Pick<CustomQueryConfig<F>, 'query'>> {
  [key: string]: any
}

export type QueryParams = {
  [FetchType.GET_LIST]: GetListParams
  [FetchType.GET_ONE]: GetOneParams
  [FetchType.GET_MANY]: GetManyParams
  [FetchType.GET_MANY_REFERENCE]: GetManyReferenceParams
  [FetchType.CREATE]: CreateParams
  [FetchType.UPDATE]: UpdateParams
  [FetchType.UPDATE_MANY]: UpdateManyParams
  [FetchType.DELETE]: DeleteParams
  [FetchType.DELETE_MANY]: DeleteManyParams
}

export type QueryResults<R extends RaRecord = any> = {
  [FetchType.GET_LIST]: GetListResult<R>
  [FetchType.GET_ONE]: GetOneResult<R>
  [FetchType.GET_MANY]: GetManyResult<R>
  [FetchType.GET_MANY_REFERENCE]: GetManyReferenceResult<R>
  [FetchType.CREATE]: CreateResult<R>
  [FetchType.UPDATE]: UpdateResult<R>
  [FetchType.UPDATE_MANY]: UpdateManyResult<R>
  [FetchType.DELETE]: DeleteResult<R>
  [FetchType.DELETE_MANY]: DeleteManyResult<R>
}
