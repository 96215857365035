import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { NOW } from 'lib/helpers/query-helpers'

export const GET_ONE_LEAD_TIMEZONE = gql`
  query LeadTimezone($lead_id: Int!) {
    returning: lead_tz(lead_id: $lead_id) {
      timezone
    }
  }
`

export const getLeadTimezone: CustomQuery<FetchType.GET_LIST> = async ({
  client,
  clientOptions,
  parseResponse,
}) => {
  const lead_id = clientOptions.variables?.where?.id?._eq

  const response = await client.query({
    ...clientOptions,

    query: GET_ONE_LEAD_TIMEZONE,

    variables: {
      lead_id,
    },
  })

  const results = parseResponse({
    data: {
      returning: [
        {
          id: lead_id,
          ...response.data.returning,
        },
      ],
    },
  })

  return results
}

export const GET_CONFLICTING_APPOINTMENTS = gql`
  query follow_up_appointments(
    $limit: Int
    $offset: Int
    $order_by: [follow_up_appointments_order_by!]!
    $where: follow_up_appointments_bool_exp
  ) {
    items: follow_up_appointments(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      # Only fetch necessary fields to reduce response size
      id
      duration_minutes
      scheduled_time
    }
  }
`

export const getConflictingAppointments: CustomQuery<FetchType.GET_MANY_REFERENCE> = async ({
  client,
  clientOptions,
  parseResponse,
}) => {
  const response = await client.query({
    ...clientOptions,

    query: GET_CONFLICTING_APPOINTMENTS,
    variables: {
      ...clientOptions.variables,

      limit: 1000, // Since we do a one-time fetch, we want to fetch all future appointments at once
      offset: 0,

      where: {
        ...clientOptions.variables?.where,

        scheduled_time: {
          _gte: NOW, // Only look at future conflicting appointments; can’t schedule in the past
        },
      },
    },
  })

  const results = parseResponse(response)

  return results
}
