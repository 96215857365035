import { useEffect, useState } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { useAuth0 } from '@auth0/auth0-react'

import { CEO_SERVICE_AUTH0_AUDIENCE } from 'lib/environment-variables'

/**
 * Get an auth token for CEO (Centralized Eligibility Service for Omnichannel).
 *
 * @see https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/3220407897/How+To+Integrate+Your+Service+With+CEO for details
 */
export const useCEOToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const datadog = useDatadogRUM()
  const [token, setToken] = useState<string | undefined>()
  const [error, setError] = useState()

  useEffect(() => {
    if (token) return

    getAccessTokenSilently({
      authorizationParams: { audience: CEO_SERVICE_AUTH0_AUDIENCE },
    })
      .then((token) => {
        setToken(token)
        setError(undefined)
      })
      .catch((error) => {
        setError(error)
        datadog.addError(error)
      })
  }, [token, getAccessTokenSilently, datadog])

  return { error, token }
}
