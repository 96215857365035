/**
 * DataGrid column width values that have same value in different Lists
 */
export const DEFAULT_COLUMN_WIDTH = {
  FULL_NAME: 200,
  LEAD_ID: 110,
  LAST_CONTACT: 140,
  LOI: 100,
  ENROLLMENT_PERIOD: 240,
  SALES_STAGE: 160,
  CHANCE_TO_CLOSE: 160,
  PHONE: 140,
  POLICY_STATUS: 130,
  ENROLLMENT_ID: 130,
  EMAIL: 250,
  STATE: 150,
  NOTES: 400,
}
