import { useListContext } from 'react-admin'

import { DataGridProProps } from '@mui/x-data-grid-pro'

export const usePaginationModel = (): Pick<
  DataGridProProps,
  'page' | 'pageSize' | 'onPageChange' | 'onPageSizeChange'
> => {
  const { page, perPage, setPage, setPerPage } = useListContext()

  return {
    page: page - 1,
    pageSize: perPage,
    onPageChange: setPage,
    onPageSizeChange: setPerPage,
  }
}
