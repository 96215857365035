import { ALL_FOLLOW_UPS_SOURCE } from 'resources/all_follow_ups/AllFollowUpsList'
import { CLIENTS_SOURCE } from 'resources/clients/ClientsList'
import { MISSED_CALLS_SOURCE } from 'resources/missed_calls/MissedCallsList'
import { OPPORTUNITIES_SOURCE } from 'resources/opportunities/OpportunitiesList'
import { PERSON_SOURCE } from 'resources/persons/PersonsShow/PersonsShow.queries'
import { ResourceTranslations } from 'types/react-admin.types'
import {
  AllFollowUpsRecord,
  CallStatRecord,
  ClientRecord,
  OpportunityRecord,
  PersonRecord,
} from 'types/records.types'

// prettier-ignore
/** Common labels across the project */
export const LABEL = {
  ACTIONS:                 'Actions',
  BIRTH_DATE:              'Birthday',
  CHANCE_TO_CLOSE:         'Chance to Close',
  CITY:                    'City',
  CREATED_AT:              'Created On',
  EMAIL:                   'Email',
  ENROLLMENT_PERIOD:       'Enrollment Period',
  FIRST_NAME:              'First Name',
  FULL_NAME:               'Name',
  GENDER:                  'Gender',
  LAST_CONTACT:            'Last Contact',
  LAST_NAME:               'Last Name',
  LEAD_ID:                 'Lead ID',
  LOI:                     'LOI',
  NOTES:                   'Notes',
  PHONE:                   'Phone',
  ASSURANCE_POLICY_NUMBER: 'Assurance Policy Number',
  POLICY_CARRIER:          'Carrier Name',
  POLICY_ENROLLMENT_ID:    'Enrollment ID',
  POLICY_NAME:             'Plan Name',
  POLICY_NUMBER:           'Carrier Policy Number',
  POLICY_PLAN_ID:          'Plan ID',
  POLICY_PREMIUM:          'Standard Monthly Premium',
  POLICY_START_DATE:       'Effective Date',
  POLICY_STATUS:           'Policy Status',
  POLICY_TERMINATION_DATE: 'Termination Date',
  POLICY_TYPE:             'Policy Type',
  PRODUCTS:                'Product Type',
  SALES_STAGE:             'Stage',
  STATE:                   'State',
  ZIP:                     'Zip',
  SELLING_AGENT:           'Selling Agent',
} as const

// prettier-ignore
const all_follow_ups = {
  name: 'Follow-Up |||| Follow-Ups',
  count: '%{smart_count} Follow-Up |||| %{smart_count} Follow-Ups',
  fields: {
    [ALL_FOLLOW_UPS_SOURCE.APPOINTMENT_NOTES]:             LABEL.NOTES,
    [ALL_FOLLOW_UPS_SOURCE.CHANCE_TO_CLOSE]:               LABEL.CHANCE_TO_CLOSE,
    [ALL_FOLLOW_UPS_SOURCE.ENROLLMENT_PERIOD]:             LABEL.ENROLLMENT_PERIOD,
    [ALL_FOLLOW_UPS_SOURCE.LAST_CONTACT_DISPOSITION_NAME]: 'Disposition',
    [ALL_FOLLOW_UPS_SOURCE.LAST_CONTACT]:                  LABEL.LAST_CONTACT,
    [ALL_FOLLOW_UPS_SOURCE.LEAD_ID]:                       LABEL.LEAD_ID,
    [ALL_FOLLOW_UPS_SOURCE.LOI]:                           LABEL.LOI,
    [ALL_FOLLOW_UPS_SOURCE.PERSON]:                        LABEL.FULL_NAME,
    [ALL_FOLLOW_UPS_SOURCE.PERSON_FIRST_NAME]:             LABEL.FIRST_NAME,
    [ALL_FOLLOW_UPS_SOURCE.PERSON_STATE]:                  LABEL.STATE,
    [ALL_FOLLOW_UPS_SOURCE.POLICY_RELATED_INFOS]:          LABEL.POLICY_STATUS,
    [ALL_FOLLOW_UPS_SOURCE.PRODUCTS]:                      LABEL.PRODUCTS,
    [ALL_FOLLOW_UPS_SOURCE.REMINDER_NOTES]:                LABEL.NOTES,
    [ALL_FOLLOW_UPS_SOURCE.SCHEDULED_TIME]:                'Follow-up Time',
    [ALL_FOLLOW_UPS_SOURCE.SALES_STAGE]:                   LABEL.SALES_STAGE,
    [ALL_FOLLOW_UPS_SOURCE.SUB_TYPE]:                      'Sub-Type',
    [ALL_FOLLOW_UPS_SOURCE.TYPE]:                          'Type',
  },
} as const satisfies ResourceTranslations<AllFollowUpsRecord>

// prettier-ignore
const clients = {
  name: 'Client |||| All My Clients',
  fields: {
    [CLIENTS_SOURCE.CHANCE_TO_CLOSE]:      LABEL.CHANCE_TO_CLOSE,
    [CLIENTS_SOURCE.ENROLLMENT_PERIOD]:    LABEL.ENROLLMENT_PERIOD,
    [CLIENTS_SOURCE.LAST_CONTACT]:         LABEL.LAST_CONTACT,
    [CLIENTS_SOURCE.LEAD_ID]:              LABEL.LEAD_ID,
    [CLIENTS_SOURCE.LOI]:                  LABEL.LOI,
    [CLIENTS_SOURCE.NEXT_FOLLOW_UP_DATE]:  'Next Follow-Up',
    [CLIENTS_SOURCE.PERSON]:               LABEL.FULL_NAME,
    [CLIENTS_SOURCE.PERSON_FIRST_NAME]:    LABEL.FIRST_NAME,
    [CLIENTS_SOURCE.PERSON_LAST_NAME]:     LABEL.LAST_NAME,
    [CLIENTS_SOURCE.PERSON_BIRTH_DATE]:    LABEL.BIRTH_DATE,
    [CLIENTS_SOURCE.PERSON_GENDER]:        LABEL.GENDER,
    [CLIENTS_SOURCE.PERSON_EMAIL]:         LABEL.EMAIL,
    [CLIENTS_SOURCE.PERSON_PHONE]:         LABEL.PHONE,
    [CLIENTS_SOURCE.PERSON_CITY]:          LABEL.CITY,
    [CLIENTS_SOURCE.PERSON_STATE]:         LABEL.STATE,
    [CLIENTS_SOURCE.PERSON_ZIP]:           LABEL.ZIP,
    [CLIENTS_SOURCE.POLICY_RELATED_INFOS]: LABEL.POLICY_STATUS,
  },
} as const satisfies ResourceTranslations<ClientRecord>

// prettier-ignore
const persons = {
  name: 'Person |||| Persons',
  fields: {
    [PERSON_SOURCE.LEAD_ID]:      LABEL.LEAD_ID,
    [PERSON_SOURCE.FIRST_NAME]:   LABEL.FIRST_NAME,
    [PERSON_SOURCE.LAST_NAME]:    LABEL.LAST_NAME,
    [PERSON_SOURCE.BIRTH_DATE]:   LABEL.BIRTH_DATE,
    [PERSON_SOURCE.GENDER]:       LABEL.GENDER,
    [PERSON_SOURCE.EMAIL]:        LABEL.EMAIL,
    [PERSON_SOURCE.PHONE]:        LABEL.PHONE,
    [PERSON_SOURCE.CITY]:         LABEL.CITY,
    [PERSON_SOURCE.STATE]:        LABEL.STATE,
    [PERSON_SOURCE.ZIP]:          LABEL.ZIP,
    [PERSON_SOURCE.SALES_STAGE]:  LABEL.SALES_STAGE,
    [PERSON_SOURCE.LAST_CONTACT]: LABEL.LAST_CONTACT,
  },
} as const satisfies ResourceTranslations<PersonRecord>

// prettier-ignore
const missed_calls = {
  name: 'Missed Call |||| Missed Calls',
  fields: {
    [MISSED_CALLS_SOURCE.DISPOSITION]:       'Disposition',
    [MISSED_CALLS_SOURCE.LAST_CONTACT]:      LABEL.LAST_CONTACT,
    [MISSED_CALLS_SOURCE.PERSON]:            LABEL.FULL_NAME,
    [MISSED_CALLS_SOURCE.PERSON_EMAIL]:      LABEL.EMAIL,
    [MISSED_CALLS_SOURCE.PERSON_FIRST_NAME]: LABEL.FIRST_NAME,
    [MISSED_CALLS_SOURCE.PERSON_LEAD_ID]:    LABEL.LEAD_ID,
    [MISSED_CALLS_SOURCE.PERSON_PHONE]:      LABEL.PHONE,
    [MISSED_CALLS_SOURCE.PERSON_STATE]:      LABEL.STATE,
  },
} as const satisfies ResourceTranslations<CallStatRecord>

// prettier-ignore
const opportunities = {
  name: 'Opportunity |||| Opportunities',
  fields: {
    [OPPORTUNITIES_SOURCE.LOI]:               LABEL.LOI,
    [OPPORTUNITIES_SOURCE.PRODUCTS]:          LABEL.PRODUCTS,
    [OPPORTUNITIES_SOURCE.SALES_STAGE]:       LABEL.SALES_STAGE,
    [OPPORTUNITIES_SOURCE.CHANCE_TO_CLOSE]:   LABEL.CHANCE_TO_CLOSE,
    [OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD]: LABEL.ENROLLMENT_PERIOD,
    [OPPORTUNITIES_SOURCE.LEAD_ID]:           LABEL.LEAD_ID,
    [OPPORTUNITIES_SOURCE.PERSON]:            LABEL.FULL_NAME,
    [OPPORTUNITIES_SOURCE.PERSON_FIRST_NAME]: LABEL.FIRST_NAME,
    [OPPORTUNITIES_SOURCE.FOLLOW_UPS_COUNT]:  'Follow-Ups',
  },
} as const satisfies ResourceTranslations<OpportunityRecord>

/**
 * Translation strings for react-admin resources
 *
 * - See [Translating Resource and Field Names](https://marmelab.com/react-admin/TranslationTranslating.html#translating-resource-and-field-names)
 * - See [Interpolation, Pluralization, and Default Translation](https://marmelab.com/react-admin/TranslationTranslating.html#interpolation-pluralization-and-default-translation)
 * - See [Polyglot API](https://airbnb.io/polyglot.js/)
 */
export const resourceTranslations = {
  // See: https://marmelab.com/react-admin/Translation.html#translating-your-own-resources
  resources: {
    clients,
    all_follow_ups,
    missed_calls,
    opportunities,
    persons,
  },
} as const
