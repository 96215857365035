import { ThemeOptions } from '@mui/material'

import Spacing from 'styles/modules/_Spacing.scss'
import Typography from 'styles/modules/_Typography.scss'

export const tabsConfig: ThemeOptions = {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingInline: '0',
          fontSize: Typography.smallFontSize,
          minWidth: 'unset',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: parseInt(Spacing.unit) * 3,
        },
      },
    },
  },
}
