import { Box, BoxProps, Tooltip, TooltipProps } from '@mui/material'

export interface ConditionalTooltipProps extends Omit<TooltipProps, 'title'> {
  /** @default true */
  enabled?: boolean

  /**
   * If title is not provided, the children will be rendered without a tooltip
   */
  title?: TooltipProps['title']

  /**
   * Props passed to the wrapper Box component
   * @default { width: '100%' }
   *
   * - [Box](https://mui.com/material-ui/react-box/)
   * - [Box API](https://mui.com/material-ui/api/box/)
   */
  wrapperProps?: BoxProps
}

/**
 * Conditionally wrap elements with a MUI Tooltip, based on the `enabled` prop.
 *
 * If `enabled` is false or `title` is not provided, the children will be
 * rendered without a tooltip.
 *
 * - [Tooltip](https://mui.com/material-ui/react-tooltip/)
 * - [Tooltip API](https://mui.com/material-ui/api/tooltip/)
 */
export const ConditionalTooltip = ({
  children,
  enabled = true,
  title,
  wrapperProps,
  ...tooltipProps
}: ConditionalTooltipProps) => {
  if (!enabled || !title) return <>{children}</>

  return (
    <Tooltip title={title} {...tooltipProps}>
      {/* HACK: Wrapped with span so tooltip can trigger on disabled elements
       ** See https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <Box component="span" {...wrapperProps}>
        {children}
      </Box>
    </Tooltip>
  )
}
