import { FieldProps, RaRecord, useRecordContext } from 'react-admin'

import { Chip, ChipProps, Tooltip } from '@mui/material'

import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

import { parseISO } from 'date-fns'

import { formatLocaleDateTime, TODAY_ISO_DATE } from 'lib/helpers/datetime-helpers'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface ExpiredOpportunityFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Omit<FieldProps<RecordType>, 'source'> {
  source: {
    expiredDate: FieldProps<RecordType>['source']
    enrollmentPeriod: FieldProps<RecordType>['source']
  }
  chipProps?: ChipProps
}

/** Renders a chip with "Expired" label if the date is in the past. */
export function ExpiredOpportunityField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  chipProps,
}: ExpiredOpportunityFieldProps<RecordType>) {
  const record = useRecordContext<RecordType>()
  const expiredDateISO = getData<string | null | undefined>(record, source.expiredDate)
  const enrollmentPeriod = getData<string | null | undefined>(record, source.enrollmentPeriod)

  if (!expiredDateISO || !enrollmentPeriod) {
    console.error('Missing expiredDateISO or enrollmentPeriod in ExpiredOpportunityField')
    return null
  }

  const expiredDateFormatted = formatLocaleDateTime(parseISO(expiredDateISO), {
    dateStyle: 'medium',
  })
  const isExpired = expiredDateISO < TODAY_ISO_DATE

  if (!isExpired) return null

  return (
    <Tooltip
      title={`You created this opportunity in ${enrollmentPeriod} enrollment period which has ended on ${expiredDateFormatted}`}
    >
      <Chip label="Expired" sx={{ backgroundColor: Colors['gray-50'] }} {...chipProps} />
    </Tooltip>
  )
}
