import { useState } from 'react'

import WarningAmber from '@mui/icons-material/WarningAmber'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  lighten,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import { useCurrentUser } from 'hooks'
import { AUTH0_DOMAIN } from 'lib/environment-variables'
import { isCurrentPageInIframe, isCurrentPageTwoIframesDeep } from 'lib/helpers/iframe-helpers'
import { CRM_LOGOUT_URL } from 'lib/helpers/path-helpers'
import { useStoreActions } from 'lib/store'
import { SessionReplayPrivacy } from 'types/datadog.types'

export const DebugMenu = () => {
  const setAgentID = useStoreActions((actions) => actions.debug.setAgentID)
  const { currentUser } = useCurrentUser()

  if (!currentUser.canMasquerade) return null

  return (
    <Box
      component="fieldset"
      sx={(theme) => ({
        position: 'relative',
        zIndex: theme.zIndex.drawer + 1,
        borderStyle: 'dotted',
        bgcolor: lighten(theme.palette.warning.light, 0.9),
        mb: 2,
        minWidth: 0, // https://stackoverflow.com/a/15478580/625710
        color: theme.palette.warning.dark,
      })}
    >
      <Typography component="legend" sx={{ display: 'flex', justifyContent: 'center' }}>
        <WarningAmber />
        &nbsp;Debug Menu
      </Typography>

      <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body1" sx={{ m: 0 }}>
            Agent&nbsp;ID:
          </Typography>
          <TextField
            id="debug-agent-id"
            data-dd-privacy={SessionReplayPrivacy.ALLOW}
            label={false}
            size="small"
            value={currentUser.id}
            onChange={(event) => {
              setAgentID(Number(event.target.value))
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ label: { display: 'none' }, input: { textAlign: 'right', width: 90 } }}
          />
        </Stack>

        <Typography variant="body1" sx={{ m: 0 }}>
          Your&nbsp;ID:&nbsp;<code>{currentUser.unmaskedID}</code>
        </Typography>

        <Stack direction="row" spacing={1} sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Typography variant="body1" sx={{ m: 0 }}>
            Roles:
          </Typography>
          <Stack component="ul" direction="row" spacing=".5em" sx={{ p: 0, overflow: 'auto' }}>
            {currentUser.roles.map((role: string) => (
              <Chip key={role} component="li" label={role} color="warning" />
            ))}
          </Stack>
        </Stack>

        <LogoutButton />
      </Stack>
    </Box>
  )
}

export const LogoutButton = () => {
  const { logout } = useAuth0()
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)

  return (
    <>
      <Button variant="outlined" color="warning" onClick={() => setLogoutDialogOpen(true)}>
        Log&nbsp;Out
      </Button>

      <Dialog open={logoutDialogOpen} maxWidth="md">
        <DialogTitle>Log Out is a Two-Step Process</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Vega maintains its own auth session in the <code>{AUTH0_DOMAIN}</code> tenant, and uses{' '}
            <Link
              href="https://github.com/assuranceiq/terraform-auth0-definitions/blob/2d46c816a79adda72a7644a06a1b3ac3d6a120a5/modules/tenants/assurance/assurancetest_oidc_connection.tf#L1"
              target="_blank"
              rel="noreferrer"
            >
              <code>assurancetest-oidc-connection</code>
            </Link>{' '}
            to automatically create a new session from an existing CRM (<code>assurancetest</code>)
            auth session.
          </Typography>

          <Typography variant="body1" gutterBottom>
            So to fully log you out and reset your session, we’re going to:
          </Typography>
          <Typography component="ol" variant="body1" gutterBottom>
            <li>
              Log you out of Vega (<code>{AUTH0_DOMAIN}</code>)
            </li>
            <li>
              Log you out of the CRM (<code>assurancetest</code>)
            </li>
          </Typography>
          <Typography variant="body1">
            <strong>
              Once logged out, you’ll need to manually{' '}
              {isCurrentPageInIframe &&
                `reload the ${isCurrentPageTwoIframesDeep ? 'Dialer' : 'CRM'} and `}
              return to the Vega app.
            </strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setLogoutDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setLogoutDialogOpen(false)
              logout({
                logoutParams: {
                  // Intentionally redirect to CRM—Vega would just log you
                  // back in with your CRM session
                  returnTo: CRM_LOGOUT_URL,
                },
              })
            }}
          >
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
