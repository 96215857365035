import { useMemo } from 'react'

import { FieldProps, RaRecord, useRecordContext } from 'react-admin'

import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'
import { PolicyRelatedInfoRecord, PolicyRelatedInfoType } from 'types/records.types'

export function rankPolicy(item: PolicyRelatedInfoRecord) {
  const { type, normalized_data: { augmented_event } = { augmented_event: false } } = item
  if (type === PolicyRelatedInfoType.BOOK_OF_BUSINESS && augmented_event) return 1
  if (type === PolicyRelatedInfoType.ENROLLMENT && augmented_event) return 2
  if (type === PolicyRelatedInfoType.ENROLLMENT && !augmented_event) return 3
  if (type === PolicyRelatedInfoType.BOOK_OF_BUSINESS && !augmented_event) return 4
  return 5
}

/**
 * Returns the source of the highest priority policy.
 *
 * @example `{ rankedPolicySource: person.policy_related_infos[1], hasNoPolicy: false }`
 */
export function useRankedPolicySource<RecordType extends TypedRaRecord = RaRecord>(
  /** The source of the policy_related_infos field */
  source: FieldProps<RecordType>['source'],
) {
  const record = useRecordContext()

  const data = useMemo(() => {
    if (!record) return

    return getData<Array<PolicyRelatedInfoRecord>>(record, source)
  }, [record, source])

  const policyIndex = useMemo(() => {
    if (data && data.length > 1) {
      return data
        .map((item, index) => ({ index, rank: rankPolicy(item) }))
        .reduce((highest, current) => (highest.rank < current.rank ? highest : current)).index
    }
    return 0
  }, [data])

  return { rankedPolicySource: `${source}[${policyIndex}]`, hasNoPolicy: !data?.length }
}
