import { useEffect } from 'react'

import { useFormDialogContext } from '@react-admin/ra-form-layout'

export interface HandleCloseFormDialogProps {
  close: boolean
  setClose: (close: boolean) => void
}

// This component is used to close the react-admin's form dialog when the `close` prop is set to
// true. It uses `close` from `useFormDialogContext` that's why it has to be a child of the form
// dialog component
export const HandleCloseFormDialog = ({ close, setClose }: HandleCloseFormDialogProps) => {
  const { close: closeDialog, isOpen } = useFormDialogContext()

  useEffect(() => {
    if (close) {
      setClose(false)
      closeDialog()
    }
  }, [close, closeDialog, setClose])

  useEffect(() => {
    if (isOpen) {
      setClose(false)
    }
  }, [isOpen, setClose])

  return null
}
