// eslint-disable-next-line no-restricted-imports
import { FunctionField, RaRecord, TextField, TextFieldProps } from 'react-admin'

import { NullValue } from 'components/values'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface NullableTextFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends TextFieldProps<RecordType> {}

/**
 * Same as React Admin’s {@link TextField}, but defaults to {@link NullValue} for `emptyText`.
 *
 * @see https://marmelab.com/react-admin/TextField.html
 */
export function NullableTextField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  ...props
}: NullableTextFieldProps<RecordType>) {
  return (
    <FunctionField
      render={(record: RecordType) => {
        const data = getData<string | null | undefined>(record, source)
        if (!data) return <NullValue />

        return <TextField source={source} {...props} />
      }}
      {...props}
    />
  )
}
