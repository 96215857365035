import { ListBase, RecordContextProvider, useRecordContext, WithListContext } from 'react-admin'

import { Box, CircularProgress, Grid, Stack } from '@mui/material'

import { CreateOpportunityButton } from 'components/CreateOpportunityButton'
import { NoResults } from 'components/NoResults'
import { PaginationIfNeeded } from 'components/PaginationIfNeeded'
import { DataProviderMeta, useCurrentUser } from 'hooks'
import { FetchType } from 'hooks/useDataProvider'
import { Resources } from 'lib/api/api.types'
import { listBaseDefaults } from 'lib/helpers/list-helpers'
import { OPPORTUNITIES_SOURCE } from 'resources/opportunities/OpportunitiesList'
import { getOpportunitiesList } from 'resources/opportunities/OpportunitiesList/OpportunitiesList.queries'
import { SortOrder } from 'types/react-admin.types'
import { OpportunityRecord, OpportunitySource, PersonRecord } from 'types/records.types'

import { CompactFollowUpList, OpportunityCard } from './components/OpportunityCard'

import styles from './OpportunitiesTab.module.scss'

export const OpportunitiesTab = () => {
  const person = useRecordContext<PersonRecord>()
  const { currentUser } = useCurrentUser()

  const lead_id = person.lead_id
  const assigned_agent_id = currentUser.id

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <CreateOpportunityButton
          record={{
            source: OpportunitySource.CLIENT_LIST,
            person,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ListBase
          {...listBaseDefaults({ primaryList: false })}
          resource={Resources.OPPORTUNITIES}
          sort={{ field: OPPORTUNITIES_SOURCE.UPDATED_AT, order: SortOrder.DESC }}
          queryOptions={{
            meta: {
              lead_id, // Force refresh on change
              assigned_agent_id, // Force refresh on change

              query: getOpportunitiesList({
                lead_id,
                assigned_agent_id,
              }),
            } as DataProviderMeta<FetchType.GET_LIST>,
          }}
        >
          <WithListContext<OpportunityRecord>
            render={({ data: opportunities, isLoading }) => {
              if (isLoading)
                return (
                  <Box className={styles.listLoading}>
                    <CircularProgress />
                  </Box>
                )

              if (!opportunities?.length)
                return (
                  <NoResults
                    title="No pipeline found"
                    sx={{ height: '30vh' }}
                    message={
                      <CreateOpportunityButton
                        record={{
                          source: OpportunitySource.CLIENT_LIST,
                          person,
                        }}
                      />
                    }
                  />
                )

              return (
                <Stack direction="column" spacing={2}>
                  {opportunities?.map((opportunity, index) => (
                    <RecordContextProvider key={opportunity.id} value={opportunity}>
                      <OpportunityCard
                        addActions
                        collapsed={index !== 0}
                        followUpsList={<CompactFollowUpList addActions />}
                      />
                    </RecordContextProvider>
                  ))}
                </Stack>
              )
            }}
          />
          <PaginationIfNeeded />
        </ListBase>
      </Grid>
    </Grid>
  )
}
