import { ThemeOptions } from '@mui/material'

import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

export const dialogConfig: ThemeOptions = {
  components: {
    MuiDialog: {
      defaultProps: {
        maxWidth: 'lg',
      },
      styleOverrides: {
        paper: {
          minWidth: '40vw', // for cases when content is too small
          marginTop: '4vh',

          '.RaFormDialogTitle-closeButton': {
            color: Colors['gray-100'] + '!important', // Not working without important
          },
        },

        container: {
          alignItems: 'flex-start',
        },
      },
    },
  },
}
