import { useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { uniq } from 'lodash-es'

import { useStoreState } from 'lib/store'

const ID_FROM_AUTH0_SUB = /\|(?<user_id>[^|]*)$/

export const UNKNOWN_USER_ID = -1
export const USER_ROLES_KEY = 'https://assurance.com/roles'

export enum CRMRole {
  Admin = 'admin',
  Agent = 'agent',
  VegaAgent = 'vega.agent',
  Developer = 'developer',
  AgentTeamLead = 'agent_team_lead',
  AgentMasqueradable = 'agent_masqueradable',
}

// TODO: replace this with permissions checks in the future—technically should not be checking roles to determine access
const MASQUERADABLE_ROLES: string[] = [CRMRole.Developer, CRMRole.AgentTeamLead]

export interface CurrentUser {
  /**
   * The user’s actual or masqueraded CRM ID.
   *
   * NOTE: This is the ID that should be used for all CRM-related queries
   *
   * @return actual ID if the user is not an admin.
   * @return masquerading ID if the user is an admin.
   * @return `UNKNOWN_USER_ID` if unable to determine CRM ID.
   */
  id: number

  /**
   * The user’s actual CRM ID
   *
   * @return actual CRM ID regardless of user role.
   * @return `UNKNOWN_USER_ID` if unable to determine CRM ID.
   */
  unmaskedID: number

  /** Auth0 user’s full name */
  fullName?: string

  /** Auth0 user’s email address */
  email?: string

  /** Auth0 user’s profile picture */
  picture?: string

  /** Assurance.com CRM roles */
  roles: string[]

  /** Can the user masquerade as another agent? */
  canMasquerade: boolean

  /** Is the user masquerading as another user? */
  isMasquerading: boolean
}

export interface CurrentUserModel {
  currentUser: CurrentUser
}

/**
 * Provides data about the current user
 */
export const useCurrentUser = (): CurrentUserModel => {
  const { user } = useAuth0()
  const debugAgentID = useStoreState((state) => state.debug.agentID)

  if (!user) throw new Error('useCurrentUser must be used within a RequireAuthentication wrapper')

  const currentUser = useMemo<CurrentUser>(() => {
    const crmID = Number(user?.sub?.match(ID_FROM_AUTH0_SUB)?.groups?.user_id ?? UNKNOWN_USER_ID)
    const roles = uniq<string>(user?.[USER_ROLES_KEY])
    const canMasquerade = roles.some((role) => MASQUERADABLE_ROLES.includes(role))

    const maskedID = canMasquerade && debugAgentID ? debugAgentID : crmID

    return {
      id: maskedID,
      unmaskedID: crmID,

      roles,

      email: user?.email,
      fullName: user?.name,
      picture: user?.picture,

      canMasquerade,
      isMasquerading: maskedID !== crmID,
    } satisfies CurrentUser
  }, [user, debugAgentID])

  return { currentUser }
}
