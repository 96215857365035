import { useCallback, useEffect, useState } from 'react'

import { DatagridConfigurableProps, usePreference } from 'react-admin'

import { GridColDef, GridColumnVisibilityModel, useGridApiContext } from '@mui/x-data-grid-pro'

import { isEqualIgnoringSort } from 'lib/helpers/comparison-helpers'

export interface PreferredColumnVisiblityHookOptions {
  /**
   * Columns to hide on initial render, listed by `source` name.
   *
   * @see [DatagridConfigurable documentation](https://marmelab.com/react-admin/Datagrid.html#configurable)
   */
  omit?: DatagridConfigurableProps['omit']
}

/**
 * Synchronize/store column visibility with React Admin preferences
 */
export const usePreferredColumnVisibility = ({ omit }: PreferredColumnVisiblityHookOptions) => {
  const [initialized, setInitialized] = useState(false)
  const apiRef = useGridApiContext()

  /**
   * Get/Set React Admin preference
   */

  // Must match the key used in React Admin <SelectColumnsButton>:
  // https://github.com/marmelab/react-admin/blob/160841a7/packages/ra-ui-materialui/src/list/datagrid/DatagridConfigurable.tsx#L139
  const [preferredVisible, _setPreferredVisible] = usePreference<string[]>('columns')

  const setPreferredVisible = useCallback(
    (visibleColumns: GridColDef[]) => {
      _setPreferredVisible(visibleColumns.map(({ field }) => field))
    },
    [_setPreferredVisible],
  )

  useEffect(
    function initializePreference() {
      if (initialized) return
      if (preferredVisible) return

      const allColumns = apiRef.current.getAllColumns()

      setPreferredVisible(allColumns.filter((column) => !omit?.includes(column.field)))
      setInitialized(true)
    },
    [apiRef, initialized, omit, preferredVisible, setPreferredVisible],
  )

  useEffect(
    function onMUIColumnVisibilityChange() {
      return apiRef.current.subscribeEvent('columnVisibilityChange', () => {
        const currentVisibleColumns = apiRef.current.getVisibleColumns()

        const matchesPreferredVisible = isEqualIgnoringSort(
          preferredVisible,
          currentVisibleColumns.map((c) => c.field),
        )

        if (matchesPreferredVisible) return

        setPreferredVisible(currentVisibleColumns)
      })
    },
    [apiRef, preferredVisible, setPreferredVisible],
  )

  useEffect(
    function onRAColumnVisibilityChange() {
      if (!preferredVisible) return

      const currentVisibleColumns = apiRef.current.getVisibleColumns()
      const matchesCurrentVisibleColumns = isEqualIgnoringSort(
        preferredVisible,
        currentVisibleColumns.map((c) => c.field),
      )

      if (matchesCurrentVisibleColumns) return

      const allColumns = apiRef.current.getAllColumns()
      const newColumnVisibilityModel = allColumns.reduce<GridColumnVisibilityModel>(
        (visibilityModel, { field }) => ({
          ...visibilityModel,
          [field]: preferredVisible.includes(field),
        }),
        {},
      )

      apiRef.current.setColumnVisibilityModel(newColumnVisibilityModel)
    },
    [apiRef, preferredVisible],
  )

  return [preferredVisible, setPreferredVisible] as const
}
