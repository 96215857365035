import { Admin, CustomRoutes, localStorageStore, Resource } from 'react-admin'

import { useTheme } from '@mui/material'

import { Route } from 'react-router-dom'

import { AlertNotification } from 'components/AlertNotification'
import { CreateFromPlates } from 'components/CreateFromPlates'
import { ReactAdminLayout } from 'components/ReactAdminLayout'
import {
  useApolloClient,
  useAuth0AuthProvider,
  useCEOToken,
  useDataProvider,
  useFeatureFlagStore,
} from 'hooks'
import { i18nProvider } from 'i18n/i18nProvider'
import { Resources } from 'lib/api/api.types'
import { CREATE_FROM_PLATES_PATH } from 'lib/helpers/path-helpers'
import {
  allFollowUpsPages,
  clientsPages,
  missedCallsPages,
  opportunitiesPages,
  personsPages,
} from 'resources'

/**
 * Bump this version when the data model changes, to invalidate the cache.
 * This will reset stored filters/sorts
 * SEE: https://marmelab.com/react-admin/Store.html#store-invalidation
 */
const STORE_VERSION = 'v2'

export const ReactAdmin = () => {
  const authProvider = useAuth0AuthProvider()
  const theme = useTheme()
  const client = useApolloClient()
  const dataProvider = useDataProvider({ client })

  useCEOToken() // Pre-fetch the CEO token for later use
  useFeatureFlagStore()

  return (
    <Admin
      key={`${dataProvider ? 'loaded' : 'loading'}-data`}
      title="Vega"
      disableTelemetry
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      layout={ReactAdminLayout}
      theme={theme}
      store={localStorageStore(STORE_VERSION)}
      notification={() => <AlertNotification />}
    >
      <Resource name={Resources.CLIENTS} list={clientsPages.list} show={personsPages.show} />
      <Resource name={Resources.ALL_FOLLOW_UPS} {...allFollowUpsPages} />
      <Resource name={Resources.OPPORTUNITIES} {...opportunitiesPages} />
      <Resource name={Resources.MISSED_CALLS} {...missedCallsPages} />
      <CustomRoutes noLayout>
        <Route path={CREATE_FROM_PLATES_PATH} element={<CreateFromPlates />} />
      </CustomRoutes>
    </Admin>
  )
}
