import { FunctionField, Labeled, WrapperField } from 'react-admin'

import { Box, Card, CardContent, Divider, Grid, Skeleton, Typography } from '@mui/material'

import { formatDistanceToNowStrict } from 'date-fns'

import {
  FullNameField,
  LastContactField,
  NullableDateField,
  NullableTextField,
  UsStateField,
} from 'components/fields'
import { LABEL } from 'i18n/resource-translations'
import { safeParseDate } from 'lib/helpers/datetime-helpers'
import { PERSON_SOURCE } from 'resources/persons/PersonsShow/PersonsShow.queries'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { PersonRecord } from 'types/records.types'

const TITLE = 'Basic Information'

const GridItemSkeleton = () => (
  <>
    <Typography variant="body1">
      <Skeleton variant="text" width="20%" />
    </Typography>
    <Typography variant="body2">
      <Skeleton variant="text" width="40%" />
    </Typography>
  </>
)

export const OverviewTab = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <Card>
      <CardContent sx={{ py: 1 }}>
        <Typography variant="subtitle1">{TITLE}</Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <GridItemSkeleton />
            ) : (
              <Labeled>
                <FullNameField<PersonRecord> label={LABEL.FULL_NAME} />
              </Labeled>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <GridItemSkeleton />
            ) : (
              <Labeled>
                <WrapperField label={LABEL.BIRTH_DATE}>
                  <Box>
                    <NullableDateField<PersonRecord>
                      source={PERSON_SOURCE.BIRTH_DATE}
                      data-dd-privacy={SessionReplayPrivacy.MASK}
                      options={{
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      }}
                    />{' '}
                    <FunctionField<PersonRecord>
                      source={PERSON_SOURCE.BIRTH_DATE}
                      data-dd-privacy={SessionReplayPrivacy.MASK}
                      render={(person) => {
                        if (!person?.birth_date) return null

                        const birthDate = safeParseDate(person.birth_date)
                        const ageInWords = formatDistanceToNowStrict(birthDate, {
                          roundingMethod: 'floor',
                        })

                        return <>({ageInWords} old)</>
                      }}
                    />
                  </Box>
                </WrapperField>
              </Labeled>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <GridItemSkeleton />
            ) : (
              <Labeled>
                <NullableTextField<PersonRecord>
                  source={PERSON_SOURCE.CITY}
                  data-dd-privacy={SessionReplayPrivacy.MASK}
                />
              </Labeled>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <GridItemSkeleton />
            ) : (
              <Labeled>
                <UsStateField<PersonRecord> source={PERSON_SOURCE.STATE} />
              </Labeled>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <GridItemSkeleton />
            ) : (
              <Labeled>
                <NullableTextField<PersonRecord>
                  source={PERSON_SOURCE.ZIP}
                  data-dd-privacy={SessionReplayPrivacy.MASK}
                />
              </Labeled>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <GridItemSkeleton />
            ) : (
              <Labeled>
                <LastContactField<PersonRecord>
                  source={PERSON_SOURCE.LAST_CONTACT}
                  dispositionNameSource={PERSON_SOURCE.LATEST_CALL_DISPOSITION_NAME}
                  label={LABEL.LAST_CONTACT}
                />
              </Labeled>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
