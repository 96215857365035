import { Dialog, DialogContent } from '@mui/material'

import { ErrorFallback } from './ErrorFallback'
import { ErrorFallbackComponent } from './ErrorFallback.types'

export const ModalErrorFallback: ErrorFallbackComponent = ({ dynamicProps, ...props }) => {
  const { slotProps, ...generatedProps } = dynamicProps?.(props.error) ?? {}
  const titleID = slotProps?.title?.id ?? 'modal-error-fallback-title'
  const messageID = slotProps?.message?.id ?? 'modal-error-fallback-message'

  return (
    <Dialog
      open
      aria-labelledby={titleID}
      aria-describedby={messageID}
      maxWidth="md"
      {...slotProps?.dialog}
    >
      <DialogContent sx={{ margin: 4 }}>
        <ErrorFallback
          {...props}
          dynamicProps={() => ({
            ...generatedProps,
            slotProps: {
              title: { id: titleID, ...slotProps?.title },
              message: { id: messageID, ...slotProps?.message },
              ...slotProps,
            },
          })}
        />
      </DialogContent>
    </Dialog>
  )
}
