import { ReactElement, useMemo, useState } from 'react'

import { FieldProps, RaRecord, sanitizeFieldRestProps, useRecordContext } from 'react-admin'

import { Tooltip, Typography, TypographyProps } from '@mui/material'

import { NullValue } from 'components/values'
import {
  DateArg,
  displayFullDateTime,
  formatLocaleDateTime,
  safeParseDate,
} from 'lib/helpers/datetime-helpers'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface DateTooltipProps {
  show?: boolean
  date: Date
  children: ReactElement
}

const OptionalTooltip = ({ show, date, children }: DateTooltipProps) => {
  if (!show) return <>{children}</>

  return <Tooltip title={displayFullDateTime(date)}>{children}</Tooltip>
}

export interface NullableDateFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Omit<FieldProps<RecordType>, 'emptyText'>,
    Omit<TypographyProps, 'textAlign'> {
  showTooltip?: boolean
  emptyText?: string | ReactElement
  options?: Intl.DateTimeFormatOptions
}

/**
 * Matches date stamps with no time information, e.g. `'2020-01-01'`.
 *
 * When formatting these with `toLocaleString`, we must always use the UTC timezone,
 * otherwise the date can be off by one day depending on the client's timezone.
 */
const DATE_ONLY_NO_TIME = /^\d{4}-\d{2}-\d{2}$/

/**
 * A DateField that:
 *  - displays {@link NullValue} if the date is null.
 *  - displays an optional tooltip with the full date when hovered.
 *
 * **NOTE:** This component is memoized—any `mutableProps` will not be updated
 * after the first render. If you need to update these props, you will need to
 * unmount and remount the component.
 *
 * @see {@link NullableDateFieldProps}
 */
export const NullableDateField = <RecordType extends TypedRaRecord = RaRecord>({
  source,
  showTooltip,
  emptyText = <NullValue />,
  ...mutableProps
}: NullableDateFieldProps<RecordType>) => {
  const record = useRecordContext<RecordType>()
  const value = getData<DateArg>(record, source)
  const [immutableProps] = useState(mutableProps) // Prevent re-renders on mutable props

  const Memoized = useMemo(() => {
    if (!value) return <>{emptyText}</>

    const { options = {}, ...fieldProps } = immutableProps
    const typographyProps = sanitizeFieldRestProps(fieldProps)

    if (typeof value === 'string' && value.match(DATE_ONLY_NO_TIME)) {
      // Ensure pure dates are not accidentally displayed in local time
      options.timeZone ??= 'UTC'

      // Default format 12/31/2023
      options.month ??= '2-digit'
      options.day ??= '2-digit'
      options.year ??= 'numeric'
    }

    const date = safeParseDate(value)
    const dateString = formatLocaleDateTime(date, options)

    return (
      <OptionalTooltip show={showTooltip} date={date}>
        <time dateTime={date.toISOString()}>
          <Typography component="span" variant="body2" {...typographyProps}>
            {dateString}
          </Typography>
        </time>
      </OptionalTooltip>
    )
  }, [value, emptyText, immutableProps, showTooltip])

  return Memoized
}
