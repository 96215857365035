import { raFormLayoutLanguageEnglish } from '@react-admin/ra-form-layout'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'

import { resourceTranslations } from './resource-translations'

/**
 * Internationalization provider for react-admin
 */
export const i18nProvider = polyglotI18nProvider(
  () => ({
    ...englishMessages,
    // `@react-admin/ra-form-layout requires us to merge in additional translations
    ...raFormLayoutLanguageEnglish,

    ...resourceTranslations,
  }),
  'en',
)
