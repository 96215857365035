import { ComponentProps } from 'react'

import { Container, Tooltip, Typography } from '@mui/material'

import { clsx } from 'clsx'

import { PatchedRadioButtonGroupInput, RadioGroupChoice } from './PatchedRadioButtonGroupInput'

import styles from './RadioCardGroupInput.module.scss'

export interface RadioCardGroupInputProps
  extends ComponentProps<typeof PatchedRadioButtonGroupInput> {
  /** Render choices to one row */
  row?: boolean
}

export const RadioCardGroupInput = ({
  className,
  row = true,
  ...props
}: RadioCardGroupInputProps) => {
  return (
    <PatchedRadioButtonGroupInput
      className={clsx(styles.root, !!row && styles.row, className)}
      optionText={(choice: RadioCardGroupChoice) => <ChoiceElement {...choice} />}
      {...props}
    />
  )
}

export type RadioCardGroupChoice = RadioGroupChoice & {
  tooltip?: string
}

const ChoiceElement = ({ name, subtext, tooltip }: RadioCardGroupChoice) => {
  return (
    <Tooltip title={tooltip} placement="bottom">
      <Container className={styles.option}>
        <Typography className={styles.optionText} gutterBottom variant="body">
          {name}
        </Typography>
        <Typography className={styles.optionSubtext} variant="body">
          {subtext}
        </Typography>
      </Container>
    </Tooltip>
  )
}
