import { FunctionField, List, ListProps, WrapperField } from 'react-admin'

import { Stack } from '@mui/material'

import { CallButton } from 'components/CallButton'
import {
  EnumField,
  ExpiredOpportunityField,
  LastContactField,
  LinkedPersonField,
  NullableDateField,
  NullableTextField,
  PhoneField,
  RatingField,
  UsStateField,
} from 'components/fields'
import { FilterByColumn } from 'components/FilterByColumn'
import { Column, MuiDatagrid, MuiDatagridListActions } from 'components/MuiDatagrid'
import { OpenPlateButton } from 'components/OpenPlateButton'
import { useCurrentUser, useRankedPolicySource } from 'hooks'
import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag'
import { LABEL } from 'i18n/resource-translations'
import { DEFAULT_COLUMN_WIDTH } from 'lib/helpers/column-width-values'
import { listDefaults } from 'lib/helpers/list-helpers'
import { RESOURCE_DESCRIPTION } from 'lib/helpers/resource-mapping'
import { VIRTUAL_SOURCE } from 'lib/helpers/virtual-sources'
import { LOI_DISPLAY_VALUES } from 'resources/opportunities/opportunity-constants'
import { PersonsShowDrawer } from 'resources/persons/components/PersonsShowDrawer'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { SortOrder } from 'types/react-admin.types'
import { ClientRecord } from 'types/records.types'

import { CLIENTS_SOURCE, getClientsList } from './ClientsList.queries'

export const ClientsList = (props: ListProps) => {
  const enableSearch = useFeatureFlag(FeatureFlag.ENABLE_CLIENT_LIST_SEARCH, {
    previewValue: true,
  })
  const enablePolicyStatus = useFeatureFlag(FeatureFlag.ENABLE_POLICY_STATUS, {
    previewValue: true,
  })

  const { currentUser } = useCurrentUser()
  const user_id = currentUser.id

  const { rankedPolicySource } = useRankedPolicySource<ClientRecord>(
    CLIENTS_SOURCE.POLICY_RELATED_INFOS,
  )

  return (
    <>
      <List
        {...listDefaults({ primaryList: true })}
        {...props}
        actions={<MuiDatagridListActions />}
        sort={{ field: CLIENTS_SOURCE.LAST_CONTACT, order: SortOrder.DESC }}
        queryOptions={{
          meta: {
            user_id, // Force re-fetch when ID changes
            query: getClientsList({ user_id }),
          } satisfies DataProviderMeta<FetchType.GET_LIST>,
        }}
        filters={
          enableSearch
            ? [<FilterByColumn key="filter-by-column" source="prevent-key-error" alwaysOn />]
            : undefined
        }
      >
        <MuiDatagrid
          bulkActionButtons={false}
          initialState={{
            pinnedColumns: {
              left: [CLIENTS_SOURCE.PERSON_FIRST_NAME],
              right: [VIRTUAL_SOURCE.ACTIONS],
            },
          }}
          omit={['lead_id']}
        >
          <Column width={DEFAULT_COLUMN_WIDTH.FULL_NAME}>
            <LinkedPersonField<ClientRecord>
              source={CLIENTS_SOURCE.PERSON}
              sortBy={CLIENTS_SOURCE.PERSON_FIRST_NAME}
            />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.LEAD_ID}>
            <NullableTextField<ClientRecord> source={CLIENTS_SOURCE.LEAD_ID} />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.LAST_CONTACT} type="dateTime">
            <LastContactField<ClientRecord>
              source={CLIENTS_SOURCE.LAST_CONTACT}
              dispositionNameSource={CLIENTS_SOURCE.LATEST_CALL_DISPOSITION_NAME}
              sortByOrder={SortOrder.DESC}
            />
          </Column>
          <Column width={180} type="dateTime" sortable={false}>
            <NullableDateField<ClientRecord>
              source={CLIENTS_SOURCE.NEXT_FOLLOW_UP_DATE}
              options={{
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
                year: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
              }}
              sortByOrder={SortOrder.DESC}
            />
          </Column>
          <Column
            width={DEFAULT_COLUMN_WIDTH.CHANCE_TO_CLOSE}
            type="number"
            headerAlign="left"
            align="left"
            sortable={false}
          >
            <RatingField<ClientRecord> source={CLIENTS_SOURCE.CHANCE_TO_CLOSE} />
          </Column>
          {enablePolicyStatus && (
            <Column width={DEFAULT_COLUMN_WIDTH.POLICY_STATUS} sortable={false}>
              <NullableTextField
                label={LABEL.POLICY_STATUS}
                source={`${rankedPolicySource}.normalized_data.policy_status`}
              />
            </Column>
          )}
          <Column width={DEFAULT_COLUMN_WIDTH.ENROLLMENT_ID} sortable={false}>
            <NullableTextField
              label={LABEL.POLICY_ENROLLMENT_ID}
              source={`${rankedPolicySource}.normalized_data.enrollment_code`}
            />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.STATE}>
            <UsStateField<ClientRecord> source={CLIENTS_SOURCE.PERSON_STATE} />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.LOI} sortable={false}>
            <EnumField<ClientRecord>
              source={CLIENTS_SOURCE.LOI}
              displayValues={LOI_DISPLAY_VALUES}
            />
          </Column>
          <Column
            width={DEFAULT_COLUMN_WIDTH.ENROLLMENT_PERIOD}
            description={RESOURCE_DESCRIPTION[CLIENTS_SOURCE.ENROLLMENT_PERIOD]}
            field={CLIENTS_SOURCE.ENROLLMENT_PERIOD}
            sortable={false}
          >
            <WrapperField label={LABEL.ENROLLMENT_PERIOD}>
              <Stack direction="row" spacing={1}>
                <NullableTextField<ClientRecord> source={CLIENTS_SOURCE.ENROLLMENT_PERIOD} />
                <ExpiredOpportunityField<ClientRecord>
                  source={{
                    expiredDate: CLIENTS_SOURCE.EXPIRATION_DATE,
                    enrollmentPeriod: CLIENTS_SOURCE.ENROLLMENT_PERIOD,
                  }}
                  chipProps={{ size: 'small' }}
                />
              </Stack>
            </WrapperField>
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.PHONE} sortable={false}>
            <PhoneField<ClientRecord>
              data-dd-privacy={SessionReplayPrivacy.MASK}
              source={CLIENTS_SOURCE.PERSON_PHONE}
            />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.EMAIL} sortable={false}>
            <NullableTextField<ClientRecord>
              data-dd-privacy={SessionReplayPrivacy.MASK}
              source={CLIENTS_SOURCE.PERSON_EMAIL}
            />
          </Column>
          <Column
            field={VIRTUAL_SOURCE.ACTIONS}
            width={220}
            filterable={false}
            sortable={false}
            resizable={false}
          >
            <FunctionField<ClientRecord>
              label={LABEL.ACTIONS}
              render={({ person }) => (
                <Stack direction="row" spacing={0}>
                  <OpenPlateButton person={person} />
                  <CallButton person={person} />
                </Stack>
              )}
            />
          </Column>
        </MuiDatagrid>
      </List>
      <PersonsShowDrawer {...props} />
    </>
  )
}
