import { useCallback, useEffect, useState } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { CreateOpportunityButton } from 'components/CreateOpportunityButton'
import { CRM_URL } from 'lib/environment-variables'
import { MessageType, ModalOpenMessage, sendCrmMessage } from 'lib/helpers/iframe-messaging'
import { OpportunitySource } from 'types/records.types'

const TOGGLE_BUTTON_ID = 'modal-button'

type IncomingMessage = ModalOpenMessage

/**
 * For use on https://assurance.com/rep/plate, to create a follow-up opportunity
 * from the plates. Intended to be used in an iframe on the CRM.
 *
 * See `app/views/agent/common/_vega_button.html.slim` in the insurance repo for
 * the iframe code.
 */
export const CreateFromPlates = () => {
  const [sessionData, setSessionData] = useState<ModalOpenMessage>()
  const datadog = useDatadogRUM()

  const onModalClosed = () => {
    sendCrmMessage(MessageType.MODAL_CLOSED)
  }

  /**
   * Respond to messages from the CRM
   * https://github.com/assuranceiq/insurance/blob/master/app/views/agent/common/_vega_pipeline_button.html.slim
   */
  const onCrmMessage = useCallback(
    (event: MessageEvent<IncomingMessage>) => {
      if (event.origin !== CRM_URL) return

      switch (event.data.type) {
        // Event triggered by CRM, in _vega_pipeline_button.html.slim
        // SEE: https://github.com/assuranceiq/insurance/blob/03d9785d21654e842ecf2c125b17931488170aa3/app/views/agent/common/_vega_pipeline_button.html.slim#L35-L63
        case MessageType.MODAL_OPEN: {
          if (!event.data?.person?.lead_id)
            throw new Error('Missing lead_id in modal open iframe message')

          setSessionData(event.data)

          const button = document.getElementById(TOGGLE_BUTTON_ID)

          if (!(button instanceof HTMLButtonElement)) {
            datadog.addError(`Could not find button with id ${TOGGLE_BUTTON_ID}`)
          } else {
            button.click()
          }

          break
        }
      }
    },
    [datadog],
  )

  useEffect(() => {
    window.addEventListener('message', onCrmMessage)
    sendCrmMessage(MessageType.LOGGED_IN)

    return () => {
      window.removeEventListener('message', onCrmMessage)
    }
  }, [onCrmMessage])

  if (!sessionData) return null

  return (
    <CreateOpportunityButton
      record={
        // prettier-ignore
        {
          agent_fname:      sessionData.agent_fname,
          delta_contact_id: sessionData.delta_contact_id,
          person:           sessionData.person,
          source:           OpportunitySource.PLATES,
        }
      }
      ButtonProps={{
        id: TOGGLE_BUTTON_ID,
        sx: {
          // HACK: React admin doesn’t provide an easy way to control modal
          // open/closed state (without routing), so we visually hide this button and
          // click it to open the modal
          display: 'none',
        },
      }}
      TransitionProps={{ onExited: onModalClosed }}
    />
  )
}
