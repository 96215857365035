import { useState } from 'react'

import { useRefresh } from 'react-admin'
import {
  EditInDialogButton as RaEditInDialogButton,
  EditInDialogButtonProps as RaEditInDialogButtonProps,
} from '@react-admin/ra-form-layout'

import { HandleCloseFormDialog } from '../HandleCloseFormDialog'

export interface EditInDialogButtonProps extends RaEditInDialogButtonProps {}

/**
 * This component is a wrapper around `EditInDialogButton` from `@react-admin/ra-form-layout`.
 * It adds the following features:
 * - Close the dialog when the mutation is successful
 * - Refresh the list on success
 */
export const EditInDialogButton = ({
  children,
  mutationOptions,
  ...props
}: EditInDialogButtonProps) => {
  const [closeDialog, setCloseDialog] = useState<boolean>(false)

  const refresh = useRefresh()

  const onSuccess = () => {
    refresh()
    setCloseDialog(true)
  }

  return (
    <RaEditInDialogButton
      {...props}
      mutationOptions={{
        ...mutationOptions,
        onSuccess: (...args) => {
          onSuccess()
          mutationOptions?.onSuccess?.(...args)
        },
      }}
    >
      <HandleCloseFormDialog close={closeDialog} setClose={setCloseDialog} />
      {children}
    </RaEditInDialogButton>
  )
}
