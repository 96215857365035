import { ThemeOptions } from '@mui/material'

import Color from '@assuranceiq/react-components/styles/colors/_colors.scss'

import Radius from 'styles/modules/_Radius.scss'
import Spacing from 'styles/modules/_Spacing.scss'
import Typography from 'styles/modules/_Typography.scss'

// TODO: Should be customized in ARC theme
export const chipConfig: ThemeOptions = {
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: Radius['border-radius-default'],
          backgroundColor: Color['gray-25'], // WARN: Overrides default color prop values

          '&.MuiChip-colorWarning': {
            backgroundColor: Color['yellow-25'],
            color: Color['yellow-100'],
            borderColor: Color['yellow-100'],
          },

          '&.MuiChip-sizeSmall': {
            fontSize: Typography.tinyFontSize,
            height: '20px',

            '& .MuiChip-label': {
              paddingLeft: parseInt(Spacing.unit) / 2,
              paddingRight: parseInt(Spacing.unit) / 2,
            },
          },

          '&.MuiChip-sizeMedium': {
            fontSize: Typography.smallFontSize,
            height: '26px',

            '& .MuiChip-label': {
              paddingLeft: Spacing.unit,
              paddingRight: Spacing.unit,
            },
          },
        },
      },
    },
  },
}
