import { Children, isValidElement, memo, ReactNode, useMemo, useState } from 'react'

import {
  FieldProps,
  RecordContextProvider,
  usePreference,
  useResourceContext,
  useTranslateLabel,
} from 'react-admin'

import type { GridAlignment } from '@mui/x-data-grid-pro'
import { DataGridProProps, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'

import { SortOrder } from 'types/react-admin.types'

import { ColumnProps } from '../Column'
import { HeaderCellWithTooltip } from '../components/HeaderCellWithTooltip'
import { COLUMN_WIDTHS_PREF_KEY, ColumnWidthPreferences } from './usePreferredColumnWidths'

declare module '@mui/x-data-grid-pro' {
  interface GridColDef {
    /**
     * React Admin Field props associated with this column
     *
     * This custom property is added by the `useColumns` hook so we can
     * reference the Field props in other hooks.
     *
     * @see [React Admin Fields documentation](https://marmelab.com/react-admin/Fields.html)
     */
    __reactAdminFieldProps: FieldProps
  }
}

export interface ColumnsHookOptions {
  /**
   * `MuiDatagrid` children
   *
   * Expected to be `<Column>` components with a single React Admin `<Field>` child.
   *
   * @see {@link Column}
   * @see [React Admin Fields documentation](https://marmelab.com/react-admin/Fields.html)
   */
  children?: ReactNode

  /**
   * Set default definitions for every column in the datagrid.
   *
   * @see [GridColDef API](https://mui.com/x/api/data-grid/grid-col-def/)
   */
  columnDefaults?: Partial<GridColDef>
}

export const DEFAULT_COLUMN_DEFINITION: Partial<GridColDef> = {
  disableColumnMenu: true, // Makes the UI more complicated and includes unimplemented features
}

const convertToGridAlignment = (value?: string): GridAlignment | undefined => {
  function isGridAlignment(value?: string): value is GridAlignment {
    return value === 'left' || value === 'right' || value === 'center'
  }

  return isGridAlignment(value) ? value : undefined
}

const renderHeader: GridColDef['renderHeader'] = ({
  colDef: { width, headerName, description },
}) => <HeaderCellWithTooltip columnWidth={width} description={description} label={headerName} />

/**
 * Convert MuiDatagrid children into MUI DataGridPro Column Definitions
 */
export const useColumns = ({
  children: datagridChildren,
  columnDefaults,
}: ColumnsHookOptions): Pick<DataGridProProps, 'columns'> => {
  const resource = useResourceContext()
  const translateLabel = useTranslateLabel()

  const [preferredWidths] = usePreference<ColumnWidthPreferences>(COLUMN_WIDTHS_PREF_KEY, {})
  // We’re only interested in cached preferences for the initial render
  const [initialPreferredWidths] = useState(preferredWidths)

  /**
   * MUI DataGrid Column Definitions
   */
  const columns: GridColDef[] = useMemo(() => {
    return Children.toArray(datagridChildren)
      .filter(isValidElement)
      .map((Column) => {
        const {
          children: columnChildren,
          field: columnField,
          width: initialWidth,
          ...columnDefinition
        } = Column.props as ColumnProps

        const RAField = Children.only(columnChildren)

        if (!isValidElement(RAField))
          throw new Error('<Column> requires a single React Admin <Field> child')

        const raFieldProps = RAField.props as FieldProps
        const { source, label, sortable, sortBy, sortByOrder, textAlign } = raFieldProps

        // NOTE: `field` is used by `DataGridPro` to identify the column, but React Admin will refer
        // to either `sortBy` or `source` depending on props.
        const field = columnField ?? sortBy ?? source

        if (!field) {
          throw new Error(
            'Unable to determine column `field`: <Column> must have a `field` prop, or a React Admin Field child with a `source`, or `sortBy` prop',
          )
        }

        const Cell = memo(function Cell({ row }: GridRenderCellParams) {
          return <RecordContextProvider value={row}>{RAField}</RecordContextProvider>
        })

        const header = label ?? translateLabel({ source, label, resource })

        return {
          ...DEFAULT_COLUMN_DEFINITION,

          __reactAdminFieldProps: raFieldProps,

          field,
          headerName: typeof header === 'string' ? header : undefined,
          headerClassName: `column-${source}`,

          ...(columnDefinition.description ? { renderHeader } : {}),

          renderCell: (props) => <Cell {...props} />,

          headerAlign: convertToGridAlignment(textAlign),
          align: convertToGridAlignment(textAlign),

          // Only add `sortable` if explicitly set to `true` or `false`
          ...(typeof sortable !== 'undefined' ? { sortable } : {}),

          // NOTE: sort order does not include `null`, to prevent “removing” sort
          // SEE: https://mui.com/x/react-data-grid/sorting/#custom-sort-order
          sortingOrder: sortByOrder === SortOrder.DESC ? ['desc', 'asc'] : ['asc', 'desc'],

          width: (field && initialPreferredWidths[field]) || initialWidth,

          ...columnDefaults,
          ...columnDefinition,
        } satisfies GridColDef
      })
  }, [columnDefaults, datagridChildren, initialPreferredWidths, resource, translateLabel])

  return { columns }
}
