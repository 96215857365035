import { useCallback } from 'react'

import { FieldProps, FunctionField, RaRecord } from 'react-admin'

import { Rating } from '@mui/material'

import { IconRatingStar } from '@assuranceiq/react-icons/bold/RatingStar'
import { IconRatingStarAlternate } from '@assuranceiq/react-icons/bold/RatingStarAlternate'

import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'

export interface RatingFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends FieldProps<RecordType> {}

/**
 * Displays a 1-5 star rating
 *
 * - [MUI Rating](https://mui.com/material-ui/react-rating/)
 * - [MUI Rating API](https://mui.com/material-ui/api/rating/)
 */
export function RatingField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  ...props
}: RatingFieldProps<RecordType>) {
  const renderRating = useCallback(
    (record: RecordType) => {
      const rating = getData<number>(record, source)
      return (
        <Rating
          value={rating}
          icon={<IconRatingStar />}
          emptyIcon={<IconRatingStarAlternate />}
          readOnly
          size="small"
        />
      )
    },
    [source],
  )

  return <FunctionField source={source} render={renderRating} {...props} />
}
