import { AppLayout } from 'components/App/AppLayout'

import { ErrorFallback } from './ErrorFallback'
import { ErrorFallbackComponent } from './ErrorFallback.types'

export const FullscreenErrorFallback: ErrorFallbackComponent = (fallbackProps) => {
  return (
    <AppLayout centered contained>
      <ErrorFallback {...fallbackProps} />
    </AppLayout>
  )
}
