import { useCallback } from 'react'

import { FunctionField, FunctionFieldProps, RaRecord } from 'react-admin'

import { ErrorValue, NullValue } from 'components/values'
import { getData } from 'lib/helpers/get-data'
import { formatPhoneNumber, PHONE_REGEX } from 'lib/helpers/phone-helpers'
import { TypedRaRecord } from 'types/react-admin.types'

export interface PhoneFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Omit<FunctionFieldProps<RecordType>, 'render' | 'children'> {
  /**
   * Hide `+1` country codes
   * @default false
   */
  displayUSCountryCode?: boolean
}

/**
 * Display phone numbers in a consistent format
 */
export const PhoneField = <RecordType extends TypedRaRecord = RaRecord>({
  displayUSCountryCode = false,
  source,
  ...props
}: PhoneFieldProps<RecordType>) => {
  const renderPhone = useCallback(
    (record: RecordType) => {
      const phone = getData<string | null>(record, source)

      if (phone === null) return <NullValue />
      if (typeof phone !== 'string')
        return <ErrorValue error={`${phone} is not a string`}>{phone}</ErrorValue>

      const match = phone.match(PHONE_REGEX)

      if (!match || !match.groups)
        return (
          <ErrorValue
            error={`${JSON.stringify(phone)} does not match expected format: ${PHONE_REGEX}`}
          >
            {phone}
          </ErrorValue>
        )

      return (
        // TODO: Link to phone number? Or dialer?
        // <Link href={`tel:${phone}`}></Link>
        <>{formatPhoneNumber(phone, { displayUSCountryCode })}</>
      )
    },
    [source, displayUSCountryCode],
  )

  return <FunctionField {...props} source={source} render={renderPhone} />
}
