import { ThemeOptions } from '@mui/material'

import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

import Spacing from 'styles/modules/_Spacing.scss'
import Typography from 'styles/modules/_Typography.scss'

const baseButtonsConfig: ThemeOptions = {
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: {
          borderRadius: Spacing.unit,
          paddingInline: parseInt(Spacing.unit) * 2,
          paddingBlock: Spacing.unit,
          fontWeight: Typography.fontWeightRegular,
          lineHeight: `${Typography.bodyLineHeight} !important`,
          textTransform: 'none',
          transition: 'none',
        },

        startIcon: {
          marginRight: parseInt(Spacing.unit) / 2,

          '>*:nth-of-type(1)': {
            fontSize: Typography.bodyFontSize,
          },
        },
      },

      variants: [
        // SEE: https://www.figma.com/file/VqGj4J0X0nNBfApyUENbHz/Design-System-v2?type=design&node-id=2692-33787&mode=dev
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            background: Colors['bg-default'],
            color: Colors['gray-100'],
            borderColor: Colors['gray-50'],

            '&:hover': {
              background: Colors['bg-default'],
              color: Colors['primary'],
              borderColor: 'currentColor',
            },

            '&:active': {
              background: Colors['bg-default'],
              color: Colors['primary-dark'],
              borderColor: 'currentColor',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            '&.Mui-disabled': {
              background: Colors['gray-50'],
              color: Colors['gray-75'],
            },
          },
        },
      ],
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiButtonGroup-grouped:hover': {
            zIndex: 1, // Ensure all borders are visible on hover
          },
        },
      },
    },
  },
}

export const buttonsConfig: ThemeOptions = {
  ...baseButtonsConfig,

  components: {
    ...baseButtonsConfig.components,

    MuiToggleButton: {
      styleOverrides: {
        root: () => {
          if (
            !baseButtonsConfig.components?.MuiButton?.variants ||
            !baseButtonsConfig.components?.MuiButton?.styleOverrides?.root
          )
            throw new Error('Missing MuiButton styles')

          return {
            ...(baseButtonsConfig.components.MuiButton.styleOverrides.root as object),
            ...(baseButtonsConfig.components.MuiButton.variants.find(
              ({ props: { variant, color } }) => variant === 'outlined' && color === 'secondary',
            )?.style as object),

            '&.Mui-selected': {
              background: 'none',
              color: Colors['primary'],
              borderColor: 'currentColor !important',
              zIndex: 1, // Ensure all borders are visible

              '&:hover': {
                background: 'none',
              },
            },
          }
        },
      },
    },
  },
}
