import { FC, useCallback } from 'react'

import { HttpError } from 'react-admin'

import { GenericError as GenericAuth0Error, OAuthError, useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'

import { ErrorBoundary, ErrorBoundaryProps, ErrorBoundaryVariant } from 'components/ErrorBoundary'
import {
  defaultFallbackProps,
  ErrorFallbackButtonProps,
  ErrorFallbackDynamicProps,
  ErrorFallbackDynamicPropsFunction,
} from 'components/ErrorBoundary/ErrorFallback'
import { MessageType, sendCrmMessage } from 'lib/helpers/iframe-messaging'
import { CREATE_FROM_PLATES_PATH } from 'lib/helpers/path-helpers'

export const CLOSE_CREATE_FROM_PLATES_MODAL_BUTTON: ErrorFallbackButtonProps = {
  label: 'Close',
  onClick: () => {
    // TODO: Send explicit abort message to CRM so it resets the iframe
    sendCrmMessage(MessageType.MODAL_CLOSED)
    // HACK: Force the iframe to reload
    window.location.reload()
  },
}

export const REAUTHENTICATE_BUTTON = (
  logout: ReturnType<typeof useAuth0>['logout'],
): ErrorFallbackButtonProps => ({
  label: 'Retry Login',
  onClick: () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  },
})

/**
 * Where most application errors are dealt with. This ErrorBoundary has access
 * to all of our global app context (auth, router, etc.), giving us the ability
 * to provide better error handling and recovery options.
 */
export const ErrorBoundaryWithAppContext: FC = ({ children }) => {
  const location = useLocation()
  const { logout } = useAuth0()

  const isCurrentLocationCreateFromPlates = location.pathname === CREATE_FROM_PLATES_PATH

  const generateFallbackProps = useCallback<ErrorFallbackDynamicPropsFunction>(
    (error) => {
      let fallbackProps: ErrorFallbackDynamicProps = defaultFallbackProps(error)

      // Override default fallback props if on the CreateFromPlates modal
      if (isCurrentLocationCreateFromPlates)
        fallbackProps = createFromPlatesFallbackProps(fallbackProps)

      if (error.message?.match(/Login required/i)) {
        fallbackProps = {
          ...fallbackProps,

          title: 'Authentication Expired',
          message: 'Please reauthenticate to continue.',
          buttons: [...(fallbackProps.buttons ?? []), REAUTHENTICATE_BUTTON(logout)],
        }
      } else if (error instanceof GenericAuth0Error || error instanceof OAuthError) {
        fallbackProps = {
          ...fallbackProps,

          title: `Authentication Error: ${error.error_description ?? error.error}`,
          message: `An error occurred while trying to authenticate you: “${error.error}”. Please reauthenticate to continue.`,
          buttons: [...(fallbackProps.buttons ?? []), REAUTHENTICATE_BUTTON(logout)],
        }
      }

      return fallbackProps
    },
    [isCurrentLocationCreateFromPlates, logout],
  )

  const omitFromDatadog = useCallback<NonNullable<ErrorBoundaryProps['omitFromDatadog']>>(
    (error) => {
      if (error instanceof HttpError && error.message.match(/Login Required/i)) {
        return true
      }
    },
    [],
  )

  return (
    <ErrorBoundary
      variant={
        isCurrentLocationCreateFromPlates
          ? ErrorBoundaryVariant.MODAL
          : ErrorBoundaryVariant.FULLSCREEN
      }
      fallbackProps={generateFallbackProps}
      omitFromDatadog={omitFromDatadog}
      resetKeys={[location.pathname]}
    >
      {children}
    </ErrorBoundary>
  )
}

const createFromPlatesFallbackProps = (
  fallbackProps: ErrorFallbackDynamicProps,
): ErrorFallbackDynamicProps => ({
  ...fallbackProps,

  buttons: [CLOSE_CREATE_FROM_PLATES_MODAL_BUTTON],

  slotProps: {
    ...fallbackProps.slotProps,

    dialog: {
      ...fallbackProps.slotProps?.dialog,

      onClose: () => sendCrmMessage(MessageType.MODAL_CLOSED),
    },
  },
})
