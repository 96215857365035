import { Typography } from '@mui/material'

import { DEFAULT_CURRENCY_OPTIONS } from 'lib/helpers/number-helpers'

const NULL_CURRENCY_VALUE = Number(0).toLocaleString(navigator.language, DEFAULT_CURRENCY_OPTIONS)

export const CurrencyNullValue = () => {
  return (
    <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
      {NULL_CURRENCY_VALUE}
    </Typography>
  )
}
