import { useEffect } from 'react'

import { usePreference } from 'react-admin'

import { GridColDef, useGridApiContext } from '@mui/x-data-grid-pro'

export const COLUMN_WIDTHS_PREF_KEY = 'columnWidths'

export type ColumnWidthPreferences = Record<GridColDef['field'], number>

/**
 * Synchronize/store column width with React Admin preferences
 */
export const usePreferredColumnWidths = () => {
  const apiRef = useGridApiContext()

  const [, setPreferredWidths] = usePreference<ColumnWidthPreferences>(COLUMN_WIDTHS_PREF_KEY)

  useEffect(
    function onMUIColumnOrderChange() {
      return apiRef.current.subscribeEvent(
        'columnWidthChange',
        ({ colDef: { field }, width: newWidth }) => {
          setPreferredWidths((prevWidths) => ({
            ...prevWidths,
            [field]: newWidth,
          }))
        },
      )
    },
    [apiRef, setPreferredWidths],
  )
}
