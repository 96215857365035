import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'

export const GET_DNC_LIST = gql`
  query GetDerivedLeadsFields($get_dnc: Boolean, $ids: [Int!]) {
    items: getDerivedLeadsFields(get_dnc: true, ids: $ids) {
      id
      dnc
    }
  }
`

export const getDNCList: CustomQuery<FetchType.GET_MANY> = async ({
  client,
  clientOptions,
  parseResponse,
}) => {
  const response = await client.query({
    ...clientOptions,
    variables: {
      ...clientOptions.variables,
      ids: clientOptions.variables?.where?.id._in,
    },

    query: GET_DNC_LIST,
  })

  return parseResponse(response)
}
