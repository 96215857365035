import { ComponentProps, ReactElement, useCallback, useRef } from 'react'

import { DateTimeInputProps, FieldTitle, useInput } from 'react-admin'

import { MenuItem, Popper, PopperProps, TextField, TextFieldProps } from '@mui/material'
import {
  DesktopDateTimePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { enUS, es } from 'date-fns/locale'

import { safeParseDate } from 'lib/helpers/datetime-helpers'

type DateComponentProps = ComponentProps<typeof DesktopDateTimePicker<Date>>

export type TimePickerOption = (params: {
  props: ComponentProps<typeof MenuItem>

  /** DateTime string in the input format or parsed if `parse` prop is present */
  optionTime: string
}) => ReactElement | null

export type MUIDateTimeInputProps = Pick<
  DateTimeInputProps,
  | 'label'
  | 'source'
  | 'resource'
  | 'className'
  | 'isRequired'
  | 'disabled'
  | 'helperText'
  | 'parse'
  | 'format'
  | 'sx'
  | 'validate'
> & {
  componentProps?: DateComponentProps
  timePickerOption?: TimePickerOption
}

const StyledPickersDay = ({ sx, ...props }: PickersDayProps<Date>) => (
  <PickersDay
    {...props}
    sx={[
      (theme) => ({ fontSize: theme.typography.body.fontSize }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  />
)

const StyledPopper = ({ sx, ...props }: PopperProps) => (
  <Popper
    {...props}
    sx={[
      (theme) => ({ zIndex: theme.zIndex.modal + 1 }), // ensure popper is above the Form Dialog
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  />
)

const StyledTextField = ({ sx, ...props }: TextFieldProps) => (
  <TextField
    {...props}
    sx={[
      {
        '& .MuiInputBase-root': {
          // restores the right padding that was removed in the theme for MuiOutlinedInput
          // SEE: https://github.com/assuranceiq/vega/blob/38740cbd27bd4da7b6eb660c729bab3ca0a7d66f/src/styles/theme/config/inputsConfig.ts#L62
          paddingRight: '14px',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  />
)

const SUPPORTED_LOCALES: Record<string, Locale> = { en: enUS, es }

function preferredLocale(): Locale {
  const preferredLanguages = navigator.languages.map((language) => language.substring(0, 2))
  const supportedLanguage = preferredLanguages.find((language) => SUPPORTED_LOCALES[language])

  return supportedLanguage && SUPPORTED_LOCALES[supportedLanguage]
    ? SUPPORTED_LOCALES[supportedLanguage]
    : enUS
}

function replaceTimeInDateTime(dateTime: string, time?: string) {
  if (!time) return dateTime

  return dateTime.substring(0, dateTime.length - time.length) + time
}

export const MUIDateTimeInput = ({
  componentProps: { slotProps, ...componentProps } = {},
  disabled,
  format,
  helperText: defaultHelperText,
  isRequired,
  label,
  parse,
  resource,
  source,
  timePickerOption,
  validate,
}: MUIDateTimeInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const {
    field: { value: fieldValue, ref: fieldRef, ...field },
    fieldState: { invalid, error },
  } = useInput({ format, isRequired, parse, source, validate })

  const helperText = error?.message || defaultHelperText

  const digitalClockItem = useCallback(
    (props: ComponentProps<typeof MenuItem>) => {
      if (!timePickerOption || !ref.current?.value) return null

      const time = props.children as string
      const currentDateTime = ref.current.value

      const itemDateTime = replaceTimeInDateTime(currentDateTime, time)

      return timePickerOption({
        props: props,
        optionTime: parse ? parse(itemDateTime) : itemDateTime,
      })
    },
    [parse, timePickerOption],
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={preferredLocale()}>
      <DesktopDateTimePicker<Date>
        {...field}
        inputRef={(element) => {
          ref.current = element
          fieldRef(element)
        }}
        label={
          <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
        }
        onAccept={() => field.onBlur()} // trigger validation on date selection
        slots={{
          day: StyledPickersDay,
          popper: StyledPopper,
          textField: StyledTextField,
          ...(timePickerOption ? { digitalClockItem } : {}),
        }}
        slotProps={{
          ...slotProps,
          textField: {
            disabled,
            error: invalid,
            helperText,
            ...slotProps?.textField,
          },
        }}
        thresholdToRenderTimeInASingleColumn={100} // to combine hours, minutes, and AM/PM into a single column
        value={fieldValue ? safeParseDate(fieldValue) : null}
        {...componentProps}
      />
    </LocalizationProvider>
  )
}
