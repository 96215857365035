import { ReactNode } from 'react'

import { Container, ContainerProps, Grid } from '@mui/material'

import { clsx } from 'clsx'

import styles from './AppLayout.module.scss'

interface AppLayoutProps {
  children?: ReactNode

  /** Horizontally & vertically center contents */
  centered?: boolean

  /**
   * Wrap contents within a MUI Container
   *
   * @see https://mui.com/material-ui/react-container/
   */
  contained?: boolean

  /** @see https://mui.com/material-ui/api/container/ */
  containerProps?: ContainerProps
}

export const AppLayout = ({ children, centered, contained, containerProps }: AppLayoutProps) => {
  return (
    <Grid container direction="column" className={styles.grid}>
      <Container
        {...containerProps}
        maxWidth={contained ? containerProps?.maxWidth : false}
        className={clsx(styles.container, centered && styles.centered, containerProps?.className)}
      >
        {children}
      </Container>
    </Grid>
  )
}
