import { FunctionField, ReferenceField } from 'react-admin'

import { LoadingButton, LoadingButtonProps } from '@mui/lab'

import { useDatadogRUM } from '@assuranceiq/react-components'
import { IconPhone } from '@assuranceiq/react-icons/bold/Phone'
import { IconPhoneActionsOff } from '@assuranceiq/react-icons/bold/PhoneActionsOff'

import { clsx } from 'clsx'

import { ConditionalTooltip } from 'components/ConditionalTooltip'
import { Resources } from 'lib/api/api.types'
import { isDialerAvailable, startCall } from 'lib/dialer'
import { DerivedLeadFieldsRecord, PersonRecord } from 'types/records.types'

import { getDNCList } from './CallButton.queries'

import styles from './CallButton.module.scss'

const DNC_LOADING_PLACEHOLDER: DerivedLeadFieldsRecord = {
  id: -1, // HACK: Using an invalid ID here to indicate loading state
  dnc: false,
}

const DNC_PROPS: Partial<LoadingButtonProps> = {
  className: isDialerAvailable ? styles.error : undefined,
  startIcon: <IconPhoneActionsOff />,
  title: 'This person is on the Do Not Call list',
  disabled: true,
  children: 'DNC',
}

const NOT_IN_DIALER_PROPS: Partial<LoadingButtonProps> = {
  title: 'Visit this page from the dialer to place calls',
  disabled: true,
}

const DNC_LOADING_PROPS: Partial<LoadingButtonProps> = {
  title: 'Loading Do Not Call status…',
  loading: true,
}

export interface CallButtonProps extends Omit<LoadingButtonProps, 'onClick' | 'disabled'> {
  person: PersonRecord | null
}

/**
 * Places a call through the dialer.
 *
 * Will be disabled if the person is on the DNC list, or the dialer is not
 * available.
 */
export const CallButton = ({ person, ...buttonProps }: CallButtonProps) => {
  const datadog = useDatadogRUM()

  if (!person) {
    datadog.addError('CallButton: person is null/undefined')
    return null
  }

  return (
    <ReferenceField<PersonRecord>
      reference={Resources.DERIVED_LEAD_FIELDS}
      queryOptions={{
        meta: { query: getDNCList },
        // HACK: Use placeholderData to prevent showing linear progress indicator
        placeholderData: [DNC_LOADING_PLACEHOLDER],
      }}
      record={person}
      source="lead_id"
    >
      <FunctionField<DerivedLeadFieldsRecord>
        render={({ id, dnc: isOnDNC }) => {
          const isDNCLoading = id === DNC_LOADING_PLACEHOLDER.id

          const {
            children = 'Call',
            disabled,
            title,
            className,
            ...props
          } = {
            ...buttonProps,
            ...(isDNCLoading
              ? DNC_LOADING_PROPS
              : isOnDNC
              ? DNC_PROPS
              : !isDialerAvailable
              ? NOT_IN_DIALER_PROPS
              : {}),
          }

          const onClick = () => {
            if (disabled) return

            startCall(person)
          }

          return (
            <ConditionalTooltip enabled={disabled} title={title}>
              <LoadingButton
                size="small"
                startIcon={<IconPhone />}
                loadingPosition="start"
                {...props}
                className={clsx(styles.callButton, className)}
                disabled={disabled}
                onClick={onClick}
              >
                {children}
              </LoadingButton>
            </ConditionalTooltip>
          )
        }}
      />
    </ReferenceField>
  )
}
