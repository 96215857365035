import { cloneElement, useEffect, useRef } from 'react'

import {
  useWizardFormContext,
  WizardFormProgressProps,
  WizardFormStepProvider,
} from '@react-admin/ra-form-layout'

import { Step, StepButton, Stepper } from '@mui/material'

export interface PatchedWizardProgressProps extends WizardFormProgressProps {
  completedStepComponent?: React.ReactNode
}

/**
 * Copied node_modules/@react-admin/ra-form-layout/src/forms/wizard-form/WizardProgress.tsx
 * with additional features (see "PATCHED" comments)
 */
export const PatchedWizardProgress = ({
  completedStepComponent,
  ...props
}: PatchedWizardProgressProps) => {
  const { currentStep, goToStep, steps } = useWizardFormContext(props)
  const stepperRef = useRef<HTMLDivElement | null>(null)

  // PATCHED: Scroll to the progress bar on every step change
  useEffect(
    function scrollToTop() {
      if (!stepperRef.current) return

      stepperRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    },
    [currentStep],
  )

  const handleStepClick = (index: number) => (): void => goToStep(index)

  return (
    <Stepper activeStep={currentStep} ref={stepperRef} {...props}>
      {steps.map((step, index) => {
        const label = cloneElement(step, { intent: 'label' })

        // PATCHED: Customize the icon for completed steps
        const icon =
          index < currentStep && completedStepComponent ? completedStepComponent : undefined

        return (
          <Step key={step.key}>
            <StepButton onClick={handleStepClick(index)} icon={icon}>
              <WizardFormStepProvider key={step.key} step={index}>
                {label}
              </WizardFormStepProvider>
            </StepButton>
          </Step>
        )
      })}
    </Stepper>
  )
}
