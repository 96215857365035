import { useCallback, useMemo } from 'react'

import { useGetManyReference, useRecordContext, Validator } from 'react-admin'

import { addMinutes } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { Resources } from 'lib/api/api.types'
import { appointmentTimeRange } from 'lib/helpers/appointment-helpers'
import { DateArg, safeParseDate, toISODateString } from 'lib/helpers/datetime-helpers'
import { FollowUpFormValues } from 'resources/all_follow_ups/components/FollowUpForm'
import { FormRecord } from 'types/forms.types'
import { SortOrder } from 'types/react-admin.types'
import { FollowUpAppointmentRecord, FollowUpType, ISODate } from 'types/records.types'

import { getConflictingAppointments } from './AppointmentDateTime.queries'

export const useFindConflictingAppointment = () => {
  const record = useRecordContext<FormRecord>()

  const { watch } = useFormContext<FollowUpFormValues>()

  const followUpId = watch('id')
  const agentId = watch('agent_id')
  const scheduledTime = watch('scheduled_time')
  const durationMinutes = watch('duration_minutes')
  const type = watch('type')
  const isEditForm = !!followUpId

  const { data: existingAppointments } = useGetManyReference<FollowUpAppointmentRecord>(
    Resources.FOLLOW_UP_APPOINTMENTS,
    {
      target: 'opportunity.assigned_agent_id',
      id: isEditForm ? agentId : record.agent_id,
      sort: { field: 'scheduled_time', order: SortOrder.ASC },
      meta: {
        query: getConflictingAppointments,
      } as DataProviderMeta<FetchType.GET_MANY_REFERENCE>,
    },
    { enabled: type === FollowUpType.APPOINTMENT },
  )

  const groupedExistingAppointmentsByDate = useMemo(() => {
    if (!existingAppointments) return

    return existingAppointments.reduce((acc, appointment) => {
      const date = toISODateString(appointment.scheduled_time)

      acc[date] = [...(acc[date] || []), appointment]
      return acc
    }, {} as Record<ISODate, FollowUpAppointmentRecord[]>)
  }, [existingAppointments])

  const conflictingTime = useCallback<(date: DateArg) => FollowUpAppointmentRecord | undefined>(
    (date) => {
      if (type === FollowUpType.REMINDER) return // Allow overlapping reminders
      if (!groupedExistingAppointmentsByDate) return

      const newAppointmentStart = typeof date === 'string' ? safeParseDate(date) : new Date(date)
      const newAppointmentStartDate = toISODateString(newAppointmentStart)
      const newAppointmentEnd = addMinutes(newAppointmentStart, durationMinutes)

      const selectedDateAppointments =
        groupedExistingAppointmentsByDate[newAppointmentStartDate] || []

      return selectedDateAppointments.find(({ id, duration_minutes, scheduled_time }) => {
        if (followUpId === id) return false

        const existingAppointmentStart = safeParseDate(scheduled_time)
        const existingAppointmentEnd = addMinutes(existingAppointmentStart, duration_minutes)

        return (
          newAppointmentStart < existingAppointmentEnd &&
          newAppointmentEnd > existingAppointmentStart
        )
      })
    },
    [type, groupedExistingAppointmentsByDate, durationMinutes, followUpId],
  )

  const conflictingAppointment = useMemo(
    () => conflictingTime(scheduledTime),
    [conflictingTime, scheduledTime],
  )

  const validateNoConflictingAppointment = useCallback<Validator>(() => {
    if (!conflictingAppointment) return

    return `This time conflicts with another appointment on ${appointmentTimeRange({
      date: conflictingAppointment.scheduled_time,
      durationMinutes: conflictingAppointment.duration_minutes,
    })}.`
  }, [conflictingAppointment])

  return {
    conflictingTime,
    existingAppointments,
    validateNoConflictingAppointment,
  }
}
