import { useDatadogRUM } from '@assuranceiq/react-components'

import axios from 'axios'
import { useQuery } from 'react-query'

import { useCEOToken } from 'hooks'
import { CEO_SERVICE_API_ENDPOINT } from 'lib/environment-variables'

export enum CEOEligibilityStatus {
  ALLOWED = 'allowed',
  NOT_ALLOWED = 'not_allowed',
}

export interface CEOEligibilityResponse {
  outreach_status: CEOEligibilityStatus
}

export interface UseDNTQueryParams {
  lead_id: number
}

export const DNT_ENDPOINT = `${CEO_SERVICE_API_ENDPOINT}/api/v1/eligibility`

/**
 * This hook is used to check whether a lead is on the "Do Not Text" list.
 */
export const useIsOnDNT = ({ lead_id }: UseDNTQueryParams) => {
  const datadog = useDatadogRUM()
  const { error: tokenError, token } = useCEOToken()
  const { data, isLoading, error } = useQuery(
    `dnt/${lead_id}`,
    async () => {
      const response = await axios.get<CEOEligibilityResponse>(DNT_ENDPOINT, {
        params: {
          outreach_type: 'sms',
          process_type: 'automated:informational',
          lead_id: lead_id.toString(),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response.data
    },
    {
      enabled: !!token,
      retry: false,
      onError(error) {
        datadog.addError(error)
      },
    },
  )
  const isOnDNT = data?.outreach_status === CEOEligibilityStatus.NOT_ALLOWED

  return {
    isLoading,
    isOnDNT,
    ...(tokenError || error
      ? {
          isError: true,
          error: tokenError || error,
          status: 'error',
        }
      : {}),
  }
}
