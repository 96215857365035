import {
  HealthProduct,
  LifeProduct,
  LOI,
  MedicareProduct,
  PAndCProduct,
  PolicyTypes,
  Product,
  SalesStage,
} from 'types/records.types'

export const LOI_DISPLAY_VALUES: Record<LOI, string> = {
  [LOI.MEDICARE]: 'Medicare',
  [LOI.HEALTH]: 'Health',
  [LOI.LIFE]: 'Life',
  [LOI.P_AND_C]: 'P&C',
}

export const PRODUCT_DISPLAY_VALUES: Record<Product, string> = {
  [MedicareProduct.MAPD]: 'MAPD',
  [MedicareProduct.DSNP]: 'DSNP',
  [MedicareProduct.MA]: 'MA',
  [MedicareProduct.CSNP]: 'CSNP',
  [MedicareProduct.PDP]: 'PDP',
  [MedicareProduct.MED_SUPP]: 'Med Supp',
  [HealthProduct.ACA]: 'ACA',
  [HealthProduct.ANCILLARY]: 'Ancillary',
  [HealthProduct.STM]: 'STM',
  [LifeProduct.FINAL_EXPENSE]: 'Final Expense',
  [LifeProduct.TERM]: 'Term',
  [LifeProduct.WHOLE]: 'Whole',
  [PAndCProduct.AUTO]: 'Auto',
  [PAndCProduct.CONDO]: 'Condo',
  [PAndCProduct.DWELLING]: 'Dwelling Fire',
  [PAndCProduct.FLOOD]: 'Flood',
  [PAndCProduct.HOME]: 'Home',
  [PAndCProduct.PET]: 'Pet',
  [PAndCProduct.RENTERS]: 'Renters',
  [PAndCProduct.TOYS]: 'Toys',
  [PAndCProduct.UMBRELLA]: 'Umbrella',
}

export const SALES_STAGE_DISPLAY_VALUES: Record<SalesStage, string> = {
  [SalesStage.QUALIFYING]: 'Qualifying',
  [SalesStage.NEEDS_ANALYSIS]: 'Needs Analysis',
  [SalesStage.EVALUATING_PROPOSAL]: 'Client Evaluating Proposal',
  [SalesStage.ENROLLING]: 'Enrolling',
  [SalesStage.CLOSED]: 'Closed Won or Lost',
}

export const POLICY_TYPE_DISPLAY_VALUES: Record<PolicyTypes, string> = {
  [PolicyTypes.MED_ADV]: 'Medicare Advantage',
  [PolicyTypes.PDP]: 'Prescription Drug Plan',
}
