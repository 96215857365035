/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * ra-data-hasura's getResponseParser without introspection
 * @see https://github.com/hasura/ra-data-hasura/blob/8503ba74adf33632b455e874356d0cc6b3f053ca/src/getResponseParser/index.ts
 */

import { FetchType } from 'hooks/useDataProvider'

import { QueryResults } from '../useDataProvider.types'
import { sanitizeResource } from './sanitizeResource'

export type GetResponseParser<F extends FetchType> = (
  fetchType: F,
) => (res: { data?: any }) => QueryResults[F]

export const getResponseParser: GetResponseParser<FetchType> = (fetchType) => (result) => {
  const response = result.data || {}

  switch (fetchType) {
    case FetchType.GET_MANY_REFERENCE:
    case FetchType.GET_LIST: {
      return {
        data: response.items.map(sanitizeResource),
        ...(response.total?.aggregate?.count !== undefined
          ? {
              total: response.total.aggregate.count,
            }
          : {
              pageInfo: {
                hasPreviousPage: true,
                hasNextPage: true, // TODO: set to false if no more records exist
              },
            }),
      }
    }

    case FetchType.GET_MANY:
      return { data: response.items.map(sanitizeResource) }

    case FetchType.GET_ONE:
      return { data: sanitizeResource(response.returning[0]) }

    case FetchType.CREATE:
    case FetchType.UPDATE:
      return { data: sanitizeResource(response.data.returning[0]) }

    case FetchType.DELETE:
      return { data: sanitizeResource(response.returning) }

    case FetchType.UPDATE_MANY:
    case FetchType.DELETE_MANY:
      return { data: response.data.affected_rows.map((x: { id: any }) => x.id) }

    default:
      throw Error(`Expected a proper fetchType, got: ${fetchType}`)
  }
}
