import { useEffect } from 'react'

import { SortPayload, useListContext } from 'react-admin'

import { GridSortModel, useGridApiContext } from '@mui/x-data-grid-pro'

import { SortOrder } from 'types/react-admin.types'

const DEFAULT_SORT = { field: 'id', order: SortOrder.ASC }

/** Convert a React Admin sort payload to a MUI DataGrid sort model */
const toMuiSortModel = (raSort: SortPayload): GridSortModel => [
  {
    field: raSort.field,
    sort: raSort.order.toLowerCase() === 'desc' ? 'desc' : 'asc',
  },
]

/** Convert a MUI DataGrid sort model to a React Admin sort payload */
const toRaSort = (muiSortModel: GridSortModel): SortPayload => {
  const [muiSort] = muiSortModel

  // MUI can set `sort` to `null` to “remove” a sort
  if (!muiSort?.sort) return DEFAULT_SORT

  return {
    field: muiSort.field,
    order: muiSort.sort.toUpperCase(),
  }
}

const sortsMatch = (raSort: SortPayload, muiSortModel: GridSortModel) => {
  if (!muiSortModel.length) return false

  const [muiSort] = muiSortModel

  return muiSort.field === raSort.field && muiSort.sort === raSort.order.toLowerCase()
}

/**
 * Synchronize MUI DataGrid sorting with React Admin
 */
export const useSortModel = () => {
  const apiRef = useGridApiContext()
  const { sort: raSort, setSort: setRaSort } = useListContext()

  useEffect(() => {
    const muiSortModel = apiRef.current.getSortModel()

    if (sortsMatch(raSort, muiSortModel)) return

    apiRef.current.setSortModel(toMuiSortModel(raSort))
  }, [apiRef, raSort])

  useEffect(() => {
    return apiRef.current.subscribeEvent('sortModelChange', (newSortModel: GridSortModel) => {
      if (sortsMatch(raSort, newSortModel)) return

      setRaSort(toRaSort(newSortModel))
    })
  }, [apiRef, raSort, setRaSort])
}
