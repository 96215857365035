import { useCallback, useEffect, useRef } from 'react'

import { DateTimeInputProps, required, SelectInput, Validator } from 'react-admin'

import {
  Alert,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { addMinutes, format, startOfDay } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { useFormContext } from 'react-hook-form'

import { MUIDateTimeInput, TimePickerOption } from 'components/inputs'
import {
  displayFullDateTimeAndTimezone,
  LOCAL_TIMEZONE,
  safeParseDate,
  timezoneShortName,
} from 'lib/helpers/datetime-helpers'
import { FollowUpFormValues } from 'resources/all_follow_ups/components/FollowUpForm'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { FollowUpType } from 'types/records.types'

import { useClientTimezone } from './useClientTimezone'
import { useFindAppointmentCallingTime } from './useFindAppointmentCallingTime'
import { useFindConflictingAppointment } from './useFindConflictingAppointment'
import { useMinAppointmentTime } from './useMinAppointmentTime'

import styles from './AppointmentDateTime.module.scss'

export const APPOINTMENT_DURATION_CHOICES = [
  { id: 15, name: '15 min' },
  { id: 30, name: '30 min' },
  { id: 60, name: '1 hr' },
]

export const DATETIME_INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm"
export const FIFTEEN_MINUTE_INTERVAL = 15
export const FIFTEEN_MINUTE_INTERVAL_IN_SECONDS = FIFTEEN_MINUTE_INTERVAL * 60

const validate15MinuteInterval: Validator = (date: FollowUpFormValues['scheduled_time']) => {
  if (!date) return
  if (safeParseDate(date).getMinutes() % FIFTEEN_MINUTE_INTERVAL !== 0)
    return 'Must be a 15 minute interval: 00, 15, 30, 45.'
}

export const AppointmentDateTime = () => {
  const {
    formState: {
      errors: { scheduled_time: scheduledTimeError },
      dirtyFields: { scheduled_time: scheduledTimeIsDirty },
    },
    setValue,
    trigger,
    watch,
  } = useFormContext<FollowUpFormValues>()

  const type = watch('type')
  const scheduledTime = watch('scheduled_time')
  const durationMinutes = watch('duration_minutes')
  const isEditForm = !!watch('id')

  const { conflictingTime, existingAppointments, validateNoConflictingAppointment } =
    useFindConflictingAppointment()
  const { clientTimezone, isLoading: isClientTimezoneLoading } = useClientTimezone()
  const appointmentTimezone = clientTimezone ?? LOCAL_TIMEZONE
  const { minAppointmentTime, validateMinAppointmentTime } = useMinAppointmentTime({
    clientTimezone,
    minuteInterval: FIFTEEN_MINUTE_INTERVAL,
  })
  const { isCallingTime, nearestCallingTime, validateAppointmentCallingTime } =
    useFindAppointmentCallingTime({
      clientTimezone: appointmentTimezone,
    })

  useEffect(
    function setNearestCallingTime() {
      if (type !== FollowUpType.APPOINTMENT || isEditForm) return

      const zonedScheduleTime = utcToZonedTime(safeParseDate(scheduledTime), appointmentTimezone)
      const nearestTime = nearestCallingTime(zonedScheduleTime)

      if (zonedScheduleTime === nearestTime) return

      setValue('scheduled_time', zonedTimeToUtc(nearestTime, appointmentTimezone).toISOString())
    },
    // only run for default scheduled_time
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentTimezone, isEditForm, nearestCallingTime, setValue, type],
  )

  useEffect(
    function revalidateScheduledTime() {
      trigger('scheduled_time')
    },
    [
      trigger,

      // re-validate `scheduled_time` if any of these change
      clientTimezone,
      durationMinutes,
      existingAppointments,
      minAppointmentTime,
      scheduledTime,
      type,
    ],
  )

  /** Parse input values coming from the datetime picker */
  const parseAppointmentDateTime = useCallback<NonNullable<DateTimeInputProps['parse']>>(
    (value: string): FollowUpFormValues['scheduled_time'] | null => {
      if (!value) return null

      const date = safeParseDate(value)

      // Reject invalid dates
      if (isNaN(date.getTime())) return null

      // Convert the appointment time from the client’s timezone to UTC
      return zonedTimeToUtc(date, appointmentTimezone).toISOString()
    },
    [appointmentTimezone],
  )

  /** Format date values from the server for the datetime picker */
  const formatAppointmentDateTime = useCallback<NonNullable<DateTimeInputProps['format']>>(
    (value?: FollowUpFormValues['scheduled_time']): string => {
      if (!value) return ''

      // Convert the appointment time from UTC to the client’s timezone
      return format(
        utcToZonedTime(safeParseDate(value), appointmentTimezone),
        DATETIME_INPUT_FORMAT,
      )
    },
    [appointmentTimezone],
  )

  const cachedShouldDisable = useRef<Record<string, boolean>>({})
  const disableTime = useCallback<(date: Date) => boolean>(
    (date) => {
      if (!isCallingTime(date)) return true
      if (!existingAppointments) return false

      const cacheKey = date.toISOString()
      let shouldDisable = cachedShouldDisable.current[cacheKey]

      if (shouldDisable === undefined) {
        const appointmentTimeToUTC = zonedTimeToUtc(date, appointmentTimezone)
        shouldDisable = !!conflictingTime(appointmentTimeToUTC)

        cachedShouldDisable.current[cacheKey] = shouldDisable
      }

      return shouldDisable
    },
    [appointmentTimezone, conflictingTime, existingAppointments, isCallingTime],
  )

  const timePickerOption = useCallback<TimePickerOption>(
    ({ props, optionTime }) => {
      const date = utcToZonedTime(optionTime, appointmentTimezone)

      if (!isCallingTime(date)) return null

      return (
        <MenuItem
          {...props}
          {...(disableTime(date)
            ? {
                'aria-disabled': true,
                disabled: true,
                sx: { textDecoration: 'line-through' },
              }
            : {})}
          // HACK: prevent auto-scrolling to the selected time
          // SEE: https://github.com/mui/mui-x/blob/4106101dedc2f8c349e628a7932ef161dd2e520d/packages/x-date-pickers/src/DigitalClock/DigitalClock.tsx#L205
          aria-selected={false}
        />
      )
    },
    [appointmentTimezone, disableTime, isCallingTime],
  )

  useEffect(
    function cacheShouldDisableForDefaultDate() {
      if (!existingAppointments || isClientTimezoneLoading) return

      cachedShouldDisable.current = {}

      const selectedDate = safeParseDate(scheduledTime)
      let dayTime = startOfDay(selectedDate)

      while (dayTime.getDay() === selectedDate.getDay()) {
        disableTime(dayTime)
        dayTime = addMinutes(dayTime, FIFTEEN_MINUTE_INTERVAL)
      }
    },
    // only run for default scheduled_time
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentTimezone, disableTime],
  )

  return (
    <div data-testid="AppointmentDateTime">
      <Stack direction="row" spacing={1}>
        <MUIDateTimeInput
          source="scheduled_time"
          disabled={isClientTimezoneLoading}
          label="Date & Time"
          helperText={false}
          parse={parseAppointmentDateTime}
          format={formatAppointmentDateTime}
          componentProps={{
            closeOnSelect: false,
            minDateTime: minAppointmentTime,
            shouldDisableTime: disableTime, // to show error state on input
            slotProps: {
              textField: {
                inputProps: {
                  'data-dd-privacy': SessionReplayPrivacy.ALLOW,
                },
                sx: {
                  mt: 0,
                  '& .MuiFormHelperText-root': { display: 'none' }, // We use custom helper text below
                },
              },
            },
            minutesStep: FIFTEEN_MINUTE_INTERVAL, // for keyboard navigating the time in input by interval
            timeSteps: { minutes: FIFTEEN_MINUTE_INTERVAL },
          }}
          timePickerOption={timePickerOption}
          validate={
            !isEditForm || scheduledTimeIsDirty // avoid initial validation in edit form
              ? [
                  required('Please enter a valid date'),
                  validate15MinuteInterval,
                  validateMinAppointmentTime,
                  validateNoConflictingAppointment,
                  validateAppointmentCallingTime,
                ]
              : undefined
          }
        />

        <SelectInput
          source="duration_minutes"
          label="Duration"
          helperText={false}
          choices={APPOINTMENT_DURATION_CHOICES}
          validate={required()}
          sx={{ minWidth: 110 }}
          data-dd-privacy={SessionReplayPrivacy.ALLOW}
        />

        <TextField
          /**
           * HACK: Though this is readonly text, we use a TextField to ensure
           * proper alignment with other form elements
           */
          variant="outlined"
          value={clientTimezone ? timezoneShortName(clientTimezone, scheduledTime) : ''}
          label="Timezone"
          InputProps={{
            startAdornment: isClientTimezoneLoading ? (
              <InputAdornment position="start">
                <CircularProgress color="inherit" size="1em" />
              </InputAdornment>
            ) : undefined,
            inputProps: {
              disabled: true, // HACK: Disable the input without applying "disabled" styles
              'data-dd-privacy': SessionReplayPrivacy.ALLOW,
            },
          }}
          sx={{
            minWidth: 110,

            // HACK: Disable input styles to present this as readonly text

            paddingLeft: 1,

            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiInputBase-root, & .MuiInputBase-input': {
              paddingLeft: 0,
            },
          }}
        />
      </Stack>

      {scheduledTimeError && (
        // HACK: Can’t use regular HelperText here as it’s constrained by the datetime input
        // width; we want it wider
        <Typography color="error" variant="caption" role="alert">
          {scheduledTimeError.message}
        </Typography>
      )}

      <Alert role="status" icon={false} sx={{ my: 1 }} className={styles.localTimeAlert}>
        {type} time in your timezone: {displayFullDateTimeAndTimezone(scheduledTime)}
      </Alert>
    </div>
  )
}
