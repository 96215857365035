import {
  HealthEnrollmentType,
  LifeEnrollmentPeriod,
  LOI,
  MedicareEnrollmentType,
  PAndCEnrollmentPeriod,
} from 'types/records.types'

import { Month } from './datetime-helpers'

export type EnrollmentType = MedicareEnrollmentType | HealthEnrollmentType

export const currentEnrollmentPeriod = (
  loi: LOI,
  enrollmentPeriod: EnrollmentType,
  now = new Date(),
) => {
  const currentYear = now.getFullYear()
  const nextYear = currentYear + 1
  const next2Years = currentYear + 2
  const isAfterJan15 = now > new Date(currentYear, Month.JANUARY, 15)
  const isAfterMarch31 = now > new Date(currentYear, Month.MARCH, 31)
  const isAfterSeptember = now.getMonth() > Month.SEPTEMBER
  const isAfterDec7 = now > new Date(currentYear, Month.DECEMBER, 7)

  switch (loi) {
    case LOI.MEDICARE:
      switch (enrollmentPeriod) {
        case MedicareEnrollmentType.OEP:
          return `${MedicareEnrollmentType.OEP} PY ${isAfterMarch31 ? nextYear : currentYear}`
        case MedicareEnrollmentType.AEP:
          return `${MedicareEnrollmentType.AEP} PY ${isAfterDec7 ? next2Years : nextYear}`
        case MedicareEnrollmentType.SEP:
          return `${MedicareEnrollmentType.SEP} PY ${isAfterSeptember ? nextYear : currentYear}`
        case MedicareEnrollmentType.IEP:
          return MedicareEnrollmentType.IEP
        default:
          return
      }

    case LOI.HEALTH:
      switch (enrollmentPeriod) {
        case HealthEnrollmentType.OEP:
          return `${HealthEnrollmentType.OEP} PY ${isAfterJan15 ? nextYear : currentYear}`
        case HealthEnrollmentType.QLE:
          return `${HealthEnrollmentType.QLE} PY ${currentYear}`
        case HealthEnrollmentType.THIRTY_DAY:
          return HealthEnrollmentType.THIRTY_DAY
        default:
          return
      }
    default:
      return
  }
}

const mapPeriods = (loi: LOI, values: EnrollmentType[]) => {
  return values
    .map((value) => currentEnrollmentPeriod(loi, value))
    .filter((value) => value !== undefined) as string[]
}

/**
 * Get available enrollment periods for the current date
 */
export const currentEnrollmentPeriods = () => {
  return {
    [LOI.MEDICARE]: mapPeriods(LOI.MEDICARE, Object.values(MedicareEnrollmentType)),
    [LOI.HEALTH]: mapPeriods(LOI.HEALTH, Object.values(HealthEnrollmentType)),
    [LOI.LIFE]: Object.values(LifeEnrollmentPeriod),
    [LOI.P_AND_C]: Object.values(PAndCEnrollmentPeriod),
  } satisfies Record<LOI, string[]>
}
