import {
  ClientTimezone,
  FollowUpSubType,
  FollowUpType,
  HealthProduct,
  LeadType,
  LifeProduct,
  LOI,
  MedicareProduct,
  PAndCProduct,
  Product,
  Rating,
  SalesStage,
} from 'types/records.types'

export const LEAD_TYPES = Object.values(LeadType)
export const LOIS = Object.values(LOI)
export const PRODUCTS: Record<LOI, Product[]> = {
  [LOI.MEDICARE]: Object.values(MedicareProduct),
  [LOI.HEALTH]: Object.values(HealthProduct),
  [LOI.LIFE]: Object.values(LifeProduct),
  [LOI.P_AND_C]: Object.values(PAndCProduct),
}
export const CLIENT_TIMEZONES = Object.values(ClientTimezone)
export const SALES_STAGES = Object.values(SalesStage)
export const FOLLOW_UP_SUB_TYPES = Object.values(FollowUpSubType)
export const FOLLOW_UP_TYPES = Object.values(FollowUpType)
export const RATINGS: Rating[] = [0, 1, 2, 3, 4, 5]
