import { FC, forwardRef } from 'react'

import { formatISO } from 'date-fns'
import ReactTimeAgo, { ReactTimeagoProps } from 'react-timeago'

import { safeParseDate } from 'lib/helpers/datetime-helpers'

// HACK: TimeAgo doesn’t play well with MUI wrappers (like `Tooltip`) as there’s
// no `ref` prop, so we have to do some workarounds. Essentially we have to
// override its `<time>` tag with our own custom one, that supports a forwarded
// `ref` prop.
//
// @see https://mui.com/material-ui/guides/composition/#caveat-with-refs
/**
 * A wrapper around `react-timeago` that supports a forwarded `ref` prop.
 *
 * @see [TimeAgo props](https://github.com/nmn/react-timeago#props)
 * @see [TimeAgo documentation](https://github.com/nmn/react-timeago)
 */
export const TimeAgo = forwardRef<HTMLTimeElement, ReactTimeagoProps>(function ForwardedTimeAgo(
  { date, ...props },
  ref,
) {
  // HACK: We want react-timeago to render as a fragment (i.e. no `component`),
  // but it requires a `component` that it will add a `title` prop to. We can’t
  // use a React.Fragment directly, because it will throw errors if given a
  // `title` prop. This component is a workaround to avoid those errors.
  const NoComponent: FC = ({ children }) => <>{children}</>

  return (
    <time ref={ref} dateTime={formatISO(safeParseDate(date))} {...props}>
      <ReactTimeAgo component={NoComponent} date={safeParseDate(date)} />
    </time>
  )
})
