import { ReactNode, useCallback, useEffect, useState } from 'react'

import {
  Link,
  NotificationOptions,
  NotificationPayload,
  undoableEventEmitter,
  useNotificationContext,
  useTranslate,
} from 'react-admin'

import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  SnackbarProps,
  useTheme,
} from '@mui/material'

import styles from './AlertNotification.module.scss'

const DEFAULT_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
}
const DEFAULT_HIDE_DURATION = 4000

interface Notification extends NotificationPayload {
  notificationOptions: Options
}

interface Options extends Omit<NotificationOptions, 'multiLine'> {
  /** Add description content for Notification */
  description?: string | ReactNode
  /**
   * Add translation arguments for description.
   *
   * SEE: https://marmelab.com/react-admin/useNotify.html#messageargs
   */
  descriptionArgs?: unknown // react-admin uses 'any' type for args
  /** Additional action, not working when 'undo' available */
  action?: ReactNode
}

export interface AlertNotificationProps extends Omit<SnackbarProps, 'open'> {}

/**
 * It's enhanced and refactored [Notification](https://marmelab.com/react-admin/Admin.html#notification) component,
 * which shows all notifications inside MUI [Alert](https://mui.com/material-ui/react-alert/) component.
 *
 * @see: [original source code](https://github.com/marmelab/react-admin/blob/dcf8eaf703e9c4d991193cf39c4f5c3a0396d384/packages/ra-ui-materialui/src/layout/Notification.tsx)
 *
 * To show notification you should use [useNotify](https://marmelab.com/react-admin/useNotify.html) hook.
 *
 * NOTE: [multiline](https://marmelab.com/react-admin/useNotify.html#multiline) prop is true by default and can't be changed.
 */
export const AlertNotification = (props: AlertNotificationProps) => {
  const {
    className,
    autoHideDuration = DEFAULT_HIDE_DURATION,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    ...snackBarProps
  } = props

  const { notifications, takeNotification } = useNotificationContext()
  const [notification, setNotification] = useState<Notification | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const translate = useTranslate()

  const translateString = (message: string | ReactNode, messageArgs?: unknown) => {
    if (!message) return null
    if (typeof message === 'string' && message.startsWith('ra.')) {
      return translate(message, messageArgs)
    }
    return message
  }

  useEffect(() => {
    if (notifications.length && !notification) {
      setNotification((takeNotification() as Notification) || undefined)
      setOpen(true)
    } else if (notifications.length && notification && open) {
      setOpen(false)
    }
  }, [notifications, notification, open, takeNotification])

  useEffect(() => {
    // Copied from react-admin: https://github.com/marmelab/react-admin/blob/d92e02ffe850df776eebd0e62680a332515dd26f/packages/ra-ui-materialui/src/layout/Notification.tsx#L46C15-L46C15
    const beforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      const confirmationMessage = ''
      e.returnValue = confirmationMessage
      return confirmationMessage
    }

    if (notification?.notificationOptions?.undoable) {
      window.addEventListener('beforeunload', beforeunload)
    }

    return () => {
      if (notification?.notificationOptions?.undoable) {
        window.removeEventListener('beforeunload', beforeunload)
      }
    }
  }, [notification?.notificationOptions?.undoable])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleExited = useCallback(() => {
    if (notification?.notificationOptions.undoable) {
      undoableEventEmitter.emit('end', { isUndo: false })
    }

    setNotification(undefined)
  }, [notification])

  const handleUndo = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    undoableEventEmitter.emit('end', { isUndo: true })
    setOpen(false)
  }, [])

  if (!notification) return null

  const {
    message,
    type,
    notificationOptions: {
      autoHideDuration: notificationAutoHideDuration,
      description,
      messageArgs,
      descriptionArgs,
      action,
      undoable,
    },
  } = notification

  return (
    <Snackbar
      className={className}
      open={open}
      autoHideDuration={autoHideDuration || notificationAutoHideDuration}
      TransitionProps={{ onExited: handleExited }}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      {...snackBarProps}
    >
      <Alert
        severity={type}
        sx={{ width: theme.breakpoints.values.md }}
        classes={{ message: styles.alertMessage, action: styles.alertActions }}
        action={
          <>
            <Box sx={{ fontSize: theme.typography.body1 }} className={styles.customAction}>
              {undoable ? (
                <Link to="#" onClick={handleUndo}>
                  {translate('ra.action.undo')}
                </Link>
              ) : (
                action
              )}
            </Box>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              className={styles.closeBtn}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <AlertTitle>{translateString(message, messageArgs)}</AlertTitle>
        {translateString(description, descriptionArgs)}
      </Alert>
    </Snackbar>
  )
}
