import { CSSProperties } from 'react'

import { ThemeOptions } from '@mui/material'

import '@fontsource-variable/open-sans' // Adds Open Sans font stylesheets

import Typography from 'styles/modules/_Typography.scss'

interface CustomTypographyVariants {
  tiny: CSSProperties
  small: CSSProperties
  body: CSSProperties
  medium: CSSProperties
  large: CSSProperties
  xl: CSSProperties
  xxl: CSSProperties
  xxxl: CSSProperties
}

/**
 * Adds custom typography variants to MUI’s theme types
 * @see https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
declare module '@mui/material/styles' {
  interface TypographyVariants extends CustomTypographyVariants {}
  interface TypographyVariantsOptions extends Partial<CustomTypographyVariants> {}
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<keyof CustomTypographyVariants, true> {}
}

// TODO: Move to ARC
export const typographyConfig: ThemeOptions = {
  typography: {
    fontFamily: [
      '"Open Sans Variable"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    xxxl: {
      fontSize: Typography.xxxlFontSize,
      lineHeight: Typography.xxxlLineHeight,
      marginBottom: Typography.xxxlMarginBottom,
    },
    xxl: {
      fontSize: Typography.xxlFontSize,
      lineHeight: Typography.xxlLineHeight,
      marginBottom: Typography.xxlMarginBottom,
    },
    xl: {
      fontSize: Typography.xlFontSize,
      lineHeight: Typography.xlLineHeight,
      marginBottom: Typography.xlMarginBottom,
    },
    large: {
      fontSize: Typography.largeFontSize,
      lineHeight: Typography.largeLineHeight,
      marginBottom: Typography.largeMarginBottom,
    },
    medium: {
      fontSize: Typography.mediumFontSize,
      lineHeight: Typography.mediumLineHeight,
      marginBottom: Typography.mediumMarginBottom,
    },
    small: {
      fontSize: Typography.smallFontSize,
      lineHeight: Typography.smallLineHeight,
      marginBottom: Typography.smallMarginBottom,
    },
    tiny: {
      fontSize: Typography.tinyFontSize,
      lineHeight: Typography.tinyLineHeight,
      marginBottom: Typography.tinyMarginBottom,
    },
    body: {
      fontSize: Typography.bodyFontSize,
      lineHeight: Typography.bodyLineHeight,
      marginBottom: Typography.bodyMarginBottom,
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        // prettier-ignore
        variantMapping: {
          xxxl:   'h1',
          xxl:    'h1',
          xl:     'h1',
          large:  'h2',
          medium: 'h3',
          body:   'p',
          small:  'p',
        },
      },
    },
  },
}
