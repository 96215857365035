import {
  DeleteWithConfirmButton,
  DeleteWithConfirmButtonProps,
  useNotify,
  useRefresh,
} from 'react-admin'

import { IconBin } from '@assuranceiq/react-icons/bold/Bin'

import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'

import { deleteOpportunities } from './DeleteOpportunity.queries'

export const DeleteOpportunityButton = (props: DeleteWithConfirmButtonProps) => {
  const notify = useNotify()
  const refresh = useRefresh()

  return (
    <DeleteWithConfirmButton
      {...props}
      confirmTitle="Delete this opportumity?"
      confirmContent="The follow-ups attached to this opportunity will also be deleted."
      icon={<IconBin />}
      color="primary"
      mutationOptions={{
        meta: {
          query: deleteOpportunities,
        } as DataProviderMeta<FetchType.DELETE>,
        onSuccess: () => {
          refresh()
          notify('You have successfully deleted an opportunity', {
            type: 'success',
          })
        },
      }}
    />
  )
}
