import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Box, Button, Stack, Typography } from '@mui/material'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { clsx } from 'clsx'

import { ReactComponent as ErrorAstronautGraphic } from 'images/error-astronaut.svg'

import {
  ErrorFallbackButtonProps,
  ErrorFallbackComponent,
  ErrorFallbackDynamicProps,
} from './ErrorFallback.types'

import styles from './ErrorFallback.module.scss'

export const RELOAD_BUTTON: ErrorFallbackButtonProps = {
  label: 'Reload',
  onClick: () => window.location.reload(),
}

export const GO_BACK_BUTTON: ErrorFallbackButtonProps = {
  label: 'Go Back',
  onClick: ({ resetErrorBoundary }) => {
    window.addEventListener('popstate', () => resetErrorBoundary(), { once: true })
    window.history.back()
  },
}

export const defaultFallbackProps = (
  error: Error,
): ErrorFallbackDynamicProps &
  Required<Pick<ErrorFallbackDynamicProps, 'title' | 'message' | 'buttons'>> => {
  return {
    title: error.name ?? 'Something went wrong',
    message: error.message ?? 'An unknown error occurred and your request couldn’t be completed.',
    buttons: [RELOAD_BUTTON, GO_BACK_BUTTON],
  }
}

export const ErrorFallback: ErrorFallbackComponent = ({
  error,
  resetErrorBoundary,
  dynamicProps,
}) => {
  const { getInternalContext } = useDatadogRUM()
  const { session_id } = getInternalContext() ?? {}

  const { title, message, buttons, slotProps } = {
    ...defaultFallbackProps(error),
    ...dynamicProps?.(error),
  }

  return (
    <Box role="alert" {...slotProps?.box} className={clsx(styles.root, slotProps?.box?.className)}>
      <ErrorAstronautGraphic />

      <Box sx={{ my: 1 }}>
        <Typography variant="medium" className={styles.heading} {...slotProps?.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={styles.message} gutterBottom {...slotProps?.message}>
          {message}
        </Typography>

        <Box component="details" className={styles.details}>
          <Typography component="summary" variant="caption" color="text.secondary">
            <InfoOutlined className={styles.infoIcon} fontSize="inherit" /> Type: {error.name}
            <> | </>
            {/**
             * Print out session ID so we can easily retrieve details when error screenshots are shared.
             * Sessions can be found at: https://app.datadoghq.com/rum/replay/sessions/{session_id}
             */}
            Datadog Session: <code>{session_id ?? 'N/A'}</code>
          </Typography>
          <Box className={styles.stackTrace}>
            <Typography variant="medium" className={styles.detailsMessage}>
              {error.name}: {error.message}
            </Typography>
            <Box component="pre">{error.stack}</Box>
          </Box>
        </Box>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 1 }}
      >
        {buttons.map(({ label, onClick, ...buttonProps }, index) => (
          <Button
            key={label}
            size="small"
            onClick={() => onClick({ error, resetErrorBoundary })}
            {...(index === buttons.length - 1
              ? {
                  variant: 'contained',
                  color: 'primary',
                  autoFocus: true,
                }
              : {
                  variant: 'outlined',
                  color: 'secondary',
                })}
            {...buttonProps}
          >
            {label}
          </Button>
        ))}
      </Stack>
    </Box>
  )
}
