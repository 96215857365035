import { ConfigurableDatagridColumn, useResourceContext, useStore } from 'react-admin'

export const useColumnPreference = (customResource?: string, customPreferenceKey?: string) => {
  const resource = useResourceContext({ resource: customResource })
  const preferenceKey = customPreferenceKey || `${resource}.datagrid`

  const [availableColumns, setAvailableColumns] = useStore<ConfigurableDatagridColumn[]>(
    `preferences.${preferenceKey}.availableColumns`,
    [],
  )
  const [omit] = useStore<string[]>(`preferences.${preferenceKey}.omit`, [])
  const [columns, setColumns] = useStore<string[]>(
    `preferences.${preferenceKey}.columns`,
    availableColumns
      .filter((column) => (column.source ? !omit?.includes(column.source) : true))
      .map((column) => column.index),
  )

  return { availableColumns, setAvailableColumns, columns, setColumns }
}
