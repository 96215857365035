import { Suspense } from 'react'

import { LayoutProps, useTranslate } from 'react-admin'
import { AppLocationContext, HorizontalMenu } from '@react-admin/ra-navigation'

import { Box, CircularProgress } from '@mui/material'

import { AppLayout } from 'components/App/AppLayout'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { DebugMenu } from 'components/ReactAdminLayout/DebugMenu'
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag'
import { Resources } from 'lib/api/api.types'

import styles from './ReactAdminLayout.module.scss'

const menuItems = [Resources.CLIENTS, Resources.ALL_FOLLOW_UPS, Resources.MISSED_CALLS]

export const Menu = () => {
  const pipelineTitle = useFeatureFlag(FeatureFlag.ENABLE_CLIENT_LIST_SEARCH)
  const translate = useTranslate()
  return (
    <HorizontalMenu>
      {menuItems.map((item) => (
        <HorizontalMenu.Item
          key={item}
          label={
            item === Resources.ALL_FOLLOW_UPS && pipelineTitle
              ? 'Pipeline Schedule'
              : translate(`resources.${item}.name`, 0)
          }
          value={item}
        />
      ))}
    </HorizontalMenu>
  )
}

/**
 * Custom react-admin Layout that extends the default Layout component.
 *
 * @see https://marmelab.com/react-admin/Layout.html
 */
export const ReactAdminLayout = ({ children }: LayoutProps) => {
  return (
    <AppLayout>
      <AppLocationContext>
        <Menu />
      </AppLocationContext>

      <Box className={styles.layout}>
        <ErrorBoundary>
          <Suspense fallback={<CircularProgress />}>{children}</Suspense>
        </ErrorBoundary>
      </Box>
      <DebugMenu />
    </AppLayout>
  )
}
