import { useCallback, useEffect } from 'react'

import { ShowProps } from 'react-admin'

import { Drawer, IconButton } from '@mui/material'

import { IconArrowRight1 } from '@assuranceiq/react-icons/bold/ArrowRight1'

import { useLocation, useSearchParams } from 'react-router-dom'

import { PersonsShow } from 'resources/persons/PersonsShow'

import styles from './PersonsShowDrawer.module.scss'

export const DRAWER_URL_KEY = 'show'

export const usePersonsShowDrawerPath = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const personsShowDrawerPath = (id: string) => {
    searchParams.set(DRAWER_URL_KEY, id)
    return `${location.pathname}?${searchParams.toString()}`
  }

  return personsShowDrawerPath
}

export const PersonsShowDrawer = (props: ShowProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const drawerId = searchParams.get(DRAWER_URL_KEY)

  const handleClose = useCallback(() => {
    searchParams.delete(DRAWER_URL_KEY)
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose])

  return (
    <Drawer
      open={!!drawerId}
      anchor="right"
      onClose={handleClose}
      classes={{ paper: styles.drawerPaper }}
      sx={(theme) => ({ '& .MuiDrawer-paper': { maxWidth: theme.breakpoints.values.lg } })}
    >
      <IconButton onClick={handleClose} className={styles.closeBtn} size="small">
        <IconArrowRight1 className={styles.arrowIcon} />
      </IconButton>
      {!!drawerId && <PersonsShow {...props} id={drawerId} className={styles.personsShow} />}
    </Drawer>
  )
}
