import { useCallback } from 'react'

import { FieldProps, FunctionField, FunctionFieldProps, RaRecord } from 'react-admin'

import { NullValue } from 'components/values'
import { getData } from 'lib/helpers/get-data'
import { TypedRaRecord } from 'types/react-admin.types'
import { PolicyRelatedInfoRecord } from 'types/records.types'

export interface PolicyPlanIDFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends Partial<FunctionFieldProps<RecordType>> {
  /**
   * The source of the policy_related_infos item
   *
   * @example `person.policy_related_infos[1]`
   */
  source?: FieldProps<RecordType>['source']
}

export function PolicyPlanIDField<RecordType extends TypedRaRecord = RaRecord>({
  source,
  ...props
}: PolicyPlanIDFieldProps<RecordType>) {
  const renderID = useCallback(
    (record: RecordType) => {
      const data = getData<PolicyRelatedInfoRecord>(record, source)
      const {
        normalized_data: { plan_hios, plan_pbp_number, plan_segment_id },
      } = data || { normalized_data: {} }

      if (!plan_hios || !plan_pbp_number || !plan_segment_id) return <NullValue />

      const ID = [
        plan_hios,
        String(plan_pbp_number).padStart(3, '0'),
        String(plan_segment_id).padStart(3, '0'),
      ].join('-')

      return <>{ID}</>
    },
    [source],
  )

  return <FunctionField {...props} render={renderID} />
}
