import { Identifier } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { PersonFields } from 'lib/helpers/query-helpers'
import { SourceDefinition } from 'types/react-admin.types'
import { AllFollowUpsRecord } from 'types/records.types'

// prettier-ignore
export const ALL_FOLLOW_UPS_SOURCE = {
  CREATED_AT:                    'created_at',
  UPDATED_AT:                    'updated_at',
  SCHEDULED_TIME:                'scheduled_time',
  SUB_TYPE:                      'sub_type',
  TYPE:                          'type',

  CHANCE_TO_CLOSE:               'opportunity.agent_assigned_opportunity_quality',
  ENROLLMENT_PERIOD:             'opportunity.enrollment_period',
  EXPIRATION_DATE:               'opportunity.expiration_date',
  LOI:                           'opportunity.loi',
  PRODUCTS:                      'opportunity.products',
  SALES_STAGE:                   'opportunity.agent_assigned_sales_stage',

  PERSON:                        'opportunity.person[0]',
  PERSON_FIRST_NAME:             'opportunity.person[0].fname',
  PERSON_STATE:                  'opportunity.person[0].contact_method.state',

  POLICY_RELATED_INFOS:          'opportunity.person[0].policy_related_infos',

  LATEST_CALL_STAT:              'opportunity.person[0].call_stats[0]',
  LAST_CONTACT:                  'opportunity.person[0].call_stats[0].started_at',
  LAST_CONTACT_DISPOSITION_NAME: 'opportunity.person[0].call_stats[0].disposition_name',

  LEAD_ID:                       'opportunity.person[0].lead_id',

  APPOINTMENT_NOTES:             'appointment_notes[0].body',
  REMINDER_NOTES:                'reminder_notes[0].body',
} as const satisfies SourceDefinition<AllFollowUpsRecord>

export const GET_ALL_FOLLOW_UPS_LIST = gql`
  ${PersonFields}

  query all_follow_ups(
    $distinct_on: [all_follow_ups_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [all_follow_ups_order_by!]
    $where: all_follow_ups_bool_exp
  ) {
    items: all_follow_ups(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id

      type
      sub_type

      created_at
      scheduled_time

      appointment_notes(limit: 1, order_by: { updated_at: desc }) {
        id # ID required for react-admin/react-query caching
        body
      }

      reminder_notes(limit: 1, order_by: { updated_at: desc }) {
        id # ID required for react-admin/react-query caching
        body
      }

      opportunity {
        id # ID required for react-admin/react-query caching
        agent_assigned_opportunity_quality
        agent_assigned_sales_stage
        enrollment_period
        expiration_date
        products
        source
        loi

        person {
          ...PersonFields

          call_stats(limit: 1, order_by: { started_at: desc }) {
            id # Used by react-query for caching
            started_at
            disposition_name
          }
        }
      }
    }

    total: all_follow_ups_aggregate(order_by: $order_by, where: $where) {
      aggregate {
        count
      }
    }
  }
`

export interface GetAllFollowUpsParams {
  assigned_agent_id: Identifier
  opportunity_id?: Identifier
}

export const getAllFollowUpsList =
  ({ assigned_agent_id, opportunity_id }: GetAllFollowUpsParams): CustomQuery<FetchType.GET_LIST> =>
  async ({ client, clientOptions, parseResponse }) => {
    const response = await client.query({
      ...clientOptions,

      query: GET_ALL_FOLLOW_UPS_LIST,

      variables: {
        ...clientOptions?.variables,

        where: {
          ...clientOptions?.variables?.where,

          opportunity: {
            ...clientOptions?.variables?.where?.opportunity,

            // Required filters
            ...(typeof opportunity_id !== 'undefined' && { id: { _eq: opportunity_id } }),
            assigned_agent_id: { _eq: assigned_agent_id },
          },
        },
      },
    })
    const results = parseResponse(response)
    return results
  }
