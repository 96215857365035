import { gql, QueryOptions } from '@apollo/client'
import { lightFormat, subMonths } from 'date-fns'

import { FetchType, QueryResults } from 'hooks/useDataProvider'

export const NOW = 'now()'
export const threeMonthsAgo = () => lightFormat(subMonths(new Date(), 3), 'yyyy-MM-dd')

export const minCallStartedAtDateString = threeMonthsAgo

/** [Partial Pagination](https://marmelab.com/react-admin/DataProviderWriting.html#partial-pagination) */
export const addPartialPagination = <F extends FetchType>(
  results: QueryResults[F],
  clientOptions: QueryOptions,
): QueryResults[F] => {
  const hasNextPage = results.data.length >= clientOptions.variables?.limit
  const hasPreviousPage = clientOptions.variables?.offset !== 0

  return {
    ...results,
    pageInfo: {
      hasNextPage,
      hasPreviousPage,
    },
  }
}

/**
 * Includes required meta data for placing calls with the Dialer
 */
export const PersonFields = gql`
  fragment PersonFields on legacy_person_people {
    id: lead_id # ID required for react-admin/react-query caching
    lead_id

    # Required for dialer lead attribution
    lead_type

    # Most fields below are required for call meta data; SEE: dialer.ts startCall() for more info

    fname
    lname

    birth_date
    gender

    contact_method {
      id: lead_id # ID required for react-admin/react-query caching
      email
      phone
      city
      state
      zip
    }

    policy_related_infos {
      de_policy_id
      normalized_data
      type
    }
  }
`
