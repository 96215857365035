import { useMemo } from 'react'

import { FilterPayload, SortPayload, useListContext } from 'react-admin'

import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material'

import { startOfDay } from 'date-fns'

import { ALL_FOLLOW_UPS_SOURCE } from 'resources/all_follow_ups/AllFollowUpsList'
import { SortOrder } from 'types/react-admin.types'

const { SCHEDULED_TIME } = ALL_FOLLOW_UPS_SOURCE

export enum QuickFilter {
  PAST = 'Past',
  CURRENT = 'Current',
}

const startOfToday = () => startOfDay(new Date()).toISOString()

export const quickFilters = (): Record<QuickFilter, FilterPayload> => ({
  [QuickFilter.CURRENT]: { [`${SCHEDULED_TIME}@_gte`]: startOfToday() },
  [QuickFilter.PAST]: { [`${SCHEDULED_TIME}@_lte`]: startOfToday() },
})

export const QUICK_FILTER_SORTS: Record<QuickFilter, SortPayload> = {
  [QuickFilter.CURRENT]: { field: SCHEDULED_TIME, order: SortOrder.ASC },
  [QuickFilter.PAST]: { field: SCHEDULED_TIME, order: SortOrder.DESC },
}

export const FollowUpsQuickFilters = () => {
  const { filterValues, setFilters, sort, setSort } = useListContext()

  const value = useMemo(() => {
    const filters = quickFilters()
    const activeFilter =
      (Object.keys(filters) as QuickFilter[]).find((key) => {
        return Object.entries(filters[key]).every(([filterKey, filterValue]) => {
          return filterValues[filterKey] === filterValue
        })
      }) ?? QuickFilter.CURRENT

    return activeFilter
  }, [filterValues])

  const onChange: ToggleButtonGroupProps['onChange'] = (_event, value) => {
    const filter = (value as QuickFilter) ?? QuickFilter.CURRENT
    setFilters(quickFilters()[filter], undefined)
    if (sort.field === SCHEDULED_TIME) {
      // HACK: Wait for the filter to be applied before sorting; otherwise React Admin overrides the sort :(
      setTimeout(() => setSort(QUICK_FILTER_SORTS[filter]), 500)
    }
  }

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      aria-label="Filter"
      size="small"
      color="primary"
    >
      {Object.values(QuickFilter).map((filter) => {
        return (
          <ToggleButton key={filter} value={filter} disabled={value === filter}>
            {filter}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
