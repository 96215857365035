import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { PersonFields } from 'lib/helpers/query-helpers'

export const GET_FOLLOW_UP = gql`
  ${PersonFields}

  query all_follow_ups($limit: Int, $where: all_follow_ups_bool_exp) {
    returning: all_follow_ups(limit: $limit, where: $where) {
      id

      duration_minutes

      type
      sub_type

      scheduled_time
      scheduled_timezone
      send_sms_confirmation

      appointment_notes(limit: 1, order_by: { updated_at: desc }) {
        id # ID required for react-admin/react-query caching
        body
      }

      reminder_notes(limit: 1, order_by: { updated_at: desc }) {
        id # ID required for react-admin/react-query caching
        body
      }

      opportunity {
        id # ID required for react-admin/react-query caching
        person {
          ...PersonFields
        }
      }
    }
  }
`

export const getFollowUp: CustomQuery<FetchType.GET_ONE> = async ({
  client,
  clientOptions,
  parseResponse,
}) => {
  const response = await client.query({
    ...clientOptions,

    query: GET_FOLLOW_UP,
  })

  return parseResponse(response)
}
