import {
  DeleteWithConfirmButton,
  DeleteWithConfirmButtonProps,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'

import { IconBin } from '@assuranceiq/react-icons/bold/Bin'

import { lowerCase } from 'lodash-es'

import { DataProviderMeta, FetchType } from 'hooks/useDataProvider'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { AllFollowUpsRecord, FollowUpType } from 'types/records.types'

import { deleteFollowUpAppointments, deleteFollowUpReminders } from './DeleteFollowUp.queries'

export const DeleteFollowUpButton = (props: DeleteWithConfirmButtonProps) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { type, opportunity } = useRecordContext<AllFollowUpsRecord>()

  const person = opportunity?.person[0]
  const fullname = `${person?.fname} ${person?.lname}`
  const isReminderFollowUp = type === FollowUpType.REMINDER

  return (
    <DeleteWithConfirmButton
      {...props}
      resource={`follow_up_${lowerCase(type)}s`}
      confirmTitle={
        <>
          Delete Follow-up with <span data-dd-privacy={SessionReplayPrivacy.MASK}>{fullname}</span>
        </>
      }
      confirmContent="The follow-up will no longer appear on this list."
      icon={<IconBin />}
      aria-label="Delete"
      label=""
      color="primary"
      mutationOptions={{
        meta: {
          query: isReminderFollowUp ? deleteFollowUpReminders : deleteFollowUpAppointments,
        } as DataProviderMeta<FetchType.DELETE>,
        onSuccess: () => {
          refresh()
          notify(`You have successfully deleted a Follow-up ${type}`, {
            type: 'success',
          })
        },
      }}
    />
  )
}
