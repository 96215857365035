import { isValidElement } from 'react'

import { BulkActionsToolbar, BulkDeleteButton, DatagridProps, useListContext } from 'react-admin'

const defaultBulkActionButtons = <BulkDeleteButton /> // https://github.com/marmelab/react-admin/blob/a834fa78/packages/ra-ui-materialui/src/list/datagrid/Datagrid.tsx#L37

/**
 * React Admin Bulk Actions Toolbar
 *
 * Copied from React Admin `<Datagrid>`
 *
 * @see https://github.com/marmelab/react-admin/blob/a834fa78/packages/ra-ui-materialui/src/list/datagrid/Datagrid.tsx#L235-L241
 */
export const RABulkActionsToolbar = ({
  bulkActionButtons = defaultBulkActionButtons, // https://github.com/marmelab/react-admin/blob/a834fa78/packages/ra-ui-materialui/src/list/datagrid/Datagrid.tsx#L126
}: Pick<DatagridProps, 'bulkActionButtons'>) => {
  const { selectedIds } = useListContext()

  // https://github.com/marmelab/react-admin/blob/a834fa78/packages/ra-ui-materialui/src/list/datagrid/Datagrid.tsx#L235-L241
  return bulkActionButtons !== false ? (
    <BulkActionsToolbar selectedIds={selectedIds}>
      {isValidElement(bulkActionButtons) ? bulkActionButtons : defaultBulkActionButtons}
    </BulkActionsToolbar>
  ) : null
}
