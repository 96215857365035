import { CRM_URL } from 'lib/environment-variables'
import { PersonRecord } from 'types/records.types'

const LEADING_SLASH = /^\//

export const VEGA_URL = window.location.origin
export const CREATE_FROM_PLATES_PATH = '/create-from-plates'

export const crmURL = (path?: string) =>
  [CRM_URL, path?.replace(LEADING_SLASH, '')].filter(Boolean).join('/')

export const CRM_LOGOUT_URL = crmURL('/logout')

/** Url to a plate for a given lead */
export const plateURL = ({ lead_id }: Pick<PersonRecord, 'lead_id'>) =>
  crmURL(`/rep/plate?lead_id=${lead_id}`)
