/**
 * Use a virtual "source" when a column has no corresponding field in the API.
 * NOTE: CAN NOT BE SORTED/FILTERED
 */
export const VIRTUAL_SOURCE = {
  ACTIONS: 'actions',
  NOTES: 'notes',
}

export const FULL_NAME_FILTER = 'lead_lower_name'
export const ENROLLMENT_CODE = {
  id: 'person#policy_related_infos#normalized_data#enrollment_code@_eq',
  key: 'enrollment_code',
}
