import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { PersonFields } from 'lib/helpers/query-helpers'
import { SourceDefinition } from 'types/react-admin.types'
import { PersonRecord } from 'types/records.types'

// prettier-ignore
export const PERSON_SOURCE = {
  LEAD_ID:                      'lead_id',
  LEAD_TYPE:                    'lead_type',

  FIRST_NAME:                   'fname',
  LAST_NAME:                    'lname',
  BIRTH_DATE:                   'birth_date',
  GENDER:                       'gender',
  EMAIL:                        'contact_method.email',
  PHONE:                        'contact_method.phone',
  CITY:                         'contact_method.city',
  STATE:                        'contact_method.state',
  ZIP:                          'contact_method.zip',

  LAST_CONTACT:                 'call_stats[0].started_at',
  LATEST_CALL_DISPOSITION_NAME: 'call_stats[0].disposition_name',

  SALES_STAGE:                  'opportunities[0].agent_assigned_sales_stage',

  POLICY_RELATED_INFOS:         'policy_related_infos',
} as const satisfies SourceDefinition<PersonRecord>

export const GET_PERSON = gql`
  ${PersonFields}

  query legacy_person_people($limit: Int, $where: legacy_person_people_bool_exp) {
    returning: legacy_person_people(limit: $limit, where: $where) {
      ...PersonFields

      call_stats(order_by: { started_at: desc }, limit: 1) {
        started_at
        disposition_name
      }

      opportunities(limit: 1, order_by: { updated_at: desc }) {
        agent_assigned_sales_stage
      }
    }
  }
`

export const getPerson: CustomQuery<FetchType.GET_ONE> = async ({
  client,
  clientOptions,
  parseResponse,
}) => {
  // Rename `id` to `lead_id` for the where clause
  if (!clientOptions.variables?.where?.id) throw new Error('Missing ID for query')
  clientOptions.variables.where.lead_id = clientOptions.variables.where.id
  delete clientOptions.variables.where.id

  const response = await client.query({
    ...clientOptions,

    query: GET_PERSON,
  })

  return parseResponse(response)
}
