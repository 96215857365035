import { AuthorizationParams } from '@auth0/auth0-react'

import {
  PERSON_SERVICE_API_ENDPOINT,
  PERSON_SERVICE_AUTH0_AUDIENCE,
} from 'lib/environment-variables'
import { ResourceName } from 'types/react-admin.types'

import { Resources } from './api.types'

export const HASURA_DEFAULT_ROLE = 'vega.permissions.scope'
export const HASURA_MANAGEMENT_ROLE = 'vega.manager.permissions.scope'

export const api = {
  uri: PERSON_SERVICE_API_ENDPOINT,
  authorizationParams: {
    audience: PERSON_SERVICE_AUTH0_AUDIENCE,
    connection: 'assurancetest-oidc-connection',
  } as AuthorizationParams,

  /**
   * Custom aliases for resource names. This is mostly useful for supporting
   * custom routes for a given resource.
   *
   * Format: `{ [alias]: 'resource_name' }`
   */
  resourceAliases: {
    clients: 'call_stats_grouped_by_leads_users',
    missed_calls: 'call_stats',
    persons: 'legacy_person_people',

    // HACK: Rename to a hasura endpoint to bypass introspection errors
    // TODO: Find a better solution/disable introspection for the data provider
    lead_tz: 'direct_leads',

    // HACK: Rename to a hasura endpoint to bypass introspection errors
    // TODO: Find a better solution/disable introspection for the data provider
    derived_lead_fields: 'direct_leads',
  } as Record<string, ResourceName>,

  resourceFields: {
    [Resources.FOLLOW_UP_APPOINTMENTS]: [
      'id',
      'appointment_type',
      'created_at',
      'duration_minutes',
      'opportunity_id',
      'scheduled_time',
      'send_sms_confirmation',
      'updated_at',
    ].join(' '),

    [Resources.FOLLOW_UP_REMINDERS]: [
      'id',
      'created_at',
      'created_by_id',
      'duration_minutes',
      'opportunity_id',
      'reminder_type',
      'scheduled_time',
      'scheduled_timezone',
      'updated_at',
    ].join(' '),

    [Resources.NOTES]: [
      'id',
      'body',
      'created_at',
      'created_by_id',
      'follow_up_appointment_id',
      'follow_up_reminder_id',
      'opportunity_id',
      'updated_at',
    ].join(' '),

    [Resources.OPPORTUNITIES]: [
      'id',
      'agent_assigned_opportunity_quality',
      'agent_assigned_sales_stage',
      'assigned_agent_id',
      'assigned_by_id',
      'created_at',
      'created_by_id',
      'delta_contact_id',
      'enrollment_period',
      'expiration_date',
      'lead_id',
      'loi',
      'products',
      'source',
      'updated_at',
    ].join(' '),
  } as Record<Resources, string>,
}
