import { ReactNode } from 'react'

import { Datagrid, ListBase, useRecordContext, WithRecord } from 'react-admin'

import { Stack } from '@mui/material'

import { PaginationIfNeeded } from 'components/PaginationIfNeeded'
import { DataProviderMeta, useCurrentUser } from 'hooks'
import { FetchType } from 'hooks/useDataProvider'
import { LABEL } from 'i18n/resource-translations'
import { Resources } from 'lib/api/api.types'
import { listBaseDefaults } from 'lib/helpers/list-helpers'
import {
  ALL_FOLLOW_UPS_SOURCE,
  getAllFollowUpsList,
} from 'resources/all_follow_ups/AllFollowUpsList'
import {
  DeleteFollowUpButton,
  EditFollowUpButton,
  ShowFollowUpButton,
} from 'resources/all_follow_ups/components'
import { SortOrder } from 'types/react-admin.types'
import { AllFollowUpsRecord, OpportunityRecord, OpportunitySource } from 'types/records.types'

import styles from './OpportunityFollowUpsList.module.scss'

const FOLLOW_UPS_PER_PAGE = 5

export interface OpportunityFollowUpsListProps {
  /**
   * Add Actions column
   *
   * @default true
   */
  addActions?: boolean

  /**
   * AllFollowUps resource fields to render inside <ListBase>
   */
  children: ReactNode
}

export const OpportunityFollowUpsList = ({
  addActions = false,
  children,
}: OpportunityFollowUpsListProps) => {
  const { currentUser } = useCurrentUser()
  const opportunity = useRecordContext<OpportunityRecord>()
  const assigned_agent_id = currentUser.id

  return (
    <ListBase
      {...listBaseDefaults({ primaryList: false })}
      resource={Resources.ALL_FOLLOW_UPS}
      sort={{ field: ALL_FOLLOW_UPS_SOURCE.SCHEDULED_TIME, order: SortOrder.ASC }}
      queryOptions={{
        meta: {
          opportunity_id: opportunity.id, // Force refresh on change
          assigned_agent_id, // Force refresh on change

          query: getAllFollowUpsList({
            opportunity_id: opportunity.id,
            assigned_agent_id,
          }),
        } as DataProviderMeta<FetchType.GET_LIST>,
      }}
      perPage={FOLLOW_UPS_PER_PAGE}
    >
      <Datagrid className={styles.table} bulkActionButtons={false}>
        {children}
        {addActions && (
          <WithRecord<AllFollowUpsRecord>
            label={LABEL.ACTIONS}
            render={(followUp) => (
              <Stack className={styles.actions} direction="row">
                <ShowFollowUpButton record={followUp} />
                <EditFollowUpButton
                  record={{
                    person: opportunity.person[0],
                    scheduled_time: followUp.scheduled_time,
                    source: OpportunitySource.CLIENT_LIST,
                  }}
                  followUpType={followUp.type}
                />
                <DeleteFollowUpButton />
              </Stack>
            )}
          />
        )}
      </Datagrid>
      <PaginationIfNeeded />
    </ListBase>
  )
}
