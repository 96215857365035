/** https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
export enum ClientTimezone {
  LOS_ANGELES = 'America/Los_Angeles',
  DENVER = 'America/Denver',
  CHICAGO = 'America/Chicago',
  NEW_YORK = 'America/New_York',
}

/**
 * Lead type values sourced from https://github.com/assuranceiq/insurance/blob/4c8cd930/app/models/concerns/constants/lead.rb#L5-L47
 */
export enum LeadType {
  AUTO = 'auto',
  FLEX = 'flex',
  HEALTH = 'health',
  HOME = 'home',
  LIFE = 'life',
  MEDICARE = 'medicare',
  MORTGAGE = 'mortgage',
  MOTORCYCLE = 'motorcycle',
  PERSONAL_FINANCE = 'pf',
  PET = 'pet',
  RENT = 'rent',
}

/**
 * @see https://docs.people.assurance.com/#definition-Loi
 */
export enum LOI {
  MEDICARE = 'medicare',
  HEALTH = 'health',
  LIFE = 'life',
  P_AND_C = 'pc',
}

export enum MedicareEnrollmentType {
  AEP = 'AEP',
  OEP = 'OEP',
  SEP = 'SEP',
  IEP = 'IEP',
}

export enum HealthEnrollmentType {
  OEP = 'OEP',
  QLE = 'QLE',
  THIRTY_DAY = '30-Day',
}

export enum LifeEnrollmentPeriod {
  THIRTY_DAY = '30-Day',
}

export enum PAndCEnrollmentPeriod {
  THIRTY_DAY = '30-Day',
}

export type EnrollmentPeriod =
  | MedicareEnrollmentType
  | HealthEnrollmentType
  | LifeEnrollmentPeriod
  | PAndCEnrollmentPeriod

export enum MedicareProduct {
  MAPD = 'mapd',
  DSNP = 'dsnp',
  MA = 'ma',
  CSNP = 'csnp',
  PDP = 'pdp',
  MED_SUPP = 'med_supp',
}

export enum HealthProduct {
  ACA = 'aca',
  ANCILLARY = 'ancillary',
  STM = 'stm',
}

export enum LifeProduct {
  TERM = 'term',
  WHOLE = 'whole',
  FINAL_EXPENSE = 'final_expense',
}

export enum PAndCProduct {
  AUTO = 'auto',
  HOME = 'home',
  CONDO = 'condo',
  RENTERS = 'renters',
  DWELLING = 'dwelling',
  TOYS = 'toys',
  FLOOD = 'flood',
  UMBRELLA = 'umbrella',
  PET = 'pet',
}

export enum PolicyStatus {
  ACTIVE = 'Active',
  NOT_ACTIVE = 'Not Active',
  PENDING = 'Pending',
}

export enum PolicyRelatedInfoType {
  ENROLLMENT = 'PolicyRelatedInfos::Enrollment',
  BOOK_OF_BUSINESS = 'PolicyRelatedInfos::BookOfBusiness',
}

/**
 * @see https://docs.people.assurance.com/#definition-Product
 */
export type Product = MedicareProduct | HealthProduct | LifeProduct | PAndCProduct

/**
 * @see https://docs.people.assurance.com/#definition-SalesStage
 */
export enum SalesStage {
  QUALIFYING = 'qualifying',
  NEEDS_ANALYSIS = 'needs_analysis',
  EVALUATING_PROPOSAL = 'evaluating_proposal',
  ENROLLING = 'enrolling',
  CLOSED = 'closed',
}

export enum FollowUpSubType {
  PRE_ENROLLMENT = 'pre_enrollment',
  ENROLLMENT = 'enrollment',
  POST_SALE = 'post_sale',
}

export enum FollowUpType {
  APPOINTMENT = 'Appointment',
  REMINDER = 'Reminder',
}

export type FollowUpNote = {
  body: string
}

export type Rating = 0 | 1 | 2 | 3 | 4 | 5

/**
 * The API returns ISO 8601 date strings with missing timezone indicator (i.e.
 * no trailing "Z" or "+00:00")
 *
 * @example "2021-03-31T18:00:00.000"
 */
export type ISODateTimeNoTimezone = string

/**
 * The API returns ISO 8601 date strings with missing timezone indicator (i.e.
 * no trailing "Z" or "+00:00") and no milliseconds
 *
 * @example "2021-03-31T18:00:00"
 */
export type ISODateTimeNoMillisecondsNoTimezone = string

/**
 * The API returns ISO 8601 date strings with no time component.
 *
 * !IMPORTANT: When formatting with `toLocaleString` or other date formatters, always use
 * the UTC timezone, otherwise the date can be off by one day, depending on the client's timezone.
 *
 * @example "2021-03-31"
 */
export type ISODate = string

/**
 * Disposition names: https://github.com/assuranceiq/twilio_dialing_service/blob/0f76fd5d/chalicelib/call_stats.py#L44-L77
 */
export type DispositionName = string

export type AggregateData = {
  aggregate: {
    count: number
  }
}

export type ClientRecord = {
  id: PersonRecord['lead_id']
  lead_id: PersonRecord['lead_id']

  latest_call_started_at: ISODateTimeNoTimezone
  latest_call_disposition_name: DispositionName

  person: PersonRecord

  opportunities: OpportunityRecord[]
}

export type PersonRecord = {
  id: number
  lead_id: number
  lead_type: LeadType

  fname?: string
  lname?: string

  birth_date?: ISODateTimeNoTimezone
  gender?: string

  contact_method: {
    email?: string
    phone?: string
    city?: string
    state?: string
    zip?: string
  }

  policy_related_infos: PolicyRelatedInfoRecord[]

  opportunities: Pick<OpportunityRecord, 'agent_assigned_sales_stage'>[] // include only one field to not overload the type

  call_stats: Omit<CallStatRecord, 'person'>[]
}

export type PolicyRelatedInfoRecord = {
  de_policy_id?: string
  type: PolicyRelatedInfoType | string
  normalized_data: {
    policy_status?: PolicyStatus
    augmented_event?: boolean

    enrollment_code?: string

    plan_hios?: string
    plan_pbp_number?: string | number
    plan_segment_id?: string | number

    policy_carrier?: string
    policy_name?: string
    policy_number?: string
    policy_premium?: number
    policy_start_date?: ISODate
    policy_termination_date?: ISODate
    policy_type?: PolicyTypes
    plan_agent?: string
  }
}

/**
 * @see https://docs.people.assurance.com/#definition-OpportunitySource
 */
export enum OpportunitySource {
  CLIENT_LIST = 'client_list',
  LEAD_GEN = 'lead_gen',
  PLATES = 'plates',
}

export type OpportunityRecord = {
  id: string
  lead_id: PersonRecord['lead_id']

  loi: LOI

  assigned_agent_id: number

  agent_assigned_sales_stage: SalesStage
  agent_assigned_opportunity_quality: Rating

  /**
   * @example 'OEP PY 2024'
   * @example 'IEP'
   * @example 'AEP PY 2023'
   */
  enrollment_period: string
  expiration_date?: ISODate

  products: Product[]

  person: PersonRecord[]

  all_follow_ups: AllFollowUpsRecord[]
  all_follow_ups_aggregate?: AggregateData

  updated_at: ISODateTimeNoTimezone
}

/**
 * @see https://docs.people.assurance.com/#definition-CreateOpportunityInput
 */
export type CreateOpportunityInput = Omit<
  OpportunityRecord,
  'id' | 'created_at' | 'updated_at' | 'person' | 'all_follow_ups' | 'all_follow_ups_aggregate'
> & {
  created_by_id: number
  delta_contact_id?: string
  source: OpportunitySource
}

type BaseFollowUpRecord = {
  id: string

  scheduled_time: string
  scheduled_timezone: ClientTimezone
  duration_minutes: number

  opportunity: Omit<OpportunityRecord, 'all_follow_ups'> // omit to avoid circular reference
}

export type AllFollowUpsRecord = BaseFollowUpRecord & {
  type: FollowUpType
  sub_type: FollowUpSubType
  send_sms_confirmation: boolean
  created_at: string
  updated_at: string

  appointment_notes: FollowUpNote[]
  reminder_notes: FollowUpNote[]
}

export type FollowUpAppointmentRecord = BaseFollowUpRecord & {
  appointment_type: FollowUpSubType
  notes: FollowUpNote[]
  send_sms_confirmation: boolean
}

export type FollowUpReminderRecord = BaseFollowUpRecord & {
  reminder_type: FollowUpSubType
  notes: FollowUpNote[]
}

/**
 * Common fields for creating both appointment and reminder follow-ups
 *
 * @see https://docs.people.assurance.com/#definition-CreateFollowUpAppointmentInput
 * @see https://docs.people.assurance.com/#definition-CreateFollowUpReminderInput
 */
export type CreateFollowUpInput =
  | Omit<
      FollowUpAppointmentRecord | FollowUpReminderRecord,
      'id' | 'created_at' | 'updated_at' | 'opportunity' | 'notes' | 'scheduled_timezone'
    > & {
      opportunity_id: OpportunityRecord['id']
      note: string
    }

export type CallStatRecord = {
  id: number

  duration: number
  started_at: ISODateTimeNoMillisecondsNoTimezone
  disposition_name: DispositionName

  person: PersonRecord | null
}

export type LeadTimezoneRecord = {
  id: PersonRecord['lead_id']
  timezone: ClientTimezone
}

export type DerivedLeadFieldsRecord = {
  id: PersonRecord['lead_id']

  /** “Do Not Call” status for the lead */
  dnc: boolean
}

export enum PolicyTypes {
  MED_ADV = 'MED_ADV',
  PDP = 'PDP', // Perscription Drug Plan
}
