import { FieldProps, Identifier, RaRecord, ResourceProps } from 'react-admin'

/**
 * A react-admin record that returns `unknown` instead of `any` for untyped
 * properties
 */
export interface TypedRaRecord<IdentifierType extends Identifier = Identifier> {
  id: RaRecord<IdentifierType>['id']

  [key: string]: unknown
}

/**
 * A react-admin resource name
 *
 * @see {@link https://marmelab.com/react-admin/Resource.html#name}
 */
export type ResourceName = ResourceProps['name']

export type ValidSource<RecordType extends TypedRaRecord> = NonNullable<
  FieldProps<RecordType>['source']
>

export type ResourceTranslations<RecordType extends TypedRaRecord> = {
  /**
   * @see https://marmelab.com/react-admin/TranslationTranslating.html#translating-resource-and-field-names
   */
  name?: string

  /**
   * @see https://marmelab.com/react-admin/TranslationTranslating.html#translating-resource-and-field-names
   */
  fields?: FieldTranslations<RecordType>

  /**
   * For displaying a pluralized count of this resource
   *
   * @example '%{smart_count} foo |||| %{smart_count} foos'
   * @see https://marmelab.com/react-admin/TranslationTranslating.html#interpolation-pluralization-and-default-translation
   */
  count?: string

  /**
   * Force the case when resource name appears in the middle of sentences
   * @see https://marmelab.com/react-admin/TranslationTranslating.html#forcing-the-case-in-confirm-messages-and-empty-page
   */
  forcedCaseName?: string
}

export type FieldTranslations<RecordType extends TypedRaRecord> = Partial<
  Record<ValidSource<RecordType>, string>
>

export type SourceDefinition<RecordType extends TypedRaRecord> = Record<
  string,
  ValidSource<RecordType>
>

export enum SortOrder {
  /** Ascending, e.g. A→Z, 0→9, Monday→Friday */
  ASC = 'ASC',
  /** Descending, e.g. Z→A, 9→0, Friday→Monday */
  DESC = 'DESC',
}
