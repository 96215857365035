import { Identifier } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { PersonFields } from 'lib/helpers/query-helpers'
import { SourceDefinition } from 'types/react-admin.types'
import { OpportunityRecord } from 'types/records.types'

// prettier-ignore
export const OPPORTUNITIES_SOURCE = {
  CHANCE_TO_CLOSE:   'agent_assigned_opportunity_quality',
  ENROLLMENT_PERIOD: 'enrollment_period',
  EXPIRATION_DATE:   'expiration_date',
  LOI:               'loi',
  PRODUCTS:          'products',
  SALES_STAGE:       'agent_assigned_sales_stage',
  UPDATED_AT:        'updated_at',

  PERSON:            'person[0]',
  PERSON_FIRST_NAME: 'person[0].fname',
  LEAD_ID:           'person[0].lead_id',

  FOLLOW_UPS_COUNT:  'all_follow_ups_aggregate.aggregate.count',
} as const satisfies SourceDefinition<OpportunityRecord>

export const GET_OPPORTUNITIES = gql`
  ${PersonFields}

  query opportunities(
    $distinct_on: [opportunities_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [opportunities_order_by!]
    $where: opportunities_bool_exp
  ) {
    items: opportunities(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      lead_id
      created_by_id
      assigned_agent_id
      delta_contact_id

      agent_assigned_sales_stage
      agent_assigned_opportunity_quality

      enrollment_period
      expiration_date
      loi
      products
      source

      updated_at
      created_at

      person {
        ...PersonFields
      }

      all_follow_ups(limit: 1, order_by: { scheduled_time: desc }) {
        id
        type
        sub_type
        scheduled_time

        appointment_notes(limit: 1, order_by: { updated_at: desc }) {
          id
          body
        }

        reminder_notes(limit: 1, order_by: { updated_at: desc }) {
          id
          body
        }
      }

      all_follow_ups_aggregate {
        aggregate {
          count
        }
      }
    }

    total: opportunities_aggregate(order_by: $order_by, where: $where) {
      aggregate {
        count
      }
    }
  }
`

export interface GetClientOpportunitiesParams {
  lead_id?: Identifier
  assigned_agent_id: Identifier
}

export const getOpportunitiesList =
  ({ assigned_agent_id, lead_id }: GetClientOpportunitiesParams): CustomQuery<FetchType.GET_LIST> =>
  async ({ client, clientOptions, parseResponse }) => {
    const response = await client.query({
      ...clientOptions,

      query: GET_OPPORTUNITIES,

      variables: {
        ...clientOptions?.variables,

        where: {
          ...clientOptions?.variables?.where,

          assigned_agent_id: { _eq: assigned_agent_id },
          ...(lead_id && { lead_id: { _eq: lead_id } }),
        },
      },
    })
    const results = parseResponse(response)
    return results
  }
