import { Identifier } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import {
  addPartialPagination,
  minCallStartedAtDateString,
  PersonFields,
} from 'lib/helpers/query-helpers'
import { FULL_NAME_FILTER } from 'lib/helpers/virtual-sources'
import { SourceDefinition } from 'types/react-admin.types'
import { CallStatRecord } from 'types/records.types'

// prettier-ignore
export const MISSED_CALLS_SOURCE = {
  DISPOSITION:       'disposition_name',
  LAST_CONTACT:      'started_at',

  PERSON:            'person',
  PERSON_FIRST_NAME: 'person.fname',
  PERSON_PHONE:      'person.contact_method.phone',
  PERSON_EMAIL:      'person.contact_method.email',
  PERSON_STATE:      'person.contact_method.state',
  PERSON_LEAD_ID:    'person.lead_id',
} as const satisfies SourceDefinition<CallStatRecord>

export const GET_MISSED_CALLS_LIST = gql`
  ${PersonFields}

  query call_stats(
    $limit: Int
    $offset: Int
    $order_by: [call_stats_order_by!]!
    $where: call_stats_bool_exp
  ) {
    items: call_stats(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id # ID required for react-admin/react-query caching
      lead_id
      duration
      started_at
      disposition_name

      person {
        ...PersonFields
      }
    }
  }
`

export const filterByDefaultOrFullName = (
  filter: Record<string, string>,
  where?: { _and: Array<object> },
) => {
  return {
    _and: [
      ...(filter?.[FULL_NAME_FILTER]
        ? [
            {
              person: {
                [FULL_NAME_FILTER]: {
                  _ilike: filter[FULL_NAME_FILTER].replaceAll(/^|\s|$/g, '%'),
                },
              },
            },
          ]
        : where?._and || []),
    ],
  }
}

export const getMissedCallsList =
  ({ user_id }: { user_id: Identifier }): CustomQuery<FetchType.GET_LIST> =>
  async ({ client, clientOptions, parseResponse, params: { filter } }) => {
    const response = await client.query({
      ...clientOptions,

      query: GET_MISSED_CALLS_LIST,

      variables: {
        ...clientOptions.variables,

        where: {
          // Default filters
          started_at: { _gte: minCallStartedAtDateString() },

          ...filterByDefaultOrFullName(filter, clientOptions.variables?.where),

          // Required filters
          user_id: { _eq: user_id },
          disposition_name: { _like: 'Missed%Inbound%' },

          lead_id: {
            ...clientOptions.variables?.where?.lead_id,
            _is_null: false, // Ensure lead_id is present (i.e. on Staging)
          },

          person: {
            ...clientOptions.variables?.where?.person,

            lead_id: {
              ...clientOptions.variables?.where?.person?.lead_id,
              _is_null: false, // Ensure person records are present (i.e. on Staging)
            },
          },
        },
      },
    })
    const results = parseResponse(response)
    const resultsWithPartialPagination = addPartialPagination<FetchType.GET_LIST>(
      results,
      clientOptions,
    )

    return resultsWithPartialPagination
  }
