import { List, ListProps, WrapperField } from 'react-admin'

import { Stack } from '@mui/material'

import {
  EnumField,
  ExpiredOpportunityField,
  LinkedPersonField,
  NullableNumberField,
  NullableTextField,
  RatingField,
} from 'components/fields'
import { Column, MuiDatagrid, MuiDatagridListActions } from 'components/MuiDatagrid'
import { useCurrentUser } from 'hooks'
import { LABEL } from 'i18n/resource-translations'
import { DEFAULT_COLUMN_WIDTH } from 'lib/helpers/column-width-values'
import { listDefaults } from 'lib/helpers/list-helpers'
import { RESOURCE_DESCRIPTION } from 'lib/helpers/resource-mapping'
import { DeleteOpportunityButton } from 'resources/opportunities/components/DeleteOpportunityButton'
import {
  LOI_DISPLAY_VALUES,
  PRODUCT_DISPLAY_VALUES,
  SALES_STAGE_DISPLAY_VALUES,
} from 'resources/opportunities/opportunity-constants'
import { PersonsShowDrawer } from 'resources/persons/components/PersonsShowDrawer'
import { SortOrder } from 'types/react-admin.types'
import { OpportunityRecord } from 'types/records.types'

import { getOpportunitiesList, OPPORTUNITIES_SOURCE } from './OpportunitiesList.queries'

/**
 * Use a virtual "source" when a column has no corresponding field in the API.
 * NOTE: CAN NOT BE SORTED/FILTERED
 */
const VIRTUAL_SOURCE = {
  ACTIONS: 'actions',
}

export const OpportunitiesList = (props: ListProps) => {
  const { currentUser } = useCurrentUser()
  const assigned_agent_id = currentUser.id

  return (
    <>
      <List
        {...listDefaults({ primaryList: true })}
        {...props}
        actions={<MuiDatagridListActions />}
        sort={{ field: OPPORTUNITIES_SOURCE.UPDATED_AT, order: SortOrder.DESC }}
        queryOptions={{
          meta: {
            assigned_agent_id, // Force re-fetch when ID changes
            query: getOpportunitiesList({ assigned_agent_id }),
          },
        }}
      >
        <MuiDatagrid
          bulkActionButtons={false}
          initialState={{
            pinnedColumns: {
              left: [OPPORTUNITIES_SOURCE.PERSON_FIRST_NAME],
              right: [VIRTUAL_SOURCE.ACTIONS],
            },
          }}
          omit={undefined} // TODO: Fix `omit` support; see VEGA-299
        >
          <Column width={DEFAULT_COLUMN_WIDTH.FULL_NAME} sortable={false}>
            <LinkedPersonField<OpportunityRecord>
              source={OPPORTUNITIES_SOURCE.PERSON}
              sortBy={OPPORTUNITIES_SOURCE.PERSON_FIRST_NAME}
            />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.LOI}>
            <EnumField<OpportunityRecord>
              source={OPPORTUNITIES_SOURCE.LOI}
              displayValues={LOI_DISPLAY_VALUES}
            />
          </Column>
          <Column width={100} sortable={false}>
            <NullableNumberField<OpportunityRecord>
              source={OPPORTUNITIES_SOURCE.FOLLOW_UPS_COUNT}
            />
          </Column>

          <Column
            width={DEFAULT_COLUMN_WIDTH.ENROLLMENT_PERIOD}
            description={RESOURCE_DESCRIPTION[OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD]}
            field={OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD}
          >
            <WrapperField label={LABEL.ENROLLMENT_PERIOD}>
              <Stack direction="row" spacing={1}>
                <NullableTextField<OpportunityRecord>
                  source={OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD}
                />
                <ExpiredOpportunityField<OpportunityRecord>
                  source={{
                    expiredDate: OPPORTUNITIES_SOURCE.EXPIRATION_DATE,
                    enrollmentPeriod: OPPORTUNITIES_SOURCE.ENROLLMENT_PERIOD,
                  }}
                  chipProps={{ size: 'small' }}
                />
              </Stack>
            </WrapperField>
          </Column>
          <Column width={150}>
            <EnumField<OpportunityRecord>
              source={OPPORTUNITIES_SOURCE.PRODUCTS}
              displayValues={PRODUCT_DISPLAY_VALUES}
            />
          </Column>
          <Column
            width={DEFAULT_COLUMN_WIDTH.SALES_STAGE}
            description={RESOURCE_DESCRIPTION[OPPORTUNITIES_SOURCE.SALES_STAGE]}
          >
            <EnumField<OpportunityRecord>
              source={OPPORTUNITIES_SOURCE.SALES_STAGE}
              displayValues={SALES_STAGE_DISPLAY_VALUES}
            />
          </Column>
          <Column width={DEFAULT_COLUMN_WIDTH.CHANCE_TO_CLOSE}>
            <RatingField<OpportunityRecord> source={OPPORTUNITIES_SOURCE.CHANCE_TO_CLOSE} />
          </Column>
          <Column
            width={130}
            field={VIRTUAL_SOURCE.ACTIONS}
            sortable={false}
            resizable={false}
            disableColumnMenu
          >
            <WrapperField label={LABEL.ACTIONS}>
              <Stack direction="row" spacing={0}>
                <DeleteOpportunityButton />
              </Stack>
            </WrapperField>
          </Column>
        </MuiDatagrid>
      </List>
      <PersonsShowDrawer {...props} />
    </>
  )
}
