import {
  ChoicesContextValue,
  ChoicesProps,
  CommonInputProps,
  FieldTitle,
  InputHelperText,
  Labeled,
  LinearProgress,
  sanitizeInputRestProps,
  useChoices,
  useChoicesContext,
  useInput,
} from 'react-admin'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { FormControlProps } from '@mui/material/FormControl'
import { RadioGroupProps } from '@mui/material/RadioGroup'
import { styled } from '@mui/material/styles'

import { clsx } from 'clsx'
import { get } from 'lodash-es'

/**
 * Copied react-admin's RadioButtonGroupInput with additional features (see "PATCHED" comments)
 * SEE: https://github.com/marmelab/react-admin/blob/23ecac7219c617c97a45ca07d786a37a3c8e58d7/packages/ra-ui-materialui/src/input/RadioButtonGroupInput.tsx
 */
export const PatchedRadioButtonGroupInput = (props: RadioButtonGroupInputProps) => {
  const {
    choices: choicesProp,
    className,
    format,
    helperText,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    label,
    margin = 'dense',
    onBlur,
    onChange,
    options = {},
    optionText = 'name',
    optionValue = 'id',
    parse,
    resource: resourceProp,
    row = true,
    source: sourceProp,
    translateChoice = true,
    validate,
    ...rest
  } = props

  const {
    allChoices,
    isLoading,
    error: fetchError,
    resource,
    source,
  } = useChoicesContext<RadioGroupChoice>({
    choices: choicesProp,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    resource: resourceProp,
    source: sourceProp,
  }) as ChoicesContextValue<RadioGroupChoice>

  if (source === undefined) {
    throw new Error(
      "If you're not wrapping the RadioButtonGroupInput inside a ReferenceArrayInput, you must provide the source prop",
    )
  }

  if (!isLoading && !fetchError && allChoices === undefined) {
    throw new Error(
      "If you're not wrapping the RadioButtonGroupInput inside a ReferenceArrayInput, you must provide the choices prop",
    )
  }

  const { id, isRequired, fieldState, field, formState } = useInput({
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest,
  })

  const { error, invalid, isTouched } = fieldState
  const { isSubmitted } = formState

  if (isLoading) {
    return (
      <Labeled
        htmlFor={id}
        label={label}
        source={source}
        resource={resource}
        className={clsx('ra-input', `ra-input-${source}`, className)}
        isRequired={isRequired}
      >
        <LinearProgress />
      </Labeled>
    )
  }
  return (
    <StyledFormControl
      component="fieldset"
      className={clsx('ra-input', `ra-input-${source}`, className)}
      margin={margin}
      error={fetchError || ((isTouched || isSubmitted) && invalid)}
      {...sanitizeRestProps(rest)}
    >
      <FormLabel component="legend" className={RadioButtonGroupInputClasses.label}>
        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      </FormLabel>

      <RadioGroup id={id} row={row} {...field} {...options} {...sanitizeRestProps(rest)}>
        {allChoices?.map((choice) => (
          <RadioButtonGroupInputItem
            key={get(choice, optionValue).toString()}
            choice={choice}
            optionText={optionText}
            optionValue={optionValue}
            source={source}
            translateChoice={translateChoice}
            // PATCHED: Add a class for styling the checked choice
            className={
              field.value === choice[optionValue] ? RadioButtonGroupInputClasses.checked : undefined
            }
          />
        ))}
      </RadioGroup>
      <FormHelperText>
        <InputHelperText
          touched={isTouched || isSubmitted || fetchError}
          error={error?.message || fetchError?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </StyledFormControl>
  )
}

interface RadioButtonGroupInputItemProps
  extends Pick<
    RadioButtonGroupInputProps,
    'optionText' | 'optionValue' | 'source' | 'translateChoice' | 'className'
  > {
  choice: RadioGroupChoice
}

/**
 * Copied react-admin's RadioButtonGroupInputItem with additional features (see "PATCHED" comments)
 * SEE: https://github.com/marmelab/react-admin/blob/23ecac7219c617c97a45ca07d786a37a3c8e58d7/packages/ra-ui-materialui/src/input/RadioButtonGroupInputItem.tsx
 */
const RadioButtonGroupInputItem = ({
  choice,
  optionText,
  optionValue,
  source,
  translateChoice,
  className,
}: RadioButtonGroupInputItemProps) => {
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  })
  const label = getChoiceText(choice)
  const value = getChoiceValue(choice)

  const nodeId = `${source}_${value}`

  return (
    <FormControlLabel
      className={className}
      label={label}
      htmlFor={nodeId}
      value={value}
      control={<Radio id={nodeId} color="primary" />}
      // PATCHED: Add disabling the certain choice
      disabled={choice.disabled}
    />
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
// copied from react-admin
const sanitizeRestProps = ({
  afterSubmit,
  allowNull,
  beforeSubmit,
  choices,
  className,
  crudGetMatching,
  crudGetOne,
  data,
  filter,
  filterToQuery,
  formatOnBlur,
  isEqual,
  limitChoicesToValue,
  multiple,
  name,
  pagination,
  perPage,
  ref,
  reference,
  refetch,
  render,
  setFilter,
  setPagination,
  setSort,
  sort,
  subscription,
  type,
  validateFields,
  validation,
  value,
  ...rest
}: any) => sanitizeInputRestProps(rest)
/* eslint-enable */

export type RadioGroupChoice = {
  id: number | string
  name?: string
  subtext?: string
  disabled?: boolean
} & { [key: string]: number | boolean | string }

export type RadioButtonGroupInputProps<C extends RadioGroupChoice = RadioGroupChoice> = Omit<
  CommonInputProps,
  'source'
> &
  Omit<ChoicesProps, 'choices'> &
  FormControlProps &
  RadioGroupProps & {
    options?: RadioGroupProps
    source?: string
    choices: Array<C>
  }

const PREFIX = 'RaRadioButtonGroupInput'

export const RadioButtonGroupInputClasses = {
  label: `${PREFIX}-label`,
  // PATCHED: Add the "checked" class
  checked: `${PREFIX}-checked`,
}

const StyledFormControl = styled(FormControl, {
  name: PREFIX,
  overridesResolver: (styles) => styles.root,
})(({ theme }) => ({
  [`& .${RadioButtonGroupInputClasses.label}`]: {
    transform: 'translate(0, 5px) scale(0.75)',
    transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
  },
}))
