/* eslint-disable @typescript-eslint/no-explicit-any */
import { Identifier } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'
import { minCallStartedAtDateString, PersonFields } from 'lib/helpers/query-helpers'
import { ENROLLMENT_CODE, FULL_NAME_FILTER } from 'lib/helpers/virtual-sources'
import { SourceDefinition } from 'types/react-admin.types'
import { ClientRecord } from 'types/records.types'

// prettier-ignore
export const CLIENTS_SOURCE = {
  LAST_CONTACT:                 'latest_call_started_at',
  LATEST_CALL_DISPOSITION_NAME: 'latest_call_disposition_name',
  LEAD_ID:                      'lead_id',

  PERSON:                       'person',
  PERSON_FIRST_NAME:            'person.fname',
  PERSON_LAST_NAME:             'person.lname',
  PERSON_BIRTH_DATE:            'person.birth_date',
  PERSON_GENDER:                'person.gender',
  PERSON_EMAIL:                 'person.contact_method.email',
  PERSON_PHONE:                 'person.contact_method.phone',
  PERSON_CITY:                  'person.contact_method.city',
  PERSON_STATE:                 'person.contact_method.state',
  PERSON_ZIP:                   'person.contact_method.zip',

  POLICY_RELATED_INFOS:         'person.policy_related_infos',
  ENROLLMENT_CODE:              'person.policy_related_infos[0].normalized_data.enrollment_code',

  ENROLLMENT_PERIOD:            'opportunities[0].enrollment_period',
  EXPIRATION_DATE:              'opportunities[0].expiration_date',
  LOI:                          'opportunities[0].loi',
  CHANCE_TO_CLOSE:              'opportunities[0].agent_assigned_opportunity_quality',

  NEXT_FOLLOW_UP_DATE:          'opportunities[0].all_follow_ups[0].scheduled_time',
} as const satisfies SourceDefinition<ClientRecord>

export const GET_CLIENTS_LIST = gql`
  ${PersonFields}

  query call_stats_grouped_by_leads_users(
    $agent_id: bigint
    $limit: Int
    $offset: Int
    $order_by: [call_stats_grouped_by_leads_users_order_by!]!
    $where: call_stats_grouped_by_leads_users_bool_exp
  ) {
    items: call_stats_grouped_by_leads_users(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id: lead_id # ID required for react-admin/react-query caching
      lead_id
      latest_call_started_at
      latest_call_disposition_name

      person {
        ...PersonFields
      }

      opportunities(
        limit: 1
        order_by: { updated_at: desc }
        where: { assigned_agent_id: { _eq: $agent_id } }
      ) {
        id
        lead_id
        created_by_id

        assigned_agent_id

        agent_assigned_opportunity_quality

        enrollment_period
        expiration_date
        loi

        all_follow_ups(limit: 1, order_by: { scheduled_time: desc }) {
          id
          scheduled_time
        }

        products
        source
      }
    }

    total: call_stats_grouped_by_leads_users_aggregate(order_by: $order_by, where: $where) {
      aggregate {
        count
      }
    }
  }
`

const policyRelatedInfosCustomFilter = (
  filter: Record<string, any>,
  filterKey: { id: string; key: string },
) => {
  return filter?.[filterKey.id]
    ? [
        {
          person: {
            policy_related_infos: {
              normalized_data: {
                _contains: {
                  [filterKey.key]: filter?.[filterKey.id],
                },
              },
            },
          },
        },
      ]
    : []
}

export const filterByDefaultOrFullName = (
  filter: Record<string, any>,
  where?: { _and: Array<object> },
) => {
  const fullNameFilter = filter?.[FULL_NAME_FILTER]
    ? [
        {
          person: {
            [FULL_NAME_FILTER]: {
              _ilike: filter[FULL_NAME_FILTER].replaceAll(/^|\s|$/g, '%'),
            },
          },
        },
      ]
    : []

  const enrollmentCodeFilter = policyRelatedInfosCustomFilter(filter, ENROLLMENT_CODE)

  const preSetFilters = [...fullNameFilter, ...enrollmentCodeFilter]

  return {
    _and: [...(preSetFilters.length > 0 ? preSetFilters : where?._and || [])],
  }
}

export const getClientsList =
  ({ user_id }: { user_id: Identifier }): CustomQuery<FetchType.GET_LIST> =>
  async ({ client, clientOptions, parseResponse, params: { filter } }) => {
    const response = await client.query({
      ...clientOptions,

      query: GET_CLIENTS_LIST,

      variables: {
        ...clientOptions.variables,

        agent_id: user_id,

        where: {
          // Default filters
          latest_call_started_at: { _gte: minCallStartedAtDateString() },

          ...filterByDefaultOrFullName(filter, clientOptions.variables?.where),

          // Required filters
          user_id: { _eq: user_id },

          lead_id: {
            ...clientOptions.variables?.where?.lead_id,
            _is_null: false, // Ensure lead_id is present (i.e. on Staging)
          },

          person: {
            ...clientOptions.variables?.where?.person,

            lead_id: {
              ...clientOptions.variables?.where?.person?.lead_id,
              _is_null: false, // Ensure person records are present (i.e. on Staging)
            },
          },
        },
      },
    })
    const results = parseResponse(response)
    return results
  }
