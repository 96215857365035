import { useCallback, useEffect, useRef } from 'react'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { useLocation } from 'react-router-dom'

import { CRM_URL, DD_SESSION_REPLAY_SAMPLE_RATE } from 'lib/environment-variables'
import { IframeMessage, MessageType } from 'lib/helpers/iframe-messaging'
import { CREATE_FROM_PLATES_PATH, VEGA_URL } from 'lib/helpers/path-helpers'

/**
 * Add a delay to stop recording after the modal closes (so we can capture any
 * success alert messages/UI)
 */
export const MODAL_CLOSE_RECORDING_DELAY = 5000

export const SessionRecorder = () => {
  const location = useLocation()
  const isCurrentLocationCreateFromPlates = location.pathname === CREATE_FROM_PLATES_PATH
  const isWaitingForAlertAnimations = useRef<boolean>(false)

  const { startSessionReplayRecording, stopSessionReplayRecording } = useDatadogRUM()

  const onMessage = useCallback(
    (event: MessageEvent<IframeMessage>) => {
      if (!isCurrentLocationCreateFromPlates) return

      switch (event.data.type) {
        case MessageType.MODAL_OPEN: {
          if (event.origin !== CRM_URL) break

          isWaitingForAlertAnimations.current = false
          startSessionReplayRecording()
          break
        }

        case MessageType.MODAL_CLOSED: {
          if (event.origin !== VEGA_URL) break

          isWaitingForAlertAnimations.current = true

          setTimeout(() => {
            if (!isWaitingForAlertAnimations.current) return

            isWaitingForAlertAnimations.current = false
            stopSessionReplayRecording()
          }, MODAL_CLOSE_RECORDING_DELAY)

          break
        }
      }
    },
    [isCurrentLocationCreateFromPlates, startSessionReplayRecording, stopSessionReplayRecording],
  )

  useEffect(() => {
    if (DD_SESSION_REPLAY_SAMPLE_RATE <= 0) return

    isCurrentLocationCreateFromPlates
      ? window.addEventListener('message', onMessage)
      : startSessionReplayRecording()

    return () => {
      window.removeEventListener('message', onMessage)
      stopSessionReplayRecording()
    }
  }, [
    isCurrentLocationCreateFromPlates,
    onMessage,
    startSessionReplayRecording,
    stopSessionReplayRecording,
  ])

  return null
}
