import { DeleteParams } from 'react-admin'

import { gql } from 'graphql-tag'

import { CustomQuery, FetchType } from 'hooks/useDataProvider'

// SEE: https://docs.people.assurance.com/#mutation-delete_opportunities
export const DELETE_OPPORTUNITIES = gql`
  mutation Delete_opportunities($id: uuid!) {
    delete_opportunities(id: $id)
  }
`

export const deleteOpportunities: CustomQuery<FetchType.DELETE> = async ({
  client,
  parseResponse,
  clientOptions,
  params,
}) => {
  const { id } = params as DeleteParams

  const variables = {
    id,
  }

  const response = await client.mutate({
    ...clientOptions,

    mutation: DELETE_OPPORTUNITIES,
    variables,
  })

  // TODO: Ask API team to change all delete endpoints to hasura standard
  return parseResponse({ data: { data: { returning: [response.data] } } })
}
