import { useNotify } from 'react-admin'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { displayFullDateTime } from 'lib/helpers/datetime-helpers'
import {
  AllFollowUpsRecord,
  FollowUpAppointmentRecord,
  FollowUpReminderRecord,
  FollowUpType,
} from 'types/records.types'

type AppointmentRecordDetails = Pick<
  FollowUpAppointmentRecord,
  'appointment_type' | 'scheduled_time'
>

type ReminderRecordDetails = Pick<FollowUpReminderRecord, 'reminder_type' | 'scheduled_time'>

type AnyFollowUpCreationResponse = Partial<AppointmentRecordDetails> &
  Partial<ReminderRecordDetails> & {
    follow_up_appointments?: AppointmentRecordDetails[]
    follow_up_reminders?: ReminderRecordDetails[]
  }

/**
 * Extracts follow-up details from any of the following follow-up creation mutations:
 * - {@link CREATE_FOLLOW_UP_WITH_OPPORTUNITY}
 * - {@link CREATE_FOLLOW_UP_APPOINTMENT}
 * - {@link CREATE_FOLLOW_UP_REMINDER}
 */
const getFollowUpDetailsFromCreationResponse = (
  response: AnyFollowUpCreationResponse,
): Pick<Partial<AllFollowUpsRecord>, 'scheduled_time' | 'type'> => {
  const followUp =
    response.follow_up_appointments?.[0] ?? response.follow_up_reminders?.[0] ?? response

  if (!followUp?.scheduled_time) return {}

  return {
    scheduled_time: followUp.scheduled_time,
    type: (followUp as AppointmentRecordDetails).appointment_type
      ? FollowUpType.APPOINTMENT
      : FollowUpType.REMINDER,
  }
}

/**
 * Hook which returns notification for successfully created Follow-up
 * @returns function to show notification
 */
export const useNotifyFollowUpCreated = () => {
  const notify = useNotify()
  const datadog = useDatadogRUM()

  return (response: AnyFollowUpCreationResponse) => {
    const { type, scheduled_time } = getFollowUpDetailsFromCreationResponse(response)

    if (!scheduled_time) datadog.addError('Unable to find follow-up data in response')

    const formattedTime = scheduled_time
      ? displayFullDateTime(scheduled_time)
      : 'the scheduled follow-up date'

    notify(`You have successfully created a Follow-up ${type ?? ''}`.trim(), {
      type: 'success',
      description: `You will receive a reminder email. Please ${
        type === FollowUpType.APPOINTMENT ? 'contact the client' : 'follow-up'
      } on ${formattedTime}`,
    })
  }
}
