import { formatISO } from 'date-fns'

export type DateArg = ConstructorParameters<typeof Date>[0]

export enum Month {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

/**
 * Matches ISO date strings without a timezone
 * @example
 *  '2021-01-01T00:00:00'           // => true
 *  '2021-01-01T00:00:00Z'          // => false
 *  '2021-01-01T00:00:00-05:00'     // => false
 *  '2021-01-01T00:00:00.000'       // => true
 *  '2021-01-01T00:00:00.000Z'      // => false
 *  '2021-01-01T00:00:00.000-05:00' // => false
 */
export const ISO_STRING_WITH_NO_TIMEZONE = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?$/

export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Takes in standard Date arguments and returns a Date object in UTC
 *
 * Ensures ISO date strings are parsed as UTC—the API returns ISO date strings
 * without the trailing 'Z' character, which causes the date to be parsed as
 * local time instead of UTC.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format:~:text=When%20the%20time,Reality%20Issue
 */
export const safeParseDate = (date: DateArg) => {
  const withTimezone =
    typeof date === 'string' && date.match(ISO_STRING_WITH_NO_TIMEZONE) ? `${date}Z` : date
  return new Date(withTimezone)
}

export const formatLocaleDateTime = (dateArg: DateArg, options?: Intl.DateTimeFormatOptions) => {
  const date = safeParseDate(dateArg)

  if (dateArg === null || isNaN(date.getTime())) return 'Invalid Date'

  return date.toLocaleString(navigator.language, options)
}

/**
 * Returns a string representation of the time in the current locale
 *
 * @example locale = 'en-US', local timezone = 'America/Los_Angeles'
 *   displayTime('2021-01-01T00:00:00.000Z')
 *   // => "12:00 AM"
 */
export const displayTime = (date: DateArg) =>
  formatLocaleDateTime(date, { hour: 'numeric', minute: 'numeric' })

/**
 * Returns a string representation of the time and timeZone in the current locale
 *
 * @param customTimeZone - If provided, the time will be displayed in this timezone
 * @example locale = 'en-US', local timezone = 'America/Los_Angeles'
 *   displayTimeAndTimezone('2021-01-01T00:00:00.000Z')
 *   // => "4:00 PM PST"
 *
 *   displayTimeAndTimezone('2021-01-01T00:00:00.000Z', 'Europe/Kiev')
 *   // => "2:00 AM GMT+2"
 */
export const displayTimeAndTimezone = (date: DateArg, customTimeZone?: string) =>
  formatLocaleDateTime(date, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: customTimeZone ?? LOCAL_TIMEZONE,
    timeZoneName: 'short',
  })

/**
 * Returns a string representation of the date and time in the current locale
 *
 * @example locale = 'en-US', local timezone = 'America/Los_Angeles'
 *   displayFullDateTime('2021-01-01T00:00:00.000Z')
 *   // => "Thursday, December 31, 2020 at 4:00 PM"
 */
export const displayFullDateTime = (date: DateArg) =>
  formatLocaleDateTime(date, { dateStyle: 'full', timeStyle: 'short' })

/**
 * Returns a string representation of the date and time with timezone in the current locale
 *
 * @param customTimeZone - If provided, the time will be displayed in this timezone
 * @example locale = 'en-US', local timezone = 'America/Los_Angeles'
 *   displayFullDateTimeAndTimezone('2021-01-01T00:00:00.000Z')
 *   // => "Thursday, December 31, 2020 at 4:00 PM PST"
 *
 *   displayFullDateTimeAndTimezone('2021-01-01T00:00:00.000Z', 'Europe/Kiev')
 *   // => "Friday, January 1, 2021 at 2:00 AM GMT+2"
 */
export const displayFullDateTimeAndTimezone = (date: DateArg, customTimeZone?: string) =>
  formatLocaleDateTime(date, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: customTimeZone ?? LOCAL_TIMEZONE,
    timeZoneName: 'short',
  })

/**
 * Returns the short name of the timezone
 *
 * If `date` is provided, the name will reflect daylight savings time if applicable.
 *
 * @example
 *   timezoneShortName('America/Los_Angeles') // => 'PST'
 *   timezoneShortName('America/Los_Angeles', '2021-06-01') // => 'PDT'
 *
 */
export const timezoneShortName = (
  timezone: string | undefined = undefined,
  date: DateArg = new Date(),
) => {
  const onDate = safeParseDate(date)
  const formatObject = new Intl.DateTimeFormat(navigator.language, {
    timeZoneName: 'short',
    timeZone: timezone,
  })

  return formatObject.formatToParts(onDate).find((part) => part.type === 'timeZoneName')?.value
}

/**
 * Returns a date in ISO format
 *
 * @example toISODateString('2021-01-01T00:00:00.000Z') // => '2021-01-01'
 */
export const toISODateString = (date: DateArg) =>
  formatISO(safeParseDate(date), { representation: 'date' })

export const TODAY_ISO_DATE = toISODateString(new Date())
export const FUTURE_ISO_DATE = '2050-01-01'
export const PAST_ISO_DATE = '2019-01-01'
