import { useEffect } from 'react'

import { useGetOne } from 'react-admin'

import { useDatadogRUM } from '@assuranceiq/react-components'

import { useFormContext } from 'react-hook-form'

import { FollowUpFormValues } from 'resources/all_follow_ups/components/FollowUpForm'
import { LeadTimezoneRecord } from 'types/records.types'

import { getLeadTimezone } from './AppointmentDateTime.queries'

export const useClientTimezone = () => {
  const datadog = useDatadogRUM()
  const { watch, setValue } = useFormContext<FollowUpFormValues>()

  const leadId = watch('person.lead_id')
  const clientTimezone = watch('scheduled_timezone')

  const {
    data: timezoneResult,
    error,
    isLoading,
  } = useGetOne<LeadTimezoneRecord>(
    'lead_tz',
    { id: leadId, meta: { query: getLeadTimezone } },
    { enabled: !clientTimezone },
  )

  useEffect(() => {
    if (isLoading || !timezoneResult) return

    setValue('scheduled_timezone', timezoneResult.timezone)
  }, [isLoading, setValue, timezoneResult])

  useEffect(() => {
    if (!error) return

    datadog.addError(error)
  }, [datadog, error])

  return { clientTimezone, isLoading }
}
