import { ReactNode } from 'react'

import { Box, BoxProps, Typography } from '@mui/material'

import { ReactComponent as EmptyAstronautGraphic } from 'images/empty-astronaut.svg'
import { ReactComponent as EmptyBoxGraphic } from 'images/empty-box.svg'

export enum NoResultsIconType {
  EmptyAstronaut = 'EmptyAstronaut',
  EmptyBox = 'EmptyBox',
}

const NoResultsIcon = {
  EmptyAstronaut: EmptyAstronautGraphic,
  EmptyBox: EmptyBoxGraphic,
}

export interface NoResultsProps {
  /**
   * The icon to display.
   *
   * @default NoResultsIconType.EmptyAstronaut
   */
  icon?: NoResultsIconType

  title?: ReactNode
  message?: ReactNode
  sx?: BoxProps['sx']
}

export const NoResults = ({
  icon = NoResultsIconType.EmptyAstronaut,
  title = 'No results',
  message = '',
  sx,
}: NoResultsProps) => {
  const Icon = NoResultsIcon[icon]

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
        width: '100%',
        ...sx,
      }}
    >
      <Box textAlign="center">
        <Icon />
        <Typography variant="large">{title}</Typography>
        <Typography variant="body">{message}</Typography>
      </Box>
    </Box>
  )
}
