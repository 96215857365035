// eslint-disable-next-line no-restricted-imports
import { FunctionField, NumberField, NumberFieldProps, RaRecord } from 'react-admin'

import { CurrencyNullValue } from 'components/values/CurrencyNullValue/CurrencyNullValue'
import { getData } from 'lib/helpers/get-data'
import { DEFAULT_CURRENCY_OPTIONS } from 'lib/helpers/number-helpers'
import { TypedRaRecord } from 'types/react-admin.types'

export interface CurrencyFieldProps<RecordType extends TypedRaRecord = RaRecord>
  extends NumberFieldProps<RecordType> {}

/**
 * Same as React Admin’s {@link NumberField}, but defaults to {@link CurrencyNullValue} for `emptyText`.
 *
 * @see https://marmelab.com/react-admin/NumberField.html
 */
export function CurrencyField<RecordType extends TypedRaRecord = RaRecord>({
  source,
}: CurrencyFieldProps<RecordType>) {
  return (
    <FunctionField
      render={(record: RecordType) => {
        const data = getData<string | null | undefined>(record, source)
        if (!data) return <CurrencyNullValue />

        return (
          <NumberField
            source={source}
            locales={navigator.language}
            options={DEFAULT_CURRENCY_OPTIONS}
          />
        )
      }}
    />
  )
}
