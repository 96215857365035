import { FieldProps, RaRecord } from 'react-admin'

import { get, has } from 'lodash-es'

import { TypedRaRecord } from 'types/react-admin.types'

/**
 * Safely fetch data from a record. If the data is not found, an error is logged
 * and a default value is returned.
 */
// NOTE: Using function overloads to provide more convenient type accuracy
// See: https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
export function getData<T = unknown, RecordType extends TypedRaRecord = RaRecord>(
  record: RecordType,
  source: FieldProps<RecordType>['source'],
  defaultValue: T,
): T
export function getData<T = unknown, RecordType extends TypedRaRecord = RaRecord>(
  record: RecordType,
  source: FieldProps<RecordType>['source'],
): T | undefined
export function getData<T = unknown, RecordType extends TypedRaRecord = RaRecord>(
  record: RecordType,
  source: FieldProps<RecordType>['source'],
  defaultValue?: T,
): T | undefined {
  if (!source) throw new Error('`source` is undefined. Did you forget to set a `source` prop?')

  const data = get(record, source) as T | undefined

  if (typeof data === 'undefined') {
    if (
      !source.includes('[') && // ignore array sources, which can return empty arrays
      !has(record, source)
    )
      console.error(`Could not find data for source "${source}" in record:`, record)

    return defaultValue as T
  }

  return data
}
