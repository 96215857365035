import { useEffect, useState } from 'react'

import { FilterPayload, useListContext } from 'react-admin'

import { DataGridProProps, GridFilterModel } from '@mui/x-data-grid-pro'

import { isEqual } from 'lodash-es'

/**
 * A mapping of react-admin filter operators to MUI filter operators
 */
const RA_TO_MUI_OPERATORS = new Map([
  // Boolean operators
  ['is', 'is'],
  ['not', 'not'],

  // Date operators
  ['after', 'after'],
  ['onOrAfter', 'onOrAfter'],
  ['before', 'before'],
  ['onOrBefore', 'onOrBefore'],

  // Numeric operators
  ['eq', '='],
  ['neq', '!='],
  ['gt', '>'],
  ['gte', '>='],
  ['lt', '<'],
  ['lte', '<='],

  // String operators
  ['contains', 'contains'],
  ['eq', 'equals'],
  ['sw', 'startsWith'],
  ['ew', 'endsWith'],
  ['isNull', 'isEmpty'],
  ['isNotNull', 'isNotEmpty'],
  ['anyOf', 'isAnyOf'],
])
const MUI_TO_RA_OPERATORS = new Map(Array.from(RA_TO_MUI_OPERATORS, ([ra, mui]) => [mui, ra]))

const FIELD_WITH_OPERATOR_REGEX = /^(?<field>.+)_(?<operator>\w+)$/

export const convertRaFiltersToMuiFilters = (filters: FilterPayload): GridFilterModel => ({
  items: Object.entries(filters).map(([fieldWithOperator, value]) => {
    const { field, operator } = fieldWithOperator.match(FIELD_WITH_OPERATOR_REGEX)?.groups ?? {
      field: fieldWithOperator,
      operator: 'eq',
    }

    return {
      columnField: field,
      operatorValue: RA_TO_MUI_OPERATORS.get(operator) ?? operator,
      value,
    }
  }),
})

export const convertMuiFiltersToRaFilters = (filterModel: GridFilterModel): FilterPayload =>
  filterModel.items.reduce((filters, { columnField, operatorValue, value }) => {
    if (!columnField || !operatorValue) return filters

    const raOperator = MUI_TO_RA_OPERATORS.get(operatorValue)
    const field = `${columnField}_${raOperator ?? operatorValue}`

    filters[field] = value

    return filters
  }, {} as FilterPayload)

/**
 * NOTE: WIP — This functionality has not been tested yet.
 * Copied from https://github.com/marmelab/ra-datagrid/blob/main/packages/ra-datagrid/src/Datagrid.tsx
 * TODO: Test this hook and update this comment block.
 */
export const useFilterModel = (): Pick<DataGridProProps, 'filterModel' | 'onFilterModelChange'> => {
  const { filterValues, setFilters } = useListContext()

  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    convertRaFiltersToMuiFilters(filterValues),
  )

  useEffect(() => {
    const newFilterModel = convertRaFiltersToMuiFilters(filterValues)

    if (isEqual(filterModel, newFilterModel)) return

    setFilterModel(newFilterModel)
  }, [filterValues, filterModel])

  const onFilterModelChange: DataGridProProps['onFilterModelChange'] = (model) => {
    setFilters(convertMuiFiltersToRaFilters(model), {})
  }

  return { filterModel, onFilterModelChange }
}
