import { useEffect, useState } from 'react'

import { CheckboxGroupInput } from 'react-admin'

import { Box, CardContent, CircularProgress, Collapse, Container, Typography } from '@mui/material'

import { useFormContext } from 'react-hook-form'

import { ConditionalTooltip } from 'components/ConditionalTooltip'
import { useIsOnDNT } from 'hooks'
import { appointmentTimeRange } from 'lib/helpers/appointment-helpers'
import { formatPhoneNumber } from 'lib/helpers/phone-helpers'
import { FollowUpFormValues } from 'resources/all_follow_ups/components/FollowUpForm'
import { SessionReplayPrivacy } from 'types/datadog.types'
import { AllFollowUpsRecord, PersonRecord } from 'types/records.types'

import sharedStyles from 'styles/Shared.module.scss'

import styles from './TextConfirmation.module.scss'

export const TextConfirmation = () => {
  const [cancelTransition, setCancelTransition] = useState(false)

  const {
    setValue,
    watch,
    formState: {
      dirtyFields: { scheduled_time: appointmentDateTimeIsDirty },
    },
  } = useFormContext<FollowUpFormValues>()

  const clientTimezone = watch('scheduled_timezone')
  const appointmentDateTime = watch('scheduled_time')
  const durationMinutes = watch('duration_minutes')
  const sendSMS = watch('send_sms_confirmation')
  const person = watch('person')
  const agentFname = watch('agent_fname')
  const isEditForm = !!watch('id')

  const { error, isOnDNT, isLoading: isDNTLoading } = useIsOnDNT(person)

  useEffect(
    function checkTextConfirmationOnDateChange() {
      if (appointmentDateTimeIsDirty && isEditForm) setValue('send_sms_confirmation', true)
    },
    [appointmentDateTimeIsDirty, setValue, isEditForm],
  )

  useEffect(
    function uncheckTextConfirmationInEditForm() {
      if (isEditForm) {
        setValue('send_sms_confirmation', false)
        setCancelTransition(true)
      }
    },
    [setValue, isEditForm],
  )

  useEffect(
    function uncheckTextConfirmationOnDNT() {
      if (isDNTLoading) return

      if (isOnDNT || error) {
        setValue('send_sms_confirmation', false)
        setCancelTransition(true)
      }
    },
    [isOnDNT, isDNTLoading, setValue, error],
  )

  const checkboxID = 'yes'
  const controlVisibility = isDNTLoading ? 'hidden' : 'visible'

  return (
    <Box className={styles.root} sx={{ mb: 1 }}>
      <ConditionalTooltip
        PopperProps={{ sx: { top: '36px' }, className: styles.tooltip }}
        enabled={isOnDNT || !!error}
        title={
          error
            ? 'Sorry, texting is not available at the moment. You can still create this Appointment.'
            : 'This phone number is on the Do Not Text List.'
        }
      >
        <CheckboxGroupInput
          source="send_sms_confirmation"
          label={false}
          choices={[
            {
              id: checkboxID,
              name: isEditForm
                ? 'Send reschedule text confirmation to client (triggered upon save)'
                : 'Send text confirmation (triggered upon creation)',
            },
          ]}
          format={(sendSMS: FollowUpFormValues['send_sms_confirmation']): string[] =>
            sendSMS ? [checkboxID] : []
          }
          parse={(choices: string[]): FollowUpFormValues['send_sms_confirmation'] =>
            choices.includes(checkboxID)
          }
          disabled={isDNTLoading || isOnDNT || !!error}
          sx={{ visibility: controlVisibility }}
        />
      </ConditionalTooltip>

      <Collapse
        in={sendSMS}
        sx={{ pl: 4, visibility: controlVisibility }}
        timeout={cancelTransition ? 0 : 'auto'}
        addEndListener={() => setCancelTransition(false)}
      >
        <TextPreview
          followUpRecord={{
            scheduled_time: appointmentDateTime,
            duration_minutes: durationMinutes,
            scheduled_timezone: clientTimezone,
            opportunity: { person: [person as PersonRecord] },
          }}
          agentFname={agentFname}
        />
      </Collapse>

      {isDNTLoading && (
        <Container className={styles.loading}>
          <CircularProgress color="inherit" size="1em" aria-describedby="checking-eligibility" />
          <Typography id="checking-eligibility" variant="small">
            Checking eligibility to text this phone number…
          </Typography>
        </Container>
      )}
    </Box>
  )
}

interface TextPreviewProps {
  followUpRecord: {
    duration_minutes: AllFollowUpsRecord['duration_minutes']
    scheduled_time: AllFollowUpsRecord['scheduled_time']
    scheduled_timezone?: AllFollowUpsRecord['scheduled_timezone']
    opportunity: { person: PersonRecord[] }
  }
  agentFname?: string
}

/**
 * Displays a preview of the text message we send to the consumer.
 *
 * IMPORTANT: Output MUST match the text message generated by Journey Composer, and metadata sent by person service
 * SEE: https://composer.assurance.com/campaigns/SMS/sms_pipeline_confirmation
 * SEE: https://github.com/assuranceiq/person-service/blob/406e9241421a61dbb57622c2c497b4aa99390d3a/vega_api/app/services/journey/appointment_sms_confirmation.rb#L26-L34
 *
 */
export const TextPreview = ({ followUpRecord, agentFname }: TextPreviewProps) => {
  const estimatedAppointmentDateAndTime = appointmentTimeRange({
    date: followUpRecord.scheduled_time,
    durationMinutes: followUpRecord.duration_minutes,
    timeZone: followUpRecord.scheduled_timezone,
    isCustomerEstimate: true, // Expands the time range we present, i.e. gives a rougher estimate.
  })

  return (
    <>
      <Typography variant="body" sx={{ mb: 1, color: 'text.secondary' }}>
        To:{' '}
        {followUpRecord.opportunity.person[0].contact_method.phone ? (
          <span data-dd-privacy={SessionReplayPrivacy.MASK}>
            {formatPhoneNumber(followUpRecord.opportunity.person[0].contact_method.phone)}
          </span>
        ) : (
          '(###) ###-####'
        )}
      </Typography>
      <CardContent className={sharedStyles.disabledInputImitation} sx={{ color: 'text.secondary' }}>
        <Typography variant="body" sx={{ mb: 0 }}>
          Hi{' '}
          <span data-dd-privacy={SessionReplayPrivacy.MASK}>
            {followUpRecord.opportunity.person[0].fname ?? '[client-first-name]'}
          </span>
          , {agentFname ?? '[agent-first-name]'} from Assurance has scheduled to call you on{' '}
          {estimatedAppointmentDateAndTime}.<br />
          Sent by Assurance IQ (Reply STOP to opt-out of texts)
        </Typography>
      </CardContent>
    </>
  )
}
